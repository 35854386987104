import React, { useState, useEffect, ChangeEvent } from 'react';
import { message, Button, Modal, Table, Row, Col, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'config';
import { JsObject, sendPostQuery, executeUpdate } from '@agentlab/sparql-jsld-client';
import YASQE from 'yasgui-yasqe';

require('yasgui-yasqe/dist/yasqe.css');

const columns: any[] = [];
let resheader = [];

interface QueryFormModalProps {
  visible?: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const QueryFormModal: React.FC<QueryFormModalProps> = ({ visible = true, onOk, onCancel }) => {
  const { t } = useTranslation();
  const handleCancel = (): void => {
    onCancel();
  };
  return (
    <div>
      <Modal
        width='70%'
        style={{ minWidth: '580px' }}
        title={t('QueryForm.title')}
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key='cancel' size='small' onClick={handleCancel}>
            {t('QueryForm.cancel')}
          </Button>,
        ]}>
        <QueryForm visible={visible} />
      </Modal>
    </div>
  );
};

interface QueryFormProps {
  visible?: boolean;
}

const QueryForm: React.FC<QueryFormProps> = ({ visible = true }) => {
  const [language, setLanguage] = useState<string>('sparql');
  const [typeOfQuery, setTypeOfQuery] = useState<string>('select');
  const [resultPerPage, setResultPerPage] = useState<number>(5);
  const [result, setResult] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');
  const [url, setUrl] = useState<string>(apiUrl);
  const [updateTable, setUpdateTable] = useState<boolean>(false);
  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState<string>('none');

  const handleLanguageChange = (e: string): void => {
    setLanguage(e);
  };

  const handleTypeOfQueryChange = (e: string): void => {
    setTypeOfQuery(e);
  };

  const handleResultsChange = (e: number): void => {
    setResultPerPage(e);
  };

  const handleQueryChange = (e: any): void => {
    setQuery(e.doc.getValue());
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setUrl(e.target.value);
  };

  const handleSelectQuery = async (): Promise<void> => {
    const queryParams = {
      queryLn: language,
      limit: '100',
      offset: result.length,
    };

    let resp;
    try {
      resp = await sendPostQuery(url, query.toString(), queryParams);
    } catch (err) {
      if (err.status === 400) {
        message.warning('Запрос составлен не верно');
      } else if (err.status > 400 && err.status < 500) {
        message.warning(`${err.statusText}`);
      } else {
        message.error(`Ошибка на сервере: ${err.toString().substring('Error: '.length)}`);
      }
      return;
    }

    try {
      let i = result.length;
      resheader = resp.data.head.vars;
      while (columns.length > 0) {
        columns.pop();
      }
      resheader.forEach((element: string) => {
        columns.push({
          title: element,
          dataIndex: element,
          key: element,
        });
      });
      const objs = resp.data.results.bindings.map((binding: JsObject) => {
        const b2: JsObject = { key: i++ };
        Object.keys(binding).forEach((key: string) => {
          b2[key] = binding[key].value;
        });
        return b2;
      });

      if (objs.length > 0) {
        setResult(result.concat(objs));
      } else {
        message.info('По данному запросу отсутствуют данные');
      }
    } catch (err) {
      message.error(`Ошибка обработки ответа: ${err.toString().substring('Error: '.length)}`);
    }
  };

  const handleUpdateQuery = async (): Promise<void> => {
    try {
      await executeUpdate(url + '/statements', query);
      message.success('Изменение успешно завершено');
    } catch (err) {
      if (err.status === 400) {
        message.warning('Запрос составлен неверно');
      } else if (err.status > 400 && err.status < 500) {
        message.warning(`${err.statusText}`);
      } else {
        message.error(`Ошибка на сервере: ${err.toString().substring('Error: '.length)}`);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleExecute = async (): Promise<void> => {
    if (query !== '') {
      if (typeOfQuery === 'select') {
        await handleSelectQuery();
      } else if (typeOfQuery === 'update') {
        await handleUpdateQuery();
      }
    } else {
      message.info('Пожалуйста заполните поле');
    }
  };

  const handleNewRequest = async (): Promise<void> => {
    setResult([]);
    setUpdateTable(true);
  };

  useEffect(() => {
    if (typeOfQuery !== 'select') {
      setResult([]);
    }
  }, [typeOfQuery]);

  useEffect(() => {
    if (visible) {
      // const doc = document.getElementById('yasqe');
      const yasqe = YASQE.fromTextArea(document.getElementById('yasqe'), {
        sparql: {
          showQueryButton: false,
          queryingDisabled: true,
        },
        requestConfig: {
          endpoint: '',
        },
      });
      yasqe.setValue('');
      yasqe.on('change', handleQueryChange);
      yasqe.on('query', handleNewRequest);
      yasqe.refresh();

      const yasqeContainer = document.querySelector('.yasqe');
      if (yasqeContainer) {
        yasqeContainer.classList.add('yasqe__one_line_mode');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (updateTable && result.length === 0) {
      handleExecute();
      setUpdateTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable]);

  useEffect(() => {
    const paginator = document.querySelector('.ant-pagination');
    const loadMoreButton = document.querySelector('.button__load_more');
    if (paginator && paginator.parentNode && loadMoreButton && loadMoreButtonVisible === 'none') {
      setLoadMoreButtonVisible('');
      paginator.parentNode.insertBefore(loadMoreButton, paginator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <>
      {visible ? (
        <>
          <Row style={{ marginTop: '1em' }}>
            <Col span={2} style={{ paddingRight: '1em' }}>
              <h4>SPARQL Endpoint</h4>
            </Col>
            <Col span={22}>
              <Input size='small' defaultValue={url} onChange={handleUrlChange} />
            </Col>
          </Row>
          <Row style={{ marginTop: '1em' }}>
            <Col span={2} style={{ paddingRight: '1em' }}>
              <h4>Query Language</h4>
            </Col>
            <Col span={10}>
              <Select
                size='small'
                showSearch
                style={{ width: 200 }}
                placeholder='Select a language'
                defaultValue='sparql'
                optionFilterProp=''
                onChange={handleLanguageChange}
                filterOption={(input, option): boolean =>
                  option
                    ? option.props.children
                      ? option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                    : false
                }>
                <Select.Option value='sparql'>SPARQL</Select.Option>
                <Select.Option value='serql'>SERQL</Select.Option>
              </Select>
            </Col>
            <Col span={2} style={{ paddingRight: '1em' }}>
              <h4>Query Type</h4>
            </Col>
            <Col span={10}>
              <Select
                size='small'
                showSearch
                style={{ width: 200 }}
                placeholder='Select a type of query'
                defaultValue='select'
                optionFilterProp=''
                onChange={handleTypeOfQueryChange}
                filterOption={(input, option): boolean =>
                  option
                    ? option.props.children
                      ? option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      : false
                    : false
                }>
                <Select.Option value='select'>Select</Select.Option>
                <Select.Option value='update'>Update</Select.Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: '1em' }}>
            <Col span={2} style={{ paddingRight: '1em' }}>
              <h4>Query</h4>
            </Col>
            <Col span={22}>
              <textarea id='yasqe' style={{ width: 400 }} />
            </Col>
          </Row>
          {typeOfQuery === 'select' ? (
            <Row style={{ marginTop: '1em' }}>
              <Col span={2} style={{ paddingRight: '1em' }}>
                <h4>Result per page</h4>
              </Col>
              <Col span={22}>
                <Select
                  size='small'
                  showSearch
                  defaultValue={resultPerPage}
                  style={{ width: 100 }}
                  optionFilterProp=''
                  onChange={handleResultsChange}
                  filterOption={(input, option): boolean =>
                    option
                      ? option.props.children
                        ? option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                      : false
                  }>
                  <Select.Option value={0}>All</Select.Option>
                  <Select.Option value={2}>2</Select.Option>
                  <Select.Option value={5}>5</Select.Option>
                  <Select.Option value={10}>10</Select.Option>
                  <Select.Option value={20}>20</Select.Option>
                </Select>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row style={{ marginTop: '1em' }}>
            <Button size='small' onClick={handleNewRequest}>
              Execute
            </Button>
          </Row>
          {typeOfQuery === 'select' ? (
            <>
              <Row>
                <Table
                  pagination={resultPerPage === 0 ? false : { pageSize: resultPerPage, position: ['topLeft'] }}
                  size='small'
                  dataSource={result}
                  columns={columns}
                  style={{ overflow: 'auto' }}
                />
              </Row>
              <Row>
                <Button
                  style={{ display: loadMoreButtonVisible, float: 'right', margin: '16px 0' }}
                  title='Load more'
                  className='button__load_more'
                  size='small'
                  onClick={handleExecute}>
                  ...
                </Button>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      ) : null}
    </>
  );
};
