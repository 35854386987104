import React, { useState, useEffect, useRef } from 'react';
import { Input, Empty, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { ElementsTree, Node } from 'components/reusable-components/ElementsTree/ElementsTree';

import { FilterByFolderContextMenu } from './FilterByFolderContextMenu';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface FilterByFolder {
  folders: JsObject[];
  selection: string;
  loading?: boolean;
  onSelect: (uri: string, folderName?: string) => void;
  onCreate?: (prop1: any, prop2: any) => any;
  onDelete?: (prop1: any, prop2: any) => void;
  onRename?: (prop1: any, prop2: any) => any;
  onMove?: (prop1: any, prop2: any, prop3: any) => void;
  // onAddFilter?: (prop1: any, prop2: string) => void;
  isDraggable?: boolean;
  autoExpandParent?: boolean;
  highlightColor?: string;
}

export const FilterByFolder: React.FC<FilterByFolder> = ({
  folders = [],
  selection,
  loading = false,
  onSelect = () => {},
  onCreate = () => {},
  onDelete = () => {},
  onRename = () => {},
  onMove = () => {},
  // onAddFilter = () => {},
  isDraggable = true,
  autoExpandParent = true,
  highlightColor = '#f50',
}) => {
  const { t } = useTranslation();
  const [treeData, setTreeData] = useState<Node[] | []>([]);
  const [defaultExpandedKeys, setDefaultExpandedFolderKeys] = useState<string[]>(['-0']);
  const [expandedFolderKeys, setExpandedFolderKeys] = useState<string[]>([]);
  const [defaultSelectedKey, setDefaultSelectedFolderKey] = useState<string>(selection);
  const [contextedFolder, setContextedFolder] = useState<JsObject>({});

  function loop(
    data: JsObject[],
    prefix: string | number,
    path: string[],
    parent: Node | null | JsObject[] = null,
  ): Node[] {
    return data.map((item: JsObject, index: number) => {
      if (item !== undefined) {
        if (item['@id'] === selection) {
          setDefaultExpandedFolderKeys(path.concat(`${prefix}-${index}`));
          setDefaultSelectedFolderKey(`${prefix}-${index}`);
        }
        if (item.children) {
          const node: any = {
            key: `${prefix}-${index}`,
            title: item.title,
            data: item,
            parent,
          };
          node.children = loop(item.children, `${prefix}-${index}`, path.concat([`${prefix}-${index}`]), node);
          return node;
        }
        return {
          key: `${prefix}-${index}`,
          title: item.title,
          data: item,
          parent,
        };
      }
    });
  }

  function findNodeByKey(treeNode: Node[], key: string) {
    let stackOfNode: Node[] = [...treeNode];
    let curNode: Node | undefined;
    while (stackOfNode.length > 0) {
      curNode = stackOfNode.pop();
      if (curNode !== undefined) {
        if (curNode.key === key) {
          return curNode;
        }
        if (curNode.children) {
          stackOfNode = [...stackOfNode, ...curNode.children];
        }
      }
    }
    return treeNode[0];
  }

  const onNodeSelect = (selectedKeys: string[], e: JsObject) => {
    if (!e.node.props.dataRef.edit) {
      onSelect(e.node.props.dataRef.data['@id'], e.node.props.dataRef.title);
      return true;
    }
    return false;
  };

  const onNodeExpand = (expandedKeys: string[], { expanded, node }: { expanded: boolean; node: JsObject }) => {
    if (!expanded) {
      setExpandedFolderKeys(expandedFolderKeys.filter((item) => item !== node.props.dataRef.key));
    } else {
      setExpandedFolderKeys(
        expandedFolderKeys.concat([node.props.dataRef.key]).filter((v, i, a) => a.indexOf(v) === i),
      );
    }
  };

  useEffect(() => {
    if (folders && folders.length > 0) {
      setTreeData(loop(folders, '', [], folders));
      setDefaultExpandedFolderKeys(
        defaultExpandedKeys.concat(expandedFolderKeys).filter((v, i, a) => a.indexOf(v) === i),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (selection === '') {
      setDefaultSelectedFolderKey('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, selection]);

  useEffect(() => {
    const foundNode = findNodeByKey(treeData, defaultSelectedKey);
    if (foundNode && defaultSelectedKey === '-0') {
      onSelect(foundNode.data['@id'], foundNode.data.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedKey]);

  const createTitleRef = useRef<Input>(null);
  const handleCreate = () => {
    if (!contextedFolder.props.dataRef.children) {
      contextedFolder.props.dataRef.children = [];
    }
    contextedFolder.props.dataRef.children.push({
      title: (
        <Input
          id='create-input'
          style={{ height: 24 }}
          ref={createTitleRef}
          defaultValue={contextedFolder.props.dataRef.title}
        />
      ),
      key: `${contextedFolder.props.dataRef.key}-${contextedFolder.props.dataRef.children.length}`,
      edit: true,
    });
    const savedFolder = contextedFolder;
    const handleClickOutside = (e: JsObject) => {
      if (e.target.id != 'create-input' && createTitleRef.current !== null) {
        onCreate(savedFolder.props.dataRef.data, createTitleRef.current.input.value).then(() => {
          savedFolder.props.dataRef.edit = false;
        });
        document.removeEventListener('click', handleClickOutside, true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    setTreeData([...treeData]);
    setExpandedFolderKeys(
      [contextedFolder.props.dataRef.key].concat(expandedFolderKeys).filter((v, i, a) => a.indexOf(v) === i),
    );
    setDefaultExpandedFolderKeys(
      defaultExpandedKeys
        .concat(expandedFolderKeys)
        .concat([contextedFolder.props.dataRef.key])
        .filter((v, i, a) => a.indexOf(v) === i),
    );
  };

  const handleDelete = () => {
    const parentFolder = contextedFolder.props.dataRef.data.parent;
    const deleteFolderUri = contextedFolder.props.dataRef.data['@id'];
    onDelete(contextedFolder.props.dataRef.data, contextedFolder.props.dataRef.parent.data);
  };

  const renameTitleRef = useRef<Input>(null);

  const handleRename = () => {
    const oldTitle = contextedFolder.props.dataRef.title.slice();

    contextedFolder.props.dataRef.title = (
      <Input id='rename-input' style={{ height: 24 }} ref={renameTitleRef} defaultValue={oldTitle} />
    );

    contextedFolder.props.dataRef.edit = true;
    const savedFolder = contextedFolder;

    let doRename = () => {};

    const handleClickOutside = (e: JsObject) => {
      if (e.target.id != 'rename-input') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        doRename();
      }
    };

    const handleKeyPress = (e: JsObject) => {
      // eslint-disable-next-line no-use-before-define
      if (e.key == 'Escape' || e.key == 'Enter') doRename();
    };

    doRename = () => {
      if (renameTitleRef.current != null) {
        onRename(savedFolder.props.dataRef.data, renameTitleRef.current.input.value).then(() => {
          savedFolder.props.dataRef.edit = false;
        });
      }
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeyPress, false);
    };

    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleKeyPress, false);
    setTreeData([...treeData]);
  };

  const handleMove = (targetNode: JsObject, dragNode: JsObject) => {
    const targetFolder = targetNode.props.dataRef.data;
    const movedFolder = dragNode.props.dataRef.data;
    const srcFolder = dragNode.props.dataRef.parent.data;
    onMove(movedFolder, srcFolder, targetFolder);
  };

  return (
    <Spin spinning={loading}>
      {!folders || folders.length === 0 ? (
        <Empty />
      ) : (
        <div>
          <ElementsTree
            elementsTreeData={treeData}
            onNodeSelect={onNodeSelect}
            onNodeExpand={onNodeExpand}
            defaultExpandedKeys={defaultExpandedKeys}
            defaultSelectedKey={defaultSelectedKey}
            onRightClick={({ event, node }: any) => {
              setContextedFolder(node);
            }}
            draggable={isDraggable}
            onNodeDrop={handleMove}
            contextMenu={
              <FilterByFolderContextMenu onCreate={handleCreate} onDelete={handleDelete} onRename={handleRename} />
            }
          />
        </div>
      )}
    </Spin>
  );
};
