import React from 'react';

import { ProjectToolbar } from 'components/main-toolbar/ProjectToolbar';
import { TableWithFilterView } from 'views/artifacts/table-with-filter/TableWithFilterView';

export const ProjectView: React.FC<{}> = () => {
  return (
    <div>
      <ProjectToolbar />
      <TableWithFilterView />
    </div>
  );
};
