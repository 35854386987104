import React from 'react';
import { Divider } from 'antd';

interface ArtifactToolbarSmall {
  id: string;
  title: string;
}

export const ArtifactToolbarSmall: React.FC<ArtifactToolbarSmall> = ({ id, title }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '12px', height: '52px' }}>
        <div style={{ flex: '1 0 auto' }}>
          <h2 style={{ fontSize: 18, marginBottom: 0 }}>
            <strong style={{ paddingRight: 10 }}>{id}</strong>
            <span>{title}</span>
          </h2>
        </div>
      </div>
      <Divider style={{ margin: 0, background: 'rgb(220, 220, 220)' }} />
    </div>
  );
};
