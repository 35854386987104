import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { FilterType } from '../../../hooks/useFilters';

interface NavTabs {
  title: string;
  handleChange?: (activeKey: string) => void;
}

export const NavTabs: React.FC<NavTabs> = ({ title = null, handleChange = () => {} }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs size='small' onChange={handleChange}>
        <Tabs.TabPane tab={t('ProjectToolbar.tabs.all')} key='all' />
        <Tabs.TabPane tab={t('ProjectToolbar.tabs.requirements')} key='requirements' />
        <Tabs.TabPane tab={t('ProjectToolbar.tabs.modules')} key='modules' />
        <Tabs.TabPane tab={t('ProjectToolbar.tabs.collections')} key='collections' />
      </Tabs>
    </div>
  );
};
