import React, { useState, useEffect } from 'react';
import { Table, Button, Col, Modal } from 'antd';
import { CheckOutlined, SwapRightOutlined, SwapLeftOutlined } from '@ant-design/icons';

import SplitPane from 'react-split-pane';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import JsonTable from './JsonSchemaTable';
import { JsObject } from '@agentlab/sparql-jsld-client';

const divStyle: React.CSSProperties = {
  position: 'relative',
};

const buttonStyle1: React.CSSProperties = {
  overflow: 'hidden',
  width: '90%',
  margin: '10px 0 0 5%',
};

const tableSchema = {
  properties: {
    title: {
      title: 'Колонка',
      width: 100,
    },
    width: {
      title: 'Ширина',
    },
    filter: {
      title: 'Фильтр',
    },
  },
};

const uiSchemaCol = {
  'ui:columnMenu': false,
  'ui:draggable': true,
  width: {
    'ui:editable': true,
  },
};

const MenuItem: React.FC<any> = ({ children, onClick, colState }) => {
  const [pickOn, setPickOn] = useState(colState);

  useEffect(() => {
    setPickOn(colState);
  }, [colState]);

  return (
    <div
      onClick={(e) => {
        setPickOn(!pickOn);
        onClick(!pickOn);
      }}>
      {pickOn ? <CheckOutlined /> : <div style={{ display: 'inline-block', width: '14px' }} />}
      {children}
    </div>
  );
};

export const useTestMenu = (parsedSchema: any, onChange: any) => {
  const [schema, setSchema] = useState(parsedSchema);
  const [invisibleColumns, setInvisibleColumns] = useState<JsObject[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<JsObject[]>([]);
  const { t } = useTranslation();

  const setChanges = (col: JsObject, dinamic: boolean, add: boolean) => {
    const newData = add
      ? visibleColumns.concat(col)
      : visibleColumns.filter((value: JsObject) => col.findIndex((val: JsObject) => val.key == value.key) === -1);
    const newInvisibleColumns = add
      ? invisibleColumns.filter((value: JsObject) => col.findIndex((val: JsObject) => val.key == value.key) === -1)
      : invisibleColumns.concat(col);
    const newSchema = { ...parsedSchema };
    col.forEach((value: JsObject) => {
      newSchema[value.key].disabled = !add;
    });
    setInvisibleColumns(newInvisibleColumns);
    setVisibleColumns(newData);
    dinamic && onChange({ id: col[0].key, width: 'auto' }, add);
  };

  const createMenuItems = () => {
    const newData = _.reduce(
      schema,
      (res: any[], value, key) => {
        res.push({
          key,
          text: (
            <MenuItem
              onClick={(pickOn: boolean) => setChanges([parsedSchema[key]], true, pickOn)}
              colState={!value.disabled}
              itemKey={key}>
              {value.title}
            </MenuItem>
          ),
        });
        return res;
      },
      [],
    );
    newData.push({
      key: 'additionally',
      text: (
        <Additionally
          parsedSchema={parsedSchema}
          onOk={(v: JsObject[], inv: JsObject[]) => {
            onChange(v);
            setVisibleColumns(v);
            setInvisibleColumns(inv);
          }}
          setSchema={setSchema}
          visibleColumns={visibleColumns}
          invisibleColumns={invisibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
      ),
    });
    return newData;
  };

  useEffect(() => {
    const visibleCol: JsObject[] = [];
    const invisibleCol: JsObject[] = [];
    _.forOwn(parsedSchema, (value: any, key: string) => {
      value.disabled ? invisibleCol.push(value) : visibleCol.push(value);
    });
    setInvisibleColumns(invisibleCol);
    setVisibleColumns(visibleCol);
    setSchema(parsedSchema);
  }, [parsedSchema]);

  return { menu: createMenuItems() };
};

const Additionally = ({ onOk, setSchema, parsedSchema, visibleColumns, invisibleColumns, setVisibleColumns }: any) => {
  const [vColumns, setVColumns] = useState(visibleColumns);
  const [invColumns, setInvColumns] = useState(invisibleColumns);
  const [SettingsModalVisible, setSettingsModalVisible] = useState(false);
  const [invisibleSelects, setInvisibleSelects] = useState<(string | number)[]>([]);
  const [visibleSelects, setVisibleSelects] = useState<(string | number)[]>([]);
  const [invisibleSelected, setInvisibleSelected] = useState<JsObject[]>([]);
  const [visibleSelected, setVisibleSelected] = useState<JsObject[]>([]);
  const { t } = useTranslation();

  const column = (t: any) => {
    return [
      {
        title: 'Не отображаемые колонки',
        dataIndex: 'title',
        key: 'title',
        width: 200,
      },
    ];
  };

  const setChanges = (col: JsObject, add: boolean) => {
    const newData = add ? vColumns.concat(col) : vColumns.filter((value: JsObject) => col.indexOf(value) == -1);
    const newInvisibleColumns = add
      ? invColumns.filter((value: JsObject) => col.indexOf(value) == -1)
      : invColumns.concat(col);
    const newSchema = { ...parsedSchema };
    col.forEach((value: JsObject) => {
      newSchema[value.key].disabled = !add;
    });
    setSchema(newSchema);
    setInvColumns(newInvisibleColumns);
    setVColumns(newData);
  };

  const invisibleSelection = {
    selectedRowKeys: invisibleSelects,
    onChange: (selects: (string | number)[], selectedCol: JsObject[]) => {
      setInvisibleSelects(selects);
      setInvisibleSelected(selectedCol);
    },
  };

  const visibleSelection = {
    selectedRowKeys: visibleSelects,
    onChange: (selects: any, selectedCol: JsObject[]) => {
      setVisibleSelects(selects);
      setVisibleSelected(selectedCol);
    },
  };
  useEffect(() => {
    setVColumns(visibleColumns);
    setInvColumns(invisibleColumns);
  }, [visibleColumns, invisibleColumns]);

  return (
    <div
      onClick={(e) => {
        setSettingsModalVisible(true);
      }}>
      <span>Дополнительно</span>
      <Modal
        visible={SettingsModalVisible}
        title='Настройки колонок'
        onCancel={(e) => {
          e.stopPropagation();
          setSettingsModalVisible(false);
          setVColumns(visibleColumns);
          setInvColumns(invisibleColumns);
        }}
        onOk={(e) => {
          e.stopPropagation();
          onOk(vColumns, invColumns);
          setSettingsModalVisible(false);
        }}
        width={'50%'}
        cancelText='Отмена'
        okText='Oк'>
        <SplitPane split='vertical' defaultSize='30%' style={divStyle}>
          <div style={divStyle}>
            <Table
              size='small'
              className='gx-table-responsive'
              pagination={false}
              columns={column(t)}
              rowSelection={invisibleSelection}
              dataSource={invColumns}
              rowKey={(record: JsObject) => record.key}
            />
          </div>
          <div style={divStyle}>
            <Col span={6} style={{ paddingTop: '50px' }}>
              <Button
                icon={<SwapLeftOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  setChanges(visibleSelected, false);
                  setVisibleSelects([]);
                }}
                style={buttonStyle1}
                disabled={visibleSelects.length == 0}>
                Убрать
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setChanges(invisibleSelected, true);
                  setInvisibleSelects([]);
                }}
                style={buttonStyle1}
                disabled={invisibleSelects.length == 0}>
                Добавить
                <SwapRightOutlined />
              </Button>
            </Col>
            <Col span={18} onClick={(e) => e.stopPropagation()}>
              <JsonTable
                size='small'
                schema={tableSchema}
                uiSchema={uiSchemaCol}
                rowKey={(record: any, index: number) => record.key}
                dataSource={vColumns}
                onChangeData={setVColumns}
                onSwap={setVColumns}
                rowSelection={visibleSelection}
              />
            </Col>
          </div>
        </SplitPane>
      </Modal>
    </div>
  );
};
