import React from 'react';
import { Sprite } from './Sprite';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface SpriteSheet {
  filename: string;
  data: JsObject;
  sprite: string | number;
}

export const SpriteSheet: React.FC<SpriteSheet> = ({ filename, data, sprite }: SpriteSheet) => {
  if (!filename || !data || !sprite) {
    return null;
  }

  const currentSprite = data[sprite];

  const spriteData = { ...currentSprite, filename };

  return <Sprite {...spriteData} />;
};
