import React, { PropsWithChildren, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomizeLinkTypeDialogContext } from 'contexts/CustomizeLinkTypeDialogContext';
import { ColumnProps } from 'antd/lib/table';
import { UiSchema } from 'react-jsonschema-form';

import SplitPane from 'react-split-pane';

import { Modal, Table, Button, Row, Col, Form as FormRow, Input } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';
import { CustomForm } from '../custom-form/CustomForm';
import { SaveReqDialog } from '../saveReqDialog/SaveReqDialog';
import { GlobalContext } from 'contexts/GlobalContext';
import { DataTypeInfo } from '../../hooks/useDataTypes';
import { JsObject } from '@agentlab/sparql-jsld-client';

const view = {
  classes: [
    /*{
      '@id: 'rmUserTypes:EnumeratedDatatype',
    },*/
    {
      '@id': 'rdfs:Datatype',
    },
  ],
};

const EditableContext = React.createContext<any>({});

const log = (type: any) => console.log.bind(console, type);
const ButtonGroup = Button.Group;

type data = string | number;

type ValueType = {
  id: string;
  key: data;
  value: data;
  label: data;
  rdfUri: data;
};

type DataType = {
  title: data;
  baseDataType: data;
  key: string;
  description: string;
  kindOfValue: string;
};

interface ColumnPropsX<T> extends ColumnProps<T> {
  editable: boolean;
}

interface EditableRow {
  form: JsObject;
  index: any;
  props: JsObject;
}

interface EditableCell {
  [key: string]: any;
}

interface EditableTable<T> {
  columns: ColumnPropsX<T>[];
  dataSource: T[];
  onChange: (e: T[]) => void;
  visible: boolean;
}
const valuesColumns: ColumnPropsX<ValueType>[] = [
  {
    title: 'Зачение',
    dataIndex: 'value',
    key: 'value',
    editable: true,
    width: '30%',
  },
  {
    title: 'Метка',
    dataIndex: 'label',
    key: 'label',
    editable: true,
    width: '30%',
  },
  {
    title: 'RDF URI',
    dataIndex: 'rdfUri',
    key: 'rdfUri',
    editable: true,
    width: '30%',
  },
];

const addValuesSchema: JsObject = {
  type: 'object',
  properties: {
    values: { type: 'string', title: 'Метки' },
  },
};

const addValuesUiSchema: UiSchema = {
  values: {
    'ui:widget': 'textarea',
    'ui:options': {
      label: false,
    },
  },
};

const dataTypesColumns: ColumnProps<DataType>[] = [
  {
    title: 'Имя',
    dataIndex: 'title',
    key: 'title',
    render: (text) => <a href='#!'>{text}</a>,
  },
  {
    title: 'Базовый тип данных',
    dataIndex: 'baseDataType',
    key: 'baseDataType',
  },
];

const divStyle: React.CSSProperties = {
  padding: '5px',
};

const divStyle2: React.CSSProperties = {
  position: 'relative',
};

const EditableRow: React.FC<PropsWithChildren<EditableRow>> = ({
  form,
  index,
  ...props
}: PropsWithChildren<EditableRow>) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = EditableRow;

const EditableCell: React.FC<EditableCell> = (props: EditableCell) => {
  const [editing, setEditing] = useState(false);
  const [form, setForm] = useState<JsObject | undefined>(undefined);
  const { editable, dataIndex, title, record, children, index, handleSave, ...restProps } = props;

  function toggleEdit() {
    const editingNow = !editing;
    setEditing(editingNow);
  }

  function save() {
    if (form && form.getFieldDecorator) {
      form.validateFields((error: JsObject, values: JsObject) => {
        toggleEdit();
        handleSave({ ...record, ...values });
      });
    }
  }

  function renderCell(newForm: JsObject) {
    setForm(newForm);
    return form && form.getFieldDecorator && editing ? (
      <FormRow.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          initialValue: record[dataIndex],
        })(<Input ref={(e: any) => e.focus()} onPressEnter={save} onBlur={save} />)}
      </FormRow.Item>
    ) : (
      <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }
  return (
    <td {...restProps}>{editable ? <EditableContext.Consumer>{renderCell}</EditableContext.Consumer> : children}</td>
  );
};

const EditableTable: React.FC<EditableTable<ValueType>> = (props: EditableTable<ValueType>) => {
  const { columns, dataSource, onChange, visible = false } = props;

  const [currentData, setCurrentData] = useState<ValueType[]>([]);
  const [selectedValues, setSelectedValues] = useState<ValueType[]>([]);
  const [selects, setSelects] = useState<(number | string)[]>([]);
  const [deleteBtnState, setDeleteBtnState] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [addedValues, setAddedValues] = useState<JsObject>({});

  function deleteAttributes() {
    const newData = currentData.filter((e: ValueType) => selectedValues.indexOf(e) === -1);
    setCurrentData(newData);
    onChange(newData);
    setSelectedValues([]);
    setSelects([]);
    setDeleteBtnState(true);
  }

  function addValues() {
    const newData = [...currentData];
    const addData = addedValues.values.split('\n');
    let newCount = count;
    addData.forEach((e: data) => {
      newData.push({
        id: '' + newCount,
        key: newCount,
        value: newCount,
        label: e,
        rdfUri: '',
      });
      newCount++;
    });
    onChange(newData);
    setCurrentData(newData);
    setDialogVisible(false);
    setCount(newCount);
  }

  function addNewValue() {
    const newData = [...currentData];
    newData.push({
      id: '' + count,
      key: count,
      value: count,
      label: 'NewValue' + count,
      rdfUri: '',
    });
    setCurrentData(newData);
    onChange(newData);
    setCount(count + 1);
  }

  function saveValue(row: ValueType) {
    const newData = [...currentData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    onChange(newData);
    setCurrentData(newData);
  }

  const rowSelection = {
    selectedRowKeys: selects,
    onChange: (selects: (number | string)[], selectedValues: ValueType[]) => {
      setSelectedValues(selectedValues);
      setDeleteBtnState(!(selects.length > 0));
      setSelects(selects);
    },
  };

  useEffect(() => {
    setCurrentData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    setCount(dataSource.length + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return visible ? (
    <div>
      <Row>
        <Col span={18} push={6}>
          <Table
            className='gx-table-responsive'
            size='small'
            bordered
            pagination={false}
            rowSelection={rowSelection}
            /*components={{
              body: {
                row: EditableFormRow,
                cell: EditableCell,
              },
            }}*/
            rowKey={(record) => record.id}
            /*columns={columns.map((col) => {
              return {
                ...col,
                onCell: (record: DataType): React.HTMLAttributes<HTMLElement> => ({
                  record,
                  editable: col.editable,
                  dataIndex: col.dataIndex,
                  title: col.title as string,
                  handleSave: saveValue,
                }),
              };
            })}*/
            dataSource={currentData}
            title={() => (
              <Row>
                <Col span={6}>
                  <b>Значения</b>
                </Col>
                <Col span={18}>
                  <ButtonGroup style={{ float: 'right' }}>
                    <Button
                      id='deleteDataValues'
                      size='small'
                      disabled={deleteBtnState}
                      onClick={() => deleteAttributes()}>
                      Удалить
                    </Button>
                    <Button id='addNewDataValue' size='small' onClick={() => addNewValue()}>
                      <PlusCircleTwoTone twoToneColor='#52c41a' />
                      Добавить
                    </Button>
                    <Button id='addNewDataValue' size='small' onClick={() => setDialogVisible(true)}>
                      <PlusCircleTwoTone twoToneColor='#52c41a' />
                      Добавить несколько
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            )}
          />
        </Col>
        <Col span={6} pull={18} style={divStyle}>
          <span style={{ float: 'right' }}>Значения:</span>
        </Col>
      </Row>
      <Modal
        title='Добавление новых значений'
        visible={dialogVisible}
        onOk={() => addValues()}
        onCancel={() => setDialogVisible(false)}
        cancelText='Отмена'
        okText='Добавить'>
        <CustomForm
          schema={addValuesSchema}
          uiSchema={addValuesUiSchema}
          liveValidate={true}
          formData={addedValues}
          onChange={(e: JsObject) => {
            setAddedValues(e.formData);
          }}
          onSubmit={log('submit')}
          onError={log('errors')}>
          <div />
        </CustomForm>
      </Modal>
    </div>
  ) : null;
};

const testData = [
  {
    key: 'q',
    title: 'test1',
    description: 'description...',
    kindOfValue: 'Простой',
    baseDataType: 'string',
  },
  {
    key: 'w',
    title: 'test2',
    description: 'description...',
    kindOfValue: 'Нумерованный список',
    baseDataType: 'string',
  },
];
const testValues = [
  {
    key: 'asd',
    value: 'as',
    label: 'связь',
    id: '1',
    rdfUri: '',
  },
  {
    key: 'qwe',
    value: '23',
    label: 'Количество',
    id: '2',
    rdfUri: '',
  },
];
export const CustomAttributeDataTypesPane: React.FC<{}> = () => {
  const { artifactTypes, dataTypes, provider } = useContext(GlobalContext);
  const [jsonSchema, setJsonSchema] = useState({});
  const [loading, setLoading] = useState<boolean>();
  const [dataSource, setDataSource] = useState<DataType[]>(testData);
  const [currentDataSource, setCurrentDataSource] = useState<DataType[]>([]);
  const [dataTypeValues, setDataTypeValues] = useState<ValueType[]>(testValues);
  const [selected, setSelected] = useState<DataType>(testData[0]);

  const [saveReq, setSaveReq] = useState<boolean>(false);
  const [saveReqDialogVisible, setSaveReqDialogVisible] = useState<boolean>(false);

  const [currentValues, setCurrentValues] = useState<ValueType[]>(testValues);
  const [currentFormData, setCurrentFormData] = useState<DataType>(testData[0]);
  const [baseDataTypeDisabled, setBaseDataTypeDisabled] = useState<boolean>(false);
  const { editing, setEditing } = useContext(CustomizeLinkTypeDialogContext);
  const [btnState, setBtnState] = useState<boolean>(true);

  const { t } = useTranslation();

  const dataTypeSchema: JsObject = {
    type: 'object',
    properties: {
      title: { type: 'string', title: 'Имя' },
      description: { type: 'string', title: 'Описание' },
      kindOfValue: {
        type: 'string',
        title: 'Тип значений',
        enum: ['Простой', 'Нумерованный список'],
        default: 'Простой',
      },
      baseDataType: {
        type: 'string',
        title: 'Тип данных',
        enum: dataTypes.map((type: DataTypeInfo) => type.value),
        enumNames: dataTypes.map((type: DataTypeInfo) => type.title),
      },
      '@id': { type: 'string', title: 'URI' },
    },
  };

  const dataTypeUiSchema: UiSchema = {
    description: {
      'ui:widget': 'textarea',
    },
    kindOfValue: {
      'ui:widget': 'radio',
    },
    baseDataType: {
      'ui:disabled': currentFormData.kindOfValue && currentFormData.kindOfValue === 'Нумерованный список',
    },
  };

  function onCancel() {
    setEditing(false);
  }

  function selectAttributeDataTypes() {
    //Подгружаем типы данных
  }

  function saveChanges() {
    setLoading(true);
    console.log('_______saveData_________');
    console.log(currentFormData, currentValues);
    console.log('________________________');
    setLoading(false);
    setBtnState(true);
  }

  function createNewAttributeDataType() {
    if (!saveReq) {
      const newAttributeDataType = {
        key: 'New',
        title: 'newAttributeDataType',
        baseDataType: 'string',
        kindOfValue: 'Нумерованный список',
        description: '',
      };
      const newData = [newAttributeDataType, ...dataSource];
      setCurrentDataSource(newData);
      setSelected(newAttributeDataType);
      setDataTypeValues([]);
      setEditing(true);
    } else {
      setSaveReqDialogVisible(true);
    }
  }
  useEffect(() => {
    selectAttributeDataTypes();
  });

  useEffect(() => {
    if (selected && selected.key === 'New') setBtnState(false);
    if (btnState) {
      setCurrentFormData(selected);
      setCurrentValues(dataTypeValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, btnState]);

  useEffect(() => {
    setSaveReq(!btnState);
  }, [btnState]);

  useEffect(() => {
    if (!editing) {
      console.log('ok');
      setJsonSchema(dataSource !== [] ? dataTypeSchema : {});
      setCurrentDataSource(dataSource);
      setSelected(dataSource[0]);
      setBtnState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, dataSource]);

  /*const mySchema = (async function iifeWrapperFuncForAsyncSyncCall(): Promise<JSONSchema6forRdf> {
    return provider.getSchemaByUri(view.classes[0]['@id]);
  })();*/

  return (
    <SplitPane split='vertical' defaultSize='20%' style={divStyle2}>
      <div>
        <Table
          className='gx-table-responsive'
          size='small'
          pagination={false}
          rowKey={(record) => record.key}
          columns={dataTypesColumns}
          dataSource={currentDataSource}
          onRow={(record) => {
            return {
              onClick: () => {
                console.log(saveReq);
                if (!saveReq) setSelected(record);
                else setSaveReqDialogVisible(true);
              },
            };
          }}
          title={() => (
            <Row>
              <b>{t('customType.title')}</b>
              <Button
                id='addNewAttributeDataType'
                size='small'
                style={{ float: 'right' }}
                onClick={() => createNewAttributeDataType()}>
                Создать
              </Button>
            </Row>
          )}
        />
      </div>
      <div>
        <Row style={divStyle} justify='end'>
          <ButtonGroup>
            <Button key='cancel' disabled={btnState} onClick={() => onCancel()}>
              Отмена
            </Button>
            <Button key='ok' disabled={btnState} type='primary' onClick={() => saveChanges()} loading={loading}>
              Сохранить
            </Button>
          </ButtonGroup>
        </Row>
        <CustomForm
          schema={jsonSchema}
          uiSchema={dataTypeUiSchema}
          liveValidate={true}
          formData={currentFormData}
          onChange={(e: JsObject) => {
            setCurrentFormData(e.formData);
            setBtnState(false);
            setEditing(true);
          }}
          onSubmit={log('submit')}
          onError={log('errors')}>
          <div />
        </CustomForm>
        <EditableTable
          columns={valuesColumns}
          dataSource={currentValues}
          onChange={(e) => {
            setEditing(true);
            setCurrentValues(e);
          }}
          visible={currentFormData.kindOfValue === 'Нумерованный список'}
        />
        <SaveReqDialog
          visible={saveReqDialogVisible}
          onOk={() => {
            saveChanges();
            setSaveReqDialogVisible(false);
          }}
          onCancel={() => setSaveReqDialogVisible(false)}
        />
      </div>
    </SplitPane>
  );
};
