import React, { useContext, CSSProperties, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import SplitPane from 'react-split-pane';

import { ViewsPane } from 'components/views-pane/ViewsPane';
import { FlatTableRichText } from 'components/flat-table-rich-text/FlatTableRichText';
import { FilterByFolder } from 'components/element-filters/FilterByFolder';
import { defaultTags, FilterByTag } from 'components/element-filters/FilterByTag';

import { useCombineLoading } from 'hooks/useCombineLoading';
import { GlobalContext } from 'contexts/GlobalContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { useTranslation } from 'react-i18next';
import { JsObject, NopSchema } from '@agentlab/sparql-jsld-client';

export const cellStyle: CSSProperties = {
  display: 'flex',
  marginRight: '24px',
  marginLeft: '24px',
  marginBottom: '24px',
};

interface TableWithFilterView {
  showFilters?: boolean;
  onSelectArtifact?: (artifacts: JsObject[]) => any;
  editable?: boolean;
  contextMenu?: boolean;
  defaultSelection?: string[];
}

export const TableWithFilterView: React.FC<TableWithFilterView> = ({
  showFilters = false,
  onSelectArtifact = () => {},
  editable = false,
  contextMenu = true,
  defaultSelection = [],
}) => {
  const { t } = useTranslation();

  const {
    projectFolders,
    projectActiveFolder,
    projectActiveFolderUpdate,
    projectFolderCreate, // Function for create new folder
    projectFolderDelete, // Function for delete existing folder
    projectFolderRename, // Function for rename existing folder
    projectFolderMove, // Function for change existing folder's parent
    loadingProjectFolders, // Read-only flag indicating initial loading of folders is in progress
    updatingProjectFolders, // Read-only flag indicating changing folders from UI is in progress
    provider,
  } = useContext(GlobalContext);

  const {
    loadingViews,
    views,
    projectActiveView,
    projectActiveViewUpdate,
    projectArtifacts,
    projectArtifactsLoading,
    projectArtifactsUpdating,
    deleteArtifacts,
    updateArtifact,
    filters,
    addFilter,
    removeFilter,
    fullTextSearchString,
    setFullTextSearchString,
  } = useContext(ProjectContext);

  const { loading } = useCombineLoading(loadingProjectFolders, loadingViews, projectArtifactsLoading);

  const [schema, setSchema] = useState(NopSchema);
  useEffect(() => {
    async function fn(): Promise<void> {
      setSchema(await provider.getSchemaByUri('rm:Artifact'));
    }
    fn();
  }, [provider]);

  return (
    <>
      {showFilters && (
        <div style={cellStyle}>
          <FilterByTag
            tags={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            loading={loading}
            fullTextSearchString={fullTextSearchString}
            setFullTextSearchString={setFullTextSearchString}
          />
        </div>
      )}
      <SplitPane split='vertical' defaultSize='15%' style={{ position: 'relative' }}>
        <Tabs defaultActiveKey='folders' size='small'>
          <Tabs.TabPane tab='Папки' key='folders'>
            <FilterByFolder
              loading={loadingProjectFolders || updatingProjectFolders}
              folders={projectFolders}
              selection={projectActiveFolder}
              onSelect={(uri: string, folderName?: string) => {
                const newFolder = {
                  property: 'assetFolder',
                  title: t('FilterByFolder.assetFolder'),
                  relation: { title: '', predicate: 'equal' },
                  value: [uri],
                  valueName: folderName ? [folderName] : [],
                };
                projectActiveFolderUpdate(uri);
                addFilter(newFolder, 'front');
              }}
              onCreate={projectFolderCreate}
              onDelete={projectFolderDelete}
              onRename={projectFolderRename}
              onMove={projectFolderMove}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Представления' key='views'>
            <ViewsPane views={views} loading={loadingViews} onClick={projectActiveViewUpdate} />
          </Tabs.TabPane>
        </Tabs>
        <FlatTableRichText
          schema={schema}
          artifacts={projectArtifacts}
          view={projectActiveView}
          loading={projectArtifactsLoading || projectArtifactsUpdating}
          onDelete={deleteArtifacts}
          onEdit={updateArtifact}
          onSelectArtifact={onSelectArtifact}
          editable={editable}
          fullTextSearchString={fullTextSearchString}
          setFullTextSearchString={setFullTextSearchString}
          contextMenu={contextMenu}
          defaultSelection={defaultSelection}
        />
      </SplitPane>
    </>
  );
};
