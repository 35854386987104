import React, { useState, useContext } from 'react';
import { Tabs, Modal, Button } from 'antd';

import { UserRolesTable } from 'components/permissions-roles/UserRolesTable';
import { PermissonsRoles } from 'components/permissions-roles/PermissionsRoles';
import { useTranslation } from 'react-i18next';
import { UserRolesTableContextProvider } from 'contexts/PermissionsAndRolesContext';

interface PermissionsAndRolesDialog {
  visible: boolean;
  onOk: () => any;
  onCancel: () => any;
}

export const PermissionsAndRolesDialog: React.FC<PermissionsAndRolesDialog> = ({
  visible,
  onOk,
  onCancel,
}: PermissionsAndRolesDialog) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  function handleOk(): void {
    setLoading(true);
    onOk();
  }

  function handleCancel(): void {
    setLoading(false);
    onCancel();
  }

  return (
    <UserRolesTableContextProvider>
      <Modal
        title={t('PermissionsAndRolesDialog.title')}
        visible={visible}
        centered={true}
        style={{ top: '10%' }}
        width='auto'
        onCancel={handleCancel}
        footer={[
          <Button key='cancel' size='small' onClick={handleCancel}>
            {t('PermissionsAndRolesDialog.cancel')}
          </Button>,
          <Button key='ok' size='small' type='primary' onClick={handleOk} loading={loading}>
            {t('PermissionsAndRolesDialog.ok')}
          </Button>,
        ]}>
        <div style={{ padding: '5px' }}>
          <Tabs type='card' defaultActiveKey='1'>
            <Tabs.TabPane tab='Назначение ролей и групп пользователям и группам' key='1'>
              <UserRolesTable />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Назначение ролей и групп' key='2'>
              <PermissonsRoles />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Modal>
    </UserRolesTableContextProvider>
  );
};
