import React, { useContext } from 'react';
import { Select } from 'antd';
import { GlobalContext } from 'contexts/GlobalContext';
import { Widget } from 'react-jsonschema-form';
import { JsObject } from '@agentlab/sparql-jsld-client';

export const ArtifactTypeWidget: Widget = ({ value, readonly, onChange = (): void => {} }) => {
  const { artifactTypes } = useContext(GlobalContext);
  if (readonly) {
    const type = (artifactTypes.find((t: JsObject) => t['@id'] === value) || {}).title || '';
    return <span>{type || value}</span>;
  }
  return (
    <Select value={value} onChange={(e): void => onChange(e)} size='small'>
      {artifactTypes.map((type: JsObject) => (
        <Select.Option key={type['@id']} value={type['@id']}>
          {type.title}
        </Select.Option>
      ))}
    </Select>
  );
};
