import React from 'react';
import { Modal } from 'antd';

interface SaveReqDialog {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const SaveReqDialog: React.FC<SaveReqDialog> = ({
  visible = true,
  onOk = () => {},
  onCancel = () => {},
}: SaveReqDialog) => {
  return (
    <Modal
      title='Несохраненные изменения'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      cancelText='Вернуться'
      okText='Сохранить'>
      <p>
        Имеются несохраненные изменения! Чтобы сохранить изменения нажмите кнопку сохранить, чтобы остаться на текущей
        вкладке нажмите Вернуться
      </p>
    </Modal>
  );
};
