import React, { useState, useEffect, useContext } from 'react';
import { useTranslation, setDefaults } from 'react-i18next';
import { Form, Input, Checkbox, Tooltip } from 'antd';
import { QuestionCircleOutlined, CaretRightFilled, CaretLeftFilled } from '@ant-design/icons';

type CSSProp = {
  span: number;
  offset?: number;
};

type ColType = {
  sm: CSSProp;
  md?: CSSProp;
  xs?: CSSProp;
};

interface Layout {
  labelCol?: ColType;
  wrapperCol: ColType;
}

const formItemLayout: Layout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 20 },
  },
};

const tailFormItemLayout: Layout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

const styleItem: React.CSSProperties = {
  marginBottom: 0,
};
interface LinkTypeData {
  id?: string;
  name: string;
  description: string;
  URI: string;
  linkDisable: boolean;
  outgoingValue: string;
  incomingValue: string;
}

interface EditorLinkType {
  initData: LinkTypeData;
  setData: (e: LinkTypeData) => void;
}

export const dataInitTemplate: LinkTypeData = {
  id: '',
  name: '',
  description: '',
  URI: '',
  linkDisable: true,
  outgoingValue: '',
  incomingValue: '',
};

export const EditorLinkType: React.FC<EditorLinkType> = ({ initData, setData }) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>(initData.name);
  const [description, setDescription] = useState<string>(initData.description);
  const [URI, setURI] = useState<string>(initData.URI);
  const [linkDisable, setLinkDisable] = useState<boolean>(initData.linkDisable);
  const [outgoingValue, setOutgoingValue] = useState<string>(initData.outgoingValue);
  const [incomingValue, setIncomingValue] = useState<string>(initData.incomingValue);

  function updateData() {
    // TODO: Наверное можно сделать поумнее
    setData({
      name: name,
      description: description,
      URI: URI,
      linkDisable: linkDisable,
      outgoingValue: outgoingValue,
      incomingValue: incomingValue,
    });
  }

  useEffect(() => {
    if (linkDisable) {
      setOutgoingValue(name);
      setIncomingValue(name);
    }
    updateData();
  }, [linkDisable, name, updateData]);

  useEffect(() => {
    updateData();
  }, [description, updateData]);

  useEffect(() => {
    updateData();
  }, [URI, updateData]);

  useEffect(() => {
    updateData();
  }, [linkDisable, updateData]);

  useEffect(() => {
    updateData();
  }, [outgoingValue, updateData]);

  useEffect(() => {
    updateData();
  }, [incomingValue, updateData]);

  return (
    <div>
      <Form>
        <Form.Item
          {...formItemLayout}
          style={styleItem}
          key='item-name'
          label={t('EditorLinkType.fields.name')}
          required>
          <Input key='name' size='small' value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={styleItem}
          key='item-description'
          label={t('EditorLinkType.fields.description')}>
          <Input key='description' size='small' value={description} onChange={(e) => setDescription(e.target.value)} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={styleItem}
          key='item-URI'
          label={
            <span>
              {t('EditorLinkType.fields.URI')}&nbsp;
              <Tooltip title={t('EditorLinkType.Tooltip.URI')}>
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }>
          <Input key='URI' size='small' value={URI} onChange={(e) => setURI(e.target.value)} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout} style={styleItem} key='item-set-relationship'>
          <Checkbox checked={!linkDisable} onChange={() => setLinkDisable(!linkDisable)}>
            {t('EditorLinkType.Checkbox.SetRelationship')}
          </Checkbox>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={styleItem}
          key='item-outgoing'
          label={
            <span>
              {t('EditorLinkType.fields.outgoing')}&nbsp;
              <CaretRightFilled />
            </span>
          }>
          <Input
            key='outgoing'
            size='small'
            disabled={linkDisable}
            value={linkDisable ? name : outgoingValue}
            onChange={(e) => setOutgoingValue(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={styleItem}
          key='item-incoming'
          label={
            <span>
              {t('EditorLinkType.fields.incoming')}&nbsp;
              <CaretLeftFilled />
            </span>
          }>
          <Input
            key='incoming'
            size='small'
            disabled={linkDisable}
            value={linkDisable ? name : incomingValue}
            onChange={(e) => setIncomingValue(e.target.value)}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
