import React, { useState, useEffect } from 'react';

import SplitPane from 'react-split-pane';
import { Tabs, List, Modal, Row } from 'antd';

import { fetchViewsFor } from 'api/view';
import { Editor } from '@tinymce/tinymce-react';

import moment from 'moment';
import { TreeTableRichText } from 'components/tree-table-rich-text/TreeTableRichText';

import { ElementDetails, elementRenderer } from 'components/element-details/ElementDetails';
import { ArtifactModuleToolbarSmall } from 'components/main-toolbar/ArtifactModuleToolbarSmall';
import { ArtifactChangesList } from 'components/artifact-changes-list/ArtifactChangesList';
import { ViewsPane } from 'components/views-pane/ViewsPane';
import { ArtifactToolbarSmall } from '../../../components/main-toolbar/ArtifactToolbarSmall';

const divStyle2: React.CSSProperties = {
  position: 'relative',
};

export const panes = [
  {
    key: '1',
    title: 'Требование',
    panels: [
      {
        key: '1',
        title: 'Атрибуты модуля',
        content: [
          {
            key: '1',
            title: 'Описание',
            value: '',
          },
          {
            key: '2',
            title: 'Проект',
            value: 'AMR',
          },
          {
            key: '3',
            title: 'Создано пользователем',
            value: 'admin',
          },
          {
            key: '4',
            title: 'Изменено',
            value: '18 марта 2014, 15:12:17',
          },
          {
            key: '5',
            title: 'Изменено пользователем',
            value: 'admin',
          },
        ],
        renderer: elementRenderer,
      },
    ],
  },
];

const changes = [
  {
    timestamp: moment(),
    author: 'Алексей Иванов',
    title: 'Добавил связь',
    payload: [
      {
        key: 'Связь',
        value: (
          <List>
            <List.Item> Добавлена: Связь </List.Item>
            <List.Item>
              {' '}
              Источник: 399: Набор требований – список требований, сформированный в соответствии с заданными условиями
              отбора.{' '}
            </List.Item>
            <List.Item> Отражено в: (Цель) 141: Служба для определения местоположения локальной организации </List.Item>
          </List>
        ),
      },
    ],
  },
  {
    timestamp: moment(),
    author: 'Алексей Иванов',
    title: 'Добавил связь',
    payload: [
      {
        key: 'Описание',
        value: 'Тестовое описание',
      },
    ],
  },
  {
    timestamp: moment(),
    author: 'Алексей Иванов',
    title: 'Добавил ресурс',
    payload: [
      {
        key: 'Формат требования',
        value: 'Добавлено: Текст',
      },
      {
        key: 'Описание',
        value: 'Добавлено:',
      },
      {
        key: 'Тип объекта',
        value: 'Добавлено: Фича',
      },
      {
        key: 'Исходный текст',
        value:
          'Служба для определения местоположения локальной организации. Как часть мобильного приложения для распределения процента дивидендов для выбранной организации, требуется услуга, которая берет текущие местоположения пользователей и предоставляет соседние участвующие организации.',
      },
      {
        key: 'Стабильность',
        value: 'Добавлено: Средняя',
      },
      {
        key: 'Статус',
        value: 'Добавлено: Одобрено',
      },
      {
        key: 'Проект',
        value: 'Добавлено: ЧТЗ Управление требованиями',
      },
      {
        key: 'Название',
        value: 'Служба для определения местоположения локальной организации',
      },
    ],
  },
];

const revisions = [
  {
    label: 'Сегодня',
    count: 0,
    filter: (date: any) => true,
  },
  {
    label: 'Вчера',
    count: 0,
  },
  {
    label: 'Прошлая неделя',
    count: 0,
  },
  {
    label: 'Прошлый месяц',
    count: 1,
  },
  {
    label: 'Ранее',
    count: 0,
  },
];

const history = [
  {
    label: 'Сегодня',
    count: 0,
    filter: (date: any) => true,
  },
  {
    label: 'Вчера',
    count: 0,
  },
  {
    label: 'Прошлая неделя',
    count: 0,
  },
  {
    label: 'Прошлый месяц',
    count: 3,
  },
  {
    label: 'Ранее',
    count: 0,
  },
];

interface ArtifactModuleHistoryView {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const ArtifactModuleHistoryView: React.FC<ArtifactModuleHistoryView> = ({ visible, onOk, onCancel }) => {
  const [loadingViews, setLoadingViews] = useState(true);
  const [views, setViews] = useState([]);

  useEffect(() => {
    const fetchViews = async (id: string) => {
      const views = await fetchViewsFor(id);
      setViews(views);
      setLoadingViews(false);
    };
    fetchViews('some_fake_Id'); // TODO: get id from current route
  });

  if (visible === true) {
    return (
      <Modal
        title='История изменений модуля требований'
        width='90%'
        closable={false}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        okText={'Сохранить'}
        cancelText={'Отмена'}>
        <div>
          <SplitPane split='vertical' defaultSize='78.75%' style={divStyle2}>
            <Tabs size='small'>
              <Tabs.TabPane tab='Ревизии' key='revisions'>
                <Tabs size='small'>
                  {revisions.map((tab, i) => (
                    <Tabs.TabPane tab={`${tab.label} (${tab.count})`} key={'' + i}>
                      <ArtifactToolbarSmall id='121' title='Формирование иерархии требований' />
                      <Row style={{ padding: '16px' }}>
                        <Editor
                          inline
                          disabled
                          value={'1.11. формирование иерархии требований;'}
                          onEditorChange={(value) => {
                            // setDescription(value);
                          }}
                          init={{
                            branding: false,
                            menubar: false,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            language_url: `${process.env.PUBLIC_URL}/lang/ru.js`,
                            language: 'ru',
                          }}
                          toolbar='undo redo | formatselect | bold italic strikethrough forecolor backcolor | table image paste link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat'
                          plugins='table image link lists'
                        />
                      </Row>
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </Tabs.TabPane>
              <Tabs.TabPane tab='История изменений' key='history'>
                <Tabs size='small'>
                  {history.map((tab, i) => (
                    <Tabs.TabPane tab={`${tab.label} (${tab.count})`} key={'' + i}>
                      <ArtifactChangesList changes={changes} />
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </Tabs.TabPane>
            </Tabs>
            <ElementDetails panes={panes} />
          </SplitPane>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};
