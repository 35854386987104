import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Button, Row, Col, Popconfirm, message } from 'antd';
import { DeleteTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { TypeLinkCreateDialogModalView } from 'views/artifacts/typelink-create-dialog/TypeLinkCreateDialogModalView';
import { dataInitTemplate } from 'components/editor-link-type/EditorLinkType';
import { GlobalContext } from 'contexts/GlobalContext';
import { JsObject } from '@agentlab/sparql-jsld-client';

function columns(
  t: (s: string) => string,
  typeLinkOnClick: (r: JsObject) => void,
  deleteReq: (id: string) => void,
): ColumnProps<JsObject>[] {
  return [
    {
      title: t('customLinkType.columns.title'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <a onClick={() => typeLinkOnClick(record)}>{text}</a>,
    },
    {
      title: t('customLinkType.columns.label'),
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => `${record.objectToSubjectLabel} / ${record.subjectToObjectLabel}`,
    },
    {
      title: t('customLinkType.columns.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      key: 'delete',
      render: (record) => (
        // Math.random() > 0.7 ? (
        <Popconfirm
          placement='left'
          title={t('customLinkType.deleteBtn.title')}
          okText={t('customLinkType.deleteBtn.ok')}
          cancelText={t('customLinkType.deleteBtn.cancel')}
          onConfirm={() => deleteReq(record.id)}>
          <DeleteTwoTone twoToneColor='#ff0000' />
        </Popconfirm>
      ),
      // ) : (
      //   <Icon
      //     title={t('customLinkType.deleteBtn.disableTitle')}
      //     type='delete'
      //     theme='twoTone'
      //     twoToneColor={'#a6a6a6'}
      //   />
      // ),
    },
  ];
}

export const CustomLinkTypePane: React.FC<{}> = () => {
  const { projectLinkTypes, provider } = useContext(GlobalContext);
  const [typeLinkData, setTypeLinkData] = useState<JsObject>(dataInitTemplate);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<JsObject[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  async function updateReq(id: string, req: JsObject): Promise<void> {
    setLoading(true);
    const data = await provider.updateObject('rm:Artifact', { identifier: id }, { identifier: id, title: req.name });
    console.log('data', data);
    setLoading(false);
  }

  const handleSave = async (row: JsObject): Promise<void> => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // Here SPARQL UPDATE
    console.log(row);
    await updateReq(row.id, row);
    setDataSource(newData);
  };
  // function selectAll() {
  //   setLoading(true);
  //   selectLinkTypes().then((data) => {
  //     console.log('data', data);
  //     setDataSource(data);
  //     setLoading(false);
  //   });
  // }

  function deleteReq(id: string) {
    message.success('Тип связи успешно удален:' + id);
    // setLoading(true);
    // api.deleteArtifact({ identifier: id })
    //   .then(data => {
    //     console.log("data", data);
    //     const newData = [...dataSource];
    //     const index = newData.findIndex(item => item.id === id);
    //     newData.splice(index, 1);
    //     console.log("data", data);
    //     setDataSource(newData);
    //     setLoading(false);
    //   })
  }

  function typeLinkOnClick(record: JsObject) {
    setTypeLinkData({
      id: record.id,
      name: record.title,
      description: record.description,
      URI: '',
      linkDisable: false,
      outgoingValue: record.subjectToObjectLabel,
      incomingValue: record.objectToSubjectLabel,
    });
    setVisible(true);
  }

  function addNewTypeLinkOnClick() {
    setTypeLinkData(dataInitTemplate);
    setVisible(true);
  }

  // useEffect(() => {
  //   selectAll();
  // }, []);

  const columnsItems = columns(t, typeLinkOnClick, deleteReq).map((col: JsObject) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: JsObject) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <TypeLinkCreateDialogModalView initData={typeLinkData} visible={visible} setVisible={setVisible} />
      <Table
        className='gx-table-responsive'
        bordered
        size='small'
        rowClassName={() => 'editable-row'}
        rowKey={(record: JsObject) => record['@id']}
        dataSource={projectLinkTypes}
        columns={columnsItems}
        title={() => (
          <Row>
            <Col span={18}>
              <b>{t('customLinkType.title')}</b>
            </Col>
            <Col span={6}>
              <Button
                id='addNewTypeLink'
                style={{ float: 'right' }}
                size='small'
                onClick={() => addNewTypeLinkOnClick()}>
                <PlusCircleTwoTone twoToneColor='#52c41a' />
                {t('customLinkType.addBtn')}
              </Button>
            </Col>
          </Row>
        )}
        // rowSelection={{
        //   onChange: sel => {
        //     onSelectArtifact(sel);
        //     setSelection(sel)
        //   }
        // }}
        loading={loading}
        // onRow={record => ({
        //   onContextMenu: event => {
        //     event.preventDefault();
        //     if (!popupVisible) {
        //       document.addEventListener(`click`, function onClickOutside() {
        //         setPopupVisible(false);
        //         document.removeEventListener(`click`, onClickOutside)
        //       })
        //     }
        //     setPopupVisible(true);
        //     setPopupRecord(record);
        //     setPopupCoords({ x: event.clientX, y: event.clientY });
        //   }
        // })
        // }
      />
    </div>
  );
};
