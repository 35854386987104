import React from 'react';
import { Form } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface LinkSourceItem {
  labelFrom: string;
  rest?: JsObject[];
}

export const LinkSourceItem: React.FC<LinkSourceItem> = ({ labelFrom, ...rest }) => {
  return (
    <Form.Item label='Связь из:' {...rest}>
      <label className='ant-form-text'>{labelFrom}</label>
    </Form.Item>
  );
};
