import tinymce from 'tinymce/tinymce';

tinymce.addI18n('ru', {
  Redo: '\u041e\u0442\u043c\u0435\u043d\u0438\u0442\u044c',
  Undo: '\u0412\u0435\u0440\u043d\u0443\u0442\u044c',
  Cut: '\u0412\u044b\u0440\u0435\u0437\u0430\u0442\u044c',
  Copy: '\u041a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c',
  Paste: '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c',
  'Select all': '\u0412\u044b\u0434\u0435\u043b\u0438\u0442\u044c \u0432\u0441\u0435',
  'New document': '\u041d\u043e\u0432\u044b\u0439 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442',
  Ok: '\u041e\u043a',
  Cancel: '\u041e\u0442\u043c\u0435\u043d\u0438\u0442\u044c',
  'Visual aids':
    '\u041f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u043a\u043e\u043d\u0442\u0443\u0440\u044b',
  Bold: '\u041f\u043e\u043b\u0443\u0436\u0438\u0440\u043d\u044b\u0439',
  Italic: '\u041a\u0443\u0440\u0441\u0438\u0432',
  Underline: '\u041f\u043e\u0434\u0447\u0435\u0440\u043a\u043d\u0443\u0442\u044b\u0439',
  Strikethrough: '\u0417\u0430\u0447\u0435\u0440\u043a\u043d\u0443\u0442\u044b\u0439',
  Superscript: '\u0412\u0435\u0440\u0445\u043d\u0438\u0439 \u0438\u043d\u0434\u0435\u043a\u0441',
  Subscript: '\u041d\u0438\u0436\u043d\u0438\u0439 \u0438\u043d\u0434\u0435\u043a\u0441',
  'Clear formatting': '\u041e\u0447\u0438\u0441\u0442\u0438\u0442\u044c \u0444\u043e\u0440\u043c\u0430\u0442',
  'Align left': '\u041f\u043e \u043b\u0435\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  'Align center': '\u041f\u043e \u0446\u0435\u043d\u0442\u0440\u0443',
  'Align right': '\u041f\u043e \u043f\u0440\u0430\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  Justify: '\u041f\u043e \u0448\u0438\u0440\u0438\u043d\u0435',
  'Bullet list':
    '\u041c\u0430\u0440\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a',
  'Numbered list':
    '\u041d\u0443\u043c\u0435\u0440\u043e\u0432\u0430\u043d\u043d\u044b\u0439 \u0441\u043f\u0438\u0441\u043e\u043a',
  'Decrease indent': '\u0423\u043c\u0435\u043d\u044c\u0448\u0438\u0442\u044c \u043e\u0442\u0441\u0442\u0443\u043f',
  'Increase indent': '\u0423\u0432\u0435\u043b\u0438\u0447\u0438\u0442\u044c \u043e\u0442\u0441\u0442\u0443\u043f',
  Close: '\u0417\u0430\u043a\u0440\u044b\u0442\u044c',
  Formats: '\u0424\u043e\u0440\u043c\u0430\u0442',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    '\u0412\u0430\u0448 \u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u043d\u0435 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442 \u043f\u0440\u044f\u043c\u043e\u0439 \u0434\u043e\u0441\u0442\u0443\u043f \u043a \u0431\u0443\u0444\u0435\u0440\u0443 \u043e\u0431\u043c\u0435\u043d\u0430. \u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430, \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0439\u0442\u0435 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0435 \u0441\u043e\u0447\u0435\u0442\u0430\u043d\u0438\u044f \u043a\u043b\u0430\u0432\u0438\u0448: Ctrl+X/C/V.',
  Headers: '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043a\u0438',
  'Header 1': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 1',
  'Header 2': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 2',
  'Header 3': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 3',
  'Header 4': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 4',
  'Header 5': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 5',
  'Header 6': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 6',
  Headings: '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043a\u0438',
  'Heading 1': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 1',
  'Heading 2': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 2',
  'Heading 3': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 3',
  'Heading 4': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 4',
  'Heading 5': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 5',
  'Heading 6': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a 6',
  Preformatted:
    '\u041f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0435 \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435',
  Div: '\u0411\u043b\u043e\u043a',
  Pre:
    '\u041f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0435 \u0444\u043e\u0440\u043c\u0430\u0442\u0438\u0440\u043e\u0432\u0430\u043d\u0438\u0435',
  Code: '\u041a\u043e\u0434',
  Paragraph: '\u041f\u0430\u0440\u0430\u0433\u0440\u0430\u0444',
  Blockquote: '\u0426\u0438\u0442\u0430\u0442\u0430',
  Inline: '\u0421\u0442\u0440\u043e\u0447\u043d\u044b\u0435',
  Blocks: '\u0411\u043b\u043e\u043a\u0438',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    '\u0412\u0441\u0442\u0430\u0432\u043a\u0430 \u043e\u0441\u0443\u0449\u0435\u0441\u0442\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u0432 \u0432\u0438\u0434\u0435 \u043f\u0440\u043e\u0441\u0442\u043e\u0433\u043e \u0442\u0435\u043a\u0441\u0442\u0430, \u043f\u043e\u043a\u0430 \u043d\u0435 \u043e\u0442\u043a\u043b\u044e\u0447\u0438\u0442\u044c \u0434\u0430\u043d\u043d\u0443\u044e \u043e\u043f\u0446\u0438\u044e.',
  'Font Family': '\u0428\u0440\u0438\u0444\u0442',
  'Font Sizes': '\u0420\u0430\u0437\u043c\u0435\u0440 \u0448\u0440\u0438\u0444\u0442\u0430',
  Class: '\u041a\u043b\u0430\u0441\u0441',
  'Browse for an image':
    '\u0412\u044b\u0431\u043e\u0440 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f',
  OR: '\u0418\u041b\u0418',
  'Drop an image here':
    '\u041f\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435 \u0441\u044e\u0434\u0430',
  Upload: '\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c',
  Block: '\u0411\u043b\u043e\u043a',
  Align: '\u0412\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  Default: '\u0421\u0442\u0430\u043d\u0434\u0430\u0440\u0442\u043d\u044b\u0439',
  Circle: '\u041e\u043a\u0440\u0443\u0436\u043d\u043e\u0441\u0442\u0438',
  Disc: '\u041a\u0440\u0443\u0433\u0438',
  Square: '\u041a\u0432\u0430\u0434\u0440\u0430\u0442\u044b',
  'Lower Alpha':
    '\u0421\u0442\u0440\u043e\u0447\u043d\u044b\u0435 \u043b\u0430\u0442\u0438\u043d\u0441\u043a\u0438\u0435 \u0431\u0443\u043a\u0432\u044b',
  'Lower Greek':
    '\u0421\u0442\u0440\u043e\u0447\u043d\u044b\u0435 \u0433\u0440\u0435\u0447\u0435\u0441\u043a\u0438\u0435 \u0431\u0443\u043a\u0432\u044b',
  'Lower Roman':
    '\u0421\u0442\u0440\u043e\u0447\u043d\u044b\u0435 \u0440\u0438\u043c\u0441\u043a\u0438\u0435 \u0446\u0438\u0444\u0440\u044b',
  'Upper Alpha':
    '\u0417\u0430\u0433\u043b\u0430\u0432\u043d\u044b\u0435 \u043b\u0430\u0442\u0438\u043d\u0441\u043a\u0438\u0435 \u0431\u0443\u043a\u0432\u044b',
  'Upper Roman':
    '\u0417\u0430\u0433\u043b\u0430\u0432\u043d\u044b\u0435 \u0440\u0438\u043c\u0441\u043a\u0438\u0435 \u0446\u0438\u0444\u0440\u044b',
  Anchor: '\u042f\u043a\u043e\u0440\u044c',
  Name: '\u0418\u043c\u044f',
  Id: 'Id',
  'Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'Id \u0434\u043e\u043b\u0436\u0435\u043d \u043d\u0430\u0447\u0438\u043d\u0430\u0442\u044c\u0441\u044f \u0441 \u0431\u0443\u043a\u0432\u044b, \u043f\u0440\u043e\u0434\u043e\u043b\u0436\u0430\u0442\u044c\u0441\u044f \u0442\u043e\u043b\u044c\u043a\u043e \u0441 \u0431\u0443\u043a\u0432\u044b, \u0446\u0438\u0444\u0440\u044b, \u0442\u0438\u0440\u0435, \u0442\u043e\u0447\u043a\u0438, \u0434\u0432\u043e\u0435\u0442\u043e\u0447\u0438\u044f \u0438\u043b\u0438 \u043f\u043e\u0434\u0447\u0435\u0440\u043a\u0438\u0432\u0430\u043d\u0438\u044f.',
  'You have unsaved changes are you sure you want to navigate away?':
    '\u0423 \u0432\u0430\u0441 \u0435\u0441\u0442\u044c \u043d\u0435 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u043d\u044b\u0435 \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u044f. \u0412\u044b \u0443\u0432\u0435\u0440\u0435\u043d\u044b, \u0447\u0442\u043e \u0445\u043e\u0442\u0438\u0442\u0435 \u0443\u0439\u0442\u0438?',
  'Restore last draft':
    '\u0412\u043e\u0441\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0435\u0433\u043e \u043f\u0440\u043e\u0435\u043a\u0442\u0430',
  'Special character':
    '\u0421\u043f\u0435\u0446\u0438\u0430\u043b\u044c\u043d\u044b\u0435 \u0441\u0438\u043c\u0432\u043e\u043b\u044b',
  'Source code': '\u0418\u0441\u0445\u043e\u0434\u043d\u044b\u0439 \u043a\u043e\u0434',
  'Insert/Edit code sample':
    '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c/\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u0440\u0438\u043c\u0435\u0440 \u043a\u043e\u0434\u0430',
  Language: '\u042f\u0437\u044b\u043a',
  'Code sample': '\u041f\u0440\u0438\u043c\u0435\u0440 \u043a\u043e\u0434\u0430',
  Color: '\u0426\u0432\u0435\u0442',
  R: 'R',
  G: 'G',
  B: 'B',
  'Left to right':
    '\u041d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043b\u0435\u0432\u0430 \u043d\u0430\u043f\u0440\u0430\u0432\u043e',
  'Right to left':
    '\u041d\u0430\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0441\u043f\u0440\u0430\u0432\u0430 \u043d\u0430\u043b\u0435\u0432\u043e',
  Emoticons: '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0441\u043c\u0430\u0439\u043b',
  'Document properties':
    '\u0421\u0432\u043e\u0439\u0441\u0442\u0432\u0430 \u0434\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u0430',
  Title: '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a',
  Keywords: '\u041a\u043b\u044e\u0447\u0438\u0432\u044b\u0435 \u0441\u043b\u043e\u0432\u0430',
  Description: '\u041e\u043f\u0438\u0441\u0430\u043d\u0438\u0435',
  Robots: '\u0420\u043e\u0431\u043e\u0442\u044b',
  Author: '\u0410\u0432\u0442\u043e\u0440',
  Encoding: '\u041a\u043e\u0434\u0438\u0440\u043e\u0432\u043a\u0430',
  Fullscreen:
    '\u041f\u043e\u043b\u043d\u043e\u044d\u043a\u0440\u0430\u043d\u043d\u044b\u0439 \u0440\u0435\u0436\u0438\u043c',
  Action: '\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u0435',
  Shortcut: '\u042f\u0440\u043b\u044b\u043a',
  Help: '\u041f\u043e\u043c\u043e\u0449\u044c',
  Address: '\u0410\u0434\u0440\u0435\u0441',
  'Focus to menubar':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u043f\u0430\u043d\u0435\u043b\u0438 \u043c\u0435\u043d\u044e',
  'Focus to toolbar':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u043f\u0430\u043d\u0435\u043b\u0438 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u043e\u0432',
  'Focus to element path':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u0435 \u043f\u0443\u0442\u0438',
  'Focus to contextual toolbar':
    '\u0424\u043e\u043a\u0443\u0441 \u043d\u0430 \u043a\u043e\u043d\u0442\u0435\u043a\u0441\u0442\u043d\u043e\u0439 \u043f\u0430\u043d\u0435\u043b\u0438 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u043e\u0432',
  'Insert link (if link plugin activated)':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443 (\u0435\u0441\u043b\u0438 \u043f\u043b\u0430\u0433\u0438\u043d link \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u043d)',
  'Save (if save plugin activated)':
    '\u0421\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c (\u0435\u0441\u043b\u0438 \u043f\u043b\u0430\u0433\u0438\u043d save \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u043d)',
  'Find (if searchreplace plugin activated)':
    '\u041d\u0430\u0439\u0442\u0438 (\u0435\u0441\u043b\u0438 \u043f\u043b\u0430\u0433\u0438\u043d searchreplace \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u043d)',
  'Plugins installed ({0}):':
    '\u0423\u0441\u0442\u0430\u043d\u043e\u0432\u043b\u0435\u043d\u043d\u044b\u0435 \u043f\u043b\u0430\u0433\u0438\u043d\u044b ({0}):',
  'Premium plugins:': '\u041f\u0440\u0435\u043c\u0438\u0443\u043c \u043f\u043b\u0430\u0433\u0438\u043d\u044b:',
  'Learn more...': '\u0423\u0437\u043d\u0430\u0442\u044c \u0431\u043e\u043b\u044c\u0448\u0435...',
  'You are using {0}': '\u0412\u044b \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0435\u0442\u0435 {0}',
  Plugins: '\u041f\u043b\u0430\u0433\u0438\u043d\u044b',
  'Handy Shortcuts': '\u0413\u043e\u0440\u044f\u0447\u0438\u0435 \u043a\u043b\u0430\u0432\u0438\u0448\u0438',
  'Horizontal line':
    '\u0413\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u0430\u044f \u043b\u0438\u043d\u0438\u044f',
  'Insert/edit image':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435',
  'Image description':
    '\u041e\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f',
  Source: '\u0418\u0441\u0442\u043e\u0447\u043d\u0438\u043a',
  Dimensions: '\u0420\u0430\u0437\u043c\u0435\u0440',
  'Constrain proportions':
    '\u0421\u043e\u0445\u0440\u0430\u043d\u044f\u0442\u044c \u043f\u0440\u043e\u043f\u043e\u0440\u0446\u0438\u0438',
  General: '\u041e\u0431\u0449\u0435\u0435',
  Advanced: '\u0420\u0430\u0441\u0448\u0438\u0440\u0435\u043d\u043d\u044b\u0435',
  Style: '\u0421\u0442\u0438\u043b\u044c',
  'Vertical space':
    '\u0412\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u044c\u043d\u044b\u0439 \u0438\u043d\u0442\u0435\u0440\u0432\u0430\u043b',
  'Horizontal space':
    '\u0413\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u044b\u0439 \u0438\u043d\u0442\u0435\u0440\u0432\u0430\u043b',
  Border: '\u0420\u0430\u043c\u043a\u0430',
  'Insert image':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435',
  Image: '\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f',
  'Image list':
    '\u0421\u043f\u0438\u0441\u043e\u043a \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0439',
  'Rotate counterclockwise':
    '\u041f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u043f\u0440\u043e\u0442\u0438\u0432 \u0447\u0430\u0441\u043e\u0432\u043e\u0439 \u0441\u0442\u0440\u0435\u043b\u043a\u0438',
  'Rotate clockwise':
    '\u041f\u043e\u0432\u0435\u0440\u043d\u0443\u0442\u044c \u043f\u043e \u0447\u0430\u0441\u043e\u0432\u043e\u0439 \u0441\u0442\u0440\u0435\u043b\u043a\u0435',
  'Flip vertically':
    '\u041e\u0442\u0440\u0430\u0437\u0438\u0442\u044c \u043f\u043e \u0432\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u0438',
  'Flip horizontally':
    '\u041e\u0442\u0440\u0430\u0437\u0438\u0442\u044c \u043f\u043e \u0433\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u0438',
  'Edit image':
    '\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435',
  'Image options':
    '\u041d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f',
  'Zoom in': '\u041f\u0440\u0438\u0431\u043b\u0438\u0437\u0438\u0442\u044c',
  'Zoom out': '\u041e\u0442\u0434\u0430\u043b\u0438\u0442\u044c',
  Crop: '\u041e\u0431\u0440\u0435\u0437\u0430\u0442\u044c',
  Resize: '\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u0430\u0437\u043c\u0435\u0440',
  Orientation: '\u041e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f',
  Brightness: '\u042f\u0440\u043a\u043e\u0441\u0442\u044c',
  Sharpen: '\u0427\u0435\u0442\u043a\u043e\u0441\u0442\u044c',
  Contrast: '\u041a\u043e\u043d\u0442\u0440\u0430\u0441\u0442',
  'Color levels': '\u0426\u0432\u0435\u0442\u043e\u0432\u044b\u0435 \u0443\u0440\u043e\u0432\u043d\u0438',
  Gamma: '\u0413\u0430\u043c\u043c\u0430',
  Invert: '\u0418\u043d\u0432\u0435\u0440\u0441\u0438\u044f',
  Apply: '\u041f\u0440\u0438\u043c\u0435\u043d\u0438\u0442\u044c',
  Back: '\u041d\u0430\u0437\u0430\u0434',
  'Insert date/time':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0434\u0430\u0442\u0443/\u0432\u0440\u0435\u043c\u044f',
  'Date/time': '\u0414\u0430\u0442\u0430/\u0432\u0440\u0435\u043c\u044f',
  'Insert link': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443',
  'Insert/edit link':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443',
  'Text to display':
    '\u041e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0435\u043c\u044b\u0439 \u0442\u0435\u043a\u0441\u0442',
  Url: '\u0410\u0434\u0440\u0435\u0441 \u0441\u0441\u044b\u043b\u043a\u0438',
  Target: '\u041e\u0442\u043a\u0440\u044b\u0432\u0430\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443',
  None: '\u041d\u0435\u0442',
  'New window': '\u0412 \u043d\u043e\u0432\u043e\u043c \u043e\u043a\u043d\u0435',
  'Remove link': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443',
  Anchors: '\u042f\u043a\u043e\u0440\u044f',
  Link: '\u0421\u0441\u044b\u043b\u043a\u0430',
  'Paste or type a link':
    '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043b\u0438 \u0432\u0441\u0442\u0430\u0432\u044c\u0442\u0435 \u0441\u0441\u044b\u043b\u043a\u0443',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    '\u0412\u0432\u0435\u0434\u0451\u043d\u043d\u044b\u0439 URL \u044f\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u043a\u043e\u0440\u0440\u0435\u043a\u0442\u043d\u044b\u043c \u0430\u0434\u0440\u0435\u0441\u043e\u043c \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u044b. \u0412\u044b \u0436\u0435\u043b\u0430\u0435\u0442\u0435 \u0434\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043f\u0440\u0435\u0444\u0438\u043a\u0441 \u00abmailto:\u00bb?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    '\u0412\u0432\u0435\u0434\u0451\u043d\u043d\u044b\u0439 URL \u044f\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u0432\u043d\u0435\u0448\u043d\u0435\u0439 \u0441\u0441\u044b\u043b\u043a\u043e\u0439. \u0412\u044b \u0436\u0435\u043b\u0430\u0435\u0442\u0435 \u0434\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u043f\u0440\u0435\u0444\u0438\u043a\u0441 \u00abhttp://\u00bb?',
  'Link list': '\u0421\u043f\u0438\u0441\u043e\u043a \u0441\u0441\u044b\u043b\u043e\u043a',
  'Insert video': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0432\u0438\u0434\u0435\u043e',
  'Insert/edit video':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u043e',
  'Insert/edit media':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c/\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u0438\u0434\u0435\u043e',
  'Alternative source':
    '\u0410\u043b\u044c\u0442\u0435\u0440\u043d\u0430\u0442\u0438\u0432\u043d\u044b\u0439 \u0438\u0441\u0442\u043e\u0447\u043d\u0438\u043a',
  Poster: '\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435',
  'Paste your embed code below:':
    '\u0412\u0441\u0442\u0430\u0432\u044c\u0442\u0435 \u0432\u0430\u0448 \u043a\u043e\u0434 \u043d\u0438\u0436\u0435:',
  Embed: '\u041a\u043e\u0434 \u0434\u043b\u044f \u0432\u0441\u0442\u0430\u0432\u043a\u0438',
  Media: '\u0412\u0438\u0434\u0435\u043e',
  'Nonbreaking space':
    '\u041d\u0435\u0440\u0430\u0437\u0440\u044b\u0432\u043d\u044b\u0439 \u043f\u0440\u043e\u0431\u0435\u043b',
  'Page break': '\u0420\u0430\u0437\u0440\u044b\u0432 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b',
  'Paste as text': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u043a\u0430\u043a \u0442\u0435\u043a\u0441\u0442',
  Preview: '\u041f\u0440\u0435\u0434\u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440',
  Print: '\u041f\u0435\u0447\u0430\u0442\u044c',
  Save: '\u0421\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c',
  Find: '\u041d\u0430\u0439\u0442\u0438',
  'Replace with': '\u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c \u043d\u0430',
  Replace: '\u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c',
  'Replace all': '\u0417\u0430\u043c\u0435\u043d\u0438\u0442\u044c \u0432\u0441\u0435',
  Prev: '\u0412\u0432\u0435\u0440\u0445',
  Next: '\u0412\u043d\u0438\u0437',
  'Find and replace': '\u041f\u043e\u0438\u0441\u043a \u0438 \u0437\u0430\u043c\u0435\u043d\u0430',
  'Could not find the specified string.':
    '\u0417\u0430\u0434\u0430\u043d\u043d\u0430\u044f \u0441\u0442\u0440\u043e\u043a\u0430 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u0430',
  'Match case': '\u0423\u0447\u0438\u0442\u044b\u0432\u0430\u0442\u044c \u0440\u0435\u0433\u0438\u0441\u0442\u0440',
  'Whole words': '\u0421\u043b\u043e\u0432\u043e \u0446\u0435\u043b\u0438\u043a\u043e\u043c',
  Spellcheck:
    '\u041f\u0440\u043e\u0432\u0435\u0440\u0438\u0442\u044c \u043f\u0440\u0430\u0432\u043e\u043f\u0438\u0441\u0430\u043d\u0438\u0435',
  Ignore: '\u0418\u0433\u043d\u043e\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c',
  'Ignore all': '\u0418\u0433\u043d\u043e\u0440\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0432\u0441\u0435',
  Finish: '\u0417\u0430\u043a\u043e\u043d\u0447\u0438\u0442\u044c',
  'Add to Dictionary':
    '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0432 \u0441\u043b\u043e\u0432\u0430\u0440\u044c',
  'Insert table': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0442\u0430\u0431\u043b\u0438\u0446\u0443',
  'Table properties': '\u0421\u0432\u043e\u0439\u0441\u0442\u0432\u0430 \u0442\u0430\u0431\u043b\u0438\u0446\u044b',
  'Delete table': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0442\u0430\u0431\u043b\u0438\u0446\u0443',
  Cell: '\u042f\u0447\u0435\u0439\u043a\u0430',
  Row: '\u0421\u0442\u0440\u043e\u043a\u0430',
  Column: '\u0421\u0442\u043e\u043b\u0431\u0435\u0446',
  'Cell properties': '\u041f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u044b \u044f\u0447\u0435\u0439\u043a\u0438',
  'Merge cells': '\u041e\u0431\u044a\u0435\u0434\u0438\u043d\u0438\u0442\u044c \u044f\u0447\u0435\u0439\u043a\u0438',
  'Split cell': '\u0420\u0430\u0437\u0431\u0438\u0442\u044c \u044f\u0447\u0435\u0439\u043a\u0443',
  'Insert row before':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u043f\u0443\u0441\u0442\u0443\u044e \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u0432\u0435\u0440\u0445\u0443',
  'Insert row after':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u043f\u0443\u0441\u0442\u0443\u044e \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u043d\u0438\u0437\u0443',
  'Delete row': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443',
  'Row properties': '\u041f\u0430\u0440\u0430\u043c\u0435\u0442\u0440\u044b \u0441\u0442\u0440\u043e\u043a\u0438',
  'Cut row': '\u0412\u044b\u0440\u0435\u0437\u0430\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443',
  'Copy row': '\u041a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443',
  'Paste row before':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u0432\u0435\u0440\u0445\u0443',
  'Paste row after':
    '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u0440\u043e\u043a\u0443 \u0441\u043d\u0438\u0437\u0443',
  'Insert column before':
    '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446 \u0441\u043b\u0435\u0432\u0430',
  'Insert column after':
    '\u0414\u043e\u0431\u0430\u0432\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446 \u0441\u043f\u0440\u0430\u0432\u0430',
  'Delete column': '\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0442\u043e\u043b\u0431\u0435\u0446',
  Cols: '\u0421\u0442\u043e\u043b\u0431\u0446\u044b',
  Rows: '\u0421\u0442\u0440\u043e\u043a\u0438',
  Width: '\u0428\u0438\u0440\u0438\u043d\u0430',
  Height: '\u0412\u044b\u0441\u043e\u0442\u0430',
  'Cell spacing': '\u0412\u043d\u0435\u0448\u043d\u0438\u0439 \u043e\u0442\u0441\u0442\u0443\u043f',
  'Cell padding': '\u0412\u043d\u0443\u0442\u0440\u0435\u043d\u043d\u0438\u0439 \u043e\u0442\u0441\u0442\u0443\u043f',
  Caption: '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a',
  Left: '\u041f\u043e \u043b\u0435\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  Center: '\u041f\u043e \u0446\u0435\u043d\u0442\u0440\u0443',
  Right: '\u041f\u043e \u043f\u0440\u0430\u0432\u043e\u043c\u0443 \u043a\u0440\u0430\u044e',
  'Cell type': '\u0422\u0438\u043f \u044f\u0447\u0435\u0439\u043a\u0438',
  Scope: 'Scope',
  Alignment: '\u0412\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  'H Align':
    '\u0413\u043e\u0440\u0438\u0437\u043e\u043d\u0442\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  'V Align':
    '\u0412\u0435\u0440\u0442\u0438\u043a\u0430\u043b\u044c\u043d\u043e\u0435 \u0432\u044b\u0440\u0430\u0432\u043d\u0438\u0432\u0430\u043d\u0438\u0435',
  Top: '\u041f\u043e \u0432\u0435\u0440\u0445\u0443',
  Middle: '\u041f\u043e \u0441\u0435\u0440\u0435\u0434\u0438\u043d\u0435',
  Bottom: '\u041f\u043e \u043d\u0438\u0437\u0443',
  'Header cell': '\u0417\u0430\u0433\u043e\u043b\u043e\u0432\u043e\u043a',
  'Row group': '\u0413\u0440\u0443\u043f\u043f\u0430 \u0441\u0442\u0440\u043e\u043a',
  'Column group': '\u0413\u0440\u0443\u043f\u043f\u0430 \u043a\u043e\u043b\u043e\u043d\u043e\u043a',
  'Row type': '\u0422\u0438\u043f \u0441\u0442\u0440\u043e\u043a\u0438',
  Header: '\u0428\u0430\u043f\u043a\u0430',
  Body: '\u0422\u0435\u043b\u043e',
  Footer: '\u041d\u0438\u0437',
  'Border color': '\u0426\u0432\u0435\u0442 \u0440\u0430\u043c\u043a\u0438',
  'Insert template': '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0448\u0430\u0431\u043b\u043e\u043d',
  Templates: '\u0428\u0430\u0431\u043b\u043e\u043d\u044b',
  Template: '\u0428\u0430\u0431\u043b\u043e\u043d',
  'Text color': '\u0426\u0432\u0435\u0442 \u0442\u0435\u043a\u0441\u0442\u0430',
  'Background color': '\u0426\u0432\u0435\u0442 \u0444\u043e\u043d\u0430',
  'Custom...': '\u0412\u044b\u0431\u0440\u0430\u0442\u044c\u2026',
  'Custom color':
    '\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0439 \u0446\u0432\u0435\u0442',
  'No color': '\u0411\u0435\u0437 \u0446\u0432\u0435\u0442\u0430',
  'Table of Contents': '\u0421\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435',
  'Show blocks': '\u041f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u0431\u043b\u043e\u043a\u0438',
  'Show invisible characters':
    '\u041f\u043e\u043a\u0430\u0437\u044b\u0432\u0430\u0442\u044c \u043d\u0435\u0432\u0438\u0434\u0438\u043c\u044b\u0435 \u0441\u0438\u043c\u0432\u043e\u043b\u044b',
  'Words: {0}': '\u041a\u043e\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u0441\u043b\u043e\u0432: {0}',
  '{0} words': '\u0441\u043b\u043e\u0432: {0}',
  File: '\u0424\u0430\u0439\u043b',
  Edit: '\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c',
  Insert: '\u0412\u0441\u0442\u0430\u0432\u0438\u0442\u044c',
  View: '\u0412\u0438\u0434',
  Format: '\u0424\u043e\u0440\u043c\u0430\u0442',
  Table: '\u0422\u0430\u0431\u043b\u0438\u0446\u0430',
  Tools: '\u0418\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b',
  'Powered by {0}': '\u041f\u0440\u0438 \u043f\u043e\u0434\u0434\u0435\u0440\u0436\u043a\u0435 {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    '\u0422\u0435\u043a\u0441\u0442\u043e\u0432\u043e\u0435 \u043f\u043e\u043b\u0435. \u041d\u0430\u0436\u043c\u0438\u0442\u0435 ALT-F9 \u0447\u0442\u043e\u0431\u044b \u0432\u044b\u0437\u0432\u0430\u0442\u044c \u043c\u0435\u043d\u044e, ALT-F10 \u043f\u0430\u043d\u0435\u043b\u044c \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u043e\u0432, ALT-0 \u0434\u043b\u044f \u0432\u044b\u0437\u043e\u0432\u0430 \u043f\u043e\u043c\u043e\u0449\u0438.',
});
