import React from 'react';

export const EditLockContext = React.createContext<boolean>(false);
export const EditDataLockContext = React.createContext<boolean>(false);

interface LockButtonsPanelProvider {
  children: any;
  editMode?: boolean;
  editData?: boolean;
}

export const LockButtonsPanelProvider: React.FC<LockButtonsPanelProvider> = ({
  children,
  editMode = false,
  editData = false,
}: LockButtonsPanelProvider) => {
  return (
    <EditDataLockContext.Provider value={editData}>
      <EditLockContext.Provider value={editMode}>{children}</EditLockContext.Provider>
    </EditDataLockContext.Provider>
  );
};
