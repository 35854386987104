import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu, message } from 'antd';
import { BarsOutlined } from '@ant-design/icons';

import { CustomizeLinkTypDialog } from 'views/artifacts/customize-dialogs/CustomizeLinkTypeDialog';
import { CustomizeLinkTypeDialogContextProvider } from 'contexts/CustomizeLinkTypeDialogContext';
import { PermissionsAndRolesDialog } from 'components/permissions-roles/PermissionsAndRolesDialog';
import { QueryFormModal } from 'components/query-form';

export function defaultOkHandler() {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve('OK');
    }, 1000),
  );
}

const CustomizeLinkTypDialogWrapper: React.FC<any> = ({ ...props }) => {
  return (
    <CustomizeLinkTypeDialogContextProvider>
      <CustomizeLinkTypDialog {...props} />
    </CustomizeLinkTypeDialogContextProvider>
  );
};
export const defaultMenuItems: MenuItem[] = [
  {
    key: 'attr-types-and-links-settings',
    view: CustomizeLinkTypDialogWrapper,
    handleOk: defaultOkHandler,
  },
  {
    key: 'permissions-and-roles-settings',
    view: PermissionsAndRolesDialog,
    handleOk: defaultOkHandler,
  },
  {
    key: 'sparql',
    view: QueryFormModal,
    handleOk: defaultOkHandler,
  },
];

export type MenuItem = {
  key: string;
  view: React.FC<any>;
  handleOk: Function;
};

interface HamburgerMenu {
  style: React.CSSProperties;
  menuList?: MenuItem[];
}

export const HamburgerMenu: React.FC<HamburgerMenu> = ({ style = {}, menuList = defaultMenuItems }) => {
  // const [visible, setVisible] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>(menuList);
  const [menu, setMenu] = useState(<div />);
  const [visibleKey, setVisibleKey] = useState<string | null>(null);
  const { t } = useTranslation();

  const onOk = (): void => {
    menuItems[menuItems.findIndex((item) => item.key === visibleKey)].handleOk().then((msg: string) => {
      /*if (msg === 'OK') {
        message.success('Операция прошла успешно');
      } else {
        message.error('Ошибка соединения с сервером');
      }*/
      setVisibleKey(null);
    });
  };

  const onCancel = () => {
    setVisibleKey(null);
  };

  useEffect(() => {
    setMenu(
      <Menu>
        {menuItems.map((item: MenuItem) => (
          <Menu.Item key={item.key} onClick={(): void => setVisibleKey(item.key)}>
            {t(`HamburgerMenu.titles.${item.key}`)}
          </Menu.Item>
        ))}
      </Menu>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  return (
    <div style={style}>
      <Dropdown overlay={menu} placement='bottomLeft' trigger={['click']}>
        <Button icon={<BarsOutlined />} />
      </Dropdown>
      {menuItems.map((e: MenuItem) => (
        <div key={e.key}>{e.view({ visible: e.key === visibleKey, onOk, onCancel })}</div>
      ))}
    </div>
  );
};
