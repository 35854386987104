import React, { useState, FunctionComponent, CSSProperties } from 'react';

import { Button, Menu, Dropdown, Radio, Row } from 'antd';
import { SortAscendingOutlined, FilterOutlined } from '@ant-design/icons';
import { JsObject } from '@agentlab/sparql-jsld-client';

const buttonStyle: CSSProperties = {
  marginLeft: '8px',
};

interface LinkButtonPanel {
  id?: string;
}

// TODO: Fix default linkData
export const WhereUsedButtonPanel: FunctionComponent<LinkButtonPanel> = ({ id }) => {
  const [filterArtifactTypeValue, setFilterArtifactTypeValue] = useState(1);
  const [filterLinkTypeValue, setFilterLinkTypeValue] = useState(1);
  const [filterProject, setFilterProject] = useState(1);

  const sortingAndGroupingMenu = (
    <Menu>
      <Menu.Item>Сортировать по: Имени</Menu.Item>
      <Menu.Item>Сортировать по: Идентификатору</Menu.Item>
      <Menu.Item>Сортировать по: Типу</Menu.Item>
      <Menu.Item>Сортировать по: Дате изменения</Menu.Item>
      <Menu.Divider />
      <Menu.Item>Группировать по: Отношение</Menu.Item>
      <Menu.Item>Группировать по: Компоненту или проекту</Menu.Item>
      <Menu.Item>Группировать по: Направлению связи</Menu.Item>
      <Menu.Item>Группировать по: Дате изменения</Menu.Item>
    </Menu>
  );

  const filterMenu = (
    <Menu>
      <Menu.SubMenu title='Тип связи'>
        <Menu.Item
          onClick={(e) => {
            setFilterLinkTypeValue(1);
          }}>
          <Radio value={1} checked={filterLinkTypeValue === 1}>
            Любой
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterLinkTypeValue(2);
          }}>
          <Radio value={2} checked={filterLinkTypeValue === 2}>
            Входящая
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterLinkTypeValue(3);
          }}>
          <Radio value={3} checked={filterLinkTypeValue === 3}>
            Исходящая
          </Radio>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Тип требования'>
        <Menu.Item
          onClick={(e) => {
            setFilterArtifactTypeValue(1);
          }}>
          <Radio value={1} checked={filterArtifactTypeValue === 1}>
            Любой
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterArtifactTypeValue(2);
          }}>
          <Radio value={2} checked={filterArtifactTypeValue === 2}>
            Фича
          </Radio>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Компонент или проект'>
        <Menu.Item
          onClick={(e) => {
            setFilterProject(1);
          }}>
          <Radio value={1} checked={filterProject === 1}>
            Любой
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterProject(2);
          }}>
          <Radio value={2} checked={filterProject === 2}>
            ЧТЗ Управление требованиями
          </Radio>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <Row onClick={(e: JsObject) => e.stopPropagation()}>
      <div style={{ marginRight: '80px' }}>
        <Dropdown overlay={sortingAndGroupingMenu} placement='bottomRight'>
          <Button style={buttonStyle} icon={<SortAscendingOutlined />} />
        </Dropdown>
        <Dropdown overlay={filterMenu} placement='bottomRight'>
          <Button style={buttonStyle} icon={<FilterOutlined />} />
        </Dropdown>
      </div>
    </Row>
  );
};
