import { CSSProperties } from 'react';

export const rowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: 10,
  // justifyContent: 'space-between'
};

export const cellStyle: CSSProperties = {
  display: 'flex',
  marginRight: '24px',
  marginLeft: '24px',
};

export const columnStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};
