import React, { useState, useEffect, useRef, useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

// see https://ant.design/components/table/#components-table-demo-edit-cell

interface EditableCellContext {
  [key: string]: any;
}

const EditableContext = React.createContext<EditableCellContext>({});

interface EditableRowProps {
  index: any;
  [key: string]: any;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: JsObject;
  handleSave: (p: JsObject, c: JsObject) => void;
  //[key: string]: any;
}

export const EditableFormRow = EditableRow;

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext);
  const cell = useRef<HTMLTableDataCellElement>(null);

  useEffect(() => {
    if (editing && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave(record, values);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: JsObject) => {
      if (dataIndex !== 'xhtmlText' && cell.current !== null && 'contains' in cell.current) {
        if (editing && cell.current !== e.target && !cell.current.contains(e.target)) {
          save();
        }
      }
    };
    if (editable) {
      document.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      if (editable) {
        document.removeEventListener('click', handleClickOutside, true);
      }
    };
  });
  const { t, i18n } = useTranslation();

  return (
    <td ref={cell} {...restProps}>
      {editable ? (
        editing ? (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`,
              },
            ]}>
            {dataIndex !== 'xhtmlText' ? (
              <Input ref={inputRef} onPressEnter={save} />
            ) : (
              <div style={{ display: 'inline-block' }}>
                <div style={{ width: '100%' }}>
                  <Editor
                    initialValue={record[dataIndex]}
                    init={{
                      branding: false,
                      menubar: false,
                      inline: true,
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      language_url: `${process.env.PUBLIC_URL}/lang/ru.js`,
                      language: i18n.language,
                      skin: 'oxide',
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      skin_url: `${process.env.PUBLIC_URL}/skins/ui/oxide`,
                      theme: 'silver',
                      // eslint-disable-next-line @typescript-eslint/camelcase
                      theme_url: `${process.env.PUBLIC_URL}/themes/silver/theme.min.js`,
                    }}
                    toolbar='undo redo | formatselect | bold italic strikethrough forecolor backcolor | table image paste link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat'
                    plugins='table image lists paste link'
                    onBlur={(editor: any) => {
                      if (editor !== null && editor.target) {
                        console.log('onBlur.e=', editor);
                        form.setFieldsValue({ [dataIndex]: editor.target.getContent() });
                        //record[dataIndex] = editor.target.getContent();
                        save();
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </Form.Item>
        ) : (
          <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
            {children}
          </div>
        )
      ) : (
        children
      )}
    </td>
  );
};
