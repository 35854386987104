import React from 'react';
import SplitPane from 'react-split-pane';
import { Tabs, List, Modal } from 'antd';
import moment from 'moment';

import { ElementDetails, elementRenderer } from 'components/element-details/ElementDetails';
import { FlatTableRichText } from 'components/flat-table-rich-text/FlatTableRichText';
import { ArtifactCollectionToolbarSmall } from 'components/main-toolbar/ArtifactCollectionToolbarSmall';
import { ArtifactChangesList } from 'components/artifact-changes-list/ArtifactChangesList';
import { ViewsPane } from 'components/views-pane/ViewsPane';

import { useViews } from 'hooks/useViews';

const divStyle2: React.CSSProperties = {
  position: 'relative',
};

export const panes = [
  {
    key: '1',
    title: 'Требование',
    panels: [
      {
        key: '1',
        title: 'Атрибуты набора',
        content: [
          {
            key: '1',
            title: 'Описание',
            value: '',
          },
          {
            key: '2',
            title: 'Проект',
            value: 'AMR',
          },
          {
            key: '3',
            title: 'Создано пользователем',
            value: 'admin',
          },
          {
            key: '4',
            title: 'Изменено',
            value: '18 марта 2014, 15:12:17',
          },
          {
            key: '5',
            title: 'Изменено пользователем',
            value: 'admin',
          },
        ],
        renderer: elementRenderer,
      },
    ],
  },
];

const changes = [
  {
    timestamp: moment(),
    author: 'Алексей Иванов',
    title: 'Добавил связь',
    payload: [
      {
        key: 'Связь',
        value: (
          <List>
            <List.Item> Добавлена: Связь </List.Item>
            <List.Item>
              {' '}
              Источник: 399: Набор требований – список требований, сформированный в соответствии с заданными условиями
              отбора.{' '}
            </List.Item>
            <List.Item> Отражено в: (Цель) 141: Служба для определения местоположения локальной организации </List.Item>
          </List>
        ),
      },
    ],
  },
  {
    timestamp: moment(),
    author: 'Алексей Иванов',
    title: 'Добавил связь',
    payload: [
      {
        key: 'Описание',
        value: 'Тестовое описание',
      },
    ],
  },
  {
    timestamp: moment(),
    author: 'Алексей Иванов',
    title: 'Добавил ресурс',
    payload: [
      {
        key: 'Формат требования',
        value: 'Добавлено: Текст',
      },
      {
        key: 'Описание',
        value: 'Добавлено:',
      },
      {
        key: 'Тип объекта',
        value: 'Добавлено: Фича',
      },
      {
        key: 'Исходный текст',
        value:
          'Служба для определения местоположения локальной организации. Как часть мобильного приложения для распределения процента дивидендов для выбранной организации, требуется услуга, которая берет текущие местоположения пользователей и предоставляет соседние участвующие организации.',
      },
      {
        key: 'Стабильность',
        value: 'Добавлено: Средняя',
      },
      {
        key: 'Статус',
        value: 'Добавлено: Одобрено',
      },
      {
        key: 'Проект',
        value: 'Добавлено: ЧТЗ Управление требованиями',
      },
      {
        key: 'Название',
        value: 'Служба для определения местоположения локальной организации',
      },
    ],
  },
];

const revisions = [
  {
    label: 'Сегодня',
    count: 0,
    filter: (date: any) => true,
  },
  {
    label: 'Вчера',
    count: 0,
  },
  {
    label: 'Прошлая неделя',
    count: 0,
  },
  {
    label: 'Прошлый месяц',
    count: 1,
  },
  {
    label: 'Ранее',
    count: 0,
  },
];

const history = [
  {
    label: 'Сегодня',
    count: 0,
    filter: (date: any) => true,
  },
  {
    label: 'Вчера',
    count: 0,
  },
  {
    label: 'Прошлая неделя',
    count: 0,
  },
  {
    label: 'Прошлый месяц',
    count: 3,
  },
  {
    label: 'Ранее',
    count: 0,
  },
];

interface ArtifactCollectionHistoryView {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const ArtifactCollectionHistoryView: React.FC<ArtifactCollectionHistoryView> = ({ visible, onOk, onCancel }) => {
  const { loadingViews, views } = useViews('some_fake_Id'); // TODO: get id from current route

  return (
    <Modal
      title='История изменений набора требований'
      width='90%'
      closable={false}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={'Сохранить'}
      cancelText={'Отмена'}>
      <div>
        <SplitPane split='vertical' defaultSize='78.75%' style={divStyle2}>
          <Tabs size='small'>
            <Tabs.TabPane tab='Ревизии' key='revisions'>
              <Tabs size='small'>
                {revisions.map((tab, i) => (
                  <Tabs.TabPane tab={`${tab.label} (${tab.count})`} key={'' + i}>
                    <ArtifactCollectionToolbarSmall id='185' title='Набор требований для примера' />
                    <SplitPane split='vertical' defaultSize='15%' style={divStyle2}>
                      <Tabs defaultActiveKey='views' size='small'>
                        <Tabs.TabPane tab='Представления' key='views'>
                          <ViewsPane views={views} loading={loadingViews} />
                        </Tabs.TabPane>
                      </Tabs>
                      {/*<FlatTableRichText />*/}
                    </SplitPane>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Tabs.TabPane>
            <Tabs.TabPane tab='История изменений' key='history'>
              <Tabs size='small'>
                {history.map((tab, i) => (
                  <Tabs.TabPane tab={`${tab.label} (${tab.count})`} key={'' + i}>
                    <ArtifactChangesList changes={changes} />
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Tabs.TabPane>
          </Tabs>
          <ElementDetails panes={panes} />
        </SplitPane>
      </div>
    </Modal>
  );
};
