import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { CustomizeLinkTypeDialogContext } from 'contexts/CustomizeLinkTypeDialogContext';

import { Row, Col, Button, Modal } from 'antd';
import { PlusCircleTwoTone } from '@ant-design/icons';

import Table from '../rjst-antd/JsonSchemaTable';
import { ColumnProps } from 'antd/lib/table';
import { UiSchema, Widget } from 'react-jsonschema-form';
import { CustomForm } from '../custom-form/CustomForm';

import { spriteSheet, SpriteType } from 'api/rm-artifact-icons-greed/rmArtifactIconsGreed';
import { SpriteSheet } from 'components/spritesheet/SpriteSheet';
import { Sprite } from 'components/spritesheet/Sprite';

import { AttributeTypeListDialog } from './AttributeTypeListDialog';
import { SaveReqDialog } from '../saveReqDialog/SaveReqDialog';
import { JsObject } from '@agentlab/sparql-jsld-client';

export interface Attribute {
  key: string;
  attributeTitle: string | undefined;
  dataType: any;
  attributeDescription: string;
  attribute: string;
  id: string;
}

interface ArtifactForm {
  formData: JsObject;
  attributes: Attribute[];
  onSave: (savedata: JsObject) => void;
  saveReq: (b: boolean) => void;
  saveReqDialogVisible: boolean;
  onCancelSaveReqDialog: () => void;
  onCancelNew: () => void;
}

/*const attrColumns: ColumnProps<Attribute>[] = [
  {
    title: 'Имя',
    dataIndex: 'attributeTitle',
    key: 'attributeTitle',
    render: (text) => <a href='#!'>{text}</a>,
  },
  {
    title: 'Тип данных',
    dataIndex: 'dataType',
    key: 'dataType',
  },
  {
    title: 'Описание',
    dataIndex: 'attributeDescription',
    key: 'attributeDescription',
  },
];*/

const testSchema = {
  properties: {
    attributeTitle: {
      title: 'Имя',
      width: 100,
    },
    dataType: {
      title: 'Тип данных',
      hasFilter: true,
    },
    attributeDescription: {
      title: 'Описание',
      hasFilter: false,
    },
  },
};

const uiSchemaCol = {
  'ui:resizeableHeader': true,
  'ui:columnMenu': true,
  dataType: {
    'ui:editable': true,
    //'ui:disabled': true,
  },
  attributeDescription: {
    'ui:searchFilter': true,
  },
};

const smallSpriteSheet: { [key: string]: SpriteType } = {};
const iconData: { key: string; icon: string }[] = [];
for (const k in spriteSheet.sprites) {
  smallSpriteSheet[k] = {
    x: spriteSheet.sprites[k].small.x,
    y: spriteSheet.sprites[k].small.y,
    width: spriteSheet.width.small,
    height: spriteSheet.height.small,
  };
  iconData.push({
    key: k,
    icon: k,
  });
}
const iconColumns = [
  {
    title: 'Выберите иконку:',
    dataIndex: 'icon',
    key: 'icon',
    render: (icon: string) => (
      <Row>
        <Col span={4}>
          <SpriteSheet filename='img/rm-artifact-icons-sprite.png' data={smallSpriteSheet} sprite={icon} />
        </Col>
        <Col span={12}>{icon}</Col>
      </Row>
    ),
  },
];

const divStyle: React.CSSProperties = {
  padding: '5px',
};

const ButtonGroup = Button.Group;

export const ArtifactForm: React.FC<ArtifactForm> = ({
  formData = {},
  attributes,
  onSave,
  saveReq,
  saveReqDialogVisible,
  onCancelSaveReqDialog,
  onCancelNew,
}) => {
  const [currentData, setCurrentData] = useState<JsObject>(formData);
  const [currentAttributes, setCurrentAttributes] = useState<Attribute[]>(attributes);
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([]);
  const [selects, setSelects] = useState<(string | number)[]>([]);
  const [saveData, setSaveData] = useState<JsObject>({ newAttributes: [], deletedAttributes: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const { artifactFormats } = useContext(GlobalContext);

  const [selectedIcon, setSelectedIcon] = useState<string>('default');
  const [dialogIconVisible, setDialogIconVisible] = useState<boolean>(false);

  const [btnState, setBtnState] = useState<boolean>(true);
  const [deleteBtnState, setDeleteBtnState] = useState<boolean>(true);
  const [attributesDialogVisible, setAttributesDialogVisible] = useState<boolean>(false);

  const { editing, setEditing } = useContext(CustomizeLinkTypeDialogContext);

  const [s, setS] = useState(true);
  const [tSchema, setTSchema] = useState<JsObject>(testSchema);

  const { t } = useTranslation();

  let typeSchema: JsObject = {};
  if (artifactFormats.length > 0) {
    typeSchema = {
      type: 'object',
      properties: {
        title: { type: 'string', title: 'Имя', default: 'имя1' },
        icon: { type: 'string', title: 'Значок', default: 'default' },
        description: { type: 'string', title: 'Описание' },
        format: {
          type: 'string',
          title: 'Формат требования по-умолчанию',
          enum: artifactFormats.map((e: JsObject) => e['@id']),
          enumNames: artifactFormats.map((e: JsObject) => e.title),
          default: artifactFormats[0] && artifactFormats[0]['@id'],
        },
      },
    };
  }

  const IconWidget: Widget = (props) => {
    console.log('GLOBAL', props);
    return (
      <Row>
        <Col span={2}>
          <Sprite
            filename='img/rm-artifact-icons-sprite.png'
            x={spriteSheet.sprites[props.value].x}
            y={spriteSheet.sprites[props.value].y}
            width={spriteSheet.width.normal}
            height={spriteSheet.height.normal}
          />
        </Col>
        <Col span={12}>
          <Button id='addNewIcon' onClick={() => setDialogIconVisible(true)}>
            Выбрать
          </Button>
        </Col>
      </Row>
    );
  };

  const typeUiSchema: UiSchema = {
    description: {
      'ui:widget': 'textarea',
    },
    icon: {
      'ui:widget': IconWidget,
    },
  };

  function changeIcon(): void {
    const newData = {
      ...currentData,
      icon: selectedIcon,
    };
    setCurrentData(newData);
    setDialogIconVisible(false);
    setBtnState(false);
    setEditing(true);
  }

  function updateCurrentData(e: JsObject): void {
    if (btnState) {
      const data = e.formData;
      setBtnState(false);
      setCurrentData(data);
    } else {
      setCurrentData(e.formData);
    }
    setEditing(true);
  }

  function addAttributes(selects: Attribute[]) {
    const newData = [...currentAttributes, ...selects];
    const newSaveData = {
      ...saveData,
      newAttributes: [...saveData.newAttributes, ...selects],
    };
    setCurrentAttributes(newData);
    setSaveData(newSaveData);
    setAttributesDialogVisible(false);
    setBtnState(false);
    setEditing(true);
  }

  function saveChanges() {
    setLoading(true);
    const newSaveData = {
      ...saveData,
      ...currentData,
    };
    onSave(newSaveData);
    setSaveData({ newAttributes: [], deletedAttributes: [] });
    setLoading(false);
    setBtnState(true);
  }

  function onCancel() {
    setEditing(false);
  }

  function deleteAttributes() {
    const newData = currentAttributes.filter((e: Attribute) => selectedAttributes.indexOf(e) === -1);
    const newSaveData = {
      ...saveData,
      deletedAttributes: [...saveData.deletedAttributes, ...selectedAttributes],
    };
    setSaveData(newSaveData);
    setCurrentAttributes(newData);
    setSelects([]);
    setBtnState(false);
    setDeleteBtnState(true);
    setEditing(true);
  }

  const rowSelection = {
    selectedRowKeys: selects,
    onChange: (selects: (string | number)[], selectedAttributes: Attribute[]) => {
      setSelectedAttributes(selectedAttributes);
      setSelects(selects);
      setDeleteBtnState(!(selects.length > 0));
    },
  };

  useEffect(() => {
    if (btnState) {
      setCurrentData(formData);
      setCurrentAttributes(attributes);
    }
    if (formData && formData.key === 'New') setBtnState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (btnState) {
      saveReq(false);
    } else {
      saveReq(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!editing) {
      if (formData !== null && formData.key === 'New') {
        onCancelNew();
      } else {
        setCurrentData(formData);
        setCurrentAttributes(attributes);
      }
      setBtnState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, formData, attributes]);

  return (
    <div>
      <Row style={divStyle} justify='end'>
        <ButtonGroup>
          <Button key='cancel' disabled={btnState} onClick={() => onCancel()}>
            Отмена
          </Button>
          <Button key='ok' disabled={btnState} type='primary' onClick={() => saveChanges()} loading={loading}>
            Сохранить
          </Button>
        </ButtonGroup>
      </Row>
      {typeSchema && (
        <CustomForm
          schema={typeSchema}
          uiSchema={typeUiSchema}
          liveValidate={true}
          formData={currentData}
          onChange={(e: JsObject) => {
            updateCurrentData(e);
            setEditing(true);
          }}>
          <div />
        </CustomForm>
      )}
      <Table
        className='gx-table-responsive'
        size='small'
        bordered
        pagination={false}
        rowKey={(record: any) => record.attribute}
        schema={tSchema}
        uiSchema={uiSchemaCol}
        dataSource={currentAttributes}
        rowSelection={rowSelection}
        title={() => (
          <Row>
            <Col span={18}>
              <b>{t('customAttribute.title')}</b>
            </Col>
            <Col span={6}>
              <ButtonGroup style={{ float: 'right' }}>
                <Button
                  id='deleteArtifactAttribute'
                  size='small'
                  disabled={deleteBtnState}
                  onClick={() => deleteAttributes()}>
                  Удалить
                </Button>
                <Button id='addNewArtifactAttribute' size='small' onClick={() => setAttributesDialogVisible(true)}>
                  <PlusCircleTwoTone twoToneColor='#52c41a' />
                  Добавить
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
      />
      <Modal
        visible={dialogIconVisible}
        title='Добавление новой иконки'
        onCancel={() => setDialogIconVisible(false)}
        onOk={() => changeIcon()}
        cancelText='Отмена'
        okText='Выбрать'>
        <Table
          className='gx-table-responsive'
          size='small'
          pagination={false}
          schema={testSchema}
          scroll={{ y: 400 }}
          rowKey={(record: any) => record.key}
          onRow={(record: any) => {
            return {
              onClick: () => setSelectedIcon(record.icon),
            };
          }}
          columns={iconColumns}
          dataSource={iconData}
          title={() => (
            <Row>
              <Col span={12}>
                <b>Текущая иконка:</b>
              </Col>
              <Col span={4}>
                <Sprite
                  filename='img/rm-artifact-icons-sprite.png'
                  x={spriteSheet.sprites[selectedIcon].x}
                  y={spriteSheet.sprites[selectedIcon].y}
                  width={spriteSheet.width.normal}
                  height={spriteSheet.height.normal}
                />
              </Col>
            </Row>
          )}
        />
      </Modal>
      <SaveReqDialog
        visible={saveReqDialogVisible}
        onOk={() => {
          saveChanges();
          onCancelSaveReqDialog();
        }}
        onCancel={() => onCancelSaveReqDialog()}
      />
      <AttributeTypeListDialog
        key={Math.random()}
        visible={attributesDialogVisible}
        onOk={(selects: Attribute[]) => addAttributes(selects)}
        onCancel={() => setAttributesDialogVisible(false)}
        attributes={[...attributes, ...currentAttributes]}
      />
    </div>
  );
};
