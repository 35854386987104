import React, { useState, useEffect, useContext } from 'react';
// Hooks
import { useViews } from 'hooks/useViews';
import { useCombineLoading } from 'hooks/useCombineLoading';
import { useFilters } from 'hooks/useFilters';

// Contexts
import { GlobalContext } from 'contexts/GlobalContext';
import { ArtifactContext } from 'contexts/ArtifactContext';

export const ArtifactCollectionContext = React.createContext<any>({});

/**
 * Context for working with collection child artifacts.
 * Will load all child artifacts for this collection on startup.
 * @param identifier - the identifier of selected collection
 * @param children - array of chlid components
 */
export function ArtifactCollectionContextProvider({ identifier, children }: any) {
  const { provider } = useContext(GlobalContext);
  // Artifact collection itself
  const { artifact } = useContext(ArtifactContext);
  // List of child artifacts of collection
  const [collectionArtifacts, setChildArtifacts] = useState<any[]>([]);
  const [collectionArtifactsLoading, setChildArtifactsLoading] = useState(true);
  const [collectionArtifactsUpdating, setChildArtifactsUpdating] = useState(false);

  // Filters
  const { addFilter, removeFilter, getFilterCondition, filters } = useFilters();
  // Views
  const { loadingViews, views } = useViews('some_fake_Id'); // TODO: get id from current route
  const [collectionActiveView, collectionActiveViewUpdate] = useState<any>(null);
  // When artifact and list of child artifacts are loaded
  const { loading: collectionLoading } = useCombineLoading(collectionArtifactsLoading);
  // When some updating on child artifacts are executed
  const { loading: collectionUpdating } = useCombineLoading(collectionArtifactsUpdating);

  /**
   * Checkout artifact collection child artifacts.
   */
  const collectionArtifactsCheckout = async () => {
    try {
      setChildArtifactsLoading(true);
      console.log('artifact=', artifact);
      const result = await provider.selectObjectsByObjRefs(
        'rmUserTypes:UsedIn',
        'rm:Artifact',
        { object: artifact['@id'] },
        { ...getFilterCondition(), '@id': 'subject' },
      );
      setChildArtifacts(result);
    } catch (error) {
      console.error(error);
      setChildArtifacts([]);
    } finally {
      setChildArtifactsLoading(false);
    }
  };

  /**
   * Call API for update artifact and update artifact list.
   * @param artifact -- исходный объект со всеми полями и URI
   * @param values -- только изменяемые поля в соответствии со схемой
   */
  const collectionArtifactsUpdate = async (artifact: any, values: any) => {
    try {
      setChildArtifactsUpdating(true);
      const id = artifact.identifier;
      await provider.updateObject('rm:Artifact', artifact, values);
      const newData = [...collectionArtifacts];
      const index = newData.findIndex((item) => item.identifier === artifact.identifier);
      if (index >= 0) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...values,
        });
        setChildArtifacts(newData);
      } else {
        console.warn(`Requrement with identifier [${id}] not found after update`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setChildArtifactsUpdating(false);
    }
  };

  /**
   * Link specified artifact to this collection.
   * @param id - the identifier of linked artifact
   */
  const collectionArtifactsLink = async (id: number) => {
    try {
      console.log(id);
      setChildArtifactsUpdating(true);
      const linkedArtifact = await provider.selectObjectById('rm:Artifact', id);
      if (linkedArtifact) {
        // create UsedIn Link
        await provider.createObject('rmUserTypes:UsedIn', {
          subject: linkedArtifact['@id'],
          object: artifact['@id'],
        });

        const newData = [...collectionArtifacts, linkedArtifact];
        setChildArtifacts(newData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setChildArtifactsUpdating(false);
    }
  };

  /**
   * Unlink specified artifact from this collection.
   * @param id - the id of unlinked artifact
   */
  const collectionArtifactsUnlink = async (id: number) => {
    try {
      setChildArtifactsUpdating(true);
      const unlinkedArtifact = await provider.selectObjectById('rm:Artifact', id);
      if (unlinkedArtifact) {
        // delete UsedIn Link
        await provider.deleteObject('rmUserTypes:UsedIn', {
          subject: unlinkedArtifact['@id'],
          object: artifact['@id'],
        });
        const newData = [...collectionArtifacts];
        const index = newData.findIndex((item: any) => item.identifier === id);
        newData.splice(index, 1);
        setChildArtifacts(newData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setChildArtifactsUpdating(false);
    }
  };
  const collectionViewsRefresh = async () => {
    collectionArtifactsCheckout();
  };
  // Load child artifacts on startup
  useEffect(() => {
    if (identifier && collectionActiveView) {
      collectionArtifactsCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier, collectionActiveView, filters]);

  // Select first view when views list is changed
  useEffect(() => {
    if (views && views.length > 0) {
      collectionActiveViewUpdate(views[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  return (
    <ArtifactCollectionContext.Provider
      value={{
        // Child artifacts
        collectionArtifacts,
        collectionArtifactsLoading,
        collectionArtifactsUpdating,
        collectionArtifactsCheckout,
        collectionArtifactsUpdate,
        collectionArtifactsLink,
        collectionArtifactsUnlink,
        collectionViewsRefresh,
        // Views
        collectionViews: views,
        collectionViewsLoading: loadingViews,
        collectionActiveView,
        collectionActiveViewUpdate,
        // Flags
        collectionLoading,
        collectionUpdating,
        //Filters
        addFilter,
        removeFilter,
        filters,
        filterLoading: collectionArtifactsLoading,
      }}>
      {children}
    </ArtifactCollectionContext.Provider>
  );
}
