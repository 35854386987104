import React from 'react';
import { List } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './FilterByFolderContextMenu.css';

const iconStyle: React.CSSProperties = {
  margin: 5,
};

const listItemClassName = 'popupListItem';

interface FilterByFolderContextMenu {
  onCreate: () => void;
  onDelete: () => void;
  onRename: () => void;
}

export const FilterByFolderContextMenu: React.FC<FilterByFolderContextMenu> = ({ onCreate, onDelete, onRename }) => {
  const { t } = useTranslation();
  return (
    <List
      size='small'
      style={{
        backgroundClip: 'padding-box',
        backgroundColor: '#fff',
      }}>
      <List.Item onClick={() => onCreate()} className={listItemClassName}>
        <PlusOutlined style={iconStyle} />
        {t('FilterByFolder.menu.create')}
      </List.Item>
      <List.Item onClick={() => onDelete()} className={listItemClassName}>
        <DeleteOutlined style={iconStyle} />
        {t('FilterByFolder.menu.delete')}
      </List.Item>
      <List.Item onClick={() => onRename()} className={listItemClassName}>
        <EditOutlined style={iconStyle} />
        {t('FilterByFolder.menu.rename')}
      </List.Item>
    </List>
  );
};
