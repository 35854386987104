import React, { useState, useContext } from 'react';
import { Tabs, Modal, Button } from 'antd';

import { CustomizeLinkTypeDialogContext } from 'contexts/CustomizeLinkTypeDialogContext';
import { CustomLinkTypePane } from 'components/custom-link-type/CustomLinkTypePane';
import { CustomAttributePane } from 'components/custom-attribute/CustomAttributePane';
import { CustomArtifactTypesPane } from 'components/custom-artifact-types/CustomArtifactTypesPane';
import { CustomAttributeDataTypesPane } from 'components/custom-attribute-data-types/CustomAttributeDataTypesPane';
import { useTranslation } from 'react-i18next';

interface CustomizeLinkTypDialog {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const CustomizeLinkTypDialog: React.FC<CustomizeLinkTypDialog> = ({ visible, onOk, onCancel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { editing, setEditing, editFunction } = useContext(CustomizeLinkTypeDialogContext);
  const [warnVisible, setWarnVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('2');
  const [currentKey, setCurrentKey] = useState('1');

  function checkEditing(key: string) {
    if (editing) {
      setWarnVisible(true);
      setCurrentKey(key);
    } else {
      setActiveKey(key);
    }
  }
  function handleOk() {
    setEditing(false);
    setLoading(true);
    onOk();
  }

  function handleCancel() {
    setEditing(false);
    setLoading(false);
    onCancel();
  }

  return (
    <Modal
      title={t('CustomizeLinkTypeDialog.title')}
      visible={visible}
      onCancel={handleCancel}
      width='90%'
      footer={[
        <Button key='cancel' size='small' onClick={handleCancel}>
          {t('CustomizeLinkTypeDialog.cancel')}
        </Button>,
        <Button key='ok' size='small' type='primary' onClick={handleOk} loading={loading}>
          {t('CustomizeLinkTypeDialog.ok')}
        </Button>,
      ]}>
      <div style={{ padding: '5px' }}>
        <Tabs type='card' activeKey={activeKey} onChange={checkEditing}>
          <Tabs.TabPane tab='Типы требований' key='1'>
            <CustomArtifactTypesPane />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Атрибуты требований' key='2'>
            <CustomAttributePane />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Типы связей' key='3'>
            <CustomLinkTypePane />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Типы данных атрибутов' key='4'>
            <CustomAttributeDataTypesPane />
          </Tabs.TabPane>
        </Tabs>
        <Modal
          title='Предупреждение!'
          visible={warnVisible}
          onOk={() => {
            setActiveKey(currentKey);
            setEditing(false);
            setWarnVisible(false);
          }}
          onCancel={() => setWarnVisible(false)}
          cancelText='Назад'
          okText='Ок'>
          <p>Имеются несохраненные изменения! При нажатии кнопки Ok все изменения будут сброшены</p>
        </Modal>
      </div>
    </Modal>
  );
};
