import React from 'react';
import moment from 'moment';
import { Collapse, Table } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

type column = {
  title: string;
  dataIndex: string;
  key: string;
};

type change = {
  author: string;
  title: string;
  timestamp: moment.Moment;
  payload: JsObject[];
};

interface ArtifactChangesList {
  changes: change[];
}

const columns: column[] = [
  {
    title: 'key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'value',
    dataIndex: 'value',
    key: 'value',
  },
];

export const ArtifactChangesList: React.FC<ArtifactChangesList> = ({ changes = [] }) => {
  return (
    <Collapse defaultActiveKey='0'>
      {changes.map((change, index) => (
        <Collapse.Panel header={`${change.author} ${change.title} ${change.timestamp.format('LLL')}`} key={index}>
          <Table
            size='small'
            bordered
            showHeader={false}
            pagination={false}
            dataSource={change.payload}
            columns={columns}
          />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};
