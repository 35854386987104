import { useState, useEffect } from 'react';
import { ObjectProvider, JsObject } from '@agentlab/sparql-jsld-client';

export function compareArtifacts(e1: any, e2: any): number {
  if (e1.title > e2.title) {
    return 1;
  }
  if (e1.title < e2.title) {
    return -1;
  }
  return 0;
}

export interface UseArtifactTypesInterface {
  artifactTypes: JsObject[];
  loadingArtifactTypes: boolean;
}

export function useArtifactTypes(provider: ObjectProvider): UseArtifactTypesInterface {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<JsObject[]>([]);

  const checkout = async (): Promise<void> => {
    //const artifactSchema = await provider.getSchemaByUri('rm:Artifact');
    //let results = await provider.selectSubclasses(artifactSchema);
    let results = await provider.selectObjectsWithTypeInfo('rm:ArtifactClasses');
    //results = results.filter((r) => r['@id'] !== 'rm:Artifact').sort(compareArtifacts);
    results = results.sort(compareArtifacts);
    console.log(results);
    setData(results);
    setLoading(false);
  };

  useEffect(() => {
    checkout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loadingArtifactTypes: loading,
    artifactTypes: data,
  };
}
