import React, { useState, useEffect } from 'react';

import { Input, Tree } from 'antd';
import { AntTreeNodeProps } from 'antd/es/tree';
import { EventDataNode } from 'rc-tree/lib/interface';
import { JsObject } from '@agentlab/sparql-jsld-client';
import { useContextMenu, ContextMenu } from './ContextMenu';

const { DirectoryTree } = Tree;
const Search = Input.Search;

export interface Node extends AntTreeNodeProps {
  key: string;
  title: string;
  children?: Node[];
  isLeaf: boolean;
  parent: Node | null;
}

interface ElementsTree {
  header?: string;
  elementsTreeData: Node[] | [];
  defaultExpandedKeys: string[];
  defaultSelectedKey: string;
  onNodeSelect: Function;
  onRightClick?: Function;
  onNodeExpand: Function;
  onNodeDrop?: Function;
  draggable?: boolean;
  contextMenu?: any;
}

const renderTreeNodes = (data: Node[], searchValue: string): any => {
  return data.map((item: Node) => {
    let { title }: any = item;
    if (typeof title === typeof 'string') {
      const index = item.title.toLowerCase().indexOf(searchValue);
      const beforeStr = item.title.substr(0, index);
      const middleStr = item.title.substr(index, searchValue.length);
      const afterStr = item.title.substr(index + searchValue.length);
      title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#f50' }}>{middleStr}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
    }
    if (item.children) {
      // console.log(item.key)
      return {
        title,
        key: item.key,
        dataRef: item,
        children: renderTreeNodes(item.children, searchValue),
      };
    }
    return {
      title,
      key: item.key,
      dataRef: item,
    };
  });
};

/**
 *
 * @param {*} onTreeSelect show return true if selection is approved, false either
 */
export const ElementsTree: React.FC<ElementsTree> = ({
  elementsTreeData,
  defaultExpandedKeys,
  defaultSelectedKey,
  onNodeSelect = () => {},
  onRightClick = () => {},
  onNodeExpand = () => {},
  onNodeDrop = () => {},
  draggable = false,
  contextMenu = undefined,
}) => {
  const rootElement = elementsTreeData;

  const [expandedKeys, setExpandedKeys] = useState<string[]>(defaultExpandedKeys);
  const [searchValue, setSearchValue] = useState<string>('');
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [selectedKey, setSelectedKey] = useState<string[]>([defaultSelectedKey]);

  const { setPopupVisible, setPopupCoords, popupVisible, popupCoords } = useContextMenu();

  const getExpandedKeys = (value: string, node: Node, parentKey: string): string[] => {
    let keys: string[] = [];
    if (node.title.toLowerCase().indexOf(value) > -1) {
      keys.push(parentKey);
    }
    if (node.children) {
      node.children.forEach((child: Node) => {
        keys = keys.concat(getExpandedKeys(value, child, node.key));
      });
    }
    return keys;
  };

  const onTreeSearchInputChanged = (e: JsObject): void => {
    const value = e.target.value.toLowerCase();
    let keys: string[] = [];
    if (value !== '') {
      rootElement.forEach((child: Node) => {
        const expandedKeys2 = getExpandedKeys(value, child, child.key).filter(
          (item, i, self) => item && self.indexOf(item) === i,
        );
        keys = keys.concat(expandedKeys2);
      });
    }
    setAutoExpandParent(true);
    setSearchValue(value);
    setExpandedKeys(keys);
  };

  const onExpandKeys = (expandedKeys3: (string | number)[], obj: any): void => {
    setAutoExpandParent(false);
    const expnd = expandedKeys3.map((k) => (typeof k === 'number' ? k.toString() : k));
    onNodeExpand(expnd, obj);
    setExpandedKeys(expnd);
  };

  const onSelect = (selectedKeys: (string | number)[], e: any): void => {
    if (onNodeSelect(selectedKey, e)) {
      setSelectedKey([e.node.props.eventKey]);
    }
  };

  const onRightClickWithContextMenu = ({ event, node }: { event: React.MouseEvent; node: EventDataNode }): void => {
    event.preventDefault();
    onRightClick({ event, node });
    if (!popupVisible) {
      document.addEventListener(`click`, function onClickOutside() {
        setPopupVisible(false);
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setPopupVisible(true);
    setPopupCoords({ x: event.clientX, y: event.clientY });
  };

  const onDrop = ({ node, dragNode }: { node: EventDataNode; dragNode: EventDataNode }) => {
    console.log(node, dragNode);
    onNodeDrop(node, dragNode);
  };

  // если этого не сделать, то при обновлении elementsTreeData не происходит раскрытия
  useEffect(() => {
    setSelectedKey(defaultSelectedKey === '' ? [] : [defaultSelectedKey]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsTreeData, defaultSelectedKey]);

  useEffect(() => {
    setExpandedKeys(defaultExpandedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExpandedKeys]);

  return (
    <div style={{ paddingLeft: 8, paddingRight: 8 }}>
      <Search placeholder='Поиск...' onChange={onTreeSearchInputChanged} />
      <DirectoryTree
        onSelect={onSelect}
        expandedKeys={expandedKeys}
        selectedKeys={selectedKey}
        onExpand={onExpandKeys}
        onRightClick={onRightClickWithContextMenu}
        autoExpandParent={autoExpandParent}
        draggable={draggable}
        onDrop={onDrop}
        treeData={renderTreeNodes(elementsTreeData, searchValue)}
      />
      <ContextMenu x={popupCoords.x} y={popupCoords.y} visible={popupVisible}>
        {contextMenu}
      </ContextMenu>
    </div>
  );
};
