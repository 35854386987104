import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Radio, TreeSelect, Select, Checkbox, Tooltip } from 'antd';
import { FolderOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { GlobalContext } from 'contexts/GlobalContext';

const RadioGroup = Radio.Group;
const Option = Select.Option;

//#region Styles
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 16 },
  },
};

const styleItem = {
  marginBottom: 0,
};
//#endregion

type CsvImportSettingsFormProps = {
  fileKey: number;
  fileName: string;
  updateState: Function;
};
declare const ValidateStatuses: ['success', 'warning', 'error', 'validating', ''];

export const CsvImportSettingsForm: React.FC<CsvImportSettingsFormProps> = ({ fileKey, fileName, updateState }) => {
  const { t } = useTranslation();

  const { projectFolders, artifactTypes } = useContext(GlobalContext);

  const [moduleNameValid, setModuleNameValid] = useState('success' as typeof ValidateStatuses[number]);
  const [moduleTypeValid, setModuleTypeValid] = useState('success' as typeof ValidateStatuses[number]);
  const [columnsImportValid, setColumnsImportValid] = useState('success' as typeof ValidateStatuses[number]);
  const [artifactsFolderValid, setArtifactsFolderValid] = useState('warning' as typeof ValidateStatuses[number]);

  const [moduleNameValue, setModuleNameValue] = useState<string | undefined>(undefined);
  const [moduleNameType, setModuleNameType] = useState<string | undefined>(undefined);
  const [artifactsFolderValue, setArtifactsFolderValue] = useState<any>(undefined);
  const [columnsImport, setColumnsImport] = useState<any>(undefined);

  const [createModuleRadioValue, setCreateModuleRadioValue] = useState(1);
  const [createColumnsRadioValue, setCreateColumnsRadioValue] = useState(1);
  const [moduleNameCheck, setModuleNameCheck] = useState(true);

  function getArtifactTypeURI(nameType: string) {
    const artifactType = artifactTypes.find((el: any) => {
      return el.title === nameType;
    });
    return artifactType && artifactType['@id'];
  }

  function updateTabState() {
    let stateIcon;
    if (
      moduleNameValid === 'success' &&
      moduleTypeValid === 'success' &&
      artifactsFolderValid === 'success' &&
      columnsImportValid === 'success'
    ) {
      stateIcon = 'check-circle';
    } else {
      if (columnsImportValid === 'error') {
        stateIcon = 'close-circle';
      } else {
        stateIcon = 'exclamation-circle';
      }
    }

    if (moduleNameType) {
      updateState({
        key: fileKey,
        status: stateIcon,
        folder: artifactsFolderValue,
        createModule: createModuleRadioValue === 1 ? false : true,
        moduleName: moduleNameValue,
        moduleType: getArtifactTypeURI(moduleNameType),
        importColumns: columnsImport,
      });
    }
  }

  useEffect(() => {
    updateTabState();
  }, [moduleNameValid, moduleTypeValid, columnsImportValid, artifactsFolderValid, updateTabState]);

  useEffect(() => {
    console.log(moduleNameValue);
    if (moduleNameValue) {
      setModuleNameValid('success');
    } else {
      setModuleNameValid('warning');
    }
  }, [moduleNameValue]);

  useEffect(() => {
    if (moduleNameType) {
      setModuleTypeValid('success');
    } else {
      setModuleTypeValid('warning');
    }
  }, [moduleNameType]);

  useEffect(() => {
    if (artifactsFolderValue) {
      setArtifactsFolderValid('success');
    } else {
      setArtifactsFolderValid('warning');
    }
  }, [artifactsFolderValue]);

  function isNumberList(value: string) {
    const listNumbers = (value + '').split(',');
    let flag = true;
    listNumbers.forEach((item) => {
      if (isNaN(Number.parseInt(item))) {
        flag = false;
      }
    });
    return flag;
  }

  useEffect(() => {
    if (isNumberList(columnsImport)) {
      setColumnsImportValid('success');
    } else {
      setColumnsImportValid('error');
    }
  }, [columnsImport]);

  useEffect(() => {
    if (createModuleRadioValue === 1) {
      setModuleNameValid('success');
      setModuleTypeValid('success');
    } else if (createModuleRadioValue === 2) {
      if (moduleNameCheck || moduleNameValue) {
        setModuleNameValid('success');
      } else {
        setModuleNameValid('warning');
      }

      if (moduleNameType) {
        setModuleTypeValid('success');
      } else {
        setModuleTypeValid('warning');
      }
    }
  }, [createModuleRadioValue, moduleNameCheck, moduleNameType, moduleNameValue]);

  useEffect(() => {
    if (createColumnsRadioValue === 1) {
      setColumnsImportValid('success');
    } else if (createColumnsRadioValue === 2) {
      if (isNumberList(columnsImport)) {
        setColumnsImportValid('success');
      } else {
        setColumnsImportValid('error');
      }
    }
  }, [columnsImport, createColumnsRadioValue]);

  useEffect(() => {
    if (moduleNameCheck || moduleNameValue) {
      setModuleNameValid('success');
    } else {
      setModuleNameValid('warning');
    }
  }, [moduleNameCheck, moduleNameValue]);

  function getTreeFolders(folders: any) {
    return folders.map((folder: any) => {
      return {
        title: folder.title,
        key: folder['@id'],
        value: folder['@id'],
        icon: <FolderOutlined />,
        children: folder.children && getTreeFolders(folder.children),
      };
    });
  }

  const treeSearchProps = {
    treeData: getTreeFolders(projectFolders),
    treeIcon: true,
    name: 'artifactsFolderValue',
    treeDefaultExpandAll: true,
    allowClear: true,
    showSearch: true,
    placeholder: t('ImportForm.CsvView.artifactsFolder.placeholder'),
    dropdownStyle: {
      maxHeight: 300,
      overflow: 'auto',
    },
  };

  return (
    <Form layout='horizontal' className='importFormBox'>
      <Form.Item {...formItemLayout} style={styleItem} label={t('ImportForm.CsvView.file.label')}>
        <b>
          <span className='ant-form-text'>{fileName}</span>
        </b>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.CsvView.artifactsFolder.label')}
        hasFeedback
        validateStatus={artifactsFolderValid}>
        <TreeSelect size='small' onChange={(value) => setArtifactsFolderValue(value)} {...treeSearchProps} />
      </Form.Item>

      <Form.Item {...formItemLayout} label={t('ImportForm.CsvView.createModuleRadioValue.label')} style={styleItem}>
        <RadioGroup
          name='createModuleRadioValue'
          onChange={(e) => setCreateModuleRadioValue(e.target.value)}
          value={createModuleRadioValue}>
          <Radio style={radioStyle} value={1}>
            {t('ImportForm.CsvView.createModuleRadioValue.radio.no')}
          </Radio>
          <Radio style={radioStyle} value={2}>
            {t('ImportForm.CsvView.createModuleRadioValue.radio.yes')}
          </Radio>
        </RadioGroup>
      </Form.Item>

      <Form.Item {...formItemLayout} label={t('ImportForm.CsvView.moduleName.label')} style={styleItem}>
        <Form.Item
          hasFeedback
          validateStatus={moduleNameValid}
          style={{ ...styleItem, ...{ display: 'inline-block', width: 'calc(60% - 12px)' } }}>
          <Input
            id='moduleName'
            size='small'
            onChange={(e) => setModuleNameValue(e.target.value)}
            disabled={createModuleRadioValue != 2 || moduleNameCheck ? true : false}
          />
        </Form.Item>
        <span style={{ ...styleItem, ...{ display: 'inline-block', width: '24px' } }} />
        <Form.Item style={{ ...styleItem, ...{ display: 'inline-block', width: 'calc(40% - 12px)' } }} colon={false}>
          <Checkbox
            disabled={createModuleRadioValue != 2 ? true : false}
            checked={moduleNameCheck}
            onChange={() => setModuleNameCheck(!moduleNameCheck)}>
            {t('ImportForm.CsvView.moduleName.checkbox')}
          </Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={t('ImportForm.CsvView.moduleTypeValue.label')}
        hasFeedback
        validateStatus={moduleTypeValid}
        style={styleItem}>
        <Select
          size='small'
          showSearch
          onChange={(value) => setModuleNameType(typeof value === 'string' ? value : value.toString())}
          disabled={createModuleRadioValue != 2 ? true : false}>
          {artifactTypes.map((d: any) => (
            <Option key={d['@id']} value={d.title}>
              {d.title}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} label={t('ImportForm.CsvView.createColumnsRadioValue.label')} style={styleItem}>
        <RadioGroup
          name='createColumnsRadioValue'
          onChange={(e) => setCreateColumnsRadioValue(e.target.value)}
          value={createColumnsRadioValue}>
          <Radio style={radioStyle} value={1}>
            {t('ImportForm.CsvView.createColumnsRadioValue.radio.yes')}
          </Radio>
          <Radio style={radioStyle} value={2}>
            {t('ImportForm.CsvView.createColumnsRadioValue.radio.no')}
          </Radio>
        </RadioGroup>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            {t('ImportForm.CsvView.columnsImport.label')}&nbsp;
            <Tooltip title={t('ImportForm.CsvView.columnsImport.tooltip')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
        hasFeedback
        validateStatus={columnsImportValid}
        style={styleItem}>
        <Input
          id='columnsImport'
          size='small'
          onChange={(e) => setColumnsImport(e.target.value)}
          disabled={createColumnsRadioValue != 2 ? true : false}
        />
      </Form.Item>
    </Form>
  );
};
