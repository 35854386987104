import { useEffect, useState } from 'react';
import { treeify, strcmp } from 'api/collections';
import { ObjectProvider, JsObject } from '@agentlab/sparql-jsld-client';
import { FilterType } from './useFilters';

export interface UseFoldersInterface {
  projectFolders: JsObject[]; // Read-only field storing folders list
  //setProjectFolders: React.Dispatch<React.SetStateAction<any[]>>;
  projectFoldersCheckout: () => Promise<void>; // Function for checkout list of folders, can be used for manual refresh
  projectFolderCreate: (parentFolder: any, dirname: string) => Promise<void>; // Function for create new folder
  projectFolderDelete: (folder: JsObject, parentFolder: JsObject) => Promise<void>; // Function for delete existing folder
  projectFolderRename: (folder: JsObject, newName: string) => Promise<void>; // Function for rename existing folder
  projectFolderMove: (movedFolder: JsObject, srcFolder: JsObject, targetFolder: JsObject) => Promise<void>; // Function for change existing folder's parent
  loadingProjectFolders: boolean; // Read-only flag indicating initial loading of folders is in progress
  //setLoadingProjectFolders: React.Dispatch<React.SetStateAction<boolean>>;
  updatingProjectFolders: boolean; // Read-only flag indicating changing folders from UI is in progress
  //setUpdatingProjectFolders: React.Dispatch<React.SetStateAction<boolean>>;
  // Project active folder
  projectActiveFolder: string; // Read-only field storing active folder
  projectActiveFolderUpdate: React.Dispatch<React.SetStateAction<string>>; // Function for change active folder
}

export function useFolders(provider: ObjectProvider, rootFolderUri: string): UseFoldersInterface {
  const [projectFolders, setProjectFolders] = useState<JsObject[]>([]);
  const [projectActiveFolder, projectActiveFolderUpdate] = useState(rootFolderUri);
  const [loadingProjectFolders, setLoadingProjectFolders] = useState(false);
  const [updatingProjectFolders, setUpdatingProjectFolders] = useState(false);

  /**
   * Select all folders and convert to tree
   * @param {*} schema
   */
  const fetchProjectFoldersTree = async (schema: any): Promise<JsObject[]> => {
    const folders = await provider.selectObjects(schema);
    return treeify(folders, '@id', 'parent', 'children', (a: JsObject, b: JsObject) => strcmp(a.title, b.title));
  };

  const projectFoldersCheckout = async (): Promise<void> => {
    try {
      setLoadingProjectFolders(true);
      const result = await fetchProjectFoldersTree('nav:folder');
      setProjectFolders([...result]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProjectFolders(false);
    }
  };

  const projectFolderCreate = async (parentFolder: any, dirname: string): Promise<void> => {
    try {
      setUpdatingProjectFolders(true);
      await provider.createObject('nav:folder', {
        title: dirname,
        parent: parentFolder['@id'],
      });

      /*if (!parentFolder.children) {
        parentFolder.children = [];
      }
      parentFolder.children.push({
        title: dirname,
      });
      setProjectFolders([...projectFolders]);*/
      await projectFoldersCheckout();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingProjectFolders(false);
    }
  };

  /**
   * Delete specified folder from project.
   * @param folder
   * @param parentFolder
   */
  const projectFolderDelete = async (folder: JsObject, parentFolder: JsObject): Promise<void> => {
    try {
      setUpdatingProjectFolders(true);
      console.log('Deleting folder', folder);
      await provider.deleteObject('nav:folder', {
        '@id': folder['@id'],
      });
      parentFolder.children = parentFolder.children.filter((val: JsObject) => val != folder);
      if (projectActiveFolder === folder['@id']) {
        projectActiveFolderUpdate(parentFolder['@id']);
      }
      setProjectFolders([...projectFolders]);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingProjectFolders(false);
    }
  };

  /**
   * Rename specified folder with new name.
   * @param folder
   * @param newName
   */
  const projectFolderRename = async (folder: JsObject, newName: string): Promise<void> => {
    try {
      setUpdatingProjectFolders(true);
      // console.log(folder);
      if (folder.title != newName) {
        await provider.updateObject('nav:folder', folder, {
          title: newName,
        });
      }
      folder.title = newName;
      setProjectFolders([...projectFolders]);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingProjectFolders(false);
    }
  };

  const projectFolderMove = async (
    movedFolder: JsObject,
    srcFolder: JsObject,
    targetFolder: JsObject,
  ): Promise<void> => {
    try {
      setUpdatingProjectFolders(true);
      await provider.updateObject('nav:folder', movedFolder, {
        parent: targetFolder['@id'],
      });
      srcFolder.children = srcFolder.children.filter((val: any) => val != movedFolder);
      targetFolder.children.push(movedFolder);
      setProjectFolders([...projectFolders]);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingProjectFolders(false);
    }
  };

  return {
    projectFolders,
    //setProjectFolders,
    projectActiveFolder,
    projectActiveFolderUpdate,
    loadingProjectFolders,
    //setLoadingProjectFolders,
    updatingProjectFolders,
    //setUpdatingProjectFolders,
    projectFoldersCheckout,
    projectFolderCreate,
    projectFolderDelete,
    projectFolderRename,
    projectFolderMove,
  };
}
