import React from 'react';
import { ArtifactGeneratedForm } from 'components/generated-form/artifact-form/ArtifactGeneratedForm';
import { Spin, Card } from 'antd';
import { FileTextTwoTone } from '@ant-design/icons';
import { JSONSchema6forRdf, JsObject } from '@agentlab/sparql-jsld-client';
import { JSONSchema6 } from 'json-schema';

interface EditArtifactAttrs {
  fields: JsObject;
  schema?: JSONSchema6forRdf | string;
  uiSchema?: JsObject;
  edit: boolean;
  onChange: (e: JsObject) => void;
  loading: boolean;
  formRef?: React.Ref<any>;
}
const { Meta } = Card;
// import { EditArtifactAttrsProvider } from './EditArtifactAttrsProvider';
// import { EditArtifactAttrsLayout } from './EditArtifactAttrsLayout';

// export function EditArtifactAttrs({ fields, edit, updateFields, loading }) {
//   return (
//     <EditArtifactAttrsProvider data={fields} disabled={edit} updateFields={updateFields}>
//       <EditArtifactAttrsLayout loading={loading} />
//     </EditArtifactAttrsProvider>
//   );
// }

export const EditArtifactAttrs: React.FC<EditArtifactAttrs> = ({
  fields,
  schema,
  uiSchema,
  edit,
  onChange,
  loading,
  formRef,
}) => {
  //const { genSchema } = useGeneratedSchema(schema);
  // const [formData, setFormData] = useState(fields);

  // useEffect(() => {
  //   setFormData(fields);
  // }, [fields]);

  console.log('FiELDS');
  console.log(fields);

  const title = '';
  //if (artifactSchema.formatField !== undefined && artifactSchema.properties !== undefined)
  //  title = `${artifactSchema.formatField(artifactSchema.properties.identifier, fields.identifier)} ${fields.title}`;

  return (
    <Spin spinning={loading}>
      {/*<Meta
        avatar={<FileTextTwoTone style={{ fontSize: '24px' }} />}
        description={' '}
        title={title}
        style={{ marginBottom: 20 }}
      />*/}
      {schema && uiSchema && (
        <ArtifactGeneratedForm
          formData={fields}
          schema={schema as JSONSchema6}
          uiSchema={uiSchema}
          editing={edit}
          forwardedRef={formRef}
          onChange={(e: JsObject) => {
            console.log(e.formData);
            onChange(e.formData);
          }}
        />
      )}
    </Spin>
  );
};
