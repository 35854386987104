import React, { useState, useContext, CSSProperties } from 'react';
import { Form, Divider, Col, Row } from 'antd';
import { GlobalContext } from 'contexts/GlobalContext';
import { LinkData } from 'views/artifacts/link-create-dialog/LinkCreateDialog';

import { TableWithFilterView } from 'views/artifacts/table-with-filter/TableWithFilterView';
import { LinkSourceItem } from './LinkSourceItem';
import { SelectLinkTypeFormItem } from './SelectLinkTypeFormItem';
import { SelectLinkTargetFormItem } from './SelectLinkTargetFormItem';
import { SelectModuleFormItem } from './SelectModuleFormItem';
import { SelectSearchFromFormItem } from './SelectSearchFromFormItem';
import { JsObject } from '@agentlab/sparql-jsld-client';

const divStyle2: CSSProperties = {
  position: 'relative',
};
type SpanType = { span?: number; offset?: number };
type ColType = {
  xs?: SpanType;
  md?: SpanType;
  sm?: SpanType;
};
interface FormItemLayout {
  labelCol: ColType;
  wrapperCol: ColType;
}
interface LinkCreateForm {
  data: LinkData;
  folders: JsObject[];
  views?: JsObject[];
  linkTypes: JsObject[];
  onChange: (e: LinkData) => void;
  defaultLinkTo: string[];
}

const formItemLayout: FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 6 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { offset: 3 },
    sm: { span: 14 },
  },
};

export const LinkCreateForm: React.FC<LinkCreateForm> = ({
  data,
  //folders = [],
  //views = [],
  linkTypes = [],
  onChange = () => {},
  defaultLinkTo,
}) => {
  const [reqType, setReqType] = useState<string>('requirement');
  const [searchType, setSearchType] = useState<string>('requirement');
  const { provider } = useContext(GlobalContext);

  const handleReqTypeChange = (e: JsObject): void => {
    setReqType(e.target.value);
  };

  const handleSearchTypeChange = (e: JsObject): void => {
    setSearchType(e.target.value);
  };

  function handleLinkTypeChanged({ type, direction }: { type: string; direction: string }): void {
    console.log('handleLinkTypeChanged', type, direction, data);
    onChange({ ...data, type, direction });
  }

  async function handleArtifactsChanged(artifacts: JsObject[]): Promise<void> {
    console.log('handleArtifactsChanged', artifacts);
    const art2 = await Promise.all<JsObject>(
      artifacts.map(async (e: JsObject) => {
        const o = await provider.selectObjectById('rm:Artifact', e.identifier);
        return o;
      }),
    );
    onChange({ ...data, linkTo: art2 });
  }

  return (
    <Form labelAlign='left' labelCol={{ span: 3 }} wrapperCol={{ span: 12 }}>
      <Row>
        <Col span={15}>
          <SelectLinkTypeFormItem linkTypes={linkTypes} onChange={handleLinkTypeChanged} />
        </Col>
        <Col span={9}>
          <LinkSourceItem labelFrom={data.labelFrom} />
        </Col>
      </Row>
      <Divider />
      <SelectLinkTargetFormItem onChange={handleReqTypeChange} />
      <SelectSearchFromFormItem onChange={handleSearchTypeChange} />
      {/* {searchType !== 'module' && (
        <Row>
          <Col style={{ paddingBottom: '20px' }}>
            <FilterByTag tags={defaultTags} />
          </Col>
        </Row>
      )} */}
      {searchType === 'module' && <SelectModuleFormItem />}
      {searchType !== 'module' && (
        <TableWithFilterView
          showFilters
          onSelectArtifact={handleArtifactsChanged}
          contextMenu={false}
          defaultSelection={defaultLinkTo}
        />
        // <SplitPane split='vertical' defaultSize='15%' style={divStyle2}>
        //   <Tabs defaultActiveKey='folders' size='small'>
        //     <Tabs.TabPane tab={'Папки'} size='small' key='folders'>
        //       <FilterByFolder data={folders} />
        //     </Tabs.TabPane>
        //     <Tabs.TabPane tab={'Представления'} size='small' key='views'>
        //       <ViewsPane views={views} />
        //     </Tabs.TabPane>
        //   </Tabs>
        //   <FlatTableRichText onSelectArtifact={handleArtifactsChanged} />
        // </SplitPane>
      )}
    </Form>
  );
};
