import React, { useState, useEffect, useContext } from 'react';
import Table from '../rjst-antd/JsonSchemaTable';

import { GlobalContext } from 'contexts/GlobalContext';
import { fakeViews } from 'api/view';
import { LinkCreateDialog } from 'views/artifacts/link-create-dialog/LinkCreateDialog';

import { FlatTableRichTextMenu } from './FlatTableRichTextMenu';
import { getUiSchema } from './TableColumns';

import './FlatTableRichText.css';
import { JSONSchema6forRdf, JsObject } from '@agentlab/sparql-jsld-client';

// TODO: Use view from props
const defaultView = fakeViews[0];

export interface FlatTableRichText {
  artifacts?: JsObject[];
  schema: JSONSchema6forRdf;
  view?: any;
  loading?: boolean;
  editable?: boolean;
  onEdit?: (item: JsObject, edited: JsObject) => void;
  onCreate?: (a: any) => void;
  onDelete?: (conditions: { identifier: string }[]) => void;
  onLink?: (s: JsObject[]) => void;
  onSelectArtifact?: any;
  fullTextSearchString?: string;
  setFullTextSearchString?: any;
  contextMenu?: boolean;
  defaultSelection?: string[];
}
/**
 * Falt table component for visaulize list of artifacts.
 * @param {Object}   props - component props
 * @param {Object[]} props.artifacts - the list of artifacts
 * @param {Object}   props.schema - the schema of artifact
 * @param {Object}   props.view - the current view of artifact
 * @param {Object}   props.loading - flag indicating that content is in loading state, false by default
 * @param {Function} props.onDelete - callback on delete artifact, empty by default
 * @param {Function} props.onEdit - callback on edit artifact, empty by default
 * @param {Function} props.onCreate - callback on create artifact, empty by default
 * @param {Function} props.onLink - callback on link artifacts, empty by default
 */
export const FlatTableRichText: React.FC<FlatTableRichText> = ({
  artifacts = [],
  schema,
  view = defaultView,
  loading = false,
  editable = false,
  onDelete = () => {},
  onEdit = () => {},
  onCreate = () => {},
  onLink = () => {},
  onSelectArtifact = () => {},
  fullTextSearchString = '',
  setFullTextSearchString = () => {},
  contextMenu,
  defaultSelection = [],
}) => {
  const {
    projectUsers,
    projectUsersLoading,
    artifactTypes,
    loadingArtifactTypes,
    artifactFormats,
    loadingArtifactFormats,
  } = useContext(GlobalContext);
  const [visibleProps, setVisibleProps] = useState<JsObject>(view);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [popupCoords, setPopupCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [popupRecord, setPopupRecord] = useState<JsObject>({});
  const [selection, setSelection] = useState<string[]>(defaultSelection);
  const [linKCreateDialogVisible, setLinkCreateDialogVisible] = useState<boolean>(false);
  const [uiSchema, setUISchema] = useState<JsObject>();

  useEffect(() => {
    if (schema.properties) {
      setUISchema(getUiSchema(schema, visibleProps, projectUsers, artifactTypes, artifactFormats));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, visibleProps, projectUsersLoading, loadingArtifactFormats, loadingArtifactTypes]);

  useEffect(() => {
    setVisibleProps(view);
  }, [view]);

  const updateVisibleProps = (newProp: JsObject, add: boolean) => {
    const copyOfVisibleProps = { ...visibleProps };
    if (add === true) {
      copyOfVisibleProps.columns.push(newProp);
    } else {
      copyOfVisibleProps.columns = copyOfVisibleProps.columns.filter((prop: JsObject) => prop.id !== newProp.id);
    }
    setVisibleProps(copyOfVisibleProps);
  };

  console.debug('FlatTableRichText');

  if (uiSchema && view) {
    return (
      <div>
        <Table
          size='small'
          schema={schema}
          uiSchema={uiSchema}
          setVisibleProps={updateVisibleProps}
          filteredValue={[fullTextSearchString]}
          rowClassName={() => 'editable-row'}
          rowKey={(record: any, index: number) => record['@id']}
          dataSource={artifacts}
          onChangeData={onEdit}
          rowSelection={{
            fixed: true,
            getCheckboxProps: () => null,
            selectedRowKeys: selection,
            onChange: (sel: any) => {
              console.log('SELECTION', selection, artifacts);
              setSelection(sel);
              onSelectArtifact(sel);
            },
          }}
          className='no-border'
          loading={loading}
          onRow={(record: JsObject) => ({
            onContextMenu: (event: JsObject) => {
              event.preventDefault();
              if (!popupVisible) {
                document.addEventListener(`click`, function onClickOutside() {
                  setPopupVisible(false);
                  document.removeEventListener(`click`, onClickOutside);
                });
              }
              setPopupVisible(true);
              console.log('setPopupRecord', record);
              setPopupRecord(record);
              setPopupCoords({ x: event.clientX, y: event.clientY });
            },
          })}
        />
        {contextMenu && (
          <FlatTableRichTextMenu
            x={popupCoords.x}
            y={popupCoords.y}
            record={popupRecord}
            selection={selection}
            visible={popupVisible}
            onCreateArtifactBefore={onCreate}
            onCreateArtifactAfter={onCreate}
            onDeleteArtifacts={(ids: string[]) => {
              const conditions = ids.map((id) => {
                return { identifier: id };
              });
              onDelete(conditions);
            }}
            onLinkArtifacts={() => setLinkCreateDialogVisible(true)}
          />
        )}
        <LinkCreateDialog
          artifact={popupRecord}
          visible={linKCreateDialogVisible}
          onOk={(links2: JsObject[]) => {
            setLinkCreateDialogVisible(false);
          }}
          onCancel={() => setLinkCreateDialogVisible(false)}
          defaultLinkTo={selection}
        />
      </div>
    );
  } else {
    return null;
  }
};
