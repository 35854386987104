import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Collapse, Row, List, Avatar } from 'antd';

import { convertLinksToPanel } from 'api/link/linksToLinksPanel';
import { ArtifactContext } from 'contexts/ArtifactContext';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface LinkList {
  links: JsObject[];
}

// TODO: Fix default linkData
export const LinkList: React.FC<LinkList> = ({ links = [] }: LinkList) => {
  const { artifact } = useContext(ArtifactContext);

  const converted = convertLinksToPanel(links, artifact.identifier);
  console.log('LinkList.converted', converted);

  return (
    <div>
      <Row style={{ overflow: 'auto' }}>
        <Collapse
          bordered={false}
          defaultActiveKey={converted.map((element: JsObject, index: any) => index.toString())}>
          {converted.map((element: JsObject, index: any) => (
            <Collapse.Panel key={index} header={`${element.linkType}  (${element.links.length})`}>
              <List
                size='small'
                itemLayout='horizontal'
                dataSource={element.links}
                renderItem={(item: JsObject) => (
                  <List.Item>
                    <List.Item.Meta
                      /*avatar={<Avatar size='small' icon={item.icon} />} */
                      title={
                        <Link style={{ color: '#1890ff' }} to={`/artifacts/${item.id}`}>
                          {' '}
                          {`${item.id}: ${item.link}`}{' '}
                        </Link>
                      }
                    />
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </Row>
    </div>
  );
};
