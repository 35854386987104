import React, { useState, useContext, useEffect } from 'react';
import { Modal, Spin } from 'antd';

import { LinkCreateForm } from 'components/link-create-form/LinkCreateForm';
import { ProjectContextProvider } from 'contexts/ProjectContext';

import { useViews } from 'hooks/useViews';
import { useCombineLoading } from 'hooks/useCombineLoading';
import { GlobalContext } from 'contexts/GlobalContext';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface LinkCreateDialog {
  artifact: JsObject;
  visible: boolean;
  onOk: (flatten: JsObject[]) => void;
  onCancel: () => void;
  defaultLinkTo?: string[];
}
export interface LinkData {
  labelFrom: string;
  linkFrom: JsObject;
  type: string | null;
  direction: string | null;
  linkTo: JsObject[];
}

export const LinkCreateDialog: React.FC<LinkCreateDialog> = ({
  artifact,
  visible,
  onOk,
  onCancel,
  defaultLinkTo = [],
}) => {
  const { projectLinkTypes, loadingProjectLinkTypes, projectFolders } = useContext(GlobalContext);
  //const { projectArtifacts } = useContext(ProjectContext);
  //const { loadingViews, views } = useViews();
  const { loading } = useCombineLoading(/*loadingViews,*/ loadingProjectLinkTypes);
  const [creating, setCreating] = useState<boolean>(false);

  const [data, setData] = useState<LinkData>({
    labelFrom: artifact.title,
    linkFrom: artifact,
    type: null,
    direction: null,
    linkTo: [],
  });

  function flatMap(values: LinkData) {
    const result = [];
    if (values.direction === 'subject') {
      for (let i = 0; i < values.linkTo.length; i++) {
        result.push({
          type: values.type,
          subject: values.linkFrom,
          object: values.linkTo[i],
        });
      }
    } else {
      for (let i = 0; i < values.linkTo.length; i++) {
        result.push({
          type: values.type,
          subject: values.linkTo[i],
          object: values.linkFrom,
        });
      }
    }
    return result;
  }

  const handleOk = async () => {
    setCreating(true);
    console.log('handleOk', data);
    const flatten = flatMap(data);
    onOk(flatten);
    setCreating(false);
  };

  const handleCancel = () => {
    onCancel();
  };
  useEffect(() => {
    setData({
      labelFrom: artifact.title,
      linkFrom: artifact,
      type: null,
      direction: null,
      linkTo: [],
    });
  }, [artifact]);

  if (visible === true) {
    return (
      <ProjectContextProvider>
        <Modal
          title='Создание связи'
          visible={visible}
          width='80%'
          style={{ overflow: 'hidden' }}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={creating}
          cancelText='Отмена'
          okText='Создать'>
          <Spin spinning={loading || creating}>
            <LinkCreateForm
              data={data}
              linkTypes={projectLinkTypes}
              folders={projectFolders}
              onChange={(e: LinkData) => setData(e)}
              defaultLinkTo={defaultLinkTo}
            />
          </Spin>
        </Modal>
      </ProjectContextProvider>
    );
  } else {
    return <></>;
  }
};
