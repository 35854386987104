import React, { useState } from 'react';
import { Menu, Input, Spin } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

export const ViewsPaneLoading: React.FC<{}> = () => {
  return (
    <div style={{ width: '100%', height: '200px', display: 'flex' }}>
      <Spin style={{ margin: 'auto' }} />
    </div>
  );
};

interface ViewsPane {
  views: any[];
  onClick?: Function;
  loading: boolean;
}

export const ViewsPane: React.FC<ViewsPane> = ({ views = [], onClick = () => {}, loading = true }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  if (loading) {
    return <ViewsPaneLoading />;
  } else {
    return (
      <div style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Input.Search placeholder='Поиск...' onChange={(e: JsObject) => setSearchValue(e.target.value)} />
        <Menu>
          {views
            .filter((item) => {
              if (searchValue) {
                console.log(item, searchValue);
                return item.title.toUpperCase().indexOf(searchValue.toUpperCase()) >= 0;
              } else {
                return true;
              }
            })
            .map((item) => (
              <Menu.Item
                onClick={(e) => {
                  const found = views.find((v) => v.key === e.key);
                  if (found) {
                    onClick(found);
                  } else {
                    console.error(`View with key [${e.key}] not found`);
                    console.error(views);
                  }
                }}
                key={item.key}>
                {item.title}
              </Menu.Item>
            ))}
        </Menu>
      </div>
    );
  }
};
