import { useState, useEffect } from 'react';
import { ObjectProvider } from '@agentlab/sparql-jsld-client';

export interface DataTypeInfo {
  '@id': string;
  title: string;
  value: string;
}

export interface UseDataTypesInterface {
  dataTypes: DataTypeInfo[];
  loadingDataTypes: boolean;
}

export function useDataTypes(provider: ObjectProvider): UseDataTypesInterface {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<DataTypeInfo[]>([]);

  useEffect(() => {
    async function selectData(): Promise<void> {
      const dt = await provider.selectDataType('rdfs:Datatype');
      const result: DataTypeInfo[] = dt.map((type) => {
        const foundValues = type['@id'].match(/:([\d\w-_а-яА-ЯёЁ]+)$/i);
        return {
          '@id': type['@id'],
          title: type.label,
          value: foundValues ? foundValues[1] : '',
        } as DataTypeInfo;
      });
      setData(result);
      setLoading(false);
    }
    selectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loadingDataTypes: loading,
    dataTypes: data,
  };
}
