import React, { useState, useContext } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { PlusOutlined, DownOutlined, VerticalRightOutlined, VerticalLeftOutlined } from '@ant-design/icons';

import { GlobalContext } from 'contexts/GlobalContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { JsObject } from '@agentlab/sparql-jsld-client';
import { cellStyle, rowStyle } from '../flexStyles';

import { FilterByTag } from 'components/element-filters/FilterByTag';
import { ArtifactCreateDialog } from 'views/artifacts/create-dialog/ArtifactCreateDialog';
import { AddExistingDialog } from 'views/artifacts/add-existing-dialog/AddExistingDialog';
import { compareArtifacts } from 'hooks/useArtifactTypes';

interface ActionRow {
  withCreateBtn?: boolean;
  createBntTitle?: string;
  showRightArrow?: boolean;
  showLeftArrow?: boolean;
  showAddExisting?: boolean;
  //onCreateArtifact?: (data: any) => void;
  onAddExistingArtifact?: (data: any) => void;
}

export const ActionRow: React.FC<ActionRow> = ({
  withCreateBtn = false,
  createBntTitle = '',
  showRightArrow = true,
  showLeftArrow = true,
  showAddExisting = false,
  //onCreateArtifact = async (data: any) => {},
  onAddExistingArtifact = async (): Promise<void> => {},
}) => {
  const { projectActiveFolder, artifactTypes } = useContext(GlobalContext);
  const {
    filterLoading,
    addFilter,
    removeFilter,
    filters,
    fullTextSearchString,
    setFullTextSearchString,
    artifactCreate: onCreateArtifact,
  } = useContext(ProjectContext);

  //console.log(filters);

  const [artifactType, setArtifactType] = useState<JsObject | null>(null);
  const [createDialogVisible, setCreateDialogVisible] = useState<boolean>(false);
  const [addExistingDialogVisible, setAddExistingDialogVisible] = useState<boolean>(false);
  const [filtersExpanded, setFiletersExpanded] = useState<boolean>(false);

  const artifactTypesCreatableSorted = artifactTypes
    .filter((e) => e.inCreationMenu && e.inCreationMenu === true)
    .sort(compareArtifacts);
  const menu = (
    <Menu>
      {showAddExisting && (
        <Menu.Item onClick={(): void => setAddExistingDialogVisible(true)}>Добавить существующий</Menu.Item>
      )}
      {artifactTypesCreatableSorted.map((e) => (
        <Menu.Item
          key={e['@id']}
          onClick={(value: JsObject): void => {
            setArtifactType(value.key);
            setCreateDialogVisible(true);
          }}>
          {e.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return !filtersExpanded ? (
    <div style={rowStyle}>
      {showLeftArrow && (
        <div style={cellStyle}>
          <Button type='default' icon={<VerticalRightOutlined />} />
        </div>
      )}
      <div style={{ ...cellStyle }}>
        {withCreateBtn && (
          <Dropdown overlay={menu} placement='bottomLeft' trigger={['click']}>
            <Button type='primary' icon={<PlusOutlined />}>
              {createBntTitle}
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
        {createDialogVisible && (
          <ArtifactCreateDialog
            onOk={async (data) => {
              try {
                await onCreateArtifact(data);
                setCreateDialogVisible(false);
              } catch (error) {
                console.error(error);
              }
            }}
            onCancel={() => setCreateDialogVisible(false)}
            assetFolder={projectActiveFolder}
            artifactType={artifactType}
          />
        )}
        <AddExistingDialog
          visible={addExistingDialogVisible}
          onOk={async (sel: any) => {
            await onAddExistingArtifact(sel);
            setAddExistingDialogVisible(false);
          }}
          onCancel={() => setAddExistingDialogVisible(false)}
        />
      </div>
      <div style={cellStyle}>
        <FilterByTag
          addFilter={addFilter}
          removeFilter={removeFilter}
          loading={filterLoading}
          expanded={filtersExpanded}
          onExpand={(val = false) => setFiletersExpanded(val)}
          tags={filters}
          fullTextSearchString={fullTextSearchString}
          setFullTextSearchString={setFullTextSearchString}
        />
      </div>
      {showRightArrow && (
        <div style={cellStyle}>
          <Button type='default' icon={<VerticalLeftOutlined />} />
        </div>
      )}
    </div>
  ) : (
    <div>
      <div style={rowStyle}>
        {showLeftArrow && (
          <div style={cellStyle}>
            <Button type='default' icon={<VerticalRightOutlined />} />
          </div>
        )}
        <div style={{ ...cellStyle }}>
          {withCreateBtn && (
            <Dropdown overlay={menu} placement='bottomLeft' trigger={['click']}>
              <Button type='primary' icon={<PlusOutlined />}>
                {createBntTitle}
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
          {createDialogVisible && (
            <ArtifactCreateDialog
              onOk={async (data) => {
                try {
                  await onCreateArtifact(data);
                  setCreateDialogVisible(false);
                } catch (error) {
                  console.error(error);
                }
              }}
              onCancel={() => setCreateDialogVisible(false)}
              assetFolder={projectActiveFolder}
              artifactType={artifactType}
            />
          )}
        </div>
        {showRightArrow && (
          <div style={cellStyle}>
            <Button type='default' icon={<VerticalLeftOutlined />} />
          </div>
        )}
      </div>
      <div style={rowStyle}>
        <div style={cellStyle}>
          <FilterByTag
            addFilter={addFilter}
            removeFilter={removeFilter}
            loading={filterLoading}
            expanded={filtersExpanded}
            onExpand={(val = false) => setFiletersExpanded(val)}
            tags={filters}
            fullTextSearchString={fullTextSearchString}
            setFullTextSearchString={setFullTextSearchString}
          />
        </div>
      </div>
    </div>
  );
};
