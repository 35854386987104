import React from 'react';
import { Form, Select } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface SelectModuleFormItem {
  disabled?: boolean;
  onChange?: (e: JsObject) => void;
  rest?: JsObject[];
}
export const SelectModuleFormItem: React.FC<SelectModuleFormItem> = ({
  disabled = false,
  onChange,
  ...rest
}: SelectModuleFormItem) => {
  return (
    <Form.Item
      {...rest}
      style={{
        display: 'inline-block',
      }}>
      <Select disabled={disabled} style={{ width: 300 }} placeholder='Выберите модуль'>
        <Select.Option value='all_modules'>Все модули</Select.Option>
        <Select.Option value='csv'>Загружено из CSV</Select.Option>
        <Select.Option value='out'>ЧТЗ Взаимодействия с внешними инструментами</Select.Option>
        <Select.Option value='chtz'>ЧТЗ Управление требованиями</Select.Option>
      </Select>
    </Form.Item>
  );
};
