import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button, Divider } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
// Components
import { LockButtonsPanel } from 'components/lock-button/LockButtonsPanel';
import { ActionRow } from './rows/ActionRow';
// Contexts
import { GlobalContext } from 'contexts/GlobalContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { ArtifactModuleContext } from 'contexts/ArtifactModuleContext';

import { ArtifactModuleHistoryView } from 'views/artifacts/module-history-view/ArtifactModuleHistoryView';

import { defaultMenuItems, defaultOkHandler } from 'components/main-toolbar/hamburger-menu/HamburgerMenu';

const artifactModuleMenuList = defaultMenuItems.concat([
  {
    key: 'history',
    view: ArtifactModuleHistoryView,
    handleOk: defaultOkHandler,
  },
]);

interface ArtifactModuleToolbar {
  id: string;
  title: string;
  edit: boolean;
  onEdit: () => void;
  onSave: () => void;
  onDone: () => void;
  onCancel: () => void;
  onBack: () => void;
  onRefresh: () => void;
  updateTitle: (title: string) => void;
}

export const ArtifactModuleToolbar: React.FC<ArtifactModuleToolbar> = ({
  id,
  title,
  edit,
  onEdit,
  onSave,
  onDone,
  onCancel,
  onRefresh,
  updateTitle,
  onBack = () => {},
}) => {
  const { t } = useTranslation();
  const { artifactTypes, projectActiveFolder } = useContext(GlobalContext);
  //const { artifactCreate } = useContext(ProjectContext);
  const { moduleArtifactsLink } = useContext(ArtifactModuleContext);

  /**
   * Handler for create new artifact and link it to collection.
   * @param {Object} data - new artifact data
   */
  const handleCreateArtifact = async (data: any) => {
    try {
      //const created = await artifactCreate(data);
      //await moduleArtifactsLink(created.identifier);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Handler for link existing artifact to collection.
   * @param {String[]} selection - array of selected artifact identifiers
   */
  const handleAddExistingArtifact = async (selection: any[]) => {
    switch (selection.length) {
      case 0:
        console.warn('Selection is empty, do nothing');
        break;
      case 1:
        await moduleArtifactsLink(selection[0]);
        break;
      default:
        await moduleArtifactsLink(selection[0]);
        console.warn('Supported only single selection yet, link only first selected artifact');
        break;
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '12px',
          height: '52px',
        }}>
        <div style={{ flex: '0 0 auto', marginRight: '24px' }}>
          <Button shape='circle' icon={<LeftOutlined />} style={{ marginLeft: 8 }} size='small' onClick={onBack} />
        </div>
        <div style={{ flex: '1 0 auto' }}>
          {edit ? (
            <Input
              style={{ fontSize: 18 }}
              addonBefore={id}
              defaultValue={title}
              size='large'
              onChange={(e) => updateTitle(e.target.value)}
            />
          ) : (
            <h2 style={{ fontSize: 18, marginBottom: 0 }}>
              <strong style={{ paddingRight: 10 }}>{id}</strong>
              <span>{title}</span>
            </h2>
          )}
        </div>
        <div style={{ flex: '0 0 auto' }}>
          <LockButtonsPanel
            style={{ float: 'right', display: 'flex' }}
            editMode={edit}
            editData={true}
            onEdit={onEdit}
            onCancel={onCancel}
            onSave={onSave}
            onDone={onDone}
            onRefresh={onRefresh}
            hamburgerMenuList={artifactModuleMenuList}
          />
        </div>
      </div>
      {/*<ActionRow
        showAddExisting
        withCreateBtn={true}
        createBntTitle={t('ArtifactModuleToolbar.create')}
        //creationMenu={artifactTypes}
        //activeFolder={projectActiveFolder}
        showRightArrow={true}
        //context={moduleContext}
        //onCreateArtifact={handleCreateArtifact}
        onAddExistingArtifact={handleAddExistingArtifact}
      />*/}
      <Divider style={{ margin: 0, background: 'rgb(220, 220, 220)' }} />
    </div>
  );
};
