import React, { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { FilterByFolder } from 'components/element-filters/FilterByFolder';
import { Widget } from 'react-jsonschema-form';
import { JsObject } from '@agentlab/sparql-jsld-client';

export const FolderWidget: Widget = ({ value, readonly, onChange = () => {} }) => {
  const { projectFolders } = useContext(GlobalContext);
  if (readonly) {
    const folder = (projectFolders.find((t: JsObject) => t['@id'] === value) || {}).title || '';
    return <span>{folder || value}</span>;
  }
  return (
    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
      <FilterByFolder folders={projectFolders} selection={value} onSelect={(e: string) => onChange(e)} />
    </div>
  );
};
