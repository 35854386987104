import React from 'react';

interface Sprite {
  filename: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export const Sprite: React.FC<Sprite> = ({ filename, x = 0, y = 0, width = 0, height = 0 }: Sprite) => {
  if (!filename) {
    return null;
  }

  const style: React.CSSProperties = {
    backgroundImage: `url(${filename})`,
    backgroundPosition: `${x * -1}px ${y * -1}px`,
    backgroundRepeat: 'no-repeat',
    width,
    height,
  };

  return <div style={style} />;
};
