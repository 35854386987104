import React, { useState, useEffect } from 'react';

import { Table, Checkbox } from 'antd';
interface DefaultCheck {
  title: string;
  children?: DefaultCheck[];
}
interface TableWithCheckBox {
  columns: any[];
  dataSource: any[];
  checkboxHeader: string;
  onSave: (checked: string[], state: boolean) => any;
  defaultCheck: DefaultCheck[];
}

export const TableWithCheckBox: React.FC<TableWithCheckBox> = ({
  columns,
  dataSource,
  checkboxHeader = '',
  onSave,
  defaultCheck,
}) => {
  const [stateTree, setStateTree] = useState<any>();
  const [currentData, setCurrentData] = useState<any>();

  function checkAll(key: string, state: boolean, checked: string[]) {
    stateTree[key].data.active.state = state;
    stateTree[key].data.active.indeterminate = false;
    stateTree[key].currentIndeterminate = 0;
    if (stateTree[key].size !== 0) {
      stateTree[key].current = state ? stateTree[key].size : 0;
      for (let i = 0; i < stateTree[key].size; i++) {
        checkAll(key + '.' + i, state, checked);
      }
    } else {
      checked.push(stateTree[key].data.title);
    }
  }

  function onChange(key: any, state: any, className: any) {
    const checked: string[] = [];
    checkAll(key, state, checked);
    /*const index = key.lastIndexOf('.');
    const newState = { ...stateTree };
    if (index !== -1) {
      checkChanges(key.slice(0, index), state, className.lastIndexOf('indeterminate') !== -1, false, false, newState);
    }
    setStateTree(newState);*/
    onSave(checked, state);
  }

  const customColumns = [
    ...columns,
    {
      key: 'active',
      dataIndex: 'active',
      title: checkboxHeader,
      render: (e: any) => {
        return (
          <Checkbox
            value={e.key}
            key={e.key}
            checked={e.state}
            indeterminate={e.indeterminate}
            onChange={(event) => onChange(event.target.value, event.target.checked, event.target.className)}
          />
        );
      },
    },
  ];

  function setIndeterminate(index: string) {
    if (index.lastIndexOf('.') !== -1) {
      const pos = index.slice(0, index.lastIndexOf('.'));
      stateTree[pos].currentIndeterminate++;
      if (stateTree[pos].currentIndeterminate === 1) {
        stateTree[pos].data.active.indeterminate = true;
        setIndeterminate(pos);
      }
    }
  }

  function checkChanges(key: string, state: boolean, oldI: boolean, i: boolean, start: boolean, newState: any) {
    if ((!i && !oldI) || (oldI && !i && state && !start)) newState[key].current += state ? 1 : -1;
    if (start && !state) newState[key].current -= 1;
    if (!oldI && i) newState[key].currentIndeterminate++;
    if (oldI && !i) newState[key].currentIndeterminate--;
    const index = key.lastIndexOf('.');
    const oldIndeterminate = newState[key].data.active.indeterminate;
    if (newState[key].size === newState[key].current && newState[key].currentIndeterminate === 0) {
      newState[key].data.active.state = true;
      newState[key].data.active.indeterminate = false;
      if (index !== -1) {
        checkChanges(
          key.slice(0, index),
          state,
          oldIndeterminate,
          newState[key].data.active.indeterminate,
          start,
          newState,
        );
      }
    } else {
      newState[key].data.active.state = false;
      if (newState[key].current === 0 && newState[key].currentIndeterminate === 0) {
        newState[key].data.active.indeterminate = false;
        if (index !== -1) {
          checkChanges(
            key.slice(0, index),
            state,
            oldIndeterminate,
            newState[key].data.active.indeterminate,
            start,
            newState,
          );
        }
      } else {
        if (!oldIndeterminate) setIndeterminate(key);

        newState[key].data.active.indeterminate = true;
      }
    }
  }

  function createDefaultState(elements: any, index: string, state: any) {
    return elements.map((e: any, key: any) => {
      const newIndex = index + key;
      const children = 'children' in e;
      const available = 'available' in e && e.available;
      const newData = {
        ...e,
        children: children ? createDefaultState(e.children, newIndex + '.', state) : undefined,
        active: {
          state: children || available,
          indeterminate: true,
          key: newIndex,
          onChange,
        },
      };
      state[newIndex] = {
        key: newIndex,
        data: newData,
        size: children ? e.children.length : 0,
        current: children ? e.children.length : 0,
        currentIndeterminate: children ? e.children.length : 0,
      };
      return newData;
    });
  }

  function createdefaultData(elements: any, index: string) {
    const newState = {};
    const newData = createDefaultState(elements, index, newState);
    return {
      newData,
      newState,
    };
  }

  function createDefaultLogic(state: any) {
    for (const key in state) {
      const index = key.lastIndexOf('.');
      if (state[key].size === 0) {
        if (index !== -1) {
          checkChanges(
            key.slice(0, index),
            state[key].data.active.state,
            state[key].data.active.indeterminate,
            false,
            true,
            state,
          );
        }
        state[key].data.active.indeterminate = false;
      }
    }
  }

  useEffect(() => {
    const { newData, newState } = createdefaultData(dataSource, '');
    createDefaultLogic(newState);
    console.log('NEWSTATE', newState);
    setCurrentData(newData);
    setStateTree(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  return <Table size='small' pagination={false} columns={customColumns} dataSource={currentData} />;
};
