import React from 'react';
import { Divider } from 'antd';
import { PlusOutlined, DeleteTwoTone, LinkOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './FlatTableRichTextMenu.css';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface FlatTableRichTextMenu {
  record: JsObject;
  selection: any[];
  visible: boolean;
  x: number | string;
  y: number | string;
  onCreateArtifactBefore: (o: any) => void;
  onCreateArtifactAfter: (o: any) => void;
  onDeleteArtifacts: (o: any) => void;
  onLinkArtifacts: (o: JsObject | any[]) => void;
}

export const FlatTableRichTextMenu: React.FC<FlatTableRichTextMenu> = ({
  record,
  selection,
  visible,
  x,
  y,
  onCreateArtifactBefore,
  onCreateArtifactAfter,
  onDeleteArtifacts,
  onLinkArtifacts,
}) => {
  const { t } = useTranslation();
  if (visible) {
    return (
      <ul className='popup' style={{ left: `${x}px`, top: `${y}px`, position: 'fixed' }}>
        <li
          onClick={() => (selection.length === 0 ? onCreateArtifactBefore(record) : onCreateArtifactBefore(selection))}>
          <PlusOutlined />
          {selection.length === 0
            ? t('table.menu.createArtifactBefore')
            : t('table.menu.createArtifactBefore', { count: selection.length })}
        </li>
        <li onClick={() => (selection.length === 0 ? onCreateArtifactAfter(record) : onCreateArtifactAfter(selection))}>
          <PlusOutlined />
          {selection.length === 0
            ? t('table.menu.createArtifactAfter')
            : t('table.menu.createArtifactAfter', { count: selection.length })}
        </li>
        <Divider style={{ margin: '2px' }} />
        <li
          onClick={() =>
            selection.length === 0 ? onDeleteArtifacts([record.identifier]) : onDeleteArtifacts(selection)
          }>
          <DeleteTwoTone twoToneColor='red' />
          {selection.length === 0
            ? t('table.menu.deleteArtifacts')
            : t('table.menu.deleteArtifacts', { count: selection.length })}
        </li>
        <Divider style={{ margin: '2px' }} />
        <li onClick={() => (selection.length === 0 ? onLinkArtifacts(record) : onLinkArtifacts(selection))}>
          <LinkOutlined style={{ color: '#08c' }} />
          {selection.length === 0
            ? t('table.menu.linkArtifacts')
            : t('table.menu.linkArtifacts', { count: selection.length })}
        </li>
      </ul>
    );
  } else {
    return null;
  }
};
