import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface RichTextItem {
  text: string;
  row: JsObject;
  onEdit?: (row: JsObject, content: string) => void;
}

export const RichTextItem: React.FC<RichTextItem> = ({ text, row, onEdit = () => {} }) => {
  //console.log("render cell.text=", text);
  //console.log("render cell.row=", row);
  //console.log("render cell.index=", index);
  //const { editIndex, setEditIndex, dataSource, setDataSource } = useContext(TreeTableRichTextContext);
  //console.log("editIndex=", editIndex);

  const { t, i18n } = useTranslation();

  const handleSave = (editor: JsObject, row: JsObject) => {
    console.log('handleSave.editor=', editor);
    console.log('handleSave.row_id=', row);
    //let newDataSource = dataSource;
    //let editedRow = searchTree(newDataSource, (elem) => elem.id === row_id);
    //editedRow.content = editor.target.getContent();
    //setDataSource(newDataSource);
    //setEditIndex(null);
    onEdit(row, editor.target.getContent());
  };
  return (
    <div style={{ width: '100%' }}>
      <Editor
        initialValue={text}
        init={{
          branding: false,
          menubar: false,
          inline: true,
          // eslint-disable-next-line @typescript-eslint/camelcase
          language_url: `${process.env.PUBLIC_URL}/lang/ru.js`,
          language: i18n.language,
          skin: 'oxide',
          // eslint-disable-next-line @typescript-eslint/camelcase
          skin_url: `${process.env.PUBLIC_URL}/skins/ui/oxide`,
          theme: 'silver',
          // eslint-disable-next-line @typescript-eslint/camelcase
          theme_url: `${process.env.PUBLIC_URL}/themes/silver/theme.min.js`,
        }}
        toolbar='undo redo | formatselect | bold italic strikethrough forecolor backcolor | table image paste link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat'
        plugins='table image lists paste link'
        onBlur={(editor) => {
          console.log('onBlur.e=', editor);
          handleSave(editor, row);
        }}
      />
    </div>
  );
};
