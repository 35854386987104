import React, { CSSProperties } from 'react';
import { JSONSchema6 } from 'json-schema';
import { Button, Row, Col } from 'antd';

import { CustomForm } from '../../custom-form/CustomForm';
import { ArrayRowTemplate } from '../antd-fields/templates/ArrayRowTemplate';
import { widgets } from '../widgets';
import { HorizontalTemplate } from 'components/generated-form/field-templates/HorizontalTemplate';
import './ArtifactGeneratedForm.css';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface ArtifactGeneratedForm {
  schema: JSONSchema6;
  uiSchema: JsObject;
  onSubmit?: () => void;
  formData: JsObject;
  forwardedRef?: React.Ref<any>;
  onChange: (e: JsObject) => void;
  style?: CSSProperties;
  withButton?: boolean;
  editing: boolean;
}

export const ArtifactGeneratedForm: React.FC<ArtifactGeneratedForm> = ({
  schema,
  uiSchema,
  onSubmit,
  formData,
  forwardedRef,
  onChange = () => {},
  style = {},
  withButton = false,
  editing = false,
}: ArtifactGeneratedForm) => {
  const getSchemaWithReadonly = (schema: JsObject, uiSchema: JsObject) => {
    const result: JsObject = {};
    // Add 'ui:readonly' for all items
    console.log('getSchemaWithReadonly', { schema, uiSchema });
    for (const key in schema.properties) {
      //console.log('getSchemaWithReadonly prop', { key, uiSchema: uiSchema[key] });
      const element = uiSchema[key];
      if (element && element['ui:disabled'] && element['ui:disabled'] === true) {
        result[key] = {
          ...element,
          'ui:readonly': true,
        };
      } else {
        result[key] = {
          ...element,
          'ui:readonly': !editing,
        };
      }
    }
    return result;
  };

  return (
    <div style={style}>
      <CustomForm
        schema={schema}
        onChange={onChange}
        onSubmit={onSubmit}
        uiSchema={getSchemaWithReadonly(schema, uiSchema)}
        formData={formData}
        widgets={widgets}
        // fields={typesToFields}
        FieldTemplate={HorizontalTemplate}
        ArrayFieldTemplate={ArrayRowTemplate}
        // disabled={editing}
        liveValidate
        showErrorList={false}
        ref={forwardedRef}>
        <div style={{ display: withButton ? 'block' : 'none' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </div>
      </CustomForm>
    </div>
  );
};
