import React from 'react';
import { Row, Col, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Widget } from 'react-jsonschema-form';

export const UriWithCopyWidget: Widget = ({ value, readonly, onChange = (): void => {} }) => {
  return (
    <Row>
      <Col span={18}>
        <span className='ant-uri' id='ant-uri' data-uri={value}>
          {value}
        </span>
      </Col>
      <Col span={4}>
        <Button
          icon={<CopyOutlined />}
          style={{ marginLeft: '7px' }}
          size='small'
          onClick={() => {
            const temp = document.createElement('textarea');
            temp.textContent = value;
            const body = document.getElementsByTagName('body')[0];
            body.appendChild(temp);
            temp.select();
            document.execCommand('copy');
            body.removeChild(temp);
          }}
        />
      </Col>
    </Row>
  );
};
