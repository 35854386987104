import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';

import { Radio } from 'antd';

import SplitPane from 'react-split-pane';

import { ElementsTree } from '../reusable-components/ElementsTree/ElementsTree';
import { TableWithCheckBox } from './TableWithCheckBox';
import { PermissionsAndRolesContext } from 'contexts/PermissionsAndRolesContext';
//import { PropertyTable } from "../reusable-components/PropertyTable/PropertyTable";

interface Columns {
  key: string;
  dataIndex: string;
  title: string;
}
interface DefaultCheck {
  title: string;
  children?: DefaultCheck[];
}

function onChange(pagination: string, filters: any, sorter: any) {
  console.log('params', pagination, filters, sorter);
}

const divStyle: React.CSSProperties = {
  padding: '5px',
  overflow: 'auto',
};

const tableOpsColumns: Columns[] = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Операция',
  },
];
const usersRolesColumns: Columns[] = [
  {
    key: 'title',
    dataIndex: 'title',
    title: 'Роль',
  },
];

const defaultCheck: DefaultCheck[] = [
  {
    title: 'Папки проекта',
    children: [
      {
        title: 'Просмотр папок проекта',
      },
      {
        title: 'Создание, изменение и удаление папок проекта',
      },
    ],
  },
  {
    title: 'Требования',
    children: [
      {
        title: 'Создание требований',
        children: [
          {
            title: 'Создание требований типа Классификатор',
          },
        ],
      },
      {
        title: 'Просмотр требований',
        children: [
          {
            title: 'Просмотр требований типа Классификатор',
          },
        ],
      },
      {
        title: 'Изменение требований',
        children: [
          {
            title: 'Изменение требований типа Классификатор',
          },
        ],
      },
      {
        title: 'Удаление требований',
        children: [
          {
            title: 'Удаление требований типа Классификатор',
          },
        ],
      },
    ],
  },
];

export const PermissonsRoles: React.FC<{}> = () => {
  const [byOps, setByOps] = useState<boolean>(true);
  const { artifactTypes } = useContext(GlobalContext);
  const { projectPolicyRoles, projectUsersOpsByGroups, projectPermissions, setProjectPermissions } = useContext(
    PermissionsAndRolesContext,
  );
  const [currentOpsByGroups, setCurrentOpsByGroups] = useState<any>();
  const [expandedGroupsKeys, setExpandedGroupsKeys] = useState<string[]>(['Папки проекта']);
  const [expandedRoleKeys, setExpandedRolesKeys] = useState<string[]>(['Гость']);
  const [selectedRole, setSelectedRole] = useState<string>('Гость');
  const [selectedGroup, setSelectedGroup] = useState<string | string[]>('Папки проекта');
  const [currentRolesTreeElements, setCurrentRolesTreeElements] = useState<any[]>([]);
  const opsHeader = 'Операция';
  const rolesHeader = 'Роль';

  const onByOpsChange = (e: any) => {
    setByOps(e.target.value);
  };

  let RightComponent: any;
  let LeftComponent: any;

  const onNodeSelect = (key: any, e: any, typeTree: string) => {
    if (typeTree === 'groupsTree') {
      setSelectedGroup(e.node.props.dataRef.title);
      return true;
    }
    if (typeTree === 'rolesTree') {
      setSelectedRole(e.node.props.dataRef.title);
      return true;
    }
    return false;
  };

  const handleRolesExpand = (expandedKeys: string[], { expanded, node }: any) => {
    if (!expanded) {
      setExpandedRolesKeys(expandedRoleKeys.filter((item) => item !== node.props.dataRef.key));
    } else {
      setExpandedRolesKeys(expandedRoleKeys.concat([node.props.dataRef.key]).filter((v, i, a) => a.indexOf(v) === i));
    }
  };
  const handleGroupsExpand = (expandedKeys: string[], { expanded, node }: any) => {
    if (!expanded) {
      setExpandedGroupsKeys(expandedGroupsKeys.filter((item) => item !== node.props.dataRef.key));
    } else {
      setExpandedGroupsKeys(
        expandedGroupsKeys.concat([node.props.dataRef.key]).filter((v, i, a) => a.indexOf(v) === i),
      );
    }
  };

  function onSaveRoles(checked: any, state: any) {
    const newData = { ...projectPermissions };
    newData[selectedRole] = state
      ? newData[selectedRole].concat(checked)
      : newData[selectedRole].filter((e: any) => checked.indexOf(e) === -1);
    setProjectPermissions(newData);
  }

  function onSaveGroups(checked: any, state: any) {
    console.log('CHECK;lkl;kl;kl;l;ED', checked);
    const newData = { ...projectPermissions };
    if (state) {
      newData[checked].push(selectedGroup);
    } else {
      newData[checked] = newData[checked].filter((e: any) => e !== selectedGroup);
    }
    console.log('CHECK;lkl;kl;kl;l;ED', checked);
    setProjectPermissions(newData);
  }

  if (byOps) {
    LeftComponent = (
      <ElementsTree
        header={opsHeader}
        elementsTreeData={currentOpsByGroups}
        defaultExpandedKeys={expandedGroupsKeys}
        defaultSelectedKey={selectedGroup[0]}
        onNodeSelect={(key: any, e: any) => onNodeSelect(key, e, 'groupsTree')}
        onNodeExpand={handleGroupsExpand}
      />
    );

    RightComponent = (
      <TableWithCheckBox
        columns={usersRolesColumns}
        dataSource={currentRolesTreeElements}
        defaultCheck={defaultCheck}
        onSave={onSaveGroups}
        checkboxHeader='Разрешено'
      />
    );
  } else {
    LeftComponent = (
      <ElementsTree
        header={rolesHeader}
        defaultExpandedKeys={['Гость']}
        defaultSelectedKey={selectedRole}
        onNodeExpand={handleRolesExpand}
        elementsTreeData={currentRolesTreeElements}
        onNodeSelect={(key: any, e: any) => onNodeSelect(key, e, 'rolesTree')}
      />
    );

    RightComponent = (
      <TableWithCheckBox
        columns={tableOpsColumns}
        dataSource={currentOpsByGroups}
        defaultCheck={defaultCheck}
        onSave={(checked, state) => onSaveRoles(checked, state)}
        checkboxHeader='Разрешено'
      />
    );
  }
  useEffect(() => {
    const newData = projectUsersOpsByGroups.map((item: any) => {
      if (item.groupName === 'Требования') {
        return {
          key: item.groupName,
          title: item.groupName,
          children: item.roles.map((role: string) => {
            return {
              key: role,
              title: role,
              children: artifactTypes.map((type: any) => {
                return {
                  key: role + ' типа ' + type.title,
                  title: role + ' типа ' + type.title,
                  available: projectPermissions[selectedRole].indexOf(role + ' типа ' + type.title) !== -1,
                };
              }),
            };
          }),
        };
      }
      return {
        key: item.groupName,
        title: item.groupName,
        children: item.roles.map((role: any) => {
          return {
            key: role,
            title: role,
            available: projectPermissions[selectedRole].indexOf(role) !== -1,
          };
        }),
      };
    });
    setCurrentOpsByGroups(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUsersOpsByGroups, selectedRole, projectPermissions]);

  useEffect(() => {
    const newData = projectPolicyRoles.map((item: any) => {
      return {
        key: item,
        title: item,
        available: projectPermissions[item].indexOf(selectedGroup) !== -1,
      };
    });
    setCurrentRolesTreeElements(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectPolicyRoles, selectedGroup, projectPermissions]);

  return (
    <div>
      <span> Показывать по: </span>
      <Radio.Group onChange={onByOpsChange} value={byOps}>
        <Radio.Button value={true}>операциям</Radio.Button>
        <Radio.Button value={false}>ролям</Radio.Button>
      </Radio.Group>
      <SplitPane split='vertical' defaultSize='20%' style={{ position: 'relative' }} size='small'>
        <div style={divStyle}>{LeftComponent}</div>
        <div style={divStyle}>{RightComponent}</div>
      </SplitPane>
    </div>
  );
};
