import * as d3 from 'd3';
import * as d3Hierarchy from 'd3-hierarchy';
import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import './LinkDendrogram.css';

export interface LinkDendogramElement {
  id: number;
  icon: string;
  title: string;
  relation?: string;
  children?: LinkDendogramElement[] | null;
}

export interface LinkDendogramGraph {
  data: LinkDendogramElement;
  handleTransform?: (e: number) => void;
}

export const LinkDendrogram = React.forwardRef(({ data, handleTransform }: LinkDendogramGraph, ref: React.Ref<any>) => {
  const [zoomCoef, setZoomCoef] = useState<number>(1);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  let node: any = null;
  let scrollInput: any = null;

  const getRef = (node2: any) => {
    node = node2;
  };

  const getInputRef = (input: any) => {
    scrollInput = input;
  };

  /*useEffect(() => {
    const createDendrogram = () => {
      console.log('creating dgram');
      const nodeWidth = 220;
      const dx = 70;
      const margin = {
        top: 10 + dx / 2,
        right: 120,
        bottom: 10 + dx / 2,
        left: 40 + nodeWidth,
      };
      const dy = 350;

      const tree = d3.tree().nodeSize([dx, dy]);

      const linkStepCurve = ({ source, target }: any): string => {
        return (
          'M' +
          source.y +
          ',' +
          source.x +
          'L' +
          (source.y * 4 + target.y * 1) / 5 +
          ',' +
          source.x +
          'L' +
          (source.y * 4 + target.y * 1) / 5 +
          ',' +
          target.x +
          'L' +
          target.y +
          ',' +
          target.x
        );
      };

      let root: d3Hierarchy.HierarchyRectangularNode<LinkDendogramElement>;
      root = d3Hierarchy.hierarchy(data);


      //let treemapRootNode: d3Hierarchy.HierarchyRectangularNode<LinkDendogramElement>;
      //const root = d3Hierarchy.hierarchy(data);

      root.x0 = dy / 2;
      root.y0 = 0;
      root.descendants().forEach((d, i) => {
        d.id = i;
        d._children = d.children;
        d.children = null;
        // if (d.depth && d.data.title.length !== 7) d.children = null;
      });

      const oldSvg = d3.select(node);
      console.log(oldSvg.selectAll('g'));
      oldSvg.selectAll('g').remove();

      const zoomed = (d) => {
        const currentTransform = d3.event.transform;
        if (d3.event.sourceEvent.type !== 'wheel') {
          currentTransform.k = zoomCoef;
          // currentTransform.x = currentTransform.invertX(currentTransform.x);
          // currentTransform.y = currentTransform.invertY(currentTransform.y);
        }
        // console.log(d3.event.sourceEvent);
        main.attr('transform', currentTransform);
        handleTransform(currentTransform.k);

        setZoomCoef(currentTransform.k);
        setX(currentTransform.x);
        setY(currentTransform.y);
      };

      const slided = (d) => {
        main.attr('transform', 'translate(' + x + ' , ' + y + ') ' + 'scale(' + d + ')');
        // zoom.scaleTo(main, d);
        setZoomCoef(d);
      };

      // this.dragged = (d) => {
      // 	console.log("dragged", this.state.zoomCoef);
      //     d3.select(this).attr("cx", d.x = d3.event.x).attr("cy", d.y = d3.event.y).attr(this.state.zoomCoef);
      // }

      // function dragended(d) {
      //     d3.select(this).classed("dragging", false);
      // }

      // function dragstarted(d) {
      // 	console.log("dragged", this.state.zoomCoef);
      //     d3.event.sourceEvent.stopPropagation();
      //     d3.select(this).classed("dragging", true);
      // }

      const zoom = d3
        .zoom()
        .scaleExtent([1, 10])
        .on('zoom', zoomed);

      // const drag = d3.drag()
      //     .subject(function (d) { return d; })
      //     .on("start", dragstarted)
      //     .on("drag", this.dragged)
      //     .on("end", dragended);

      const svg = d3
        .select(node)
        .attr('width', width)
        .attr('height', dx)
        .attr('viewBox', [-margin.left, -margin.top, width, dx])
        .style('font', '10px sans-serif')
        .style('user-select', 'none')
        .call(zoom);

      const main = svg.append('g');

      // .classed('g-main', true);

      // const slider = d3.select(this.scrollInput)
      // .attr("type", "range")
      // .attr("value", zoom.scaleExtent()[0])
      // .attr("min",   zoom.scaleExtent()[0])
      // .attr("max",   zoom.scaleExtent()[1])
      // .attr("step", (zoom.scaleExtent()[1] - zoom.scaleExtent()[0]) / 100)
      // .on("input", this.slided);

      // this.svg.call(this.zoom);

      const gLink = main
        .append('g')
        .attr('fill', 'none')
        .attr('stroke', '#555')
        .attr('stroke-opacity', 1)
        .attr('stroke-width', 1.5);

      const gNode = main.append('g').attr('cursor', 'pointer');

      const update = (source: any) => {
        const duration = d3.event && d3.event.altKey ? 2500 : 250;
        const nodes = root.descendants().reverse();
        const links = root.links();

        // Compute the new tree layout.
        tree(root);

        let left = root;
        let right = root;

        const leftY = root;
        let rightY = root;
        root.eachBefore((node) => {
          if (node.x < left.x) {
            left = node;
          }
          if (node.x > right.x) {
            right = node;
          }
          if (node.y < leftY.y) {
            left = node;
          }
          if (node.y > rightY.y) {
            rightY = node;
          }
        });

        const newWidth = width; //Math.max(width, rightY.y - leftY.y + margin.left + margin.right);
        // this.setState({ ...this.state, width : newWidth, height});

        const transition = svg
          .transition()
          .duration(duration)
          .attr('width', newWidth)
          .attr('height', height)
          .attr('viewBox', [-margin.left, left.x - margin.top, width, height])
          .tween('resize', window.ResizeObserver ? null : () => () => svg.dispatch('toggle'));

        // Update the nodes…
        const node = gNode.selectAll('g').data(nodes, (d) => d.id);

        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node
          .enter()
          .append('g')
          .attr('transform', (d) => `translate(${source.y0},${source.x0})`)
          .attr('fill-opacity', 0)
          .attr('stroke-opacity', 0);
        // .on('click', d => {
        // 	d.children = d.children ? null : d._children;
        // 	update(d);
        // });

        nodeEnter
          .append('circle')
          .attr('r', 2.5)
          .attr('fill', (d) => (d._children ? '#555' : '#999'));

        nodeEnter
          .append('text')
          .attr('dy', '0.31em')
          .attr('x', (d) => (d._children ? -6 : 6))
          .attr('text-anchor', (d) => (d._children ? 'end' : 'start'))
          // .text(d => d.data.name)
          .clone(true)
          .lower()
          .attr('stroke-linejoin', 'round')
          .attr('stroke-width', 3)
          .attr('stroke', 'white');

        const nodeDiv = nodeEnter
          .append('foreignObject')
          .attr('x', -nodeWidth - 10)
          .attr('y', -(dx - 10) / 2)
          .attr('width', 250)
          .attr('height', 70)
          .append('xhtml:div')
          .append('div')
          .attr('class', 'node');

        nodeDiv
          .append('div')
          .html((d) => ReactDOMServer.renderToStaticMarkup(<Icon style={{ margin: 5 }} type={d.data.icon} />));
        nodeDiv.append('text').text((d) => `${d.data.id} : ${d.data.title}`);
        nodeDiv
          .append('text')
          .attr('class', (d) => (d.data.relation ? 'node-link' : 'd-none'))
          .text((d) => d.data.relation);
        nodeDiv
          .append('div')
          .attr('class', 'node-collapse')
          .attr('style', (d) => `display: ${d._children ? 'inherit' : 'none'}`)
          .text('+')
          .on('click', (d) => {
            d.children = d.children ? null : d._children;
            update(d);
          });

        // Transition nodes to their new position.
        const nodeUpdate = node
          .merge(nodeEnter)
          .transition(transition)
          .attr('transform', (d) => `translate(${d.y},${d.x})`)
          .attr('fill-opacity', 1)
          .attr('stroke-opacity', 1);

        // Transition exiting nodes to the parent's new position.
        const nodeExit = node
          .exit()
          .transition(transition)
          .remove()
          .attr('transform', (d) => `translate(${source.y},${source.x})`)
          .attr('fill-opacity', 0)
          .attr('stroke-opacity', 0);

        // Update the links…
        const link = gLink.selectAll('path').data(links, (d) => d.target.id);

        // Enter any new links at the parent's previous position.
        const linkEnter = link
          .enter()
          .append('path')
          .attr('d', (d) => {
            const o = {
              x: d.source.x0,
              y: d.source.y0,
            };
            // console.log(d);
            return linkStepCurve({
              source: o,
              target: o,
            });
          });

        // Transition links to their new position.
        link
          .merge(linkEnter)
          .transition(transition)
          .attr('d', linkStepCurve);

        // Transition exiting nodes to the parent's new position.
        link
          .exit()
          .transition(transition)
          .remove()
          .attr('d', (d) => {
            const o = {
              x: d.source.x,
              y: d.source.y,
            };
            return linkStepCurve({
              source: o,
              target: o,
            });
          });

        // Stash the old positions for transition.
        root.eachBefore((d) => {
          d.x0 = d.x;
          d.y0 = d.y;
        });
      };

      update(root);
    };

    console.log('mounting');
    setWidth(Math.max(ref.clientWidth, 300));
    setHeight(Math.max(ref.clientHeight, 700));
    //console.log(this.state);
    createDendrogram();
  }, [data, height, node, ref.clientHeight, ref.clientWidth, width, x, y, zoomCoef]);*/

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      <svg ref={getRef} />
    </div>
  );
});
