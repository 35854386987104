import React, { useState, useEffect } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTestMenu } from './ColumnsMenu';
import { EditableTable } from './EditableTable';
import ReactDragListView from 'react-drag-listview';

import _ from 'lodash';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface JsonSchemaTable<T> {
  schema: JsObject;
  uiSchema?: JsObject;
  setVisibleProps?: Function;
  onChangeData?: Function;
  onSwap?: Function;
  filteredValue?: string[];
  [key: string]: any;
}

export const JsonSchemaTable: React.FC<JsonSchemaTable<any>> = ({
  schema = {},
  uiSchema = {},
  setVisibleProps = () => {},
  dataSource = [],
  filteredValue,
  onSwap = () => {},
  onChangeData = () => {},
  rowKey,
  ...props
}) => {
  const [parsedSchema, setParsedSchema] = useState<JsObject>({});
  const [columns, setColumns] = useState<JsObject[]>([]);
  const [tableMenu, setTableMenu] = useState();
  const [data, setData] = useState<JsObject[]>(dataSource);

  const dragProps = {
    onDragEnd(fromIndex: number, toIndex: number) {
      const newData = [...data];
      console.log('~~~~NEWDATA1~~~~~~~~~~~', newData);
      const item = newData.splice(fromIndex, 1)[0];
      newData.splice(toIndex, 0, item);
      console.log('~~~~NEWDATA2~~~~~~~~~~~', newData);
      setData(newData);
      onSwap(newData);
    },
    handleSelector: 'tr.ant-table-row',
    ignoreSelector: 'tr.ant-table-expanded-row',
    nodeSelector: 'tr.ant-table-row',
  };

  const handleResize = (key: any) => (e: any, { size }: any) => {
    const newData = [...columns];
    newData[key].width = size.width;
    setColumns(newData);
  };

  const setProperty = (schema: JsObject, obj: JsObject, key: string) => {
    schema.hasOwnProperty('ui:' + key) &&
      Object.defineProperty(obj, key, {
        value: schema['ui:' + key],
        enumerable: true,
      });
  };

  const handleSearch = (selectedKeys: any[], confirm: Function) => {
    confirm();
  };

  const handleReset = (clearFilters: Function) => {
    clearFilters();
  };

  const getColumnFilterProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: JsObject) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {},
  });

  const createColumnFromSchema = (key: string) => {
    const newColumn: JsObject = {
      title: schema.properties[key].title,
      key: key,
      dataIndex: key,
      width: 'auto',
      editable: false,
      disabled: false,
      disabledInColumnMenu: false,
      ellipsis: true,
    };
    if (schema.properties[key].hasOwnProperty('formatters')) {
      newColumn.render = schema.properties[key].formatters.default;
    }
    if (uiSchema.hasOwnProperty(key)) {
      setProperty(uiSchema[key], newColumn, 'width');
      setProperty(uiSchema[key], newColumn, 'editable');
      setProperty(uiSchema[key], newColumn, 'sorter');
      setProperty(uiSchema[key], newColumn, 'sortDirections');
      setProperty(uiSchema[key], newColumn, 'disabled');
      setProperty(uiSchema[key], newColumn, 'disabledInColumnMenu');
      setProperty(uiSchema[key], newColumn, 'render');

      uiSchema[key]['ui:searchFilter'] &&
        _.forOwn(getColumnFilterProps(key), (value: any, key: string) => {
          newColumn[key] = value;
        });
    }

    return newColumn;
  };

  const onChangeCell = (row: JsObject, conditions: JsObject) => {
    const uniqValueKeys = Object.keys(conditions).filter((k) => row[k] !== conditions[k]);
    if (uniqValueKeys.length > 0) {
      onChangeData(row, conditions);
    }
  };

  const { menu } = useTestMenu(parsedSchema, setVisibleProps);
  const rowSelection = uiSchema['ui:columnMenu']
    ? {
        ...props.rowSelection,
        selections: menu,
        hideDefaultSelections: true,
      }
    : props.rowSelection;

  const JsonSchemaT = (
    <EditableTable
      {...props}
      bordered={uiSchema['ui:resizeableHeader']}
      rowKey={rowKey}
      filteredValue={filteredValue}
      columns={columns}
      dataSource={data}
      tableMenu={tableMenu}
      rowSelection={rowSelection}
      handleResize={handleResize}
      resizeableHeader={uiSchema['ui:resizeableHeader']}
      onChangeCell={onChangeCell}
      pagination={uiSchema.hasOwnProperty('ui:pagination') ? uiSchema['ui:pagination'] : false}
    />
  );

  useEffect(() => {
    const newData: JsObject = {};
    const visibleColumns: JsObject[] = [];
    Object.keys(schema.properties)
      .sort((a, b) => {
        if (a === 'identifier' && b === 'description') return -1;
        if (a === 'description' && b === 'identifier') return 1;
        if (a === 'description' || a === 'identifier') return -1;
        if (b === 'description' || b === 'identifier') return 1;
        return 0;
      })
      .forEach((key) => {
        newData[key] = createColumnFromSchema(key);
        !newData[key].disabled && visibleColumns.push(newData[key]);
      });
    setParsedSchema(newData);
    setColumns(visibleColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, uiSchema]);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  console.debug('JsonSchemaTable');

  return uiSchema['ui:draggable'] ? <ReactDragListView {...dragProps}>{JsonSchemaT}</ReactDragListView> : JsonSchemaT;
};

export default JsonSchemaTable;
