import { useState, useEffect } from 'react';
import { ObjectProvider, JsObject, JSONSchema6forRdf } from '@agentlab/sparql-jsld-client';

export interface UseObjectsInterface {
  //setConditions: React.Dispatch<React.SetStateAction<any>>;
  objects: JsObject[];
  loading: boolean;
  checkout: (conditions?: JsObject) => Promise<void>; // for refresh only
  create: (data: JsObject) => Promise<JsObject>;
  updating: boolean;
  update: (artifact: any, values: any) => Promise<void>;
  deleteObject: (conds: any) => Promise<void>;
  deleteObjects: (conds: any) => Promise<void>;
}

/**
 * React Hook for working with Objects list.
 * Used for ProjectView and ArtifactCollectionView.
 */
export function useObjects(
  provider: ObjectProvider,
  schemaOrStringForSelect: JSONSchema6forRdf | string,
  //conditionsForSelect: any = {},
): UseObjectsInterface {
  const [schemaOrString, setSchemaOrString] = useState<JSONSchema6forRdf | string>(schemaOrStringForSelect);
  const [schema, setSchema] = useState<JSONSchema6forRdf | string>(schemaOrString);
  //const [conditions, setConditions] = useState<any>(conditionsForSelect);

  const [objects, setObjects] = useState<JsObject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  /**
   * Checkout project artifacts.
   * @param conditions
   */
  const checkout = async (conditions: JsObject = {}): Promise<void> => {
    try {
      setLoading(true);
      if (typeof schemaOrString === 'string') {
        const sch = await provider.getSchemaByUri(schemaOrString);
        setSchema(sch);
      } else {
        setSchema(schemaOrString);
      }
      const results = await provider.selectObjectsWithTypeInfo(schema, conditions);
      console.log(results);
      setObjects(results);
    } catch (error) {
      console.error(error);
      setObjects([]);
    } finally {
      setLoading(false);
    }
  };

  // Load on startup
  //useEffect(() => {
  //  checkout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [conditions]);

  /**
   * Call API for update artifact and update artifact list.
   * @param artifact -- исходный объект со всеми полями и URI
   * @param values -- только изменяемые поля в соответствии со схемой
   */
  const update = async (artifact: any, values: any): Promise<void> => {
    try {
      setUpdating(true);
      const id = artifact.identifier;
      await provider.updateObject(schema, artifact, values);
      const newData = [...objects];
      const index = newData.findIndex((item: any) => item.identifier === artifact.identifier);
      if (index >= 0) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...values,
        });
        setObjects(newData);
      } else {
        console.warn(`Requrement with identifier [${id}] not found after update`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Call API for delete single artifact and update artifact list.
   * @param conds
   */
  const deleteObject = async (conds: any): Promise<void> => {
    try {
      setUpdating(true);
      await provider.deleteObject(schema, conds);
      const newData = [...objects];
      const index = newData.findIndex((item: any) => item.identifier === conds.identifier);
      newData.splice(index, 1);
      setObjects(newData);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
    }
  };

  /**
   * Call API for delete multiple artifacts and update artifact list.
   * @param conds
   */
  const deleteObjects = async (conds: any): Promise<void> => {
    try {
      setUpdating(true);
      const newData = [...objects];
      for (let i = 0; i < conds.length; i++) {
        const cond = conds[i];
        // eslint-disable-next-line no-await-in-loop
        await provider.deleteObject(schema, cond);
        const index = newData.findIndex((item: any) => item.identifier === cond.identifier);
        newData.splice(index, 1);
      }
      setObjects(newData);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
    }
  };

  const create = async (data: JsObject): Promise<JsObject> => {
    try {
      setUpdating(true);
      const created = await provider.createObject(schema, data);
      const selected = await provider.selectObjectById(schema, created.identifier);
      if (selected) {
        const newData = [...objects, selected];
        setObjects(newData);
        return selected;
      }
      throw new Error(`Cannot create artifact=${data}`);
    } finally {
      setUpdating(false);
    }
  };

  return {
    //setConditions,
    objects,
    loading,
    checkout,
    create,
    updating,
    update,
    deleteObject,
    deleteObjects,
  };
}
