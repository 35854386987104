import { importStartUrl, importRepositoryID, importNamespace, rdfServerUrl } from 'config';
import { ObjectProvider } from '@agentlab/sparql-jsld-client';

export interface ReqsImportData {
  //namespace: string;
  //graph: string;

  createDoc: boolean;
  docTitle?: string;
  docType?: string; //http://cpgu.kbpm.ru/ns/rm/cpgu#Classifier
  docFormat?: string; //http://cpgu.kbpm.ru/ns/rm/user-types#_YwcOsRmREemK5LEaKhoOow_Module

  headerType?: string; //http://cpgu.kbpm.ru/ns/rm/cpgu#Группировка
  headerFormat?: string; //http://cpgu.kbpm.ru/ns/rm/user-types#_YwcOsRmREemK5LEaKhoOow_Text
  headerPattern?: string; //'^[0-9]+(\\.[0-9]+)*[\\.]?[ \\t]+'

  paragraphType?: string; //http://cpgu.kbpm.ru/ns/rm/cpgu#Группировка
  paragraphFormat?: string; //http://cpgu.kbpm.ru/ns/rm/user-types#_YwcOsRmREemK5LEaKhoOow_Text

  folder?: string; //http://cpgu.kbpm.ru/ns/rm/folders#imported

  delimiters?: Record<string, any>;
  importColumns?: string;
}

export interface ReqsImportFileData {
  file: string;
  fileName: string;
}

export type ReqsImportDataWithFile = ReqsImportData & ReqsImportFileData;

export async function reqsImport(params: ReqsImportDataWithFile, provider: ObjectProvider) {
  const formData = new FormData();
  //rdfServerUrl && formData.append('rdf4jServer', encodeURIComponent(rdfServerUrl));
  formData.append('repositoryID', importRepositoryID);
  formData.append('file', params.file);
  formData.append('fileName', params.fileName);

  importNamespace && formData.append('namespace', encodeURI(importNamespace));
  //formData.append('graph', encodeURIComponent('')); //null

  params.createDoc && formData.append('createDoc', params.createDoc.toString());
  params.docTitle && formData.append('docTitle', params.docTitle);
  params.docType && formData.append('docType', encodeURI(provider.deAbbreviateIri(params.docType)));
  params.docFormat && formData.append('docFormat', encodeURI(provider.deAbbreviateIri(params.docFormat)));

  params.headerType && formData.append('headerType', encodeURI(provider.deAbbreviateIri(params.headerType)));
  params.headerFormat && formData.append('headerFormat', encodeURI(provider.deAbbreviateIri(params.headerFormat)));
  params.headerPattern && formData.append('headerPattern', encodeURIComponent(params.headerPattern));

  params.paragraphType && formData.append('paragraphType', encodeURI(provider.deAbbreviateIri(params.paragraphType)));
  params.paragraphFormat &&
    formData.append('paragraphFormat', encodeURI(provider.deAbbreviateIri(params.paragraphFormat)));

  params.folder && formData.append('folder', encodeURI(provider.deAbbreviateIri(params.folder)));

  params.delimiters && formData.append('delimiters', JSON.stringify(params.delimiters));
  params.importColumns && formData.append('importColumns', params.importColumns);

  return fetch(importStartUrl, {
    method: 'POST',
    body: formData,
  }).then((response) => {
    if (!response.ok) throw new Error(response.statusText);
    return response;
  });
}
