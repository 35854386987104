import React, { useContext, useState, CSSProperties } from 'react';
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import SplitPane from 'react-split-pane';
import { Row, Collapse } from 'antd';
// Components
import 'i18n/ru/ru';
import { ArtifactToolbar } from 'components/main-toolbar/ArtifactToolbar';
import { CommentList } from 'components/comment-list/CommentList';
import { LinkList } from 'components/details-panes/LinkList';
import { LinkButtonPanel } from 'components/details-panes/LinkButtonPanel';
import { WhereUsedButtonPanel } from 'components/details-panes/WhereUsedButtonPanel';
import { WhereUsedList } from 'components/details-panes/WhereUsedList';
import { EditArtifactAttrs } from 'components/edit-artifact-attrs/EditArtifactAttrs';
import { Editor } from '@tinymce/tinymce-react';
import { ArtifactContext } from 'contexts/ArtifactContext';

const divStyle2: CSSProperties = {
  position: 'relative',
  width: '100%',
};

export const ArtifactTextView: React.FC<{}> = () => {
  const {
    artifact,
    artifactSchema,
    artifactUiSchema,
    artifactLoading,
    artifactUpdating,
    artifactRefresh,
    artifactComments, // Artifact comments
    artifactLinks, // Artifact links
    artifactUsedIn, // Artifact where used
    artifactEdited, // Edited values of artifact
    artifactEditedUpdate,
    artifactEditMode, // Flag indicating that artifact is in edit mode, read only
    artifactEditRequest, // Functions for begin and finish editing
    artifactEditCancel,
    artifactEditSave,
    artifactEditDone,
  } = useContext(ArtifactContext);
  const [showLinkButtonPanel, setShowLinkButtonPanel] = useState(false);
  const [showWhereUsedButtonPanel, setShowWhereUsedButtonPanel] = useState(false);
  const { history } = useReactRouter();
  const { i18n } = useTranslation();

  const genExtraLink = (): React.ReactNode => {
    if (showLinkButtonPanel) {
      return <LinkButtonPanel />;
    }
    return null;
  };

  const genExtraWhereUsed = (): React.ReactNode => {
    if (showWhereUsedButtonPanel) {
      return <WhereUsedButtonPanel />;
    }
    return null;
  };

  const showButtonPanel = (e: any): void => {
    setShowLinkButtonPanel(e.indexOf('links') !== -1);
    setShowWhereUsedButtonPanel(e.indexOf('usage') !== -1);
  };

  // filter xhtmlText property
  const artifactSchemaFiltered = {
    ...artifactSchema,
  };
  artifactSchemaFiltered.properties = Object.keys(artifactSchema.properties)
    .filter((prop) => prop !== 'xhtmlText')
    .reduce((obj: any, key) => {
      obj[key] = artifactSchema.properties[key];
      return obj;
    }, {});

  return (
    <div>
      <ArtifactToolbar
        id={artifact.identifier}
        title={artifact.title}
        edit={artifactEditMode}
        onBack={(): void => history.go(-1)}
        onEdit={artifactEditRequest}
        onSave={artifactEditSave}
        onDone={artifactEditDone}
        onRefresh={artifactRefresh}
        onCancel={(): void => {
          artifactEditCancel();
          if (artifact) {
            //tinymce.get('reqdescriptioneditor').setContent(artifact.description ? artifact.description : '');
          }
        }}
        updateTitle={(val): void => artifactEditedUpdate({ ...artifactEdited, title: val })}
      />
      <Row>
        <SplitPane split='vertical' defaultSize='78.75%' style={divStyle2}>
          <div style={{ padding: '16px' }}>
            <Editor
              inline
              disabled={!artifactEditMode}
              id='reqdescriptioneditor'
              value={artifact.xhtmlText}
              onEditorChange={(value): void => artifactEditedUpdate({ ...artifactEdited, xhtmlText: value })}
              init={{
                branding: false,
                menubar: false,
                // eslint-disable-next-line @typescript-eslint/camelcase
                language_url: `${process.env.PUBLIC_URL}/lang/ru.js`,
                language: i18n.language,
                skin: 'oxide',
                // eslint-disable-next-line @typescript-eslint/camelcase
                skin_url: `${process.env.PUBLIC_URL}/skins/ui/oxide`,
                theme: 'silver',
                // eslint-disable-next-line @typescript-eslint/camelcase
                theme_url: `${process.env.PUBLIC_URL}/themes/silver/theme.min.js`,
              }}
              toolbar='undo redo | formatselect | bold italic strikethrough forecolor backcolor | table image paste link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat | code'
              plugins='table image link paste lists code'
            />
          </div>
          <Collapse bordered={false} defaultActiveKey='attributes' onChange={showButtonPanel}>
            <Collapse.Panel header='Атрибуты' key='attributes'>
              <EditArtifactAttrs
                fields={artifactEditMode ? artifactEdited : artifact}
                schema={artifactSchemaFiltered}
                uiSchema={artifactUiSchema}
                onChange={(attr): void => {
                  console.log('EditArtifactAttrs', { attr, artifactEdited });
                  artifactEditedUpdate({ ...artifactEdited, ...attr });
                }}
                edit={artifactEditMode}
                loading={artifactLoading || artifactUpdating}
              />
            </Collapse.Panel>
            <Collapse.Panel header='Комментарии' key='comments'>
              <CommentList comments={artifactComments} />
            </Collapse.Panel>
            <Collapse.Panel header='Связи' key='links' extra={genExtraLink()}>
              <LinkList links={artifactLinks} />
            </Collapse.Panel>
            <Collapse.Panel header='Где используется' key='usage' extra={genExtraWhereUsed()}>
              <WhereUsedList artifactUsedIn={artifactUsedIn} />
            </Collapse.Panel>
          </Collapse>
        </SplitPane>
      </Row>
    </div>
  );
};
