import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, message } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { LockFilled, UnlockFilled, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { EditLockContext, EditDataLockContext } from './LockButtonsPanelProvider';
import { HamburgerMenu, defaultMenuItems } from 'components/main-toolbar/hamburger-menu/HamburgerMenu';
import { LockButtonsPanel } from './LockButtonsPanel';

const marginBtn: React.CSSProperties = {
  margin: '0px 8px',
};

interface LockButtonsPanelLayout {
  size: SizeType;
  hamburgerMenuList: any;
  style: React.CSSProperties;
  onEdit: Function;
  onDone: Function;
  onSave: Function;
  onCancel: Function;
  onRefresh: Function;
}
export const LockButtonsPanelLayout: React.FC<LockButtonsPanel> = ({
  size = 'middle',
  hamburgerMenuList = defaultMenuItems,
  style,
  onEdit,
  onDone,
  onSave,
  onCancel,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const firstRender = useRef(true);
  const editLock = useContext(EditLockContext);
  const editDataLock = useContext(EditDataLockContext);
  const [disabled, setDisabled] = useState(false);
  const [btnLock, setBtnLock] = useState({ state: false, context: 'editLock' });

  useEffect(() => {
    if (editLock) {
      setDisabled(true);
      setBtnLock({ state: true, context: 'editLock' });
    } else {
      setDisabled(false);
      setBtnLock({ state: false, context: 'editLock' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLock]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (btnLock.context == 'editLock') {
      return;
    }
    if (btnLock.state) message.success(t('LockButtonsPanelLayout.message.lock'));
    else message.success(t('LockButtonsPanelLayout.message.unlock'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnLock]);

  function paddingSize(size: SizeType) {
    switch (size) {
      case 'small':
        return 4;
      case 'large':
        return 11;
      default:
        return 8;
    }
  }
  const styleBtn: React.CSSProperties = {
    paddingLeft: paddingSize(size),
    paddingRight: paddingSize(size),
  };

  return editLock ? (
    <div style={style}>
      <Button
        size={size}
        style={{ ...styleBtn, ...marginBtn }}
        onClick={() => setBtnLock({ state: !btnLock.state, context: 'btnLock' })}
        disabled={disabled}>
        {btnLock.state ? <LockFilled /> : <UnlockFilled />}
      </Button>
      <Button size={size} style={marginBtn} disabled={!editDataLock} type='primary' onClick={onSave}>
        {t('LockButtonsPanelLayout.saveBtn')}
      </Button>
      <Button size={size} style={marginBtn} type='primary' onClick={onDone}>
        {t('LockButtonsPanelLayout.doneBtn')}
      </Button>
      <Button size={size} style={marginBtn} onClick={onCancel}>
        {t('LockButtonsPanelLayout.cancelBtn')}
      </Button>
    </div>
  ) : (
    <div style={style}>
      <Button
        size={size}
        style={{ ...styleBtn, ...marginBtn }}
        onClick={() => setBtnLock({ state: !btnLock.state, context: 'btnLock' })}
        disabled={disabled}>
        {btnLock.state ? <LockFilled /> : <UnlockFilled />}
      </Button>
      <Button size={size} style={{ ...styleBtn, ...marginBtn }} onClick={onRefresh}>
        <SyncOutlined />
      </Button>
      <HamburgerMenu style={marginBtn} menuList={hamburgerMenuList} />
      <Button size={size} type='primary' style={{ ...styleBtn, ...marginBtn }} onClick={onEdit}>
        {t('LockButtonsPanelLayout.editBtn')}
      </Button>
    </div>
  );
};
