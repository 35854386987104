import React from 'react';
import axios from 'axios';

import { Upload, message } from 'antd';
import { CloudTwoTone } from '@ant-design/icons';
import { importFileUploadUrl } from 'config';

const { Dragger } = Upload;

interface FileUpload {
  addFilesList: (p: any) => void;
}

export const FileUpload: React.FC<FileUpload> = ({ addFilesList }: FileUpload) => {
  const props = {
    name: 'file',
    multiple: true,
    accept: '.docx,.csv,.reqifz',
    showUploadList: false,
    action: importFileUploadUrl,
    customRequest: (options: any) => {
      const data = new FormData();
      data.append('file', options.file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data;',
        },
      };
      axios
        .post(options.action, data, config)
        .then((res: any) => {
          options.onSuccess(res.data, options.file);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    },
    onChange(info: any) {
      const status = info.file.status;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} -- файл загружен успешно.`);
        addFilesList(info.file);
      } else if (status === 'error') {
        message.error(`${info.file.name} -- ошибка загрузки файла.`);
      }
    },
  };

  return (
    <Dragger {...props}>
      <p className='ant-upload-drag-icon'>
        <CloudTwoTone />
      </p>
      <p className='ant-upload-text'>Нажмите или перетащите файл в эту область, чтобы загрузить</p>
    </Dragger>
  );
};
