import React, { MouseEvent } from 'react';
import { Input, Button, Divider } from 'antd';
import { LeftOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import { LockButtonsPanel } from 'components/lock-button/LockButtonsPanel';
import { ArtifactTextHistoryView } from 'views/artifacts/text-history-view/ArtifactTextHistoryView';
import { defaultMenuItems, defaultOkHandler, MenuItem } from 'components/main-toolbar/hamburger-menu/HamburgerMenu';
import { JsObject } from '@agentlab/sparql-jsld-client';

const artifactMenuList: MenuItem[] = defaultMenuItems.concat([
  {
    key: 'history',
    view: ArtifactTextHistoryView,
    handleOk: defaultOkHandler,
  },
]);

export interface ArtifactToolbar {
  id: string;
  title: string;
  edit: boolean;
  onEdit: () => void;
  onSave: (e: MouseEvent) => void;
  onDone: (e: MouseEvent) => void;
  onCancel: (e: MouseEvent) => void;
  onRefresh: (e: MouseEvent) => void;
  onBack: (e: MouseEvent) => void;
  updateTitle: (title: string) => void;
  onRightBtn?: (e: MouseEvent) => void;
}
export const ArtifactToolbar: React.FC<ArtifactToolbar> = ({
  id,
  title,
  edit,
  onEdit,
  onSave,
  onDone,
  onRefresh,
  onCancel,
  onBack,
  updateTitle,
  onRightBtn,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: '12px',
          height: '52px',
        }}>
        <div style={{ flex: '0 0 auto', marginRight: '24px' }}>
          <Button shape='circle' icon={<LeftOutlined />} style={{ marginLeft: 8 }} size='small' onClick={onBack} />
        </div>
        <div style={{ flex: '1 0 auto' }}>
          {edit ? (
            <Input
              style={{ fontSize: 18 }}
              addonBefore={id}
              defaultValue={title}
              size='large'
              onChange={(e) => updateTitle(e.target.value)}
            />
          ) : (
            <h2 style={{ fontSize: 18, marginBottom: 0 }}>
              <strong style={{ paddingRight: 10 }}>{id}</strong>
              <span>{title}</span>
            </h2>
          )}
        </div>
        <div style={{ flex: '0 0 auto' }}>
          <LockButtonsPanel
            style={{ float: 'right', display: 'flex' }}
            editMode={edit}
            editData={true}
            onEdit={onEdit}
            onCancel={onCancel}
            onSave={onSave}
            onDone={onDone}
            onRefresh={onRefresh}
            hamburgerMenuList={artifactMenuList}
          />
        </div>
        <div style={{ flex: '0 0 auto' }}>
          <Button icon={<VerticalLeftOutlined />} onClick={onRightBtn} />
        </div>
      </div>
      <Divider style={{ margin: 0, background: 'rgb(220, 220, 220)' }} />
    </div>
  );
};
