import React, { useContext } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { container } from 'ioc';
import { Provider } from 'ioc.react';
import { ConfigProvider } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
// Components
import { textFormatUri, collectionFormatUri, moduleFormatUri } from 'config';
import { ProjectView } from 'views/ProjectView';
import { ArtifactTextView } from 'views/artifacts/text-view/ArtifactTextView';
import { ArtifactModuleView } from 'views/artifacts/module-view/ArtifactModuleView';
import { ArtifactCollectionView } from 'views/artifacts/collection-view/ArtifactCollectionView';
// Contexts
import { GlobalContextProvider } from 'contexts/GlobalContext';
import { ProjectContextProvider } from 'contexts/ProjectContext';
import { ArtifactCollectionContextProvider } from 'contexts/ArtifactCollectionContext';
import { ArtifactContext, ArtifactContextProvider } from 'contexts/ArtifactContext';
import { ArtifactModuleContextProvider } from 'contexts/ArtifactModuleContext';

export const ArtifactFormatDispatcher: React.FC = (props: any) => {
  const { artifact, artifactLoading } = useContext(ArtifactContext);
  const artifactFormat = artifact && artifact.artifactFormat;
  switch (artifactFormat) {
    case textFormatUri:
      return <ArtifactTextView key={props.match.params.id} />;
    case collectionFormatUri:
      console.log('here');
      return (
        <ArtifactCollectionContextProvider identifier={props.match.params.id}>
          <ArtifactCollectionView key={props.match.params.id} />
        </ArtifactCollectionContextProvider>
      );
    case moduleFormatUri:
      return (
        <ArtifactModuleContextProvider identifier={props.match.params.id}>
          <ArtifactModuleView key={props.match.params.id} />
        </ArtifactModuleContextProvider>
      );
    default:
      if (artifactLoading) {
        return <div>Loading...</div>;
      }
      return <div>{`Unknown artifactFormat ${artifactFormat}...`}</div>;
  }
};

const ruRuMod = {
  ...ruRu,
  Empty: {
    description: 'Данные не найдены',
  },
};

/**
 * Main Application component.
 * BrowserRouter basename='/expert-system-views'
 */
export const App: React.FC = () => {
  return (
    <div style={{ padding: '24px' }}>
      <ConfigProvider locale={ruRuMod}>
        <Provider container={container}>
          <GlobalContextProvider>
            <BrowserRouter>
              <Route
                exact
                path='/'
                component={(props: any) => (
                  <ProjectContextProvider>
                    <ProjectView {...props} />
                  </ProjectContextProvider>
                )}
              />
              <Route
                path='/artifacts/:id'
                component={(props: any) => (
                  <ArtifactContextProvider identifier={props.match.params.id}>
                    <ArtifactFormatDispatcher {...props} />
                  </ArtifactContextProvider>
                )}
              />
            </BrowserRouter>
          </GlobalContextProvider>
        </Provider>
      </ConfigProvider>
    </div>
  );
};
