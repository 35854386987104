import React, { useState } from 'react';

export const CustomizeLinkTypeDialogContext = React.createContext<any>({});

export function CustomizeLinkTypeDialogContextProvider({ children }: any): JSX.Element {
  const [editing, setEditing] = useState(false);

  return (
    <CustomizeLinkTypeDialogContext.Provider
      value={{
        editing,
        setEditing,
      }}>
      {children}
    </CustomizeLinkTypeDialogContext.Provider>
  );
}
