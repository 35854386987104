import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValueOfFilter } from 'hooks/useFilters';
import { equalTypesOfRelations } from './Filter';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface BodyOfIntegerFilterProps {
  handleChange: (value: JsObject) => void;
  defaultValues: ValueOfFilter;
  type?: string;
}

export const BodyOfIntegerFilter: React.FC<BodyOfIntegerFilterProps> = ({ handleChange, defaultValues, type }) => {
  const { t } = useTranslation();

  const relationForOneNumber = { title: t('CreateFilter.equal'), predicate: 'equal', type: 'singleNumber' };
  const relationForSomeNumbers = { title: t('CreateFilter.any'), predicate: 'any', type: 'hiddenSingleNumber' };

  const [inputValue, setInputValue] = useState<string>(defaultValues.value.join(', '));

  useEffect(() => {
    setInputValue(defaultValues.value.join(', '));
  }, [defaultValues]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const reg = /^([1-9][0-9]*)([,\s][0-9]*)*$/;
    if (reg.test(value) || value === '') {
      setInputValue(value);
      handleChange({ value: value ? [value] : [], valueName: [] });
    }
  };

  const onBlur = () => {
    const arrayOfValue = inputValue.split(/[\s,]/).filter((val) => val !== '');
    setInputValue(arrayOfValue.join(', '));
    const newValue: JsObject = { value: arrayOfValue, valueName: [] };
    if (arrayOfValue.length > 1) {
      newValue['relation'] = relationForSomeNumbers;
    } else {
      newValue['relation'] = relationForOneNumber;
    }
    handleChange(newValue);
  };

  if (equalTypesOfRelations(type, 'singleNumber')) {
    return (
      <Tooltip trigger={'focus'} title={t('CreateFilter.inputOneOrMoreNumbers')} placement='bottomLeft'>
        <Input value={inputValue} style={{ marginTop: 25 }} onChange={onChange} onBlur={onBlur} />
      </Tooltip>
    );
  }
  return <></>;
};
