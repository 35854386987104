import React, { useContext, useState, useEffect, CSSProperties } from 'react';
import useReactRouter from 'use-react-router';
import { Tabs, Collapse } from 'antd';
import SplitPane from 'react-split-pane';
// Components
import { FlatTableRichText } from 'components/flat-table-rich-text/FlatTableRichText';
import { ArtifactCollectionToolbar } from 'components/main-toolbar/ArtifactCollectionToolbar';
import { ViewsPane } from 'components/views-pane/ViewsPane';
import { EditArtifactAttrs } from 'components/edit-artifact-attrs/EditArtifactAttrs';
import { CommentList } from 'components/comment-list/CommentList';
import { LinkList } from 'components/details-panes/LinkList';
import { WhereUsedList } from 'components/details-panes/WhereUsedList';
import { LinkButtonPanel } from 'components/details-panes/LinkButtonPanel';
import { WhereUsedButtonPanel } from 'components/details-panes/WhereUsedButtonPanel';
// Contexts
import { GlobalContext } from 'contexts/GlobalContext';
import { ArtifactContext } from 'contexts/ArtifactContext';
import { ArtifactCollectionContext } from 'contexts/ArtifactCollectionContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { JsObject, NopSchema } from '@agentlab/sparql-jsld-client';

const divStyle2: CSSProperties = {
  position: 'relative',
};

export const ArtifactCollectionView: React.FC<{}> = () => {
  const {
    artifact,
    artifactSchema,
    artifactUiSchema,
    artifactLoading,
    artifactUpdating,
    artifactRefresh,
    // Artifact comments
    artifactComments,
    // Artifact links
    artifactLinks,
    // Artifact where used
    artifactUsedIn,
    // Edited values of artifact
    artifactEdited,
    artifactEditedUpdate,
    // Flag indicating that artifact is in edit mode, read only
    artifactEditMode,
    // Functions for begin and finish editing
    artifactEditRequest,
    artifactEditCancel,
    artifactEditSave,
    artifactEditDone,
  } = useContext(ArtifactContext);

  const {
    collectionArtifacts,
    collectionArtifactsUnlink,
    collectionArtifactsUpdate,
    collectionArtifactsLoading,
    collectionArtifactsUpdating,
    collectionViewsRefresh,
    collectionViews,
    collectionActiveView,
    collectionActiveViewUpdate,
    collectionViewsLoading,
  } = useContext(ArtifactCollectionContext);

  const { provider } = useContext(GlobalContext);
  //const { fullTextSearchString, setFullTextSearchString } = useContext(ProjectContext);

  const { history } = useReactRouter();
  const [showLinkButtonPanel, setShowLinkButtonPanel] = useState(false);
  const [showWhereUsedButtonPanel, setShowWhereUsedButtonPanel] = useState(false);

  const [schema, setSchema] = useState(NopSchema);
  useEffect(() => {
    async function fn(): Promise<void> {
      setSchema(await provider.getSchemaByUri('rm:Artifact'));
    }
    fn();
  }, [provider]);

  /**
   * Handler for unlink artifact from collection.
   * @param {String[]} selection - array of selected artifact identifiers
   */
  const handleUnlinkArtifact = async (selection: JsObject[]) => {
    const unwrapped = selection.map((s) => s.identifier);
    console.log(unwrapped);
    switch (unwrapped.length) {
      case 0:
        console.warn('Selection is empty, do nothing');
        break;
      case 1:
        await collectionArtifactsUnlink(unwrapped[0]);
        break;
      default:
        await collectionArtifactsUnlink(unwrapped[0]);
        console.warn('Supported only single selection yet, link only first selected artifact');
        break;
    }
  };
  console.log('ArtifactCollection artifacts=', collectionArtifacts);

  const genExtraLink = () => {
    if (showLinkButtonPanel) {
      return <LinkButtonPanel />;
    }
    return null;
  };

  const genExtraWhereUsed = () => {
    if (showWhereUsedButtonPanel) {
      return <WhereUsedButtonPanel />;
    }
    return null;
  };

  const showButtonPanel = (e: any) => {
    setShowLinkButtonPanel(e.indexOf('links') !== -1);
    setShowWhereUsedButtonPanel(e.indexOf('usage') !== -1);
  };

  const refresh = async () => {
    collectionViewsRefresh();
    artifactRefresh();
  };

  // filter xhtmlText property
  const artifactSchemaFiltered = {
    ...artifactSchema,
  };
  artifactSchemaFiltered.properties = Object.keys(artifactSchema.properties)
    .filter((prop) => prop !== 'xhtmlText')
    .reduce((obj: any, key) => {
      obj[key] = artifactSchema.properties[key];
      return obj;
    }, {});

  return (
    <div>
      <ArtifactCollectionToolbar
        id={artifact.identifier}
        title={artifact.title}
        edit={artifactEditMode}
        onBack={() => history.go(-1)}
        onEdit={artifactEditRequest}
        onSave={artifactEditSave}
        onDone={artifactEditDone}
        onRefresh={refresh}
        onCancel={artifactEditCancel}
        updateTitle={(val: string) => artifactEditedUpdate({ ...artifactEdited, title: val })}
      />
      <SplitPane split='vertical' defaultSize='15%' style={divStyle2}>
        <Tabs defaultActiveKey='views' size='small'>
          <Tabs.TabPane tab='Представления' key='views'>
            <ViewsPane views={collectionViews} loading={collectionViewsLoading} onClick={collectionActiveViewUpdate} />
          </Tabs.TabPane>
        </Tabs>
        <SplitPane split='vertical' defaultSize='75%' style={divStyle2}>
          <div style={{ overflow: 'hidden' }}>
            {collectionActiveView && (
              <FlatTableRichText
                schema={schema}
                artifacts={collectionArtifacts}
                loading={collectionArtifactsLoading || collectionArtifactsUpdating}
                onDelete={handleUnlinkArtifact}
                onEdit={collectionArtifactsUpdate}
                view={collectionActiveView}
                //fullTextSearchString={fullTextSearchString}
                //setFullTextSearchString={setFullTextSearchString}
                contextMenu={false}
              />
            )}
          </div>
          <Tabs defaultActiveKey='collection' size='small'>
            <Tabs.TabPane tab='Набор' key='collection'>
              <Collapse bordered={false} defaultActiveKey='attributes' onChange={showButtonPanel}>
                <Collapse.Panel header='Атрибуты' key='attributes'>
                  <EditArtifactAttrs
                    fields={artifactEditMode ? artifactEdited : artifact}
                    schema={artifactSchemaFiltered}
                    uiSchema={artifactUiSchema}
                    onChange={(attr) => {
                      console.log(attr);
                      artifactEditedUpdate({ ...artifactEdited, ...attr });
                    }}
                    edit={artifactEditMode}
                    loading={artifactLoading || artifactUpdating}
                  />
                </Collapse.Panel>
                <Collapse.Panel header='Комментарии' key='comments'>
                  <CommentList comments={artifactComments} />
                </Collapse.Panel>
                <Collapse.Panel header='Связи' key='links' extra={genExtraLink()}>
                  <LinkList links={artifactLinks} />
                </Collapse.Panel>
                <Collapse.Panel header='Где используется' key='usage' extra={genExtraWhereUsed()}>
                  <WhereUsedList artifactUsedIn={artifactUsedIn} />
                </Collapse.Panel>
              </Collapse>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Выбранное требование' key='selected' disabled>
              <Collapse bordered={false} defaultActiveKey='attributes'>
                <Collapse.Panel header='Атрибуты' key='attributes'>
                  <EditArtifactAttrs
                    fields={artifact}
                    onChange={(attr) => artifactEditedUpdate({ ...artifactEdited, ...attr })}
                    edit={artifactEditMode}
                    loading={artifactLoading || artifactUpdating}
                  />
                </Collapse.Panel>
                <Collapse.Panel header='Комментарии' key='comments'>
                  <CommentList comments={artifactComments} />
                </Collapse.Panel>
                <Collapse.Panel header='Связи' key='links'>
                  <LinkList links={artifactLinks} />
                </Collapse.Panel>
                <Collapse.Panel header='Где используется' key='usage'>
                  <WhereUsedList artifactUsedIn={artifactUsedIn} />
                </Collapse.Panel>
              </Collapse>
            </Tabs.TabPane>
          </Tabs>
        </SplitPane>
      </SplitPane>
    </div>
  );
};
