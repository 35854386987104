import React, { useState } from 'react';
import { Modal, Spin } from 'antd';

import { TableWithFilterView } from 'views/artifacts/table-with-filter/TableWithFilterView';

interface AddExistingDialog {
  visible: boolean;
  onOk: (data: any) => Promise<void>;
  onCancel: () => void;
}

export const AddExistingDialog: React.FC<AddExistingDialog> = ({
  visible = true,
  onOk = (data: any): Promise<void> => {
    return Promise.resolve();
  },
  onCancel = () => {},
}: AddExistingDialog) => {
  const [creating, setCreating] = useState(false);
  const [selection, setSelection] = useState<any[]>([]);

  const handleOk = async () => {
    setCreating(true);
    await onOk(selection);
    setCreating(false);
  };

  return (
    <Modal
      title='Добавить существующий'
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      width={'90%'}
      confirmLoading={creating}
      cancelText='Отмена'
      okText='Создать'>
      <Spin spinning={creating}>
        <TableWithFilterView showFilters onSelectArtifact={setSelection} />
      </Spin>
    </Modal>
  );
};
