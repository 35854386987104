import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValueOfFilter } from 'hooks/useFilters';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface BodyOfStringFilterProps {
  handleChange: (value: JsObject) => void;
  defaultValues: ValueOfFilter;
  type?: string;
}

export const BodyOfStringFilter: React.FC<BodyOfStringFilterProps> = ({ handleChange, defaultValues, type }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string>(defaultValues.value[0]);

  useEffect(() => {
    setInputValue(defaultValues.value[0]);
  }, [defaultValues]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    handleChange({ value: value ? [value] : [], valueName: [] });
  };

  if (type === 'singleString') {
    return <Input style={{ marginTop: '1em' }} value={inputValue} onChange={onChange} />;
  }
  if (type === 'regEx') {
    return (
      <React.Fragment>
        <Input style={{ marginTop: '1em', marginBottom: '1em' }} value={inputValue} onChange={onChange} />
        <Typography.Text type='secondary'>{t('CreateFilter.regExExample')}</Typography.Text>
        <br />
        <Typography.Text type='secondary'>{t('CreateFilter.regExStartString')}</Typography.Text>
        <br />
        <Typography.Text type='secondary'>{t('CreateFilter.regExEndString')}</Typography.Text>
        <br />
        <Typography.Text type='secondary'>
          <span>{`${t('CreateFilter.regExDoc')} `}</span>
          <a
            href='https://www.w3.org/TR/xpath-functions/#regex-syntax'
            title={t('CreateFilter.regExDocTitle')}
            target='blank'>
            {t('CreateFilter.regExDocLink')}
          </a>
        </Typography.Text>
      </React.Fragment>
    );
  }
  return <></>;
};
