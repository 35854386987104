import React, { useState, useEffect, useContext } from 'react';
import { Modal, Spin } from 'antd';
import { CustomForm } from 'components/custom-form/CustomForm';

import { GlobalContext } from 'contexts/GlobalContext';
import { widgets } from 'components/generated-form/widgets';
import { HorizontalTemplate } from 'components/generated-form/field-templates/HorizontalTemplate';
import { JsObject } from '@agentlab/sparql-jsld-client';

const view = ['title', 'xhtmlText', 'type', 'artifactFormat', 'assetFolder'];

interface ArtifactCreateDialogProps {
  visible?: boolean;
  assetFolder?: string;
  artifactType?: any;
  onOk?: (data?: any) => void;
  onCancel?: () => void;
}

export const ArtifactCreateDialog: React.FC<ArtifactCreateDialogProps> = ({
  visible = true,
  assetFolder = '',
  artifactType,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const { artifactFormats, provider } = useContext(GlobalContext);
  const [schema, setSchema] = useState<JsObject>({});
  const [uiSchema, setUiSchema] = useState<JsObject>({});
  const [creating, setCreating] = useState<boolean>(false);
  const [data, setData] = useState<JsObject>({
    '@type': artifactType,
    title: 'Требование',
    xhtmlText: '',
    assetFolder,
    artifactFormat: artifactFormats && artifactFormats.length > 0 ? artifactFormats[0]['@id'] : undefined,
  });

  // получить конкретную схему и ui-схему подкласса
  // отфильтровать read-only поля
  useEffect(() => {
    if (artifactType) {
      async function fn(): Promise<void> {
        const sch = await provider.getSchemaByUri(artifactType);
        const uiSch = await provider.getUiSchemaByUri(artifactType);
        Object.keys(uiSch).forEach((key) => {
          if ((uiSch[key] && uiSch[key]['ui:disabled'] === true) || !view.includes(key)) {
            delete uiSch[key];
            if (sch.properties) delete sch.properties[key];
          }
        });
        setSchema(sch);
        setUiSchema(uiSch);
        setData({ ...data, artifactFormat: sch.defaultFormat });
      }
      fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactType, provider]);

  console.log('ArtifactCreateDialog.assetFolder=', assetFolder);

  function setDescriptionDisabled(artFormat: string): void {
    if (
      artFormat === 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Module' ||
      artFormat === 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Collection'
    ) {
      const newData = {
        ...uiSchema,
        xhtmlText: {
          'ui:disabled': true,
        },
      };
      setUiSchema(newData);
    } else {
      const newData = {
        ...uiSchema,
        xhtmlText: {
          'ui:disabled': false, //'ui:widget': 'TinyMCEWidget',
        },
      };
      setUiSchema(newData);
    }
  }

  useEffect(() => {
    if (data.artifactFormat) setDescriptionDisabled(data.artifactFormat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleOk = async () => {
    setCreating(true);
    await onOk(data);
    setCreating(false);
  };

  return (
    <Modal
      title='Создание требования'
      visible={visible}
      onOk={handleOk}
      onCancel={() => {
        onCancel();
      }}
      confirmLoading={creating}
      cancelText='Отмена'
      okText='Создать'>
      <Spin spinning={creating}>
        <CustomForm
          schema={schema}
          uiSchema={uiSchema}
          formData={data}
          widgets={widgets}
          FieldTemplate={HorizontalTemplate}
          className='ant-form ant-form-horizontal'
          onChange={(e: JsObject) => {
            if (e.formData.format !== undefined) {
              setDescriptionDisabled(e.formData.format);
            }
            setData(e.formData);
          }}
          showErrorList={false}>
          <br />
        </CustomForm>
      </Spin>
    </Modal>
  );
};
