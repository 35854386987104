import { JsObject } from '@agentlab/sparql-jsld-client';

export function treeify(
  list: JsObject[],
  idAttr = 'id',
  parentAttr = 'parent',
  childrenAttr = 'children',
  cmpFunc: (a: any, b: any) => number,
): JsObject[] {
  const lookup: JsObject = {};
  list.forEach((obj) => {
    lookup[obj[idAttr]] = obj;
    obj[childrenAttr] = [];
  });
  // console.log("lookup", lookup);
  const treeList: JsObject[] = [];
  let ob: JsObject;
  list.forEach((obj) => {
    if (obj[parentAttr] != null && obj[parentAttr] !== obj[idAttr]) {
      ob = lookup[obj[parentAttr]];
      if (ob !== undefined) {
        ob[childrenAttr].push(obj);
        ob[childrenAttr] = ob[childrenAttr].sort(cmpFunc);
      } else {
        treeList.push(obj);
      }
    } else {
      treeList.push(obj);
    }
  });
  // console.log(treeList);
  return treeList.sort((a, b) => cmpFunc(a[childrenAttr], b[childrenAttr]));
}

export function strcmp(a: string, b: string): number {
  if (a.length < b.length) {
    return -1;
  }
  if (a.length > b.length) {
    return 1;
  }
  return a < b ? -1 : a > b ? 1 : 0;
}

/**
 * Creates new object based only on those properties and values from conditions object which exists in data object
 * @param {*} conditions -- object with properties to copy to new object
 * @param {*} data -- object with properties to filter conditioina properties
 */
export function createObjWithFilteredProps(conditions: JsObject, data: JsObject): JsObject {
  const res: JsObject = {};
  for (const key in conditions) {
    if (data[key] !== undefined) {
      res[key] = conditions[key];
    }
  }
  return res;
}
