import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import { Table, Spin } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import { GlobalContext } from 'contexts/GlobalContext';
import { fakeViewsForModule } from 'api/view';

//import { convertFromSchema, useStyledColumns } from './TableColumns';
import './TreeTableRichText.css';
import { Interface } from 'readline';
import { JsObject, JSONSchema6forRdf } from '@agentlab/sparql-jsld-client';
import { useResizeableHeader } from 'components/flat-table-rich-text/TableHeader';
import { convertFromSchema, getUiSchema } from 'components/flat-table-rich-text/TableColumns';
import { JsonSchemaTable } from 'components/rjst-antd/JsonSchemaTable';

// TODO: Use view from props
const defaultView = fakeViewsForModule[0];

export const TreeTableRichTextContext = React.createContext({});

const expandStyle: CSSProperties = {
  cursor: 'pointer',
  color: '#C0C0C0',
};

interface TreeTableRichText {
  artifacts?: JsObject[];
  schema: JSONSchema6forRdf;
  view?: any;
  loading?: boolean;
  onEdit?: (prop1: JsObject, prop2: JsObject) => void;
  onRowClick?: (record: JsObject) => void;
  fullTextSearchString?: string;
  setFullTextSearchString?: any;
  defaultSelection?: string[];
}

function CustomExpandIcon(props: any) {
  if (props.record.children === undefined) return <></>;

  if (props.record.children.length <= 0) return <></>;

  if (props.expanded) {
    return (
      <CaretDownOutlined
        style={expandStyle}
        className='expand-row-icon'
        onClick={(e) => props.onExpand(props.record, e)}
      />
    );
  }
  return (
    <CaretRightOutlined
      style={expandStyle}
      className='expand-row-icon'
      onClick={(e) => props.onExpand(props.record, e)}
    />
  );
}

export const TreeTableRichText: React.FC<TreeTableRichText> = ({
  artifacts = [],
  schema,
  view = defaultView,
  loading = false,
  onEdit = () => {},
  onRowClick = () => {},
  fullTextSearchString = '',
  setFullTextSearchString = () => {},
  defaultSelection = [],
}) => {
  const {
    projectUsers,
    projectUsersLoading,
    artifactTypes,
    loadingArtifactTypes,
    artifactFormats,
    loadingArtifactFormats,
  } = useContext(GlobalContext);
  const [visibleProps, setVisibleProps] = useState<any>(view);
  /*const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [popupCoords, setPopupCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [popupRecord, setPopupRecord] = useState<JsObject>({});
  const [selection, setSelection] = useState<string[]>(defaultSelection);
  const [linKCreateDialogVisible, setLinkCreateDialogVisible] = useState<boolean>(false);*/
  const [uiSchema, setUISchema] = useState<JsObject>();

  useEffect(() => {
    if (schema.properties) {
      setUISchema(getUiSchema(schema, visibleProps, projectUsers, artifactTypes, artifactFormats));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, visibleProps, projectUsersLoading, loadingArtifactFormats, loadingArtifactTypes]);

  useEffect(() => {
    setVisibleProps(view);
  }, [view]);

  const updateVisibleProps = (newProp: JsObject, add: boolean) => {
    const copyOfVisibleProps = { ...visibleProps };
    if (add === true) {
      copyOfVisibleProps.columns.push(newProp);
    } else {
      copyOfVisibleProps.columns = copyOfVisibleProps.columns.filter((prop: JsObject) => prop.id !== newProp.id);
    }
    setVisibleProps(copyOfVisibleProps);
  };

  console.log('TreeTableRichText');

  if (uiSchema && view) {
    return (
      <div>
        <JsonSchemaTable
          bordered
          pagination={false}
          size='small'
          expandIcon={CustomExpandIcon}
          schema={schema}
          uiSchema={uiSchema}
          setVisibleProps={updateVisibleProps}
          filteredValue={[fullTextSearchString]}
          rowClassName={() => 'editable-row'}
          rowKey={(record: any, index: number) => record['@id']}
          dataSource={artifacts}
          onChangeData={onEdit}
          expandable={{
            defaultExpandAllRows: true,
          }}
          /*rowSelection={{
            fixed: true,
            getCheckboxProps: () => null,
            selectedRowKeys: selection,
            onChange: (sel: any) => {
              console.log('SELECTION', selection, artifacts);
              setSelection(sel);
              onSelectArtifact(sel);
            },
          }}*/
          className='no-border'
          loading={loading}
          /*onRow={(record: JsObject) => ({
            onContextMenu: (event: JsObject) => {
              event.preventDefault();
              if (!popupVisible) {
                document.addEventListener(`click`, function onClickOutside() {
                  setPopupVisible(false);
                  document.removeEventListener(`click`, onClickOutside);
                });
              }
              setPopupVisible(true);
              console.log('setPopupRecord', record);
              setPopupRecord(record);
              setPopupCoords({ x: event.clientX, y: event.clientY });
            },
          })}*/
          //defaultExpandAllRows={true}
          //expandIconColumnIndex={2}
        />
      </div>
    );
  } else {
    return null;
  }
};
