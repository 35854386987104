import React, { useContext } from 'react';
import { Select } from 'antd';
import { GlobalContext } from 'contexts/GlobalContext';
import { Widget } from 'react-jsonschema-form';

export const UserWidget: Widget = ({ value, disabled, readonly, onChange = (): void => {} }) => {
  const { projectUsers } = useContext(GlobalContext);
  if (readonly) {
    const user: string = (projectUsers.find((u) => u['@id'] === value) || {}).name || '';
    return <span>{user}</span>;
  }
  return (
    <Select disabled={disabled} value={value} onChange={(e): void => onChange(e)} size='small'>
      {projectUsers.map((u) => (
        <Select.Option key={u['@id']} value={u['@id']}>
          {u.name}
        </Select.Option>
      ))}
    </Select>
  );
};
