import './TableColumns.css';
import React from 'react';
import { Sprite } from 'components/spritesheet/Sprite';
import { Link } from 'react-router-dom';
import { strcmp } from 'api/collections';
import moment from 'moment';
import { JSONSchema6forRdf, getPropertyFormatterAndDefault } from '@agentlab/sparql-jsld-client';
import { RichTextItem } from 'components/tree-table-rich-text/RichTextItem';

export function convertFromSchema(
  schema: JSONSchema6forRdf,
  view: any,
  projectUsers: any,
  projectArtifactTypes: any,
  projectArtifactFormats: any,
) {
  // console.log(projectArtifactFormats);
  let r = [];
  if (view && view.columns) {
    r = view.columns.map((col: any) => {
      const columnId = col.id;
      const columnEditable = col.editable || false;
      const { propertyFormatter, propertyDefault, propertyTitle } = getPropertyFormatterAndDefault(schema, columnId);
      if (propertyFormatter && /*propertyDefault &&*/ propertyTitle) {
        return {
          title: propertyTitle,
          dataIndex: columnId,
          key: columnId,
          className: 'align-top-cell',
        };
      } else {
        return {
          title: 'Unknown#' + columnId,
          dataIndex: columnId,
          key: columnId,
          className: 'align-top-cell',
        };
      }
    });
  }

  return r;
}

export function getUiSchema(schema: any, view: any, projectUsers: any, artifactTypes: any, artifactFormats: any) {
  // console.log(projectArtifactFormats);
  let uiSchema: any = {
    'ui:bordered:': true,
    'ui:resizeableHeader': true,
    'ui:columnMenu': true,
    'ui:pagination': false,
  };
  Object.keys(schema.properties).forEach((key) => {
    uiSchema[key] = { 'ui:disabled': true };
  });
  if (view && view.columns) {
    uiSchema = view.columns.reduce((s: any, col: any) => {
      const columnId = col.id;
      const columnEditable = col.editable || false;
      let newCol;
      const { propertyFormatter, propertyDefault } = getPropertyFormatterAndDefault(schema, columnId);
      if (propertyFormatter !== undefined && propertyDefault !== undefined) {
        let propertyRenderer: (value: any, row: any) => any;
        if (columnId === 'identifier') {
          propertyRenderer = (value: any, row: any) => (
            <Link to={`/artifacts/${row.identifier}`}>{propertyFormatter(value)}</Link>
          );
        } else if (columnId === 'title') {
          propertyRenderer = (value: any, row: any) => (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'inline-block', padding: '5px 5px 0 0' }}>
                <Sprite
                  filename={process.env.PUBLIC_URL + '/img/rm-artifact-icons-sprite.png'}
                  x={442}
                  y={455}
                  width={16}
                  height={16}
                />
              </div>
              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</div>
            </div>
          );
        } else if (columnId === 'type') {
          propertyRenderer = (value: any, row: any) =>
            (artifactTypes.find((t: any) => t['@id'] === value) || {}).title || '';
        } else if (columnId === 'artifactFormat') {
          propertyRenderer = (value: any, row: any) => {
            const format = artifactFormats.find((t: any) => t['@id'] === value) || {};
            return format.title || value;
          };
        } else if (columnId === 'modifiedBy' || columnId === 'creator') {
          propertyRenderer = (value: any, row: any) => {
            const val = projectUsers.find((t: any) => t['@id'] === value) || {};
            return val.name || value;
          };
        } else if (columnId === 'xhtmlText') {
          propertyRenderer = (value: any, row: any) => (
            <div style={{ display: 'inline-block' }}>
              <RichTextItem text={value} row={row} onEdit={() => {}} />
            </div>
          );
        } else {
          propertyRenderer = (value: any, row: any) => value || '';
        }
        newCol = {
          'ui:disabled': false,
          'ui:editable': columnEditable,
          'ui:sorter': (a: any, b: any) => {
            return strcmp(
              propertyFormatter(a[columnId] || propertyDefault),
              propertyFormatter(b[columnId] || propertyDefault),
            );
          },
          'ui:sortDirections': ['descend', 'ascend'],
          'ui:render': (value: any, row: any) => {
            if (row.labels && row.labels[columnId]) {
              // If row has label for columnId, use it
              return row.labels[columnId];
            }
            // If no label, use property renderer
            return propertyRenderer(propertyFormatter(value || propertyDefault), row);
          },
        };
      } else {
        newCol = {
          'ui:disabled': false,
          'ui:editable': false,
          'ui:render': () => <div>NaN</div>,
        };
      }
      s[columnId] = { ...s[columnId], ...newCol };
      return s;
    }, uiSchema);
  }
  return uiSchema;
}
