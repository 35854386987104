import React from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { Widget } from 'react-jsonschema-form';

export const DateTimeWidget: Widget = ({ value, disabled, readonly, onChange = () => {} }) => {
  const date = moment(value);
  if (readonly) {
    return <span>{date.format('LLL')}</span>;
  }
  return (
    <DatePicker
      showTime
      value={date}
      disabled={disabled}
      placeholder='Select Time'
      onChange={(e) => onChange(e)}
      onOk={(e: Moment) => onChange(e)}
      style={{ width: '100%' }}
      size='small'
    />
  );
};
