import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Widget } from 'react-jsonschema-form';

export const DifficultyWidget: Widget = ({ value, readonly, onChange = (): void => {} }) => {
  const { t } = useTranslation();
  if (readonly) {
    if (value) {
      return <span>{value}</span>;
    } else {
      return <span style={{ color: 'lightgray' }}>Не заполнено</span>;
    }
  }
  return (
    <Select value={value} onChange={(e): void => onChange(e)} size='small'>
      <Select.Option value={t<string>('EditArtifact.difficulty.high')}>
        {t('EditArtifact.difficulty.high')}
      </Select.Option>
      <Select.Option value={t<string>('EditArtifact.difficulty.medium')}>
        {t('EditArtifact.difficulty.medium')}
      </Select.Option>
      <Select.Option value={t<string>('EditArtifact.difficulty.low')}>{t('EditArtifact.difficulty.low')}</Select.Option>
    </Select>
  );
};
