import React, { useState, useEffect, useContext, CSSProperties } from 'react';
import { useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';
import { CustomizeLinkTypeDialogContext } from 'contexts/CustomizeLinkTypeDialogContext';
import { GlobalContext } from 'contexts/GlobalContext';
import { DataTypeInfo } from '../../hooks/useDataTypes';

import { Table, Button, Row } from 'antd';
import { CustomForm } from '../custom-form/CustomForm';
import { UiSchema } from 'react-jsonschema-form';
import SplitPane from 'react-split-pane';
import { SaveReqDialog } from '../saveReqDialog/SaveReqDialog';
import { JsObject } from '@agentlab/sparql-jsld-client';

function columns(
  t: (ns?: string | string[] | undefined, options?: UseTranslationOptions | undefined) => UseTranslationResponse,
) {
  return [
    {
      title: t('customAttribute.columns.title'),
      dataIndex: 'title',
      key: 'title',
      render: (text: any) => <a href='#!'>{text}</a>,
    },
  ];
}

const initialAttrSchema: JsObject = {
  type: 'object',
  properties: {
    title: { type: 'string', title: 'Имя' },
    description: { type: 'string', title: 'Описание' },
    rangeId: {
      type: 'string',
      title: 'Тип данных',
      enum: ['string', 'integer', 'iri', 'date-time', 'double'],
      enumNames: ['Строка', 'Целое число', 'URI ссылка', 'Дата и время', 'Вещественное число'],
    },
    default: { type: 'string', title: 'Начальное значение' },
  },
};

const attrUiSchema: UiSchema = {
  description: {
    'ui:widget': 'textarea',
  },
};

const divStyle: CSSProperties = {
  padding: '5px',
};
const divStyle2: CSSProperties = {
  position: 'relative',
};

const log = (type: any) => console.log.bind(console, type);
const ButtonGroup = Button.Group;

export const CustomAttributePane: React.FC<{}> = () => {
  const [jsonSchema, setJsonSchema] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingAttributeDefinitions, setLoadingAttributeDefinitions] = useState(false);
  const [loadingAttributeTypes, setLoadingAttributeTypes] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [currentDataSource, setCurrentDataSource] = useState<any[]>([]);
  const [attributeTypes, setAttributeTypes] = useState<any[]>([]);
  const [attrSchema, setAttrSchema] = useState(initialAttrSchema);
  const [currentData, setCurrentData] = useState({});
  const [btnState, setBtnState] = useState(true);
  const [saveReq, setSaveReq] = useState(false);
  const [saveReqDialogVisible, setSaveReqDialogVisible] = useState(false);

  const { editing, setEditing } = useContext(CustomizeLinkTypeDialogContext);
  const { dataTypes, provider } = useContext(GlobalContext);

  const { t } = useTranslation();

  function updateReq(id: number, req: any) {
    /*setLoading(true);
    attributeTypeApi.updateAttributeDefinition({ identifier: id, title: req.name }).then((data: any) => {
      console.log('data', data);
      setLoading(false);
    });*/
  }

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item: any) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // Here SPARQL UPDATE
    console.log(row);
    updateReq(row.id, row);
    setDataSource(newData);
  };

  /*function selectAttributeTypes() {
    setLoadingAttributeTypes(true);
    return attributeTypeApi
      .selectAttributeTypes()
      .then((data) => {
        setAttributeTypes(data);
        const newAttrSchema = {
          ...attrSchema,
          properties: {
            ...attrSchema.properties,
            rangeId: {
              ...attrSchema.properties.dataType,
              enum: data.map((_, index) => index),
              enumNames: data.map((type) => type.title),
            },
          },
        };
        console.log('AttrSchema', newAttrSchema);
        setAttrSchema(newAttrSchema);
      })
      .then(() => setLoadingAttributeTypes(false));
  }*/
  function compareArttributes(e1: any, e2: any) {
    if (e1.title > e2.title) {
      return 1;
    }
    if (e1.title < e2.title) {
      return -1;
    }
    return 0;
  }

  async function selectAttributeDefinitions() {
    setLoadingAttributeDefinitions(true);

    const allProperty = (await provider.getAllProperties('rm:Artifact'))[0];
    console.log('schema', allProperty);

    const data = [];

    if (allProperty) {
      for (const key in allProperty) {
        const p = allProperty[key];
        data.push({
          key: p['@id'],
          title: p.title,
          rangeId: p.format ? p.format : p.type,
          description: p.description ? p.description : '',
          default: p.default ? p.default : '',
        });
      }
    }
    data.sort(compareArttributes);
    console.log('data', data);
    setDataSource(data);

    if (data.length > 0) {
      setSelected(data[0]);
    }

    setLoadingAttributeDefinitions(false);

    /*return attributeTypeApi
      .selectAttributeDefinitions()
      .then((data) => {
        data.forEach((attr) => {
          const range = attr.range;
          const index = attributeTypes.findIndex((item) => item.id === range);
          if (index !== -1) {
            attr.rangeId = index;
          }
        });
        setDataSource(data);
        if (data.length > 0) {
          setSelected(data[0]);
        }
      })
      .then(() => setLoadingAttributeDefinitions(false));*/
  }

  function deleteReq(id: number) {
    /*setLoading(true);
    attributeTypeApi.deleteAttributeDefinition({ identifier: id }).then((data: any) => {
      console.log('data', data);
      const newData = [...dataSource];
      const index = newData.findIndex((item) => item.id === id);
      newData.splice(index, 1);
      console.log('data', data);
      setDataSource(newData);
      setLoading(false);
    });*/
  }

  function createNewAttribute() {
    if (!saveReq) {
      const newArtifactData = {
        key: 'New',
        title: 'newAttribute',
      };
      const newData = [newArtifactData, ...currentDataSource];
      setCurrentDataSource(newData);
      setSelected(newArtifactData);
      setEditing(true);
    } else {
      setSaveReqDialogVisible(true);
    }
  }

  function saveChanges() {
    setLoading(true);
    console.log('_______saveData_________');
    console.log(selected.key, currentData);
    console.log('________________________');
    setLoading(false);
    setBtnState(true);
  }

  function updateCurrentData(e: any) {
    if (btnState) {
      const data = e.formData;
      setBtnState(false);
      setCurrentData(data);
    } else {
      setCurrentData(e.formData);
    }
  }

  function onCancel() {
    setEditing(false);
  }
  /*useEffect(() => {
    selectAttributeTypes();
  }, []);*/

  useEffect(() => {
    initialAttrSchema.properties.rangeId.enum = [];
    initialAttrSchema.properties.rangeId.enumNames = [];
    dataTypes.forEach((type: DataTypeInfo) => {
      initialAttrSchema.properties.rangeId.enum.push(type.value);
      initialAttrSchema.properties.rangeId.enumNames.push(type.title);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTypes]);

  useEffect(() => {
    selectAttributeDefinitions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeTypes]);

  useEffect(() => {
    if (btnState) {
      setCurrentData(selected);
    }
    if (selected && selected.key === 'New') setBtnState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, btnState]);

  useEffect(() => {
    if (!editing) {
      setJsonSchema(dataSource !== [] ? initialAttrSchema : {});
      setSelected(dataSource[0]);
      setCurrentDataSource(dataSource);
      setBtnState(true);
    }
    console.log('strange');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing, dataSource]);

  useEffect(() => {
    if (btnState) {
      setSaveReq(false);
    } else {
      setSaveReq(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnState]);

  return (
    <SplitPane split='vertical' defaultSize='20%' style={divStyle2}>
      <div style={divStyle}>
        <Table
          className='gx-table-responsive'
          loading={loadingAttributeDefinitions && loadingAttributeTypes}
          size='small'
          pagination={false}
          rowKey={(record) => record.id}
          columns={columns(t)}
          onRow={(record) => {
            return {
              onClick: () => {
                console.log(saveReq);
                if (!saveReq) setSelected(record);
                else setSaveReqDialogVisible(true);
              },
            };
          }}
          dataSource={currentDataSource}
          title={() => (
            <Row>
              <b>{t('customAttribute.title')}</b>
              <Button
                id='addNewArtifactType'
                size='small'
                style={{ float: 'right' }}
                onClick={() => createNewAttribute()}>
                Создать
              </Button>
            </Row>
          )}
        />
      </div>
      <div style={divStyle}>
        <Row style={divStyle} justify='end'>
          <ButtonGroup>
            <Button key='cancel' disabled={btnState} onClick={() => onCancel()}>
              Отмена
            </Button>
            <Button key='ok' disabled={btnState} type='primary' onClick={() => saveChanges()} loading={loading}>
              Сохранить
            </Button>
          </ButtonGroup>
        </Row>
        <CustomForm
          schema={jsonSchema}
          uiSchema={attrUiSchema}
          liveValidate={true}
          formData={currentData}
          onChange={(e: any) => {
            updateCurrentData(e);
            setEditing(true);
          }}
          onSubmit={log('submitted')}
          onError={log('errors')}>
          <div />
        </CustomForm>
        <SaveReqDialog
          visible={saveReqDialogVisible}
          onOk={() => {
            saveChanges();
            setSaveReqDialogVisible(false);
          }}
          onCancel={() => setSaveReqDialogVisible(false)}
        />
      </div>
    </SplitPane>
  );
};
