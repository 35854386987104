import React from 'react';
import { Input } from 'antd';
import { Widget } from 'react-jsonschema-form';
import { JsObject } from '@agentlab/sparql-jsld-client';

export const BaseInput: Widget = ({ value, disabled, readonly, onChange = () => {} }) => {
  if (readonly) {
    if (value) {
      return <span>{value}</span>;
    } else {
      return <span style={{ color: 'lightgray' }}>Не заполнено</span>;
    }
  }
  return <Input disabled={disabled} value={value} onChange={(e: JsObject) => onChange(e.target.value)} size='small' />;
};
