import React, { useContext, useState } from 'react';
import { GlobalContext } from './GlobalContext';
import { useObjects } from 'hooks/useObjects';
import { defaultRoles, defaultOpsByGroups, defaultPermissions } from 'components/permissions-roles/defaultData';

function compareOpsByGroups(e1: any, e2: any): number {
  if (e1.groupName > e2.groupName) return 1;
  if (e1.groupName < e2.groupName) return -1;
  return 0;
}

export const PermissionsAndRolesContext = React.createContext<any>({});

export function UserRolesTableContextProvider(props: any): JSX.Element {
  const { provider, artifactTypes } = useContext(GlobalContext);

  const { objects: projectUsersGroups, loading: projectUsersGroupsLoading } = useObjects(
    provider,
    'pporoles:UserGroup',
  );
  defaultRoles.sort();
  const [projectPolicyRoles, setProjectPolicyRoles] = useState<string[]>(defaultRoles);
  defaultOpsByGroups.sort(compareOpsByGroups);
  defaultOpsByGroups.forEach((e: any) => {
    e.roles.sort();
  });
  const [projectUsersOpsByGroups, setProjectUsersOpsByGroups] = useState<Record<string, any>[]>(defaultOpsByGroups);
  const [projectPermissions, setProjectPermissions] = useState(defaultPermissions);

  return (
    <PermissionsAndRolesContext.Provider
      value={{
        // Project Users Attributes
        projectPolicyRoles,
        projectUsersGroups,
        projectUsersOpsByGroups,
        projectPermissions,
        setProjectPermissions,
      }}>
      {props.children}
    </PermissionsAndRolesContext.Provider>
  );
}
