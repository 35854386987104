import React from 'react';
import { Input, Tabs, Collapse } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface ElementProperty {
  title: string;
  value: string | number;
  edit?: boolean;
}

interface ElementRenderer {
  elements: JsObject[];
  edit: boolean;
}

interface ElementAccordion {
  panels: JsObject[];
}

interface ElementDetails {
  panes: JsObject[];
}
export const ElementProperty: React.FC<ElementProperty> = ({ title, value, edit = false }) => {
  return (
    <div>
      <span>{`${title}: `}</span>
      {edit ? <Input value={value} /> : <span style={{ color: '#000000' }}>{value}</span>}
    </div>
  );
};

export function elementRenderer({ elements = [], edit = false }: ElementRenderer) {
  // console.log(elements)
  return elements.map((element, index) => {
    return <ElementProperty title={element.title} value={element.value} key={index} edit={edit} />;
  });
}

export const ElementAccordion: React.FC<ElementAccordion> = ({ panels = [] }) => {
  // console.log(fields);
  return (
    <Collapse bordered={false} defaultActiveKey='0'>
      {panels.map((panel, index) => {
        return (
          // panel.title === "Атрибуты" ? null :
          <Collapse.Panel header={panel.title} key={index}>
            {panel.renderer && panel.renderer(panel.content)}
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export const ElementDetails: React.FC<ElementDetails> = ({ panes = [] }) => {
  if (panes && panes.length === 1) {
    return <ElementAccordion panels={panes[0].panels} />;
  } else {
    return (
      <Tabs size='small'>
        {panes &&
          panes.map((pane, index: number) => (
            <Tabs.TabPane tab={pane.title} key={'' + index}>
              <ElementAccordion panels={pane.panels} />
            </Tabs.TabPane>
          ))}
      </Tabs>
    );
  }
};
