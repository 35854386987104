import React, { useContext } from 'react';
import { Select } from 'antd';
import { GlobalContext } from 'contexts/GlobalContext';
import { Widget } from 'react-jsonschema-form';
import { JsObject } from '@agentlab/sparql-jsld-client';

export const StatusWidget: Widget = ({ value, readonly, onChange = (): void => {} }) => {
  const { artifactStatus } = useContext(GlobalContext);
  if (readonly) {
    if (!value) {
      return <span style={{ color: 'lightgray' }}>Не заполнено</span>;
    }
    const e = (artifactStatus.find((t: JsObject) => t['@id'] === value) || {}).title || '';
    return <span>{e || value}</span>;
  }
  return (
    <Select value={value} onChange={(e): void => onChange(e)} style={{ width: 120 }} size='small'>
      {artifactStatus.map((e: JsObject) => (
        <Select.Option key={e['@id']} value={e['@id']}>
          {e.title}
        </Select.Option>
      ))}
    </Select>
  );
};
