import React, { useState, useContext, FunctionComponent, CSSProperties } from 'react';
import { Button, Menu, Dropdown, Radio, Row } from 'antd';
import { DeploymentUnitOutlined, LinkOutlined, SortAscendingOutlined, FilterOutlined } from '@ant-design/icons';

import { LinkCreateDialog } from 'views/artifacts/link-create-dialog/LinkCreateDialog';
import { LinkDendrogramDialog } from 'views/artifacts/link-diagram-dialog/LinkDendrogramDialog';
import { ArtifactContext } from 'contexts/ArtifactContext';
import { JsObject } from '@agentlab/sparql-jsld-client';

const buttonStyle: CSSProperties = {
  marginLeft: '8px',
};

interface LinkButtonPanel {
  id?: string;
}

// TODO: Fix default linkData
export const LinkButtonPanel: FunctionComponent<LinkButtonPanel> = ({ id }) => {
  const { artifact, artifactLinksCreate } = useContext(ArtifactContext);
  const [filterArtifactTypeValue, setFilterArtifactTypeValue] = useState(1);
  const [filterLinkTypeValue, setFilterLinkTypeValue] = useState(1);
  const [filterProject, setFilterProject] = useState(1);
  const [linkCreateDialogVisible, setLinkCreateDialogVisible] = useState(false);
  const [linkDiagramDialogVisible, setLinkDiagramDialogVisible] = useState(false);

  const sortingAndGroupingMenu = (
    <Menu>
      <Menu.Item>Сортировать по: Имени</Menu.Item>
      <Menu.Item>Сортировать по: Идентификатору</Menu.Item>
      <Menu.Item>Сортировать по: Типу</Menu.Item>
      <Menu.Item>Сортировать по: Дате изменения</Menu.Item>
      <Menu.Divider />
      <Menu.Item>Группировать по: Отношение</Menu.Item>
      <Menu.Item>Группировать по: Компоненту или проекту</Menu.Item>
      <Menu.Item>Группировать по: Направлению связи</Menu.Item>
      <Menu.Item>Группировать по: Дате изменения</Menu.Item>
    </Menu>
  );

  const filterMenu = (
    <Menu>
      <Menu.SubMenu title='Тип связи'>
        <Menu.Item
          onClick={(e) => {
            setFilterLinkTypeValue(1);
          }}>
          <Radio value={1} checked={1 === filterLinkTypeValue ? true : false}>
            Любой
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterLinkTypeValue(2);
          }}>
          <Radio value={2} checked={2 === filterLinkTypeValue ? true : false}>
            Входящая
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterLinkTypeValue(3);
          }}>
          <Radio value={3} checked={3 === filterLinkTypeValue ? true : false}>
            Исходящая
          </Radio>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Тип требования'>
        <Menu.Item
          onClick={(e) => {
            setFilterArtifactTypeValue(1);
          }}>
          <Radio value={1} checked={1 === filterArtifactTypeValue ? true : false}>
            Любой
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterArtifactTypeValue(2);
          }}>
          <Radio value={2} checked={2 === filterArtifactTypeValue ? true : false}>
            Фича
          </Radio>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Компонент или проект'>
        <Menu.Item
          onClick={(e) => {
            setFilterProject(1);
          }}>
          <Radio value={1} checked={1 === filterProject ? true : false}>
            Любой
          </Radio>
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            setFilterProject(2);
          }}>
          <Radio value={2} checked={2 === filterProject ? true : false}>
            ЧТЗ Управление требваниями
          </Radio>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <Row onClick={(e: JsObject) => e.stopPropagation()}>
      <div style={{ marginRight: '80px' }}>
        <Button
          style={buttonStyle}
          icon={<DeploymentUnitOutlined />}
          onClick={() => setLinkDiagramDialogVisible(true)}
        />
        <Button style={buttonStyle} icon={<LinkOutlined />} onClick={() => setLinkCreateDialogVisible(true)} />
        <Dropdown overlay={sortingAndGroupingMenu} placement='bottomRight'>
          <Button style={buttonStyle} icon={<SortAscendingOutlined />} />
        </Dropdown>
        <Dropdown overlay={filterMenu} placement='bottomRight'>
          <Button style={buttonStyle} icon={<FilterOutlined />} />
        </Dropdown>
        <LinkCreateDialog
          artifact={artifact}
          visible={linkCreateDialogVisible}
          onOk={(links2: JsObject[]) => {
            artifactLinksCreate(links2);
            setLinkCreateDialogVisible(false);
          }}
          onCancel={() => setLinkCreateDialogVisible(false)}
          defaultLinkTo={[]}
        />
        <LinkDendrogramDialog
          id={id}
          visible={linkDiagramDialogVisible}
          onOk={() => setLinkDiagramDialogVisible(false)}
          onCancel={() => setLinkDiagramDialogVisible(false)}
        />
      </div>
    </Row>
  );
};
