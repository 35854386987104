/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';

// Import TinyMCE
//import 'tinymce/skins/content/default/content.min.css';
// A theme is also required
// import 'tinymce/themes/mobile';
// import 'tinymce/themes/silver';
import { Editor } from '@tinymce/tinymce-react';
// Any plugins you want to use has to be imported
// import 'tinymce/plugins/paste';
// import 'tinymce/plugins/link';
// import 'tinymce/plugins/table';
// import 'tinymce/plugins/image';
// import 'tinymce/plugins/lists';
// import 'tinymce/plugins/code';
//
// import 'tinymce/plugins/autolink';
// import 'tinymce/plugins/codesample';
// import 'tinymce/plugins/quickbars';
// import 'tinymce/plugins/textcolor';
// import 'tinymce/plugins/media';

import { useTranslation } from 'react-i18next';

import { Widget } from 'react-jsonschema-form';
import { JsObject } from '@agentlab/sparql-jsld-client';

export const TinyMCEWidget: Widget = ({ value, onChange = () => {} }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className='ant-input' style={{ width: '200pt', height: '100pt' }}>
      <Editor
        initialValue={value}
        inline
        init={{
          branding: false,
          menubar: false,
          toolbar: false,
          plugins: ['autolink codesample link lists media paste table textcolor image quickbars'],
          quickbars_insert_toolbar: 'paste quicktable image',
          quickbars_selection_toolbar: 'bold italic | h2 h3 | blockquote quicklink',
          contextmenu: 'inserttable | cell row column deletetable | link image imagetools | codesample',

          language_url: `${process.env.PUBLIC_URL}/lang/ru.js`,
          language: i18n.language,
          skin: 'oxide',
          skin_url: `${process.env.PUBLIC_URL}/skins/ui/oxide`,
          content_css: `${process.env.PUBLIC_URL}/skins/ui/oxide/content.min.css`,
          theme: 'silver',
          theme_url: `${process.env.PUBLIC_URL}/themes/silver/theme.min.js`,
        }}
        //plugins='link image code'
        //toolbar='undo redo | bold italic | alignleft aligncenter alignright | code'
        onChange={(e: JsObject) => onChange(e.target.getContent())}
      />
    </div>
  );
};
