import React, { useContext, useEffect, useState } from 'react';
import { Divider } from 'antd';

import { ActionRow } from './rows/ActionRow';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { NavTabs } from './NavTabs';
import { HamburgerMenu, defaultMenuItems, defaultOkHandler } from './hamburger-menu/HamburgerMenu';

import { columnStyle, rowStyle, cellStyle } from './flexStyles';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'contexts/GlobalContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { FilterType } from 'hooks/useFilters';
import { JsObject } from '@agentlab/sparql-jsld-client';
import { JSONSchema6forRdf } from '@agentlab/sparql-jsld-client';
import { DocxImportView } from 'components/import-form-docx/DocxImportView';

const btnStyle: React.CSSProperties = {
  margin: '0px 8px',
  padding: '0px 8px 0px 8px',
};

const projectMenuList = [
  {
    key: 'import',
    view: DocxImportView,
    handleOk: defaultOkHandler,
  },
  //{
  //  key: 'export',
  //  view: ExportXlsx,
  //  handleOk: defaultOkHandler,
  //},
  ...defaultMenuItems,
];

export const ProjectToolbar: React.FC<{}> = () => {
  const { artifactFormats, provider } = useContext(GlobalContext);
  const { refreshArtifacts, addFilter, removeFilter } = useContext(ProjectContext);
  const [schema, setSchema] = useState<JSONSchema6forRdf>();
  const [schemaIsLoading, setSchemaIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    const loadSchema = async () => {
      setSchemaIsLoading(true);
      const result = await provider.getSchemaByUri('rm:Artifact');
      setSchema(result);
      setSchemaIsLoading(false);
    };
    loadSchema();
  }, []);

  return (
    <div style={columnStyle}>
      <div style={rowStyle}>
        <div style={cellStyle}>
          <h2>{t('ProjectToolbar.tabs.title')}</h2>
        </div>
        <div style={cellStyle}>
          {schemaIsLoading === false && (
            <NavTabs
              title=''
              handleChange={(activeKey: string) => {
                const filterData: FilterType = {
                  relation: { title: t('CreateFilter.equal'), predicate: 'any' },
                  title: (schema && schema.properties && schema.properties.artifactFormat.title) || '',
                  property: 'artifactFormat',
                  value: [],
                  valueName: [],
                };

                let foundFormat: JsObject | undefined;
                if (activeKey === 'requirements') {
                  foundFormat = artifactFormats.find((format: JsObject) => format.value === 'Text');
                } else if (activeKey === 'modules') {
                  foundFormat = artifactFormats.find((format: JsObject) => format.value === 'Module');
                } else if (activeKey === 'collections') {
                  foundFormat = artifactFormats.find((format: JsObject) => format.value === 'Collection');
                } else {
                  foundFormat = undefined;
                }

                if (foundFormat) {
                  filterData.value = [foundFormat['@id']];
                  filterData.valueName = [foundFormat.title];
                }

                if (filterData.value.length > 0) {
                  addFilter(filterData);
                } else {
                  removeFilter(filterData);
                }
              }}
            />
          )}
        </div>
        <div style={{ ...cellStyle, marginLeft: 'auto' }}>
          <Button icon={<SyncOutlined />} style={btnStyle} onClick={refreshArtifacts}></Button>
          <HamburgerMenu style={{}} menuList={projectMenuList} />
        </div>
      </div>

      <ActionRow withCreateBtn={true} createBntTitle={t('ProjectToolbar.create')} showRightArrow={false} />
      <Divider style={{ margin: 0, background: 'rgb(220, 220, 220)' }} />
    </div>
  );
};
