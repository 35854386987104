import React, { useState, useContext, useEffect, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { JsObject, ArtifactShapeSchema } from '@agentlab/sparql-jsld-client';
import { GlobalContext } from 'contexts/GlobalContext';
import { CustomizeLinkTypeDialogContext } from 'contexts/CustomizeLinkTypeDialogContext';

import { Row, Button, Table } from 'antd';
import SplitPane from 'react-split-pane';
import { ArtifactForm } from './ArtifactForm';

function columns(t: any) {
  return [
    {
      title: t('customType.columns.title'),
      dataIndex: 'title',
      key: 'title',
      render: (text: any) => <a href='#!'>{text}</a>,
    },
  ];
}

const divStyle: CSSProperties = {
  padding: '5px',
};
const divStyle2: CSSProperties = {
  position: 'relative',
};

export const CustomArtifactTypesPane: React.FC<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [loadingAttributeDefinitions, setLoadingAttributeDefinitions] = useState(false);
  const [loadingAttributeTypes, setLoadingAttributeTypes] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [saveReq, setSaveReq] = useState(false);

  const [systemAttributes, setSystemAttributes] = useState<any[]>([]);
  const [customAttributes] = useState<any[]>([]);
  const [allAttributes, setAllAttributes] = useState<any[]>([]);
  const [saveReqDialogVisible, setSaveReqDialogVisible] = useState(false);

  const { artifactTypes, provider } = useContext(GlobalContext);
  const [attributeTypes] = useState<any[]>([]);
  const { setEditing } = useContext(CustomizeLinkTypeDialogContext);
  const { t } = useTranslation();

  function updateReq(id: number, req: any) {
    setLoading(true);
    provider.updateSubclass(ArtifactShapeSchema, { identifier: id, title: req.name }).then((data: any) => {
      console.log('data', data);
      setLoading(false);
    });
  }

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item: any) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // Here SPARQL UPDATE
    console.log(row);
    updateReq(row.id, row);
    setDataSource(newData);
  };

  function saveChanges(data: JsObject) {
    console.log('__________Changes_______________');
    console.log(selected.key, data);
    console.log('________________________________');
  }
  /*function selectAttributeTypes2() {
    setLoadingAttributeTypes(true);
    return selectAttributeTypes()
      .then((data) => {
        setAttributeTypes(data);
        const newAttrSchema = {
          ...attrSchema,
          properties: {
            ...attrSchema.properties,
            rangeId: {
              // ...attrSchema.properties.dataType,
              enum: data.map((_, index) => index),
              enumNames: data.map((type) => type.title),
            },
          },
        };
        console.log('AttrSchema', newAttrSchema);
        setAttrSchema(newAttrSchema);
      })
      .then(() => setLoadingAttributeTypes(false));
  }*/
  function selectArtifactTypes() {
    setLoadingAttributeDefinitions(true);
    console.log('SCHEMA DATA', artifactTypes);
    const data = artifactTypes.map((e: any) => {
      return {
        key: e['@id'],
        title: e.title,
        description: e.description || '',
        icon: e.iconReference ? e.iconReference.match(/[\/]([\d\w-_а-яА-ЯёЁ]+)$/i)[1] : 'default',
        format: e.defaultFormat ? e.defaultFormat : 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Collection',
      };
    });
    setDataSource(data);
    if (data.length > 0) {
      setSelected(data[0]);
    }
    setLoadingAttributeDefinitions(false);

    /*return selectObjectTypes()
      .then((data) => {
        if (data) {
          data.forEach((attr) => {
            const range = attr.range;
            const index = attributeTypes.findIndex((item) => item.id === range);
            if (index !== -1) {
              attr.rangeId = index;
            }
          });
          setDataSource(data);
          if (data.length > 0) {
            setSelected(data[0]);
          }
        }
      })
      .then(() => setLoadingAttributeDefinitions(false));*/
  }

  /*function selectArtifactTypeCustomAttributes2() {
    return selectArtifactTypeCustomAttributes(selected.id).then((data) => {
      console.log('data', data);
      setCustomAttributes(data);
    });
  }*/
  function onCancelNewArtifact() {
    const newData = [...dataSource];
    newData.splice(0, 1);
    setDataSource(newData);
    setSelected(newData[0]);
  }

  function createNewArtifact() {
    if (!saveReq) {
      const newArtifactData = {
        key: 'New',
        title: 'newArtifact',
      };
      const newData = [newArtifactData, ...dataSource];
      setDataSource(newData);
      setSelected(newArtifactData);
      setAllAttributes([]);
      setEditing(true);
    } else {
      setSaveReqDialogVisible(true);
    }
  }

  function deleteReq(id: number) {
    setLoading(true);
    provider.deleteSubclass(ArtifactShapeSchema, { identifier: id }).then((data: any) => {
      console.log('data', data);
      const newData = [...dataSource];
      const index = newData.findIndex((item) => item.id === id);
      newData.splice(index, 1);
      console.log('data', data);
      setDataSource(newData);
      setLoading(false);
    });
  }

  /*useEffect(() => {
    selectAttributeTypes2();
  }, []);*/
  useEffect(() => {
    selectArtifactTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeTypes]);

  useEffect(() => {
    if (selected) {
      async function selectArtifactTypeSystemAttributes2(): Promise<void> {
        const schema = await provider.getSchemaByUri(selected.key);
        // const schema = getSchema(selected.key);
        // const schema = getSchemaByUri(selected.key);

        const data = [];
        let p;

        if (schema && schema.properties) {
          for (const key in schema.properties) {
            p = schema.properties[key];
            data.push({
              key: p['@id'],
              attributeTitle: p.title,
              dataType: p.format ? p.format : p.type,
              attributeDescription: p.description,
            });
          }
        }

        console.log('data', data);
        setSystemAttributes(data);

        /*return selectArtifactTypeSystemAttributes(selected.id).then((data) => {
          console.log('data', data);
          setSystemAttributes(data);
        });*/
      }
      selectArtifactTypeSystemAttributes2();
      //selectArtifactTypeCustomAttributes2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setAllAttributes([...systemAttributes, ...customAttributes]);
  }, [customAttributes, systemAttributes]);

  return (
    <div>
      <SplitPane split='vertical' defaultSize='20%' style={divStyle2}>
        <div style={divStyle}>
          <Table
            className='gx-table-responsive'
            size='small'
            pagination={false}
            columns={columns(t)}
            dataSource={dataSource}
            rowKey={(record) => record.id}
            onRow={(record) => {
              return {
                onClick: () => {
                  console.log(saveReq);
                  if (!saveReq) setSelected(record);
                  else setSaveReqDialogVisible(true);
                },
              };
            }}
            title={() => (
              <Row>
                <b>{t('customType.title')}</b>
                <Button
                  id='addNewArtifactType'
                  size='small'
                  style={{ float: 'right' }}
                  onClick={() => createNewArtifact()}>
                  Создать
                </Button>
              </Row>
            )}
          />
        </div>
        <div style={divStyle}>
          <ArtifactForm
            formData={selected}
            attributes={allAttributes}
            onSave={(saveData: JsObject) => saveChanges(saveData)}
            onCancelNew={() => onCancelNewArtifact()}
            saveReq={(b: boolean) => setSaveReq(b)}
            saveReqDialogVisible={saveReqDialogVisible}
            onCancelSaveReqDialog={() => setSaveReqDialogVisible(false)}
          />
        </div>
      </SplitPane>
    </div>
  );
};
