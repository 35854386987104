import React from 'react';

import { Tooltip, Comment, List } from 'antd';
import moment from 'moment';

type RenderItem = {
  author: string;
  avatar: string;
  content: string;
  datetime: moment.Moment;
};

interface CommentRenderer {
  comments: RenderItem[];
}

export const commentRenderer: React.FC<CommentRenderer> = ({ comments = [] }) => {
  const formatDatetime = (datetime: moment.Moment) => {
    return (
      <Tooltip title={datetime.format('YYYY-MM-DD HH:mm:ss')}>
        <span>{datetime.fromNow()}</span>
      </Tooltip>
    );
  };

  const actions: JSX.Element[] = [<span key={1}>Reply to</span>];
  return (
    <List
      className='comment-list'
      header={`${comments.length} replies`}
      itemLayout='horizontal'
      dataSource={comments}
      renderItem={(item: RenderItem) => (
        <Comment
          actions={actions}
          author={item.author}
          avatar={item.avatar}
          content={<p> {item.content} </p>}
          datetime={formatDatetime(item.datetime)}
        />
      )}
    />
  );
};

export function CommentList(props: CommentRenderer) {
  return commentRenderer(props);
}
