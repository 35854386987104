import React, { PropsWithChildren } from 'react';
import RjsfForm from 'rjsf-antd';
import { RjsfFormProps } from 'rjsf-antd/Form';
import Form from 'react-jsonschema-form';
import 'index.css';

export const CustomForm: React.ForwardRefExoticComponent<
  PropsWithChildren<Omit<RjsfFormProps<any>, 'layout'>> & React.RefAttributes<Form<any>>
> = React.forwardRef((props: PropsWithChildren<Omit<RjsfFormProps<any>, 'layout'>>, ref: React.Ref<Form<any>>) => {
  return (
    <RjsfForm
      className='setting-form'
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      labelAlign='left'
      layout='horizontal'
      {...props}
      ref={ref}
    />
  );
});
