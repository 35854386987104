export default {
  table: {
    menu: {
      createArtifactBefore: '',
      createArtifactAfter: '',
      deleteArtifacts: '',
      linkArtifacts: '',
    },
  },
  customLinkType: {
    title: 'Link types',
    columns: {
      title: 'Title',
      label: 'Labels',
      description: 'Description',
    },
    addBtn: 'Add',
    deleteBtn: {
      title: 'Are you sure?',
      disableTitle: 'You cannot delete the system link type.',
      ok: 'Yes',
      cancel: 'No',
    },
  },
  customAttribute: {
    title: 'Attributes',
    columns: {
      title: 'Name',
    },
  },
  ExportDocx: {
    ExportDocxView: {
      dropdown: {
        exportDocx: ' Export in Docx document',
      },
    },
    title: 'Export in Docx document',
    cancel: 'Cancel',
    ok: 'Export',
    fields: {
      module: 'Module',
      includeComments: 'Include comments',
      includeAttributes: 'Include attributes',
      includeTitlesForInsertedArtifacts: 'Include titles for inserted requirements',
      embeddedArtifactsBehavior: 'Embedded requirements behavior',
      moduleLayout: 'Module layout',
    },
    moduleLayoutValue: {
      book: 'Book',
      table: 'Table',
    },
    embeddedArtifactsBehaviorValue: {
      defaultEmbeddedBehavior: 'Default embedded behavior',
      maximizeAllEmbeddedArtifacts: 'Maximize all embedded requirements',
      minimizeAllEmbeddedArtifacts: 'Minimize all embedded requirements',
    },
  },
  ExportXlsx: {
    ExportXlsxView: {
      dropdown: {
        export: ' Export requirements',
      },
    },
    title: 'Export',
    cancel: 'Cancel',
    ok: 'Export',
    info: 'Select a file format for export',
  },
  QueryForm: {
    title: 'Testing SPARQL-query',
    cancel: 'Cancel',
  },
  ArtifactCreateDialog: {
    createBtn: 'Create requirement',
    Drawer: {
      title: 'Create new requirement',
      cancelBtn: 'Cancel',
      createBtn: 'Create',
    },
  },
  LockButtonsPanelLayout: {
    message: {
      lock: 'Requirement is taken for editing',
      unlock: 'Request removed from editing',
    },
    saveBtn: 'Save',
    editBtn: 'Edit',
    doneBtn: 'Done',
    cancelBtn: 'Cancel',
  },
  EditArtifact: {
    fields: {
      artifact: 'Requirement',
      module: 'Module',
      description: 'Description',
      project: 'Project',
      teamOwnership: 'Team ownership',
      created: 'Created on',
      creator: 'Created by',
      modified: 'Modified on',
      modifiedBy: 'Modified by',
      type: 'Type',
      format: 'Format',
      businessPriority: 'Business priority',
      status: 'Status',
      difficulty: 'Difficulty',
      stability: 'Stability',
      origin: 'Origin',
      foreignCreator: 'Foreign created by',
      foreignCreated: 'Foreign created on',
      foreignId: 'Foreign id',
      foreignModifiedBy: 'Foreign modified by',
      foreignModified: 'Foreign modified on',
    },
    businessPriority: {
      must: 'Must',
      should: 'Should',
      could: 'Could',
      wont: "Won't",
    },
    status: {
      draft: 'Draft',
      underReview: 'Under review',
      approved: 'Approved',
      deprecated: 'Deprecated',
      rejected: 'Rejected',
      playback: 'Playback',
    },
    difficulty: {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
    },
    stability: {
      high: 'High',
      medium: 'Medium',
      low: 'Low',
    },
    origin: {
      customer: 'Customer',
      prospect: 'Prospect',
      marketAnalyst: 'Market analyst',
      development: 'Development',
      quality: 'Quality',
      competitor: 'Competitor',
      partner: 'Partner',
      support: 'Support',
    },
  },
  EditorLinkType: {
    fields: {
      name: 'Name',
      description: 'Description',
      URI: 'URI',
      outgoing: 'Outgoing',
      incoming: 'Incoming',
    },
    Tooltip: {
      URI:
        'This value uniquely identifies an artifact across linked data and is required for cross-project linking. Example: https://hostname.com/domain/myLabel',
    },
    Checkbox: {
      SetRelationship: 'Provide directional labels for the relationship (such as parent and child)',
    },
    ValidateMessages: {
      name: 'Please set name value!',
    },
  },
  TypeLinkCreateDialogModalView: {
    title: {
      create: 'Create new type link',
      edit: 'Edit type link',
    },
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
    },
  },
  ProjectToolbar: {
    create: 'Create',
    tabs: {
      all: 'All',
      artifacts: 'Requirements',
      modules: 'Modules',
      collections: 'Collections',
    },
  },
  ArtifactModuleToolbar: {
    create: 'Add',
  },
  ArtifactCollectionToolbar: {
    create: 'Add',
  },
  ImportForm: {
    title: 'Requirements import',
    Buttons: {
      close: 'Close',
      import: 'Import',
    },
    Steps: {
      add: 'Add',
      setting: 'Setting',
      start: 'Start',
      complete: 'Complete',
    },
    Tabs: {
      DocxTab: {
        title: 'File Docx',
      },
      CSVTab: {
        title: 'File CSV',
      },
      ReqifTab: {
        title: 'File Reqif',
      },
    },
    DocxView: {
      file: {
        label: 'File',
      },
      artifactsFolder: {
        label: 'Location requirements',
        placeholder: 'Select requirements location folder',
      },
      createModuleRadioValue: {
        label: 'Create module?',
        radio: {
          yes: 'Yes, import requirements and create a module',
          no: 'No, just import the requirements',
        },
      },
      moduleName: {
        label: 'Module name',
        checkbox: 'File name',
      },
      moduleTypeValue: {
        label: 'Module Requirement Type',
      },
      headerTypeValue: {
        label: 'Header Requirement Type',
      },
      otherTextTypeValue: {
        label: 'Paragraph Requirement Type',
      },
      delimitersTable: {
        label: 'Text separators',
        buttons: {
          add: 'Add separator',
          delete: {
            title: 'delete',
            confirm: 'Delete separator?',
            ok: 'Yes',
            cancel: 'Cancel',
          },
          row: {
            startCell: 'Start',
            endCell: 'End',
            typeCell: 'Type',
            actionCell: 'Action',
          },
        },
      },
    },
    CsvView: {
      file: {
        label: 'File',
      },
      artifactsFolder: {
        label: 'Location requirements',
        placeholder: 'Select requirements location folder',
      },
      createModuleRadioValue: {
        label: 'Create module?',
        radio: {
          yes: 'Yes, import requirements and create a module',
          no: 'No, just import the requirements',
        },
      },
      moduleName: {
        label: 'Module name',
        checkbox: 'File name',
      },
      moduleTypeValue: {
        label: 'Module Requirement Type',
      },
      createColumnsRadioValue: {
        label: 'Import all columns?',
        radio: {
          yes: 'Yes, import all columns',
          no: 'No, import only list',
        },
      },
      columnsImport: {
        label: 'Import columns',
        tooltip: 'Comma-separated column numbers. Sample: 0,2,4',
      },
    },
  },
};
