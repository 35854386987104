import React, { useState, useEffect, useContext } from 'react';
// Apis
import { treeify, strcmp } from 'api/collections';
// Hooks
import { useViews } from 'hooks/useViews';
import { useCombineLoading } from 'hooks/useCombineLoading';
import { useFilters } from 'hooks/useFilters';
// Contexts
import { GlobalContext } from 'contexts/GlobalContext';
import { ArtifactContext } from 'contexts/ArtifactContext';

import eachDeep from 'deepdash-es/filterDeep';
import { isArray, isObject } from 'lodash';

export const ArtifactModuleContext = React.createContext<any>({});

/**
 * Context for working with module child artifacts.
 * Will load all child artifacts for this module on startup.
 * @param identifier - the identifier of selected module
 * @param children - array of chlid components
 */
export function ArtifactModuleContextProvider({ identifier, children }: any): JSX.Element {
  const { provider } = useContext(GlobalContext);
  // Artifact module itself
  const { artifact } = useContext(ArtifactContext);
  // List of child artifacts of module
  const [moduleArtifacts, setChildArtifacts] = useState<any[]>([]);
  const [moduleArtifactsLoading, setChildArtifactsLoading] = useState(true);
  const [moduleArtifactsUpdating, setChildArtifactsUpdating] = useState(false);
  // Filters
  const { addFilter, removeFilter, getFilterCondition, filters } = useFilters();
  // Views
  const { loadingViews, views } = useViews('module'); // TODO: get id from current route
  const [moduleActiveView, moduleActiveViewUpdate] = useState<any>(null);
  // When artifact and list of child artifacts are loaded
  const { loading: moduleLoading } = useCombineLoading(moduleArtifactsLoading);
  // When some updating on child artifacts are executed
  const { loading: moduleUpdating } = useCombineLoading(moduleArtifactsUpdating);

  /**
   * Checkout artifact module child artifacts.
   */
  const moduleArtifactsCheckout = async () => {
    try {
      setChildArtifactsLoading(true);

      const moduleLinks = await provider.selectObjectsWithTypeInfo('rmUserTypes:UsedInModule', {
        ...getFilterCondition(),
        object: artifact['@id'],
      });
      const result = [];
      console.log('moduleLinks', moduleLinks);
      for (let i = 0; i < moduleLinks.length; i++) {
        const link = moduleLinks[i];
        const subject = link.subject;
        console.log('link', link);
        console.log('subject', subject);
        const child = await provider.selectObjectsWithTypeInfo('rm:Artifact', { '@id': subject });
        console.log('child', child);
        if (child.length > 0) {
          const artifact = child[0];
          // TODO: Remove after validation fixes
          artifact['@id'] = subject;
          artifact.parent = link.parentBinding;
          artifact.depth = link.depth;
          artifact.bookOrder = link.bookOrder;
          artifact.key = parseInt(link.bookOrder, 10);
          artifact.sectionNumber = link.sectionNumber;
          result.push(artifact);
        }
      }
      console.log('result', result);
      const data2 = treeify(result, '@id', 'parent', 'children', (a: any, b: any) => {
        /*if (a.identifier === undefined) {
          console.log(a);
          return -1;
        }
        return strcmp(a.identifier, b.identifier);*/
        return a.bookOrder - b.bookOrder;
      });
      console.log('data2', data2);
      setChildArtifacts(data2);
    } catch (error) {
      console.error(error);
      setChildArtifacts([]);
    } finally {
      setChildArtifactsLoading(false);
    }
  };

  /**
   * Call API for update artifact and update artifact list.
   * @param artifact -- исходный объект со всеми полями и URI
   * @param values -- только изменяемые поля в соответствии со схемой
   */
  const moduleArtifactsUpdate = async (artifact: any, values: any) => {
    try {
      setChildArtifactsUpdating(true);
      const id = artifact.identifier;
      provider.updateObject('rm:Artifact', artifact, values);

      //console.log('moduleArtifactsUpdate.artifact', artifact);
      //console.log('moduleArtifactsUpdate.values', values);
      ///console.log('moduleArtifactsUpdate.moduleArtifacts', moduleArtifacts);
      //const newData = [...moduleArtifacts];
      //console.log('moduleArtifactsUpdate.newData', newData);
      const newData = [...moduleArtifacts];

      eachDeep(
        newData,
        (item: any, key: any, parent: any) => {
          if (item.identifier === id) {
            //console.log('moduleArtifactsUpdate.found.item', item);
            //console.log('moduleArtifactsUpdate.found.key', key);
            //console.log('moduleArtifactsUpdate.found.parent', parent);
            const newItem = {
              ...item,
              ...values,
            };
            //console.log('moduleArtifactsUpdate.newItem', newItem);
            if (isArray(parent)) {
              parent[key] = newItem;
            } else if (
              isObject(parent) &&
              (parent as any).children !== undefined &&
              isArray((parent as any).children)
            ) {
              (parent as any).children[key] = newItem;
            } else {
              console.warn(`Requrement with identifier [${id}] not found after update`);
            }
          }
        },
        { childrenPath: 'children' },
      );
      //console.log('moduleArtifactsUpdate.newData', newData);
      setChildArtifacts(newData);
    } catch (error) {
      console.error(error);
    } finally {
      setChildArtifactsUpdating(false);
    }
  };

  /**
   * Link specified artifact to this module.
   * @param {String} id - the identifier of linked artifact
   */
  const moduleArtifactsLink = async (id: number) => {
    try {
      setChildArtifactsUpdating(true);
      const linkedArtifact = await provider.selectObjectById('rm:Artifact', id);
      if (linkedArtifact) {
        const maxId = await provider.selectMaxObjectNumField(
          'rmUserTypes:UsedInModule',
          {
            object: artifact['@id'],
          },
          'bookOrder',
        );
        // create UsedInModule Link
        await provider.createObject('rmUserTypes:UsedInModule', {
          subject: linkedArtifact['@id'],
          object: artifact['@id'],
          parentBinding: artifact['@id'],
          depth: 0,
          bookOrder: maxId + 1,
        });
        const newData = [...moduleArtifacts, linkedArtifact];
        setChildArtifacts(newData);
        console.log('moduleArtifactsLink.maxId', maxId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setChildArtifactsUpdating(false);
    }
  };

  /**
   * Unlink specified artifact from this module.
   * @param {String} id - the id of unlinked artifact
   */
  const moduleArtifactsUnlink = async (id: number) => {
    try {
      setChildArtifactsUpdating(true);
      const unlinkedArtifact = await provider.selectObjectById('rm:Artifact', id);
      if (unlinkedArtifact) {
        // delete UsedInModule Link
        await provider.deleteObject('rmUserTypes:UsedInModule', {
          subject: unlinkedArtifact['@id'],
          object: artifact['@id'],
        });
        const newData = [...moduleArtifacts];
        const index = newData.findIndex((item) => item.identifier === id);
        newData.splice(index, 1);
        setChildArtifacts(newData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setChildArtifactsUpdating(false);
    }
  };

  const moduleViewsRefresh = async () => {
    moduleArtifactsCheckout();
  };
  // Load child artifacts on startup
  useEffect(() => {
    if (identifier && moduleActiveView) {
      moduleArtifactsCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier, moduleActiveView]);

  // Select first view when views list is changed
  useEffect(() => {
    if (views && views.length > 0) {
      moduleActiveViewUpdate(views[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  return (
    <ArtifactModuleContext.Provider
      value={{
        // Child artifacts
        moduleArtifacts,
        moduleArtifactsLoading,
        moduleArtifactsUpdating,
        moduleArtifactsCheckout,
        moduleArtifactsUpdate,
        moduleArtifactsLink,
        moduleArtifactsUnlink,
        // Views
        moduleViews: views,
        moduleViewsLoading: loadingViews,
        moduleActiveView,
        moduleActiveViewUpdate,
        moduleViewsRefresh,
        // Flags
        moduleLoading,
        moduleUpdating,
        //Filters
        addFilter,
        removeFilter,
        filters,
        filterLoading: moduleArtifactsLoading,
      }}>
      {children}
    </ArtifactModuleContext.Provider>
  );
}
