import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Menu, Dropdown, Radio, Collapse, Row, List, Avatar, Tree } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

const { TreeNode } = Tree;

const buttonStyle: React.CSSProperties = {
  marginLeft: '8px',
};

/**
 *
 */
function groupListToTree(
  list: JsObject[] = [],
  propertyName: string,
  mapElemToRoot: (e: JsObject) => JsObject,
  mapElemToChild: (e: JsObject) => JsObject,
  childrenAttr = 'children',
): JsObject[] {
  const treeList: JsObject[] = [];
  const lookup: JsObject = {};
  let rootOb: JsObject;
  let childObj: JsObject;
  let propertyVal: string;

  list.forEach((elem) => {
    propertyVal = elem[propertyName];
    if (propertyVal) {
      rootOb = lookup[propertyVal];
      if (rootOb === undefined) {
        // create root obj
        rootOb = mapElemToRoot(elem);
        if (rootOb) {
          if (rootOb[childrenAttr] === undefined) {
            rootOb[childrenAttr] = [];
          }
          lookup[propertyVal] = rootOb;
          treeList.push(rootOb);
        }
      }
      if (rootOb) {
        // if root exists attach child
        childObj = mapElemToChild(elem);
        if (childObj) {
          rootOb[childrenAttr].push(childObj);
        }
      }
    }
  });
  return treeList;
}

const renderers: JsObject = {
  group: (obj: JsObject) => <b>{obj.title}</b>,
  grouped: (obj: JsObject) => (
    <Link style={{ color: '#1890ff', marginLeft: '4px' }} to={`/artifacts/${obj.key}`}>
      {`${obj.title}`}
    </Link>
  ),
};

function renderTreeNodes(data: JsObject[], renderers: JsObject): any {
  return data.map((item) => {
    console.log('renderTreeNodes for item', item);
    const renderer = renderers[item.type];
    const title = renderer ? renderer(item) : item.title;
    if (item.children) {
      return {
        title,
        key: item.key,
        dataRef: item,
        selectable: false,
        children: renderTreeNodes(item.children, renderers),
      };
    }
    return {
      title,
      key: item.key,
      dataRef: item,
      selectable: false,
    };
  });
}

/**
 * Просмотр объектов, использующих заданный объект
 * Выбираются все объекты, на которые указывает object
 * Приходит список объектов
 * В зависимости от выбранного поля список объектов группируется и трансформируется в дерево
 * По полям
 *    * Формат
 *    * Тип
 *    * Название
 */

interface WhereUsedList {
  artifactUsedIn: JsObject[];
}
export const WhereUsedList: React.FC<WhereUsedList> = ({ artifactUsedIn = [] }: WhereUsedList) => {
  const myTree = groupListToTree(
    artifactUsedIn,
    'artifactFormat',
    (e) => {
      const r = {
        key: e.artifactFormat,
        title: 'Требование',
        type: 'group',
      };

      // это надо запрашивать в контексте в закэшированных форматах
      if (e.artifactFormat === 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Collection') {
        r.title = 'Набор';
      } else if (e.artifactFormat === 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Module') {
        r.title = 'Модуль';
      }
      return r;
    },
    (e) => ({
      key: e.identifier,
      title: e.identifier + ': ' + e.title,
      type: 'grouped',
    }),
  );

  console.log('myTree', myTree);

  return (
    <div>
      <Row style={{ overflow: 'auto' }}>
        <Tree defaultExpandAll selectable={false} treeData={renderTreeNodes(myTree, renderers)} />
      </Row>
    </div>
  );
};
