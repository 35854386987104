import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';

import { EditorLinkType, dataInitTemplate } from 'components/editor-link-type/EditorLinkType';

interface TypeLinkCreateDialogModalView {
  initData: any;
  visible: boolean;
  setVisible: (b: boolean) => void;
}

export const TypeLinkCreateDialogModalView: React.FC<TypeLinkCreateDialogModalView> = ({
  initData,
  visible,
  setVisible,
}: TypeLinkCreateDialogModalView) => {
  const { t } = useTranslation();

  const [statusValidate, setStatusValidate] = useState(false);
  const [data, setData] = useState(initData);
  useEffect(() => {
    setStatusValidate(!data.name);
  }, [data.name]);

  const [loading, setLoading] = useState(false);

  function saveTypeLink() {
    console.log('Type link data:');
    console.log(data);
    setLoading(true);
    // TODO: create typeLink
    setTimeout(() => {
      setVisible(false);
      setLoading(false);
    }, 1000);
  }

  function clearData() {
    setData(dataInitTemplate);
  }

  return (
    <Modal
      title={
        initData.id === ''
          ? t('TypeLinkCreateDialogModalView.title.create')
          : t('TypeLinkCreateDialogModalView.title.edit')
      }
      visible={visible}
      width={900}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key='cancel' size='small' onClick={() => setVisible(false)}>
          {t('TypeLinkCreateDialogModalView.buttons.cancel')}
        </Button>,
        <Button
          key='save'
          size='small'
          type='primary'
          disabled={statusValidate}
          loading={loading}
          onClick={() => saveTypeLink()}>
          {t('TypeLinkCreateDialogModalView.buttons.save')}
        </Button>,
      ]}
      destroyOnClose={true}>
      <EditorLinkType initData={initData} setData={setData} />
    </Modal>
  );
};
