import React, { useState, useRef } from 'react';

import { LinkDendrogram, LinkDendogramGraph } from './LinkDendrogram';
import { Slider } from 'antd';

type SliderValue = number | [number, number];
export const LinkDendrogramGraph: React.FC<LinkDendogramGraph> = ({ data }: LinkDendogramGraph) => {
  const [zoomCoef, setZoomCoef] = useState<number>(100);
  const dendrogramRef = useRef<typeof LinkDendrogram>(null);

  const sliderChange = (value: SliderValue): void => {
    if (typeof value === 'number') {
      setZoomCoef(value);
      //if (dendrogramRef.current !== null && dendrogramRef.current.slided !== undefined) {
      //  dendrogramRef.current.slided(value / 100);
      //}
    }
  };

  const handleTransform = (e: number): void => {
    setZoomCoef(e * 100);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <LinkDendrogram data={data} ref={dendrogramRef} handleTransform={handleTransform} />
      <Slider
        style={{ width: 100, float: 'right', bottom: 50 }}
        onChange={sliderChange}
        value={zoomCoef}
        min={100}
        max={500}
        step={1}
      />
    </div>
  );
};
