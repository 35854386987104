import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { Button, Table, Input, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { PermissionsAndRolesContext } from 'contexts/PermissionsAndRolesContext';

const { Option } = Select;

function onChange(pagination: any, filters: any, sorter: any) {
  console.log('params', pagination, filters, sorter);
}

export const UserRolesTable: React.FC<{}> = () => {
  const { projectUsers } = useContext(GlobalContext);
  const { projectPolicyRoles, projectUsersGroups } = useContext(PermissionsAndRolesContext);
  const [dataSource, setDataSource] = useState<any[]>([]);

  /*const handleSearch = (selectedKeys, confirm) => () => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };*/

  const roleSelectChildren = projectPolicyRoles.map((elem: any) => {
    console.log('roleSelectChildren.elem', elem);
    return (
      <Option key={elem} value={elem}>
        <span>{elem}</span>
      </Option>
    );
  });

  const groupSelectChildren = projectUsersGroups.map((elem: any) => {
    return (
      <Option key={elem.name} value={elem}>
        <span>{elem.name}</span>
      </Option>
    );
  });

  console.log(roleSelectChildren);

  const columns: ColumnProps<any>[] = [
    {
      title: 'Пользователь или группа пользователей',
      dataIndex: 'name',
      key: 'name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: any) => (
        <div>
          <Input
            placeholder='Введите имя пользователя'
            value={selectedKeys[0]}
            onChange={(e: any) => {
              console.log(e.target.value);
              const value = e.target.value;
              setSelectedKeys(value ? [value] : []);
            }}
            onPressEnter={(e: any) => {
              console.log(e.target.value);
              const value = e.target.value;
              setSelectedKeys(value ? [value] : []);
              confirm();
            }}
            allowClear
          />
          <Button type='primary' onClick={confirm}>
            Поиск
          </Button>
          <Button
            type='default'
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}>
            Сброс
          </Button>
        </div>
      ),
      onFilter: (value: string | number | boolean, record: any) =>
        record.name.toLowerCase().includes(typeof value === 'string' ? value.toLowerCase() : value),
      render: (text: any) => text,
      sorter: (a: any, b: any) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Назначены роли',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles: any) => {
        return (
          <Select
            mode='multiple'
            defaultValue={roles}
            style={{
              minWidth: '180px',
            }}>
            {roleSelectChildren}
          </Select>
        );
      },
    },
    {
      title: 'Входит в группы пользователей',
      dataIndex: 'groups',
      key: 'groups',
      render: (groups: any) => {
        return (
          <Select
            mode='multiple'
            defaultValue={groups}
            style={{
              minWidth: '180px',
            }}>
            {groupSelectChildren}
          </Select>
        );
      },
    },
  ];

  useEffect(() => {
    const newData = projectUsers.concat(projectUsersGroups).map((e: any, key: any) => {
      const newElement = {
        key,
        name: e.name,
        roles: ['гость'],
        groups: projectUsersGroups
          .filter((g: any) => g.member && g.member.indexOf(e['@id']) !== -1)
          .map((g: any) => g.name),
      };
      return newElement;
    });
    setDataSource(newData);
  }, [projectUsers, projectUsersGroups]);

  return <Table size='small' columns={columns} dataSource={dataSource} onChange={onChange} />;
};
