import React from 'react';
import { Table } from 'antd';
import { EditableCell, EditableFormRow } from './EditableCell';
import { HeaderCell } from './TableHeader';
import _ from 'lodash';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface EditableTable<T> {
  onChangeCell: Function;
  resizeableHeader: boolean;
  handleResize?: Function;
  filteredValue?: string[];
  [key: string]: any;
}

export const EditableTable: React.FC<EditableTable<any>> = ({
  columns = [],
  onChangeCell,
  filteredValue,
  handleResize = () => {},
  resizeableHeader = false,
  ...props
}) => {
  const editableColumns = columns.map((col: JsObject, index: number) => ({
    ...col,
    filteredValue: index === 0 ? filteredValue : [''],
    onHeaderCell: (column: any) => ({
      width: col.width,
      onResize: handleResize(index),
      resizeableHeader,
    }),
    onCell: (record: string) => ({
      record,
      editable: col.editable,
      dataIndex: col.key,
      title: col.title,
      handleSave: onChangeCell,
    }),
  }));

  const components: any = {
    header: {
      cell: HeaderCell,
    },
    body: {
      row: EditableFormRow,
      cell: (props: any) => <EditableCell {...props} />,
    },
  };

  return <Table {...props} columns={editableColumns} components={components} />;
};
