import React, { PropsWithChildren, useState, useEffect } from 'react';
// Components
import { rdfServerUrl, rmRepositoryParam, rootFolderUri } from 'config';
// Hooks
import { useFolders, UseFoldersInterface } from 'hooks/useFolders';
import { useArtifactTypes, UseArtifactTypesInterface } from 'hooks/useArtifactTypes';
import { useDataTypes, UseDataTypesInterface } from 'hooks/useDataTypes';

import { useInjection } from 'ioc.react';
import { ObjectProvider, JsObject } from '@agentlab/sparql-jsld-client';
import { useObjects } from 'hooks/useObjects';

export interface UseArtifactEnumsInterface {
  loadingArtifactFormats: boolean;
  artifactFormats: JsObject[];
  artifactStatus: JsObject[];
  getTitleByEnumURI: (uri: string) => string;
}
/*export interface UseProjectPolicyRolesInterface {
  projectPolicyRoles: string[];
}
export interface UseProjectOpsByGroupsInterface {
  projectUsersOpsByGroups: Record<string, any>[];
}*/

export interface GlobalContextProviderInterface
  extends UseFoldersInterface,
    UseArtifactTypesInterface,
    UseDataTypesInterface,
    UseArtifactEnumsInterface {
  provider: ObjectProvider;
  projectActiveFolder: string; // Read-only field storing active folder
  projectActiveFolderUpdate: React.Dispatch<React.SetStateAction<string>>; // Function for change active folder
  // Project link types
  projectLinkTypes: JsObject[];
  projectLinkTypesCheckout: () => Promise<void>;
  loadingProjectLinkTypes: boolean;
  updatingProjectLinkTypes: boolean;
  // Project users
  projectUsers: JsObject[];
  projectUsersLoading: boolean;
  // Project Users Attributes
  //projectUsersGroups: JsObject[];
  //projectPermissions: JsObject;
  //setProjectPermissions: React.Dispatch<React.SetStateAction<JsObject>>;
}

export const GlobalContext = React.createContext<GlobalContextProviderInterface>(null!);

export function GlobalContextProvider({ children }: PropsWithChildren<any>): JSX.Element {
  // Server endpoint
  const provider = useInjection<ObjectProvider>('objectProvider');
  provider.setUser('users:amivanoff');
  const client = provider.getClient();
  client.setServerUrl(rdfServerUrl);
  client.setRepositoryId(rmRepositoryParam['Repository ID']);

  // Project folders
  const {
    projectFolders,
    //setProjectFolders,
    loadingProjectFolders,
    //setLoadingProjectFolders,
    updatingProjectFolders,
    //setUpdatingProjectFolders,
    projectFoldersCheckout,
    projectFolderCreate,
    projectFolderDelete,
    projectFolderRename,
    projectFolderMove,
    projectActiveFolder,
    projectActiveFolderUpdate,
  } = useFolders(provider, rootFolderUri);

  // Project artifact types
  const { artifactTypes, loadingArtifactTypes } = useArtifactTypes(provider);
  // Project artifact formats
  const {
    objects: artifactFormats,
    checkout: checkoutProjectArtifactFormats,
    loading: loadingArtifactFormats,
  } = useObjects(provider, 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow');
  //Статус артефакта
  const {
    objects: artifactStatus,
    checkout: checkoutProjectArtifactStatus,
    loading: loadingArtifactStatus,
  } = useObjects(provider, 'rmUserTypes:_YwrbNRmREemK5LEaKhoOow');
  //Типы групп классификатора
  //Статус артефакта
  const {
    objects: classifierGroupTypes,
    checkout: checkoutClassifierGroupTypes,
    loading: loadingClassifierGroupTypes,
  } = useObjects(provider, 'rmUserTypes:_Ywrb56mREemK5LEaKhoOow');
  const getTitleByEnumURI = (uri: string): string => {
    const foundEnumElem = artifactFormats.find((enumElem: JsObject) => enumElem['@id'] === uri);
    return foundEnumElem ? foundEnumElem.title : uri;
  };

  // Project link types
  const [projectLinkTypes, setProjectLinkTypes] = useState<JsObject[]>([]);
  const [loadingProjectLinkTypes, setLoadingProjectLinkTypes] = useState<boolean>(false);
  const [updatingProjectLinkTypes, setUpdatingProjectLinkTypes] = useState<boolean>(false);

  // users information
  const { objects: projectUsers, loading: projectUsersLoading } = useObjects(provider, 'pporoles:User');

  const { loadingDataTypes, dataTypes } = useDataTypes(provider);

  const projectLinkTypesCheckout = async (): Promise<void> => {
    try {
      setLoadingProjectLinkTypes(true);
      let result = await provider.selectObjectsWithTypeInfo('rm:LinkClasses', {});
      console.log('ProjectLinkTypes', result);
      result.sort((e1: JsObject, e2: JsObject) => {
        if (e1.title > e2.title) {
          return 1;
        }
        if (e1.title < e2.title) {
          return -1;
        }
        return 0;
      });
      result = result.filter((linkType: JsObject) => linkType['@id'] !== 'rm:Link');
      console.log('ProjectLinkTypes set', result);
      setProjectLinkTypes(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProjectLinkTypes(false);
    }
  };

  useEffect(() => {
    checkoutProjectArtifactFormats();
    checkoutProjectArtifactStatus();
    checkoutClassifierGroupTypes();

    projectFoldersCheckout();
    projectLinkTypesCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        provider,
        // Project artifact types
        artifactTypes,
        loadingArtifactTypes,
        // Project data-types
        dataTypes,
        loadingDataTypes,
        // Project artifact formats
        artifactFormats,
        loadingArtifactFormats,
        artifactStatus,
        //loadingArtifactStatus,
        getTitleByEnumURI,
        // Project folders
        projectFolders, // Read-only field storing folders list
        projectFoldersCheckout, // Function for checkout list of folders, can be used for manual refresh
        projectFolderCreate, // Function for create new folder
        projectFolderDelete, // Function for delete existing folder
        projectFolderRename, // Function for rename existing folder
        projectFolderMove, // Function for change existing folder's parent
        loadingProjectFolders, // Read-only flag indicating initial loading of folders is in progress
        updatingProjectFolders, // Read-only flag indicating changing folders from UI is in progress
        // Project active folder
        projectActiveFolder, // Read-only field storing active folder
        projectActiveFolderUpdate, // Function for change active folder
        // Project link types
        projectLinkTypes,
        projectLinkTypesCheckout,
        loadingProjectLinkTypes,
        updatingProjectLinkTypes,
        // Project users
        projectUsers,
        projectUsersLoading,
        // Project Users Attributes
        //projectPolicyRoles,
        //projectUsersGroups,
        //projectUsersOpsByGroups,
        //projectPermissions,
        //setProjectPermissions,
      }}>
      {children}
    </GlobalContext.Provider>
  );
}
