// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../types/react-resizable.d.ts"/>

import React from 'react';
import './TableHeader.css';
import { Resizable } from 'react-resizable';

interface Props {
  onResize: any;
  width: number | string;
  resizeableHeader: boolean;
  [key: string]: any;
}

interface ResizeableHeaderCell {
  cell: any;
  onResize: any;
  width: number | string;
}

export const ResizeableHeaderCell: React.FC<ResizeableHeaderCell> = ({ cell, onResize, width }) => {
  if (!width || width === 'auto') {
    width = 200;
  }

  if (typeof width === 'string') {
    const initialWidth = width.split('px')[0];
    width = parseInt(initialWidth);
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      {cell}
    </Resizable>
  );
};

export const HeaderCell: React.FC<Props> = ({ resizeableHeader, onResize, width, ...restProps }) => {
  const Cell = <th {...restProps} />;
  return resizeableHeader ? <ResizeableHeaderCell cell={Cell} onResize={onResize} width={width} /> : Cell;
};
