import { LinkDendogramElement } from 'components/link-dendrogram-graph/LinkDendrogram';

export function getDendogramFor(): LinkDendogramElement {
  return {
    id: 289,
    icon: 'file-text',
    title: 'Лаборатория',
    children: [
      {
        id: 324,
        icon: 'file-text',
        title: 'Организаци',
        relation: 'Входит в состав',
        children: [
          {
            id: 293,
            icon: 'file-text',
            title: 'Отделение',
            relation: 'Включает',
          },
          {
            id: 295,
            icon: 'file-text',
            title: 'Учереждение',
            relation: 'Включает',
          },
          {
            id: 321,
            icon: 'file-text',
            title: 'Общие объекты и субъекты',
            relation: 'Входит в состав',
          },
          {
            id: 359,
            icon: 'file-text',
            title: 'Юридическое лицо',
            relation: 'Включает',
          },
          {
            id: 360,
            icon: 'file-text',
            title: 'Отчитывающаяся организаци',
            relation: 'Включает',
          },
          {
            id: 361,
            icon: 'file-text',
            title: 'Экспертный совет',
            relation: 'Включает',
          },
        ],
      },
      {
        id: 438,
        icon: 'file-text',
        title: 'Адрес',
        relation: 'Включает',
      },
      {
        id: 439,
        icon: 'file-text',
        title: 'Полное наименование',
        relation: 'Включает',
      },
    ],
  };
}
