import React, { MouseEvent, CSSProperties } from 'react';

import { LockButtonsPanelProvider } from './LockButtonsPanelProvider';
import { LockButtonsPanelLayout } from './LockButtonsPanelLayout';
import { SizeType } from 'antd/es/config-provider/SizeContext';

export interface LockButtonsPanel {
  size?: SizeType;
  style?: CSSProperties;
  editMode?: boolean;
  editData?: boolean;
  onEdit?: () => void;
  onSave?: (e: MouseEvent) => void;
  onDone?: (e: MouseEvent) => void;
  onCancel?: (e: MouseEvent) => void;
  onRefresh?: (e: MouseEvent) => void;
  hamburgerMenuList?: any;
}

export const LockButtonsPanel: React.FC<LockButtonsPanel> = ({
  size,
  style,
  editMode,
  editData,
  onEdit,
  onDone,
  onSave,
  onCancel,
  onRefresh,
  hamburgerMenuList = undefined,
}) => {
  return (
    <LockButtonsPanelProvider editMode={editMode} editData={editData}>
      <LockButtonsPanelLayout
        size={size}
        style={style}
        onEdit={onEdit}
        onDone={onDone}
        onSave={onSave}
        onCancel={onCancel}
        onRefresh={onRefresh}
        hamburgerMenuList={hamburgerMenuList}
      />
    </LockButtonsPanelProvider>
  );
};
