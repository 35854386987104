import { useState, useEffect } from 'react';

export function useCombineLoading(...loadings: any[]) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(loadings.reduce((a, b) => a || b));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadings]);
  return { loading };
}
