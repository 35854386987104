import { useState, useEffect } from 'react';
import { selectComments } from 'api/comments';

export function useComments(id: string) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    async function selectData(id2: string) {
      const result = await selectComments(id2);
      setData(result);
      setLoading(false);
    }
    selectData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loadingComments: loading,
    comments: data,
  };
}
