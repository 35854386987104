import React from 'react';
import { UiSchema, ArrayFieldTemplateProps } from 'react-jsonschema-form';
import { Alert, List, Form as AntForm } from 'antd';
import { defaultRowStyle } from './styles';
import { JsObject } from '@agentlab/sparql-jsld-client';

type Props = {
  items: {
    [key: string]: {
      [key: string]: any;
    };
  }[];
  classNames: string;
  uiSchema: any;
};

export const ArrayRowTemplate: React.FC<ArrayFieldTemplateProps<any>> = ({
  items,
  uiSchema,
  schema,
  className /*, id, help, required, description, errors, children, disabled*/,
}: ArrayFieldTemplateProps<any>) => {
  //console.log('AEA', props);
  //   //   console.log(errors);
  //   const errList = (
  //     <List size='small' dataSource={errors.props.errors} renderItem={(item) => <List.Item>{item}</List.Item>} />
  //   );
  return (
    <div className={className} style={{ flexDirection: 'row' }}>
      {/* <AntForm.Item key={id} style={defaultRowStyle} disabled={disabled}>
        {description.props.description && (
          <Alert message={description.props.description} type='info' showIcon style={{ display: 'inline-block' }} />
        )}
        {children}
        {errors.props.errors !== undefined &&
          (errors.props.errors.length > 1 ? (
            <Alert message={errList} type='error' showIcon style={{ display: 'inline-block' }} />
          ) : (
            <Alert message={errors.props.errors[0]} type='error' showIcon style={{ display: 'inline-block' }} />
          ))}
        {help.props.help && <Alert message={help.props.help} type='info' />}
      </AntForm.Item> */}
      {items.map((item: JsObject, index: number) => {
        console.log(index, item);
        if (
          schema !== undefined &&
          schema.items !== undefined &&
          Array.isArray(schema.items) &&
          schema.items[index] !== undefined
        ) {
          return (
            <div key={index} style={{ display: 'inline-block', margin: 10 }}>
              {' '}
              {/*uiSchema[schema.items[index].__arrayItemName]['ui:field'](item.children.props)*/}{' '}
            </div>
          );
        } else return <></>;
      })}
    </div>
  );
};
