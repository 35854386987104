import React, { CSSProperties } from 'react';
import { Form, Divider, Col, Row } from 'antd';
import { Z_BLOCK } from 'zlib';
import './HorizontalTemplate.css';
import { JsObject } from '@agentlab/sparql-jsld-client';

type SpanType = {
  span: number;
};
type ColType = {
  sm: SpanType;
};
interface FormLayout {
  labelCol: ColType;
  wrapperCol: ColType;
}
interface HorizontalTemplate {
  id: string;
  label: string;
  classNames: string;
  children: any;
  schema: JsObject;
}

export const HorizontalTemplate: React.FC<HorizontalTemplate> = ({ id, label, classNames, children, schema }) => {
  if (id === 'root') {
    return <div className={classNames}>{children}</div>;
  }
  if (schema.type === 'null') {
    return (
      <div className={classNames}>
        <Divider style={{ margin: '5px 0' }} />
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', paddingBottom: '5px' }}>
      <label className={'label-nowrap'} htmlFor={id}>
        {label}:
      </label>
      <div className={'input-margin'}>{children}</div>
    </div>
  );
};
