import React from 'react';
import { Modal } from 'antd';

import { getDendogramFor } from 'api/dendogram';

import { LinkDendrogramGraph } from 'components/link-dendrogram-graph/LinkDendrogramGraph';

interface LinkDendrogramDialog {
  id?: string;
  visible?: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const LinkDendrogramDialog: React.FC<LinkDendrogramDialog> = ({ id = '', visible = true, onOk, onCancel }) => {
  const data = getDendogramFor();
  return (
    <Modal
      title='Дендрограмма'
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width='90%'
      style={{ height: '90%' }}
      bodyStyle={{ height: '100%' }}>
      <LinkDendrogramGraph data={data} />
    </Modal>
  );
};
