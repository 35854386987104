import { useState, useEffect } from 'react';
import { ObjectProvider, JsObject, JSONSchema6forRdf } from '@agentlab/sparql-jsld-client';

export interface UseObjectInterface {
  object: JsObject;
  schema: JSONSchema6forRdf | string; // actual concrete element schema
  uiSchema: JsObject;
  setConditions: React.Dispatch<React.SetStateAction<any>>;
  loading: boolean;
  checkout: () => Promise<void>; // for refresh only
  updating: boolean;
  update: (artifact: any, values: any) => Promise<void>;
}

/**
 * React Hook for working with single Object. Could retrieve element by parent or concrete class uri and conditions
 * and provide update function.
 * @param provider - async object provider
 * @param schemaOrStringForSelect - schema or schema uri for select, could be parent schema
 * @param conditionsForSelect - conditions
 */
export function useObject(
  provider: ObjectProvider,
  schemaOrStringForSelect: JSONSchema6forRdf | string,
  conditionsForSelect: any = {},
): UseObjectInterface {
  const [schemaOrString, setSchemaOrString] = useState<JSONSchema6forRdf | string>(schemaOrStringForSelect);
  const [schema, setSchema] = useState<JSONSchema6forRdf | string>(schemaOrString);
  const [conditions, setConditions] = useState<any>(conditionsForSelect);

  const [object, setObject] = useState<JsObject>({});
  const [uiSchema, setUiSchema] = useState<JsObject>({});
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const checkout = async (): Promise<void> => {
    try {
      setLoading(true);
      const results = await provider.selectObjectsWithTypeInfo(schemaOrString, conditions);
      let result: JsObject = {};
      if (results.length > 0) {
        result = results[0];
        const sch = await provider.getSchemaByUri(result['@type']);
        const uiSch = await provider.getUiSchemaByUri(result['@type']);
        setSchema(sch);
        setUiSchema(uiSch);
      } else {
        if (typeof schemaOrString === 'string') {
          const sch = await provider.getSchemaByUri(schemaOrString);
          const uiSch = await provider.getUiSchemaByUri(schemaOrString);
          setSchema(sch);
          setUiSchema(uiSch);
        }
      }
      setObject(result);
    } catch (error) {
      console.error(error);
      setObject({});
    } finally {
      setLoading(false);
    }
  };

  // Load on startup
  useEffect(() => {
    checkout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions]);

  useEffect(() => {
    if (conditions.identifier !== conditionsForSelect.identifier) {
      setConditions(conditionsForSelect);
    }
    // setConditions(conditionsForSelect);
  }, [conditionsForSelect]);

  /**
   * Update
   * @param elem -- исходный объект со всеми полями и URI
   * @param values -- только изменяемые поля в соответствии со схемой
   */
  const update = async (elem: any, values: any): Promise<void> => {
    try {
      setUpdating(true);
      await provider.updateObject(schema, elem, values);
      setObject({ ...elem, ...values });
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
    }
  };

  return {
    setConditions,
    object,
    schema,
    uiSchema,
    loading,
    checkout,
    updating,
    update,
  };
}
