import React, { useState } from 'react';
import { Form, Radio } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface SelectLinkTargetFormItem {
  labelFrom?: string;
  disabled?: boolean;
  onChange: (e: JsObject) => void;
  reast?: JsObject[];
}

export const SelectLinkTargetFormItem: React.FC<SelectLinkTargetFormItem> = ({
  labelFrom,
  disabled = false,
  onChange = () => {},
  ...rest
}: SelectLinkTargetFormItem) => {
  return (
    <Form.Item label='Целевой объект связи: ' {...rest}>
      <Radio.Group defaultValue='requirement' disabled={disabled} onChange={onChange}>
        {/* TODO(!!!) При определенной ширине окна текст вылезает из второй кнопки */}
        <Radio.Button value='requirement'>Требование</Radio.Button>
        <Radio.Button value='web'>Веб-страница</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};
