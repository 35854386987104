import React, { useState } from 'react';
import './ContextMenu.css';

interface ContextMenu {
  visible: boolean;
  x: string | number;
  y: string | number;
  children: any;
}

export function useContextMenu() {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupCoords, setPopupCoords] = useState({ x: 0, y: 0 });
  const [popupData, setPopupData] = useState(null);

  return {
    popupVisible,
    setPopupVisible,
    popupCoords,
    setPopupCoords,
    popupData,
    setPopupData,
  };
}

export const ContextMenu: React.FC<ContextMenu> = ({ visible, x, y, children }) => {
  if (visible) {
    return (
      <div className='popup' style={{ left: `${x}px`, top: `${y}px`, position: 'fixed', zIndex: 100 }}>
        {children}
      </div>
    );
  }
  return null;
};
