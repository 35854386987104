// TODO: When SPARQL request will be ready, remove it
const fake = true;
const fakeTimeout = 1000;

export const fakeViews = [
  {
    title: 'По умолчанию',
    key: 'default',
    columns: [
      {
        id: 'identifier',
        width: 40,
      },
      {
        id: 'title',
        width: 50,
        editable: true,
      },
      {
        id: 'type',
        width: 140,
      },
      {
        id: 'artifactFormat',
        width: 140,
      },
      {
        id: 'modifiedBy',
        width: 140,
      },
      {
        id: 'modified',
        width: 240,
      },
    ],
  },
  {
    title: 'Все требования',
    key: 'all',
    columns: [
      {
        id: 'identifier',
        width: 40,
      },
      {
        id: 'title',
        width: 50,
        editable: true,
      },
      {
        id: 'type',
        width: 140,
      },
      {
        id: 'modifiedBy',
        width: 140,
      },
      {
        id: 'modified',
        width: 240,
      },
    ],
  },
  // {
  //   title: 'Бизнес-правила',
  //   key: 'business-rules',
  // },
  // {
  //   title: 'Разработанные пользовательские истории',
  //   key: 'user-stories',
  // },
  // {
  //   title: 'Функции с сценариями тестирования',
  //   key: 'functions',
  // },
  // {
  //   title: 'Функции с наивысшим приоритетом',
  //   key: 'top_finctions',
  // },
];

export const fakeViewsForModule = [
  {
    title: 'Загружено из CSV',
    key: 'https://agentlab.ru/expert/rm/expert/reqs-module#',
    columns: [
      {
        id: 'identifier',
        width: 15,
      },
      {
        id: 'xhtmlText',
        width: 240,
        editable: true,
      },
      /*{
        id: 'type',
        width: 140,
      },
      {
        id: 'modifiedBy',
        width: 140,
      },
      {
        id: 'modified',
        width: 240,
      },*/
    ],
  },
  {
    title: 'ЧТЗ Взаимодействия с внешними инструментами',
    key: 'https://agentlab.ru/expert/rm/expert/reqs-module-docx#',
    columns: [
      {
        id: 'identifier',
        width: 10,
      },
      {
        id: 'xhtmlText',
        width: 140,
        editable: true,
      },
      /*{
        id: 'type',
        width: 140,
      },
      {
        id: 'modifiedBy',
        width: 140,
      },
      {
        id: 'modified',
        width: 240,
      },*/
    ],
  },
  {
    title: 'ЧТЗ Управление требованиями',
    key: 'https://agentlab.ru/expert/rm/expert/reqs-module-docx-big#',
    columns: [
      {
        id: 'identifier',
        width: 10,
      },
      {
        id: 'xhtmlText',
        width: 200,
        editable: true,
      },
      /*{
        id: 'type',
        width: 140,
      },
      {
        id: 'modifiedBy',
        width: 140,
      },
      {
        id: 'modified',
        width: 240,
      },*/
    ],
  },
];

/**
 * Retrieve views for specified artifact.
 * @param {string} id the identifier of artifact
 * @param {string} graph the graph URI
 * @param {string} url the repository URL
 */
export async function fetchViewsFor(id) {
  if (fake) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (id === 'module') {
          resolve(fakeViewsForModule);
        } else {
          resolve(fakeViews);
        }
      }, fakeTimeout);
    });
  }

  // TODO: real SPARQL here
}
