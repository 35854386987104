import moment from 'moment';

export const fakeCommnets = [
  {
    author: 'Admin',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    content: 'Да, требует.',
    datetime: moment().subtract(1, 'days'),
  },
  {
    author: 'Admin',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    content: 'Это требование требует изменений?',
    datetime: moment().subtract(3, 'days'),
  },
];

/**
 * Retrieve comments for specified artifact.
 * @param id the identifier of artifact
 */
export function selectComments(id: string) {
  return new Promise<any[]>((resolve) => {
    setTimeout(() => {
      resolve(fakeCommnets);
    }, 1000);
  });
}
