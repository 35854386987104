import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Widget } from 'react-jsonschema-form';

export const BusinessPriorityWidget: Widget = ({ value, readonly, onChange = (): void => {} }) => {
  const { t } = useTranslation();
  if (readonly) {
    if (value) {
      return <span>{value}</span>;
    } else {
      return <span style={{ color: 'lightgray' }}>Не заполнено</span>;
    }
  }
  return (
    <Select
      value={value}
      onChange={(e): void => {
        console.log(e);
        onChange(e);
      }}
      size='small'>
      <Select.Option value={t<string>('EditArtifact.business_priority.must')}>
        {t<string>('EditArtifact.business_priority.must')}
      </Select.Option>
      <Select.Option value={t<string>('EditArtifact.business_priority.should')}>
        {t<string>('EditArtifact.business_priority.should')}
      </Select.Option>
      <Select.Option value={t<string>('EditArtifact.business_priority.could')}>
        {t<string>('EditArtifact.business_priority.could')}
      </Select.Option>
      <Select.Option value={t<string>('EditArtifact.business_priority.wont')}>
        {t<string>('EditArtifact.business_priority.wont')}
      </Select.Option>
    </Select>
  );
};
