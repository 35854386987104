import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute } from './ArtifactForm';
import { ColumnProps } from 'antd/lib/table';

import { Table, Modal } from 'antd';

import { GlobalContext } from 'contexts/GlobalContext';
import { TableRowSelection } from 'antd/lib/table/interface';

interface AttributeTypeListDialog {
  visible: boolean;
  attributes: Attribute[];
  onOk: (selects: Attribute[]) => void;
  onCancel: () => void;
}

const attrColumns: ColumnProps<Attribute>[] = [
  {
    title: 'Имя',
    dataIndex: 'attributeTitle',
    key: 'attributeTitle',
    render: (text) => <a href='#!'> {text} </a>,
  },
  {
    title: 'Тип данных',
    dataIndex: 'dataType',
    key: 'dataType',
  },
  {
    title: 'Описание',
    dataIndex: 'attributeDescription',
    key: 'attributeDescription',
  },
];

export const AttributeTypeListDialog: React.FC<AttributeTypeListDialog> = ({
  visible = true,
  attributes = [],
  onOk = () => {},
  onCancel = () => {},
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAttributeDefinitions, setLoadingAttributeDefinitions] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Attribute[]>([]);
  const [selectedData, setSelectedData] = useState<Attribute[]>([]);
  const [attributeTypes, setAttributeTypes] = useState([]);
  const [selects, setSelects] = useState<(number | string)[]>([]);
  const { t } = useTranslation();
  const { provider } = useContext(GlobalContext);

  const rowSelection: TableRowSelection<Attribute> = {
    selectedRowKeys: selects,
    onChange: (selectedRowKeys: (string | number)[], records: Attribute[]) => {
      setSelects(selectedRowKeys);
      setSelectedData(records);
    },
  };

  useEffect(() => {
    // wrapper func for sync call of async functions
    async function selectAttributeDefinitions(): Promise<void> {
      setLoadingAttributeDefinitions(true);
      const schema = await provider.getSchemaByUri('cpgu:Classifier');
      console.log('schema', schema);

      const data: Attribute[] = [];
      let p;

      if (schema && schema.properties) {
        for (const key in schema.properties) {
          if (
            attributes.findIndex((item: Attribute) => {
              if (schema.properties !== undefined) {
                return item.key === schema.properties[key]['@id'];
              }
            }) === -1
          ) {
            p = schema.properties[key];
            data.push({
              id: p['@id'] || 'undefined',
              key: p['@id'] || 'undefined',
              attributeTitle: p.title,
              dataType: p.format ? p.format : p.type,
              attributeDescription: p.description ? p.description : '',
              attribute: p['@id'] || 'undefined',
            });
          }
        }
      }
      console.log(data);
      setDataSource(data);
      setLoadingAttributeDefinitions(false);
    }
    selectAttributeDefinitions();
    console.log(visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeTypes]);

  return (
    <Modal
      title='Выбор атрибутов'
      visible={visible}
      onOk={() => onOk(selectedData)}
      onCancel={onCancel}
      cancelText='Отмена'
      okText='Выбрать'>
      <Table
        className='gx-table-responsive'
        size='small'
        bordered
        pagination={false}
        rowKey={(record) => record.id}
        columns={attrColumns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        title={() => <b> {t('customAttribute.title')} </b>}
      />
    </Modal>
  );
};
