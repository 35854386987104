import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableTable } from './text-delimiters-table';

import { Form, Input, Radio, TreeSelect, Select, Checkbox } from 'antd';
import { FolderOutlined } from '@ant-design/icons';

import { GlobalContext } from 'contexts/GlobalContext';
import { ReqsImportData } from 'api/import';
import { JsObject } from '@agentlab/sparql-jsld-client';

const RadioGroup = Radio.Group;
const Option = Select.Option;

//#region Styles
const radioStyle: React.CSSProperties = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 16 },
  },
};

const styleItem = {
  marginBottom: 0,
};
//#endregion

type DocxImportSettingsForm = {
  fileName: string;
  fileKey: number;
  updateState(param: ReqsImportDataWithKeyStatus): void;
  param: ReqsImportDataWithKeyStatus;
};

export interface ReqsImportDataWithKeyStatus extends ReqsImportData {
  key: number;
  status: string;
  importColumnsStrategy?: string;
}

export const DocxImportSettingsForm: React.FC<DocxImportSettingsForm> = ({ fileName, fileKey, updateState, param }) => {
  const { t } = useTranslation();

  const { projectFolders, artifactTypes, artifactFormats } = useContext(GlobalContext);

  //#region Valid
  const [docTitleValid, setDocTitleValid] = useState('success' as any);
  const [docTypeValid, setDocTypeValid] = useState('success' as any);
  const [docFormatValid, setDocFormatValid] = useState('success' as any);

  const [headerTypeValid, setHeaderTypeValid] = useState('success' as any);
  const [headerFormatValid, setHeaderFormatValid] = useState('success' as any);

  const [paragraphTypeValid, setParagraphTypeValid] = useState('success' as any);
  const [paragraphFormatValid, setParagraphFormatValid] = useState('success' as any);

  const [folderValid, setFolderValid] = useState('success' as any);
  const [delimitersValid, setDelimitersValid] = useState('success' as any);
  //#endregion

  //#region Value
  const [createDocRadio, setCreateDocRadio] = useState(2);
  const [docTitle, setDocTitle] = useState<string | undefined>(param.docTitle);
  const [docTitleCheck, setDocTitleCheck] = useState(true);
  const [docType, setDocType] = useState<string | undefined>(param.docType);
  const [docFormat, setDocFormat] = useState<string | undefined>(param.docFormat);

  const [headerType, setHeaderType] = useState<string | undefined>(param.headerType);
  const [headerFormat, setHeaderFormat] = useState<string | undefined>(param.headerFormat);
  const [headerPattern, setHeaderPattern] = useState<string | undefined>(param.headerPattern);

  const [paragraphType, setParagraphType] = useState<string | undefined>(param.paragraphType);
  const [paragraphFormat, setParagraphFormat] = useState<string | undefined>(param.paragraphFormat);

  const [folder, setFolder] = useState<string | undefined>(param.folder);
  const [delimiters, setDelimiters] = useState([]);
  //#endregion

  useEffect(() => {
    if (docTitle && docTitle.length > 0) {
      setDocTitleValid('success');
    } else {
      setDocTitleValid('warning');
    }
  }, [docTitle]);

  useEffect(() => {
    if (createDocRadio === 1) {
      setDocTitleValid('success');
      setDocTypeValid('success');
      setDocFormatValid('success');
    } else if (createDocRadio === 2) {
      if (docTitleCheck || docTitle) {
        setDocTitleValid('success');
      } else {
        setDocTitleValid('warning');
      }

      if (docType) {
        setDocTypeValid('success');
      } else {
        setDocTypeValid('warning');
      }

      if (docFormat) {
        setDocFormatValid('success');
      } else {
        setDocFormatValid('warning');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDocRadio]);

  useEffect(() => {
    if (docTitleCheck || docTitle) {
      setDocTitleValid('success');
    } else {
      setDocTitleValid('warning');
    }
    if (docTitleCheck) setDocTitle(fileName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docTitleCheck]);

  //if (docTitleCheck) setDocTitle(fileName);

  useEffect(() => {
    if (docType) {
      setDocTypeValid('success');
    } else {
      setDocTypeValid('warning');
    }
  }, [docType]);

  useEffect(() => {
    if (docFormat) {
      setDocFormatValid('success');
    } else {
      setDocFormatValid('warning');
    }
  }, [docFormat]);

  useEffect(() => {
    if (headerType) {
      setHeaderTypeValid('success');
    } else {
      setHeaderTypeValid('warning');
    }
  }, [headerType]);

  useEffect(() => {
    if (headerFormat) {
      setHeaderFormatValid('success');
    } else {
      setHeaderFormatValid('warning');
    }
  }, [headerFormat]);

  useEffect(() => {
    if (paragraphType) {
      setParagraphTypeValid('success');
    } else {
      setParagraphTypeValid('warning');
    }
  }, [paragraphType]);

  useEffect(() => {
    if (paragraphFormat) {
      setParagraphFormatValid('success');
    } else {
      setParagraphFormatValid('warning');
    }
  }, [paragraphFormat]);

  useEffect(() => {
    if (folder) {
      setFolderValid('success');
    } else {
      setFolderValid('warning');
    }
  }, [folder]);

  function getArtifactTypeURI(nameType: string) {
    const artifactType = artifactTypes.find((el: any) => {
      return el.title === nameType;
    });
    return artifactType && artifactType['@id'];
  }

  useEffect(() => {
    let stateIcon;
    if (
      docTitleValid === 'success' &&
      docTypeValid === 'success' &&
      docFormatValid === 'success' &&
      headerTypeValid === 'success' &&
      headerFormatValid === 'success' &&
      paragraphTypeValid === 'success' &&
      paragraphFormatValid === 'success' &&
      folderValid === 'success' &&
      delimitersValid === 'success'
    ) {
      stateIcon = 'check-circle';
    } else {
      stateIcon = 'exclamation-circle';
    }
    const delimitersDataURI = JSON.parse(JSON.stringify(delimiters));
    delimitersDataURI.forEach((e: any) => {
      e.type = getArtifactTypeURI(e.type);
    });
    const state: ReqsImportDataWithKeyStatus = {
      key: fileKey,
      status: stateIcon,
      createDoc: createDocRadio === 1 ? false : true,
    };
    state.docTitle = docTitle;
    state.docType = docType;
    state.docFormat = docFormat;

    state.headerType = headerType;
    state.headerFormat = headerFormat;
    state.headerPattern = headerPattern;

    state.paragraphType = paragraphType;
    state.paragraphFormat = paragraphFormat;

    state.folder = folder;
    if (delimiters) state.delimiters = delimitersDataURI;
    updateState(state);
  }, [
    docTitleValid,
    docTypeValid,
    docFormatValid,
    headerTypeValid,
    headerFormatValid,
    paragraphTypeValid,
    paragraphFormatValid,
    folderValid,
    delimitersValid,
    createDocRadio,
    docTitle,
    docTitleCheck,
    docType,
    docFormat,
    headerType,
    headerFormat,
    headerPattern,
    paragraphType,
    paragraphFormat,
    folder,
    delimiters,
  ]);

  function getTreeFolders(folders: JsObject) {
    return folders.map((folder: JsObject) => {
      return {
        title: folder.title,
        key: folder['@id'],
        value: folder['@id'],
        icon: <FolderOutlined />,
        children: folder.children && getTreeFolders(folder.children),
      };
    });
  }

  const treeSearchProps = {
    treeData: getTreeFolders(projectFolders),
    treeIcon: true,
    name: 'artifactsFolderValue',
    treeDefaultExpandAll: true,
    allowClear: true,
    showSearch: true,
    placeholder: t('ImportForm.DocxView.folder.placeholder'),
    dropdownStyle: {
      maxHeight: 300,
      overflow: 'auto',
    },
  };

  return (
    <Form layout='horizontal' className='importFormBox'>
      <Form.Item {...formItemLayout} style={styleItem} label={t('ImportForm.DocxView.file.label')}>
        <b>
          <span className='ant-form-text'>{fileName}</span>
        </b>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.DocxView.folder.label')}
        hasFeedback
        validateStatus={folderValid}>
        <TreeSelect
          size='small'
          value={folder}
          onChange={(value, key) => {
            setFolder(value);
          }}
          {...treeSearchProps}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} style={styleItem} label={t('ImportForm.DocxView.createDocRadio.label')}>
        <RadioGroup
          name='createDocRadio'
          size='small'
          onChange={(e) => setCreateDocRadio(e.target.value)}
          value={createDocRadio}
          disabled={true}>
          <Radio style={radioStyle} value={1}>
            {t('ImportForm.DocxView.createDocRadio.radio.no')}
          </Radio>
          <Radio style={radioStyle} value={2}>
            {t('ImportForm.DocxView.createDocRadio.radio.yes')}
          </Radio>
        </RadioGroup>
      </Form.Item>

      <Form.Item {...formItemLayout} style={styleItem} label={t('ImportForm.DocxView.docTitle.label')}>
        <Form.Item
          style={{ ...styleItem, ...{ display: 'inline-block', width: 'calc(60% - 12px)' } }}
          hasFeedback
          validateStatus={docTitleValid}>
          <Input
            id='docTitle'
            size='small'
            value={docTitle}
            onChange={(e) => setDocTitle(e.target.value)}
            disabled={createDocRadio !== 2 || docTitleCheck}
          />
        </Form.Item>
        <span style={{ display: 'inline-block', width: '24px' }} />
        <Form.Item style={{ display: 'inline-block', width: 'calc(40% - 12px)', marginBottom: 0 }} colon={false}>
          <Checkbox
            disabled={createDocRadio !== 2}
            checked={docTitleCheck}
            onChange={() => setDocTitleCheck(!docTitleCheck)}>
            {t('ImportForm.DocxView.docTitle.checkbox')}
          </Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.DocxView.docType.label')}
        hasFeedback
        validateStatus={docTypeValid}>
        <Select
          size='small'
          showSearch
          disabled={createDocRadio !== 2}
          onChange={(value) => setDocType(value)}
          value={docType}>
          {artifactTypes.map((d: any) => (
            <Option key={d['@id']} value={d['@id']}>
              {d.title}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.DocxView.docFormat.label')}
        hasFeedback
        validateStatus={docFormatValid}>
        <Select
          size='small'
          showSearch
          disabled={createDocRadio !== 2}
          onChange={(value) => setDocFormat(value)}
          value={'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Module'}>
          {artifactFormats
            .filter((a) => a['@id'] === 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Module')
            .map((d) => (
              <Option key={d['@id']} value={d['@id']}>
                {d.title}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.DocxView.headerType.label')}
        hasFeedback
        validateStatus={headerTypeValid}>
        <Select size='small' showSearch onChange={(value) => setHeaderType(value)} value={headerType}>
          {artifactTypes.map((d: any) => (
            <Option key={d['@id']} value={d['@id']}>
              {d.title}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.DocxView.headerFormat.label')}
        hasFeedback
        validateStatus={headerFormatValid}>
        <Select size='small' showSearch onChange={(value) => setHeaderFormat(value)} value={headerFormat}>
          {artifactFormats.map((d: any) => (
            <Option key={d['@id']} value={d['@id']}>
              {d.title}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...formItemLayout} style={styleItem} label={t('ImportForm.DocxView.headerPattern.label')} hasFeedback>
        <Input
          id='headerPattern'
          size='small'
          defaultValue={headerPattern}
          onChange={(e) => setHeaderPattern(e.target.value)}
          placeholder={'ImportForm.DocxView.headerPattern.placeholder'}
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.DocxView.paragraphType.label')}
        hasFeedback
        validateStatus={paragraphTypeValid}>
        <Select size='small' showSearch onChange={(value) => setParagraphType(value)} value={paragraphType}>
          {artifactTypes.map((d: any) => (
            <Option key={d['@id']} value={d['@id']}>
              {d.title}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={styleItem}
        label={t('ImportForm.DocxView.paragraphFormat.label')}
        hasFeedback
        validateStatus={paragraphFormatValid}>
        <Select size='small' showSearch onChange={(value) => setParagraphFormat(value)} value={paragraphFormat}>
          {artifactFormats.map((d: any) => (
            <Option key={d['@id']} value={d['@id']}>
              {d.title}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/*<Form.Item {...formItemLayout} label={t('ImportForm.DocxView.delimitersTable.label')}>
        <EditableTable setStatus={setDelimitersValid} data={delimitersData} setData={setDelimitersData} />
      </Form.Item>*/}
    </Form>
  );
};
