import React from 'react';
import { Form, Select } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

const separator = '^';
interface SelectLinkTypeFormItem {
  linkTypes: JsObject[];
  onChange: (e: { type: string; direction: string }) => void;
  rest?: JsObject[];
}

export const SelectLinkTypeFormItem: React.FC<SelectLinkTypeFormItem> = ({
  linkTypes,
  onChange = () => {},
  ...rest
}: SelectLinkTypeFormItem) => {
  function onChangeInternal(value: string) {
    console.log('onChangeInternal.value', value);
    const [type, direction] = value.split(separator);
    onChange({ type, direction });
  }
  console.log('SelectLinkTypeFormItem.myTree=', linkTypes);
  return (
    <Form.Item label='Тип связи' {...rest}>
      <Select placeholder='Выберите тип связи' onChange={onChangeInternal}>
        {linkTypes.map((linkType, index) => (
          <Select.OptGroup key={index} label={linkType.title}>
            <Select.Option key={linkType['@id'] + 'subject'} value={linkType['@id'] + separator + 'subject'}>
              {linkType.subjectToObjectLabel}
            </Select.Option>
            <Select.Option key={linkType['@id'] + 'object'} value={linkType['@id'] + separator + 'object'}>
              {linkType.objectToSubjectLabel}
            </Select.Option>
          </Select.OptGroup>
        ))}
      </Select>
    </Form.Item>
  );
};
