import React, { PropsWithChildren, useState, useEffect, useContext } from 'react';
// Components
import { rootFolderUri } from 'config';
// Hooks
import { useViews, UseViewsInterface } from 'hooks/useViews';
import { useCombineLoading } from 'hooks/useCombineLoading';
import { useFilters, UseFiltersInterface } from 'hooks/useFilters';

import { JsObject } from '@agentlab/sparql-jsld-client';
import { useObjects } from 'hooks/useObjects';
import { GlobalContext } from './GlobalContext';

export interface UseArtifactsInterface {
  projectArtifacts: JsObject[];
  projectArtifactsLoading: boolean;
  projectArtifactsUpdating: boolean;
  updateArtifact: (artifact: any, values: any) => Promise<void>;
  deleteArtifact: (conds: any) => Promise<void>;
  deleteArtifacts: (conds: any) => Promise<void>;
  artifactCreate: (data: JsObject) => Promise<JsObject>;
}

export interface ProjectContextProviderInterface
  extends UseArtifactsInterface,
    UseViewsInterface,
    Omit<UseFiltersInterface, 'getFilterCondition'> {
  refreshArtifacts: () => Promise<void>;
  // Project views
  projectActiveView: any; // Read-only field storing active view
  projectActiveViewUpdate: React.Dispatch<any>; // Function for change active view
  // Filters
  filterLoading: boolean;
  fullTextSearchString: string;
  setFullTextSearchString: React.Dispatch<React.SetStateAction<string>>;
  // Flags
  projectLoading: boolean;
  projectUpdating: boolean;
}

export const ProjectContext = React.createContext<ProjectContextProviderInterface>(null!);

/**
 * Context for working with Project and it's artifacts.
 * Will load all artifacts for project on startup.
 * @param children - array of chlid components
 */
export function ProjectContextProvider({ children }: PropsWithChildren<any>): JSX.Element {
  const { provider, projectFolders, projectActiveFolder, projectActiveFolderUpdate } = useContext(GlobalContext);
  // Project views
  const { loadingViews, views } = useViews('');
  const [projectActiveView, projectActiveViewUpdate] = useState<any>(null);
  // Filters
  const { addFilter, removeFilter, getFilterCondition, filters } = useFilters();

  const [fullTextSearchString, setFullTextSearchString] = useState<string>('');

  // Project artifacts
  const {
    //setConditions: setProjectArtifactsConditions,
    objects: projectArtifacts,
    //setProjectArtifacts,
    loading: projectArtifactsLoading,
    //setProjectArtifactsLoading,
    updating: projectArtifactsUpdating,
    //setProjectArtifactsUpdating,
    checkout: checkoutProjectArtifacts,
    update: updateArtifact,
    deleteObject: deleteArtifact,
    deleteObjects: deleteArtifacts,
    create: artifactCreate,
  } = useObjects(provider, 'rm:Artifact');
  // When some updating on artifacts are executed
  const { loading: projectUpdating } = useCombineLoading(projectArtifactsUpdating);

  // When list of artifacts are loaded
  const { loading: projectLoading } = useCombineLoading(
    projectArtifactsLoading,
    loadingViews,
    //projectUsersLoading,
    //projectUsersGroupsLoading,
  );

  const refreshArtifacts = async (): Promise<void> => {
    if (filters.length > 0) {
      checkoutProjectArtifacts({ ...getFilterCondition() });
    }
  };

  useEffect(() => {
    const folderFilter = filters.find((filter) => filter.property === 'assetFolder');
    if (folderFilter) {
      projectActiveFolderUpdate(folderFilter.value[0]);
    } else {
      projectActiveFolderUpdate('');
    }
  }, [filters]);

  // Select first (root) folder when folders list is changed
  //useEffect(() => {
  //if (projectFolders && projectFolders.length > 0) {
  //  projectActiveFolderUpdate(projectFolders[0]['@id']);
  //}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [projectFolders]);

  // Select first view when views list is changed
  useEffect(() => {
    if (views && views.length > 0) {
      projectActiveViewUpdate(views[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  useEffect(() => {
    refreshArtifacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectActiveView, filters]);

  return (
    <ProjectContext.Provider
      value={{
        // Project artifacts
        projectArtifacts,
        projectArtifactsLoading,
        projectArtifactsUpdating,
        //checkoutProjectArtifacts,
        refreshArtifacts,
        updateArtifact,
        deleteArtifact,
        deleteArtifacts,
        artifactCreate,
        // Project views
        views,
        loadingViews,
        projectActiveView, // Read-only field storing active view
        projectActiveViewUpdate, // Function for change active view

        // Filters
        addFilter,
        removeFilter,
        filters,
        filterLoading: projectArtifactsLoading,
        fullTextSearchString,
        setFullTextSearchString,
        // Flags
        projectLoading,
        projectUpdating,
      }}>
      {children}
    </ProjectContext.Provider>
  );
}
