import React, { useState, useEffect, useContext } from 'react';
// Hooks
import { useObject, UseObjectInterface } from 'hooks/useObject';
import { useCombineLoading } from 'hooks/useCombineLoading';
import { useComments } from 'hooks/useComments';
// Contexts
import { GlobalContext } from 'contexts/GlobalContext';
import { JsObject } from '@agentlab/sparql-jsld-client';

//export interface ProjectContextProviderInterface extends UseArtifactInterface {
//  identifier: any;
//  children: any;
//}

export const ArtifactContext = React.createContext<any>({});

/**
 * Context for working with artifact.
 * Will load this artifact on startup.
 * @param identifier - the identifier of selected artifact
 * @param children - array of chlid components
 */
export function ArtifactContextProvider({ identifier, children }: any): JSX.Element {
  const { projectLinkTypes, provider } = useContext(GlobalContext);

  const {
    object: artifact,
    schema: artifactSchema,
    uiSchema: artifactUiSchema,
    loading: artifactLoading,
    updating: artifactUpdating,
    checkout: artifactCheckout,
    update: artifactUpdate,
  } = useObject(provider, 'rm:Artifact', { identifier: Math.floor(identifier) });
  // Artifact comments
  const { loadingComments, comments } = useComments('id');
  // Artifact links
  const [artifactLinks, setArtifactLinks] = useState<any[]>([]);
  const [loadingArtifactLinks, setLoadingArtifactLinks] = useState(true);
  const [updatingArtifactLinks, setUpdatingArtifactLinks] = useState(true);
  // Artifact where used
  const [loadingUsedIn, setLoadingUsedIn] = useState(true);
  const [artifactUsedIn, setArtifactUsedIn] = useState<any[]>([]);
  // Flag indicating that artifact is in edit mode
  const [artifactEditMode, setArtifactEditMode] = useState(false);
  const [artifactEdited, artifactEditedUpdate] = useState<any>({});
  // When artifact and list of child artifacts are loaded
  const { loading: artifactCombinedLoading } = useCombineLoading(artifactLoading);
  // When some updating on child artifacts are executed
  const { loading: artifactCombinedUpdating } = useCombineLoading(artifactUpdating);

  /**
   * Checkout list of artifacts where used this artifact.
   */
  const artifactUsedInCheckout = async () => {
    try {
      setLoadingUsedIn(true);
      console.log(artifact);
      console.log(artifact['@id']);
      const result = await provider.selectObjectsByObjRefs(
        'rmUserTypes:UsedIn',
        'rm:Artifact',
        { subject: artifact['@id'] },
        { '@id': 'object' },
      );
      console.log(result);
      setArtifactUsedIn(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUsedIn(false);
    }
  };

  /**
   * Checkout list of artifact's links.
   */
  const artifactLinksCheckout = async (): Promise<void> => {
    try {
      setLoadingArtifactLinks(true);
      const populate = (item: any) => {
        const linkType = projectLinkTypes.filter((linkType: any) => linkType['@id'] === item['@type']);
        return {
          ...item,
          type: linkType.length > 0 ? linkType[0] : null,
        };
      };
      const resultOutbound = await provider.selectObjectsWithTypeInfo('rm:Link', { object: artifact['@id'] });
      const resultInbound = await provider.selectObjectsWithTypeInfo('rm:Link', { subject: artifact['@id'] });
      const resultOutboundWithType = resultOutbound.map(populate);
      const resultInboundWithType = resultInbound.map(populate);
      const result = [...resultInboundWithType, ...resultOutboundWithType].filter((item) => item.type);
      const mappedResult = [];
      for (let i = 0; i < result.length; i++) {
        const item = result[i];
        const subject = await provider.selectObjectsWithTypeInfo('rm:Artifact', { '@id': item.subject });
        const object = await provider.selectObjectsWithTypeInfo('rm:Artifact', { '@id': item.object });
        mappedResult.push({
          identifier: item['@id'],
          type: item.type['@id'],
          subjectToObjectLabel: item.type.subjectToObjectLabel,
          objectToSubjectLabel: item.type.objectToSubjectLabel,
          subject: {
            identifier: subject[0].identifier,
            title: subject[0].title,
          },
          object: {
            identifier: object[0].identifier,
            title: object[0].title,
          },
        });
      }
      console.log('ArtifactLinks', mappedResult);
      setArtifactLinks(mappedResult);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingArtifactLinks(false);
    }
  };

  const artifactRefresh = async (): Promise<void> => {
    artifactCheckout();
    artifactLinksCheckout();
    artifactUsedInCheckout();
  };

  /**
   * Create new link instance.
   * @param data link data
   * @param graph the graph URI
   * @param url the repository URL
   */
  const createLink = async (data: any): Promise<JsObject> => {
    const schema = {
      ...(await provider.getSchemaByUri('rmUserTypes:UsedIn')),
      '@id': data.type,
    };

    console.log('createLink.schema', schema);
    console.log('createLink.data', data);

    return provider.createObject(schema, {
      subject: data.subject['@id'],
      object: data.object['@id'],
    });
  };

  /**
   * Create artifact's links.
   */
  const artifactLinksCreate = async (links: any): Promise<void> => {
    try {
      await Promise.all(links.map(async (data: any) => createLink(data)));
      artifactLinksCheckout();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingArtifactLinks(false);
    }
  };

  const artifactEditRequest = (): void => {
    artifactEditedUpdate({ ...artifact });
    setArtifactEditMode(true);
    console.log(artifact, artifactEdited);
  };

  const artifactEditCancel = (): void => {
    console.log(artifact, artifactEdited);
    setArtifactEditMode(false);
  };

  const artifactEditSave = async (): Promise<void> => {
    console.log(artifact, artifactEdited);
    return artifactUpdate(artifact, artifactEdited);
  };

  const artifactEditDone = async (): Promise<void> => {
    console.log('artifactEditDone', { artifact, artifactEdited });
    await artifactUpdate(artifact, artifactEdited);
    setArtifactEditMode(false);
  };

  // Load artifact on startup
  /*useEffect(() => {
    artifactCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);*/

  // Load artifact links when artifact changed
  useEffect(() => {
    if (artifact['@id'] && projectLinkTypes) {
      artifactLinksCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifact, projectLinkTypes]);

  // Load where used when artifact changed
  useEffect(() => {
    if (artifact['@id']) {
      artifactUsedInCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifact]);

  return (
    <ArtifactContext.Provider
      value={{
        // Artifact
        artifact,
        artifactSchema,
        artifactUiSchema,
        artifactLoading,
        artifactUpdating,
        //artifactCheckout,
        artifactUpdate,
        artifactRefresh,
        // Artifact comments
        artifactComments: comments,
        artifactCommentsLoading: loadingComments,
        // Artifact links
        artifactLinks,
        artifactLinksCheckout,
        loadingArtifactLinks,
        updatingArtifactLinks,
        artifactLinksCreate,
        // Artifact where used
        artifactUsedIn,
        artifactUsedInCheckout,
        // Flag indicating that artifact is in edit mode, read only
        artifactEditMode,
        // Edited values of artifact
        artifactEdited,
        artifactEditedUpdate,
        // Functions for begin and finish editing
        artifactEditRequest,
        artifactEditCancel,
        artifactEditSave,
        artifactEditDone,
        // Flags
        //artifactCombinedLoading,
        //artifactCombinedUpdating,
      }}>
      {children}
    </ArtifactContext.Provider>
  );
}
