import { useState } from 'react';

export interface Relation {
  title: string;
  predicate: string;
  type?: string;
}

export interface FilterType {
  value: any[];
  valueName?: string[];
  title: string;
  property: string;
  relation: Relation;
}

export interface ValueOfFilter {
  value: any[];
  valueName?: string[];
}

export interface UseFiltersInterface {
  filters: FilterType[];
  addFilter: (filter: any, location?: string) => void;
  removeFilter: (filter: any) => void;
  getFilterCondition: () => any;
}

export function useFilters(): UseFiltersInterface {
  const [filters, setFilters] = useState<FilterType[]>([]);

  const addFilter = (filter: FilterType, location = 'back') => {
    if (filters.indexOf(filter) === -1) {
      let newFilters = [];
      // if (filter && filter.property === 'assetFolder') {
      //   setFilterByFolderIsExist(true);
      // }
      if (location === 'back') {
        newFilters = filters.filter((value) => value.property !== filter.property).concat(filter);
      } else {
        newFilters = [filter, ...filters.filter((value) => value.property !== filter.property)];
      }
      setFilters(newFilters);
    }
  };

  const removeFilter = (filter: any): void => {
    console.log(filters, filter);
    const newFilters = filters.filter((value) => value.property !== filter.property);
    setFilters(newFilters);
    // if (filter.property === 'assetFolder') {
    //   setFilterByFolderIsExist(false);
    // }
  };

  const getFilterCondition = (): any => {
    const res: any = {};
    console.log('getFilterCondition filters', filters);
    filters.forEach((filter) => {
      if (filter.property) {
        res[filter.property] = filter.value;
      }
      if (filter.property && filter.relation) {
        res[filter.property] = {
          value: filter.value,
          relation: filter.relation.predicate,
        };
      }
    });
    console.log('FILTER CONDITION', res);
    return res;
  };

  return {
    filters,
    addFilter,
    removeFilter,
    getFilterCondition,
  };
}
