import { JsObject } from '@agentlab/sparql-jsld-client';

interface UserData {
  key: string;
  name: string;
  roles: string[];
  groups: string[];
}
interface OpsByGroups {
  groupName: string;
  roles: string[];
}
export const defaultUserData: UserData[] = [
  {
    key: '1',
    name: 'Сергей Селезнев',
    roles: ['Гость'],
    groups: ['Пользователи'],
  },
  {
    key: '2',
    name: 'Аркадий Вознесенский',
    roles: ['Администратор'],
    groups: ['Администраторы'],
  },
  {
    key: '3',
    name: 'Иван Иванов',
    roles: ['Участник', 'Участник рабочей группы'],
    groups: ['Пользователи'],
  },
  {
    key: '4',
    name: 'Константин Субботин',
    roles: ['Проектировщик'],
    groups: ['Пользователи'],
  },
  {
    key: '5',
    name: 'Группа Гости',
    roles: ['Гость'],
    groups: [],
  },
  {
    key: '6',
    name: 'Группа Пользователи',
    roles: ['Зарегистрированный пользователь'],
    groups: ['Гости'],
  },
  {
    key: '7',
    name: 'Группа Администраторы',
    roles: ['Администратор'],
    groups: ['Пользователи'],
  },
];

export const defaultRoles: string[] = [
  'Гость',
  'Зарегистрированный пользователь',
  'Администратор',
  'Создатель требования',
  'Аналитик',
  'Проектировщик',
  'Ответственный',
  'ADMS Owner',
  'ADMS Publisher',
  'Эксперт',
  'Исполнитель',
  'Ответственный исполнитель',
  'Руководитель рабочей группы',
  'Участник рабочей группы',
];
export const defaultPermissions: JsObject = {
  Гость: ['Просмотр папок проекта', 'Удаление комментариев требований'],
  'Зарегистрированный пользователь': [
    'Просмотр папок проекта',
    'Создание, изменение и удаление папок проекта',
    'Создание комментариев требований',
    'Редактирование комментариев требований',
    'Удаление комментариев требований',
    'Просмотр типов связей требований',
    'Создание, редактирование типов связей требований',
    'Удаление типов связей требований',
  ],
  Администратор: [
    'Просмотр папок проекта',
    'Создание, изменение и удаление папок проекта',
    'Создание комментариев требований',
    'Редактирование комментариев требований',
    'Удаление комментариев требований',
    'Просмотр типов связей требований',
    'Создание, редактирование типов связей требований',
    'Удаление типов связей требований',
  ],
  'Создатель требования': [],
  Аналитик: [],
  Проектировщик: ['Просмотр папок проекта', 'Удаление комментариев требований'],
  Ответственный: [
    'Просмотр папок проекта',
    'Создание, изменение и удаление папок проекта',
    'Создание комментариев требований',
    'Редактирование комментариев требований',
    'Удаление комментариев требований',
    'Просмотр типов связей требований',
    'Создание, редактирование типов связей требований',
    'Удаление типов связей требований',
  ],
  'ADMS Owner': [],
  'ADMS Publisher': [],
  Эксперт: ['Просмотр папок проекта', 'Удаление комментариев требований'],
  Исполнитель: [
    'Просмотр папок проекта',
    'Создание, изменение и удаление папок проекта',
    'Создание комментариев требований',
    'Редактирование комментариев требований',
    'Удаление комментариев требований',
    'Просмотр типов связей требований',
    'Создание, редактирование типов связей требований',
    'Удаление типов связей требований',
  ],
  'Ответственный исполнитель': ['Просмотр папок проекта', 'Удаление комментариев требований'],
  'Руководитель рабочей группы': ['Просмотр папок проекта', 'Удаление комментариев требований'],
  'Участник рабочей группы': ['Просмотр папок проекта', 'Удаление комментариев требований'],
};

export const defaultGroups: string[] = ['Гости', 'Пользователи', 'Администраторы'];

export const defaultOpsByGroups: OpsByGroups[] = [
  {
    groupName: 'Папки проекта',
    roles: ['Просмотр папок проекта', 'Создание, изменение и удаление папок проекта'],
  },
  {
    groupName: 'Требования',
    roles: ['Просмотр требований', 'Создание требований', 'Изменение требований', 'Удаление требований'],
  },

  {
    groupName: 'Атрибуты требований',
    roles: [
      'Просмотр атрибутов требований',
      'Создание, редактирование атрибутов требований',
      'Удаление атрибутов требований',
    ],
  },
  {
    groupName: 'Типы связей требований',
    roles: [
      'Просмотр типов связей требований',
      'Создание, редактирование типов связей требований',
      'Удаление типов связей требований',
    ],
  },
  {
    groupName: 'Типы требований',
    roles: ['Просмотр типов требований', 'Создание, редактирование типов требований', 'Удаление типов требований'],
  },
  {
    groupName: 'Группы пользователей',
    roles: [
      'Просмотр групп пользователей',
      'Создание, редактирование и удаление групп пользователей',
      'Включение и исключение пользователей в/из групп',
    ],
  },
  {
    groupName: 'Комментарии требований',
    roles: [
      'Создание комментариев требований',
      'Редактирование комментариев требований',
      'Удаление комментариев требований',
    ],
  },
];
