import { useState, useEffect } from 'react';
import { fetchViewsFor } from 'api/view';

export interface UseViewsInterface {
  views: any[];
  loadingViews: boolean;
}

export function useViews(id: string): UseViewsInterface {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    async function selectData(id2: string): Promise<void> {
      const result = await fetchViewsFor(id2);
      //console.log('useViews.result', result);
      setData(result);
      setLoading(false);
    }
    selectData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loadingViews: loading,
    views: data,
  };
}
