export default {
  table: {
    type: {
      requirement: 'Требование',
      module: 'Модуль',
      collection: 'Набор',
    },
    menu: {
      createArtifactBefore: 'Создать перед',
      createArtifactBefore0: 'Создать перед {{ count }} требованием',
      createArtifactBefore1: 'Создать перед {{ count }} требованиями',
      createArtifactBefore2: 'Создать перед {{ count }} требованиями',
      createArtifactAfter: 'Создать после',
      createArtifactAfter0: 'Создать после {{ count }} требованием',
      createArtifactAfter1: 'Создать после {{ count }} требований',
      createArtifactAfter2: 'Создать после {{ count }} требований',
      deleteArtifacts: 'Удалить требование',
      deleteArtifacts0: 'Удалить {{ count }} требование',
      deleteArtifacts1: 'Удалить {{ count }} требования',
      deleteArtifacts2: 'Удалить {{ count }} требований',
      linkArtifacts: 'Слинковать',
      linkArtifacts0: 'Слинковать {{ count }} требование',
      linkArtifacts1: 'Слинковать {{ count }} требования',
      linkArtifacts2: 'Слинковать {{ count }} требований',
    },
  },
  customLinkType: {
    title: 'Типы связей',
    columns: {
      title: 'Имя',
      label: 'Метки взаимосвязи',
      description: 'Описание',
    },
    addBtn: 'Добавить',
    deleteBtn: {
      title: 'Вы уверены?',
      disableTitle: 'Удалить системный тип связи невозможно.',
      ok: 'Да',
      cancel: 'Нет',
    },
  },
  customAttribute: {
    title: 'Атрибуты',
    columns: {
      title: 'Имя',
    },
  },
  customType: {
    title: 'Типы требований',
    columns: {
      title: 'Имя',
    },
  },
  ExportDocx: {
    ExportDocxView: {
      dropdown: {
        exportDocx: ' Экспорт требований в документ DOCX',
      },
    },
    title: 'Экспорт в документ DOCX',
    cancel: 'Отмена',
    ok: 'Экспорт',
    fields: {
      module: 'Модуль',
      includeComments: 'Включить комментарии',
      includeAttributes: 'Включить атрибуты',
      includeTitlesForInsertedArtifacts: 'Включить заголовки для вставленных требований',
      embeddedArtifactsBehavior: 'Поведение встроенных требований',
      moduleLayout: 'Разметка модуля',
    },
    moduleLayoutValue: {
      book: 'Книга',
      table: 'Таблица',
    },
    embeddedArtifactsBehaviorValue: {
      defaultEmbeddedBehavior: 'Стандартное встроенное поведение',
      maximizeAllEmbeddedArtifacts: 'Максимизировать все встроенные требования',
      minimizeAllEmbeddedArtifacts: 'Минимизировать все встроенные требования',
    },
  },
  ExportXlsx: {
    ExportXlsxView: {
      dropdown: {
        export: ' Экспорт требований',
      },
    },
    title: 'Экспорт',
    cancel: 'Отмена',
    ok: 'Экспорт',
    info: 'Выберите формат файла для экспорта',
  },
  QueryForm: {
    title: 'Тестирование SPARQL-запросов',
    cancel: 'Отмена',
    send: 'Отправить',
  },
  ArtifactCreateDialog: {
    createBtn: 'Создать требование',
    Drawer: {
      title: 'Создать новое требование',
      cancelBtn: 'Отмена',
      createBtn: 'Создать',
    },
  },
  FilterByFolder: {
    menu: {
      create: 'Создать папку',
      delete: 'Удалить папку',
      rename: 'Переименовать папку',
      move: 'Переместить папку',
    },
    assetFolder: 'Папка',
  },
  LockButtonsPanelLayout: {
    message: {
      lock: 'Требование взято на редактирование',
      unlock: 'Требование снято с редактирования',
    },
    saveBtn: 'Сохранить',
    editBtn: 'Редактировать',
    doneBtn: 'Готово',
    cancelBtn: 'Отменить',
  },
  EditorLinkType: {
    fields: {
      name: 'Наименование',
      description: 'Описание',
      URI: 'URI',
      outgoing: 'Исходящая',
      incoming: 'Входящая',
    },
    Tooltip: {
      URI:
        'Это значение однозначно идентифицирует ребование для связанных данных и является обязательным для межпроектных связей. Пример: https://hostname.com/domain/myLabel',
    },
    Checkbox: {
      SetRelationship: 'Укажите метки направления для отношений (например, родитель и потомок)',
    },
    ValidateMessages: {
      name: 'Пожалуйста введите наименование связи!',
    },
  },
  TypeLinkCreateDialogModalView: {
    title: {
      create: 'Создать новый тип связи',
      edit: 'Редактирование типа связи',
    },
    buttons: {
      cancel: 'Отмена',
      save: 'Сохранить',
    },
  },
  ProjectToolbar: {
    create: 'Создать',
    tabs: {
      all: 'Все',
      title: 'Требования',
      requirements: 'Требования',
      modules: 'Модули',
      collections: 'Наборы',
    },
  },
  ArtifactModuleToolbar: {
    create: 'Добавить',
  },
  ArtifactCollectionToolbar: {
    create: 'Добавить',
  },
  FilterByTag: {
    fullTextSearchValue: 'Содержит текст',
    add: 'Добавить',
  },
  CreateFilterModal: {
    title: 'Создание фильтра',
    add: 'Добавить',
    addAndClose: 'Добавить и закрыть',
    close: 'Отмена',
  },
  CreateFilter: {
    artifactType: 'Тип требования',
    emptyFilter: 'Пусто',
    choiceAtribute: 'Выберите атрибут',
    choiceValue: 'Выберите значение',
    resultFilter: 'Итоговый фильтр',
    inputOneOrMoreNumbers: 'Введите одно или более чисел (через символ [Пробел] или запятая)',
    contains: 'содержит',
    notContains: 'не содержит',
    equal: 'равняется',
    startWith: 'начинается с',
    endWith: 'заканчивается на',
    regEx: 'регулярное выражение',
    exist: 'сущестует',
    notExist: 'не сущестует',
    unassigned: 'не назначен',
    regExExample: 'Пример регулярного выражения: "^Привет"',
    regExStartString: '^ - Начало строки',
    regExEndString: '$ - Конец строки',
    regExDocTitle: 'Докуументация',
    regExDoc: 'Подробная инструкция по использованию на',
    regExDocLink: 'сайте документации',
    any: 'любой из',
    notAny: 'ни один из',
    each: 'каждый из',
    notEach: 'все кроме',
    searchValues: 'Поиск значения',
    dateEqual: 'в',
    dateNotEqual: 'не в',
    after: 'после (включая)',
    before: 'до (включая)',
    between: 'между',
    daysAgo: 'Дней назад',
    monthsAgo: 'Месяцев назад',
    yearsAgo: 'Лет назад',
    today: 'Сегодня',
    fromYesterdayToToday: 'Со вчера на сегодня',
    yesterday: 'Вчера',
    date: 'Дата',
  },
  HamburgerMenu: {
    titles: {
      import: 'Импорт требований',
      export: 'Экспорт требований',
      'attr-types-and-links-settings': 'Настройка типов атрибутов и связей',
      history: 'История изменений',
      'permissions-and-roles-settings': 'Настройки ролей и прав доступа',
      sparql: 'Тестирование SPARQL-запросов',
    },
  },
  CustomizeLinkTypeDialog: {
    title: 'Настройка атрибутов и связей',
    cancel: 'Отмена',
    ok: 'Сохранить',
  },
  PermissionsRoles: {
    title: 'Назначение прав доступа ролям',
    cancel: 'Отмена',
    ok: 'Сохранить',
  },
  PermissionsAndRolesDialog: {
    title: 'Настройка ролей и прав доступа',
    cancel: 'Отмена',
    ok: 'Сохранить',
  },
  ImportForm: {
    title: 'Импорт требований',
    Buttons: {
      close: 'Закрыть',
      import: 'Импорт',
    },
    Steps: {
      add: 'Добавить',
      setting: 'Настроить',
      start: 'Запустить',
      complete: 'Готово',
    },
    Tabs: {
      DocxTab: {
        title: 'Файл Docx',
      },
      CSVTab: {
        title: 'Файл CSV',
      },
      ReqifTab: {
        title: 'Файл Reqif',
      },
    },
    DocxView: {
      file: {
        label: 'Файл',
      },
      folder: {
        label: 'Расположение требований',
        placeholder: 'Выберите папку расположения требований',
      },
      createDocRadio: {
        label: 'Создать группировку?',
        radio: {
          yes: 'Импортировать текст, создать группирующий документ',
          no: 'Просто импортировать текст',
        },
      },
      docTitle: {
        label: 'Наименование документа',
        checkbox: 'Наименование файла',
      },
      docType: {
        label: 'Тип документа',
      },
      docFormat: {
        label: 'Формат документа',
      },
      headerType: {
        label: 'Тип заголовков',
      },
      headerFormat: {
        label: 'Формат заголовков',
      },
      headerPattern: {
        label: 'Шаблон номера заголовка',
        placeholder: 'Введите регулярное выражение',
      },
      paragraphType: {
        label: 'Тип абзацев',
      },
      paragraphFormat: {
        label: 'Формат абзацев',
      },
      delimitersTable: {
        label: 'Разделители текста',
        buttons: {
          add: 'Добавить разделитель',
          delete: {
            title: 'Удалить',
            confirm: 'Точно удалить?',
            ok: 'Да',
            cancel: 'Отмена',
          },
        },
        row: {
          startCell: 'Начинается',
          endCell: 'Заканчивается',
          typeCell: 'Тип требования',
          actionCell: 'Действие',
        },
      },
    },
    CsvView: {
      file: {
        label: 'Файл',
      },
      artifactsFolder: {
        label: 'Расположение требований',
        placeholder: 'Выберите папку расположения требований',
      },
      createModuleRadioValue: {
        label: 'Создавать модуль?',
        radio: {
          yes: 'Да, импортировать требования и создать модуль',
          no: 'Нет, просто импортировать требования',
        },
      },
      moduleName: {
        label: 'Наименование модуля',
        checkbox: 'Наименование файла',
      },
      moduleTypeValue: {
        label: 'Тип требования модуля',
      },
      createColumnsRadioValue: {
        label: 'Импортировать все столбцы?',
        radio: {
          yes: 'Да, импортировать все столбцы',
          no: 'Нет, импортировать только перечисленные',
        },
      },
      columnsImport: {
        label: 'Импортируемые столбцы',
        tooltip: 'Перечисление номеров столбцов через запятую. Пример: 0,2,4',
      },
    },
  },
};
