import React from 'react';
import { Form, Radio } from 'antd';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface SelectSearchFromFormItem {
  disabled?: boolean;
  onChange: (e: JsObject) => void;
  rest?: JsObject[];
}

export const SelectSearchFromFormItem: React.FC<SelectSearchFromFormItem> = ({
  disabled = false,
  onChange = () => {},
  ...rest
}: SelectSearchFromFormItem) => {
  return (
    <Form.Item label='Поиск по: '>
      <Radio.Group disabled={disabled} defaultValue='requirement' onChange={onChange}>
        <Radio.Button value='requirement'>Требованиям</Radio.Button>
        <Radio.Button value='module'>Модулям</Radio.Button>
        <Radio.Button value='collection'>Наборам</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};
