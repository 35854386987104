import { ArtifactTypeWidget } from './ArtifactTypeWidget';
import { ArtifactFormatWidget } from './ArtifactFormatWidget';
import { FolderWidget } from './FolderWidget';
import { TextareaWidget } from './TextareaWidget';
import { BaseInput } from './BaseInput';
import { DateTimeWidget } from './DateTimeWidget';
import { DividerWidget } from './DividerWidget';
import { DifficultyWidget } from './DifficultyWidget';
import { BusinessPriorityWidget } from './BusinessPriorityWidget';
import { StatusWidget } from './StatusWidget';
import { UserWidget } from './UserWidget';
import { TinyMCEWidget } from './TinyMCEWidget';
import { Widget } from 'react-jsonschema-form';
import { UriWithCopyWidget } from './UriWithCopyWidget';

/**
 * Default registered widgets.
 */
export const widgets: { [key: string]: Widget } = {
  // Override default react-jsonschema-form widgets
  BaseInput: BaseInput,
  TextareaWidget: TextareaWidget,
  DateTimeWidget: DateTimeWidget,
  // Custom
  ArtifactTypeWidget: ArtifactTypeWidget,
  ArtifactFormatWidget: ArtifactFormatWidget,
  FolderWidget: FolderWidget,
  DividerWidget: DividerWidget,
  DifficultyWidget: DifficultyWidget,
  BusinessPriorityWidget: BusinessPriorityWidget,
  StatusWidget: StatusWidget,
  UserWidget: UserWidget,
  TinyMCEWidget: TinyMCEWidget,
  UriWithCopyWidget: UriWithCopyWidget,
};
