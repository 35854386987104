import React from 'react';
import { Input } from 'antd';
import { Widget } from 'react-jsonschema-form';

export const TextareaWidget: Widget = ({ value, disabled, readonly, onChange = () => {} }) => {
  return (
    <Input.TextArea
      disabled={disabled || readonly}
      value={value}
      onChange={(e) => {
        console.log(e);
        onChange(e.target.value);
      }}
    />
  );
};
