import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Layout, Steps, Button, Modal, Tabs, Empty, message } from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
  DownOutlined,
} from '@ant-design/icons';

import { reqsImport, ReqsImportDataWithFile, ReqsImportFileData } from '../../api/import';

import { FileUpload } from '../files-upload';
import { DocxImportSettingsForm, ReqsImportDataWithKeyStatus } from './DocxImportSettingsForm';
import { CsvImportSettingsForm } from '../import-form-csv';
import { GlobalContext } from 'contexts/GlobalContext';
import { ProjectContext } from 'contexts/ProjectContext';

const TabPane = Tabs.TabPane;

interface SlidingTabsProps {
  stage: number;
  files: ReqsImportDataWithKeyStatusFile[];
  setMainInfo: Function;
  t: Function;
}

type ReqsImportDataWithKeyStatusFile = ReqsImportDataWithKeyStatus & ReqsImportFileData;

const SlidingTabs: React.FC<SlidingTabsProps> = ({ stage, files, setMainInfo, t }) => {
  function getColorForState(status: string): string {
    switch (status) {
      case 'exclamation-circle':
        return '#faad14';
      case 'check-circle':
        return '#52c41a';
      case 'close-circle':
        return '#ff0000';
      default:
        return '#faad14';
    }
  }

  function getIconForState(status: string) {
    switch (status) {
      case 'exclamation-circle':
        return <ExclamationCircleTwoTone twoToneColor={getColorForState(status)} />;
      case 'check-circle':
        return <CheckCircleTwoTone twoToneColor={getColorForState(status)} />;
      case 'close-circle':
        return <CloseCircleTwoTone twoToneColor={getColorForState(status)} />;
    }
  }

  function updateState(params: ReqsImportDataWithKeyStatus) {
    const changeStatusFiles = JSON.parse(JSON.stringify(files));
    const index = changeStatusFiles.findIndex(function (item: any) {
      return item.key === params.key;
    });
    if (index != -1) {
      changeStatusFiles[index] = {
        ...changeStatusFiles[index],
        ...params,
      };
    }
    setMainInfo(changeStatusFiles);
  }

  function getExtFile(fileName: string) {
    return fileName.split('.').pop();
  }

  return (
    <div>
      {stage === 0 ? (
        <Empty description={'Нет файлов для импорта'} />
      ) : (
        <Tabs defaultActiveKey='1' tabPosition='left'>
          {files.map((file) => {
            switch (getExtFile(file.fileName)) {
              case 'docx': {
                return (
                  <TabPane
                    tab={
                      <span>
                        {getIconForState(file.status)}
                        {t('ImportForm.Tabs.DocxTab.title')} {file.key}
                      </span>
                    }
                    key={file.key}>
                    <DocxImportSettingsForm
                      fileName={file.fileName}
                      fileKey={file.key}
                      updateState={updateState}
                      param={file}
                    />
                  </TabPane>
                );
              }
              case 'csv': {
                return (
                  <TabPane
                    tab={
                      <span>
                        {getIconForState(file.status)}
                        {t('ImportForm.Tabs.CSVTab.title')} {file.key}
                      </span>
                    }
                    key={file.key}>
                    <CsvImportSettingsForm fileName={file.file} fileKey={file.key} updateState={updateState} />
                  </TabPane>
                );
              }
              case 'reqifz': {
                return (
                  <TabPane
                    tab={
                      <span>
                        {getIconForState(file.status)}
                        {t('ImportForm.Tabs.ReqifTab.title')} {file.key}
                      </span>
                    }
                    key={file.key}></TabPane>
                );
              }
            }
          })}
        </Tabs>
      )}
    </div>
  );
};

const { Content } = Layout;
const Step = Steps.Step;

const progressStyle = {
  marginTop: 24,
  marginBottom: 24,
};

interface DocxImportViewProps {
  visible: boolean;
  onOk: Function;
  onCancel: Function;
}

export const DocxImportView: React.FC<DocxImportViewProps> = ({ visible, onOk, onCancel }: DocxImportViewProps) => {
  const { t } = useTranslation();

  const [stage, setStage] = useState(0 as number);
  const [mainInfo, setMainInfo] = useState<ReqsImportDataWithKeyStatusFile[]>([]);
  const [sizeMainInfo, setSizeMainInfo] = useState(1);
  const [loadingImportBtn, setLoadingImportBtn] = useState(false);
  const { provider, projectActiveFolder } = useContext(GlobalContext);
  const { refreshArtifacts } = useContext(ProjectContext);

  function checkStage() {
    if (mainInfo.length == 0) {
      setStage(0);
    } else {
      let allTabsSuccess = true;
      mainInfo.forEach((item: any) => {
        if (item.status != 'check-circle') {
          allTabsSuccess = false;
        }
      });
      if (allTabsSuccess) {
        setStage(2);
      } else {
        setStage(1);
      }
    }
  }

  useEffect(() => {
    checkStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainInfo]);

  const handleOk = (e: any) => {
    setLoadingImportBtn(true);
    console.log(mainInfo);
    const promises = mainInfo.map((fileInfo: ReqsImportDataWithFile) => {
      return reqsImport(fileInfo, provider)
        .then(() => {
          message.success(`${fileInfo.fileName} -- импорт прошел успешно.`);
        })
        .catch((err: Error) => {
          console.warn(`${fileInfo.fileName} -- импорт неудачен.`);
          console.warn(err);
          message.error(`${fileInfo.fileName} -- импорт неудачен.`);
        });
    });
    Promise.all(promises).then(() => {
      setStage(0);
      setMainInfo([]);
      setSizeMainInfo(1);
      setLoadingImportBtn(false);
      onOk();
      refreshArtifacts();
    });
  };

  const handleCancel = (e: any) => {
    setStage(0);
    setMainInfo([]);
    setSizeMainInfo(1);
    setLoadingImportBtn(false);
    onCancel();
  };

  function addFilesList(newFile: any) {
    let flag = true;
    mainInfo.forEach((item) => {
      if (item.fileName === newFile.name) {
        flag = false;
      }
    });
    if (flag) {
      setSizeMainInfo(sizeMainInfo + 1);
      const returnFileList = mainInfo.concat([
        {
          key: sizeMainInfo,
          status: 'exclamation-circle',
          file: newFile.response,
          fileName: newFile.name,
          createDoc: true,
          docFormat: 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Module',
          docType: 'cpgu:Document',
          headerPattern: '^[0-9]+(\\.[0-9]+)*[\\.]?[ \\t]+',
          headerFormat: 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Text',
          headerType: 'cpgu:Document',
          paragraphFormat: 'rmUserTypes:_YwcOsRmREemK5LEaKhoOow_Text',
          paragraphType: 'cpgu:Document',
          folder: projectActiveFolder,
        },
      ]);
      setStage(1);
      setMainInfo(returnFileList);
    }
  }

  const importState = () => {
    if (loadingImportBtn) {
      return <LoadingOutlined />;
    } else {
      return null;
    }
  };

  return (
    <Modal
      title={t('ImportForm.title')}
      width='90%'
      closable={false}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      footer={[
        <Button key='back' onClick={handleCancel} size='small'>
          {t('ImportForm.Buttons.close')}
        </Button>,
        <Button
          key='import'
          type='primary'
          onClick={handleOk}
          icon={<DownOutlined />}
          size='small'
          loading={loadingImportBtn}
          disabled={stage === 2 ? false : true}>
          {t('ImportForm.Buttons.import')}
        </Button>,
      ]}>
      <Content
        style={{
          background: '#fff',
          margin: 0,
        }}>
        <Row>
          <div style={{ height: 115 }}>
            <FileUpload addFilesList={addFilesList} />
          </div>
        </Row>
        <Row style={progressStyle}>
          <Col span={24}>
            <Steps size='small' current={stage}>
              <Step title={t('ImportForm.Steps.add')} />
              <Step title={t('ImportForm.Steps.setting')} />
              <Step title={t('ImportForm.Steps.start')} icon={importState()} />
              <Step title={t('ImportForm.Steps.complete')} />
            </Steps>
          </Col>
        </Row>
        <Row>
          <SlidingTabs stage={stage} files={mainInfo} setMainInfo={setMainInfo} t={t} />
        </Row>
      </Content>
    </Modal>
  );
};
