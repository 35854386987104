import { defaultLinks } from 'api/link';

/**
 * Retrieve links instances for specified requirement id.
 * @param id requirement identifier
 * @param graph the graph URI
 * @param url the repository URL
 */
export function selectLinks(id: string /*, graph: string, url: string*/) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(defaultLinks);
    }, 1000);
  });
}

function addLinkElement(linkData: any, type: any, id: string, icon: string, link: string) {
  const index = linkData.findIndex((i: any) => i.linkType === type);

  if (index !== -1) {
    linkData[index].links.push({ id: id, icon: icon, link: link });
  } else {
    linkData.push({
      linkType: type,
      links: [{ id: id, icon: icon, link: link }],
    });
  }
}

export function convertLinksToPanel(data: any, id: string) {
  const linkData: any = [];
  data.forEach((e: any) => {
    console.log('convertLinksToPanel.e', e);
    if (e.subject.identifier === id) {
      addLinkElement(linkData, e.subjectToObjectLabel, e.object.identifier, 'file-text', e.object.title);
    }
    if (e.object.identifier === id) {
      addLinkElement(linkData, e.objectToSubjectLabel, e.subject.identifier, 'file-text', e.subject.title);
    }
  });

  return linkData;
}

export function SelectLinksToPanel(id: string) {
  return selectLinks(id).then((data: any) => convertLinksToPanel(data, id));
}
