import React, { useContext } from 'react';
import { Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValueOfFilter } from 'hooks/useFilters';
import { FilterByFolder } from './FilterByFolder';
import { GlobalContext } from 'contexts/GlobalContext';
import { JsObject } from '@agentlab/sparql-jsld-client';

interface BodyOfFolderFilterProps {
  handleChange: (value: JsObject) => void;
  defaultValues: ValueOfFilter;
  type?: string;
}

export const BodyOfFolderFilter: React.FC<BodyOfFolderFilterProps> = ({ handleChange, defaultValues }) => {
  const { projectFolders } = useContext(GlobalContext);

  const onSelect = (folderUrl: string, folderName?: string): void => {
    handleChange({ value: [folderUrl], valueName: folderName ? [folderName] : [] });
  };

  return (
    <FilterByFolder
      folders={projectFolders}
      selection={defaultValues.value[0]}
      onSelect={onSelect}
      isDraggable={false}
    />
  );
};
